.SignIn {
  display: flex;
  min-height: 100vh;
  align-items: center;
  justify-content: center;
  padding: 1rem 0 1rem 0.5rem;

  .container {
    max-width: 1300px;
    margin: 0 auto;
  }

  .row {
    @media (min-width: 992px) {
      gap: 0;
      align-items: center;
      justify-content: center;
    }
  }

  &__container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 4rem;
    max-width: 1400px;
    width: 100%;
    align-items: center;
  }

  &__leftBanner {
    width: 100%;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    min-height: 54rem;

    @media (min-width: 992px) {
      max-width: 100%;
      margin: 0;
      padding-right: 0;
      transform: scale(1.05);
    }
  }

  @media (max-width: 991px) {
    padding: 2rem 1.6rem;

    &__container {
      grid-template-columns: 1fr;
      gap: 2rem;
      width: 100%;
      max-width: 450px;
      margin: 0 auto;
    }

    &__leftBanner {
      min-height: auto;
      max-height: 42rem;
      order: -1;
    }
  }

  @media (max-width: 757px) {
    padding: 1.6rem 0;

    &__container {
      gap: 1.6rem;
    }

    &__leftBanner {
      max-height: 32rem;
      background-size: cover;
      padding: 0 1.6rem;
    }
  }

  @media (max-width: 480px) {
    padding: 1.2rem 0;

    &__container {
      gap: 1.2rem;
    }

    &__leftBanner {
      max-height: 28rem;
      padding: 0 1rem;
    }
  }
}

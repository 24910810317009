@import './variables';
@import './grid_system';
@import './flexbox';

.container {
  --gutter-x: 3rem;

  padding-inline: calc(var(--gutter-x) * 0.5);
  margin-inline: auto;
}

.PricingFaq {
  padding: 2rem 0;
  padding-top: 6rem;
  padding-block-end: 9rem;
  background-color: #fff;

  &__title {
    font-size: 2rem;
    margin-bottom: 0.5rem;
    color: #000000;
    text-align: center;
  }

  &__subtitle {
    font-size: 1.6rem;
    color: #333;
    margin-bottom: 2rem;
    text-align: center;
  }
} 
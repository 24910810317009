.Summary {
  &__summary {
    background-color: var(--background-color);
    border: 1px solid rgb(226 232 240);
    border-radius: 0.8rem;
    padding: 1.6rem;
    margin-bottom: 2rem;
    font-family: Roboto;
    transition: all 0.2s ease;
    
    &:hover {
      border-color: rgb(203 213 225);
      box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.06);
      transform: translateY(-1px);
    }

    &-content {
      display: flex;
      align-items: center;
      gap: 1.2rem;
    }

    &-icon {
      color: rgb(37 99 235);
      font-size: 2rem;
    }

    &-info {
      flex: 1;
    }

    &-total {
      font-size: 1.4rem;
      font-weight: 600;
      color: rgb(51 65 85);
    }
  }

  &__stats {
    display: flex;
    padding: 1.2rem 1.6rem;
    background: linear-gradient(135deg, rgb(239 246 255 / 95%) 0%, rgb(219 234 254 / 95%) 100%);
    border-radius: 0.8rem;
    border: 1px solid rgb(191 219 254);
    margin-bottom: 2rem;
    width: 100%;
    backdrop-filter: blur(8px);
    transition: all 0.3s ease;

    @media (max-width: 1110px) {
      padding: 1.2rem;
    }

    &:hover {
      border-color: rgb(37 99 235);
      box-shadow: 0 4px 12px -2px rgba(37, 99, 235, 0.1);
      transform: translateY(-1px);
    }

    &-header {
      display: flex;
      align-items: center;
      gap: 1.2rem;
      width: 100%;
    }

    &-icon {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 3.2rem;
      height: 3.2rem;
      border-radius: 0.8rem;
      color: rgb(37 99 235);
      flex-shrink: 0;

      @media (max-width: 1110px) {
        width: 2.8rem;
        height: 2.8rem;
      }

      svg {
        width: 1.8rem;
        height: 1.8rem;
        
        @media (max-width: 1110px) {
          width: 1.6rem;
          height: 1.6rem;
        }
      }
    }

    &-info {
      flex: 1;
    }

    &-details {
      display: flex;
      align-items: center;
      gap: 1.6rem;

      @media (max-width: 1110px) {
        flex-direction: column;
        align-items: flex-start;
        gap: 0.4rem;
      }
    }

    &-item {
      font-family: roboto;
      font-size: 1.4rem;
      color: rgb(71 85 105);
      display: flex;
      align-items: center;
      gap: 0.6rem;

      @media (max-width: 1110px) {
        font-size: 1.3rem;
      }

      &--premium {
        &:first-child {
          font-weight: 600;
          font-size: 1.5rem;
          color: rgb(37 99 235);
          
          @media (max-width: 1110px) {
            font-size: 1.4rem;
            margin-bottom: 0.2rem;
          }
        }

        span.premium {
          font-weight: 600;
          background: linear-gradient(135deg, rgb(37 99 235) 0%, rgb(29 78 216) 100%);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        }
      }
    }
  }

  &__summary-container {
    background-color: var(--background-color);
    border: 1px solid #cccccc;
    border-radius: 0.8rem;
    padding: 1.6rem;
    margin-bottom: 2rem;
    font-family: Roboto;
    transition: all 0.2s ease;
    
    &:hover {
      border-color: var(--grayscale-color-200);
      box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.06);
      transform: translateY(-1px);
    }

    &__header {
      margin-bottom: 2rem;
      h3 {
        font-size: 1.7rem;
        font-weight: 600;
        color: #333333;
        margin: 0;
      }
    }

    &__text {
      font-size: 1.5rem;
      line-height: 1.6;

      p {
        color: #333333;
        margin-bottom: 2.4rem;
      }
    }
  }

  &__summary-section {
    margin-top: 2.4rem;
    padding-top: 2rem;
    border-top: 1px solid rgb(226 232 240);

    h4 {
      font-size: 1.6rem;
      font-weight: 600;
      color: #333333;
      margin-bottom: 1.2rem;
    }

    p {
      font-size: 1.5rem;
      line-height: 1.6;
      color: #333333;
      margin: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__summary-abstract {
    position: relative;
    
    &--preview {
      p {
        margin-bottom: 0;
        mask-image: linear-gradient(
          to bottom,
          black calc(100% - 12rem),
          transparent 100%
        );
      }
    }

    p {
      font-size: 1.5rem;
      line-height: 1.6;
      color: #333333;
      margin-bottom: 2.4rem;
    }
  }

  &__preview {
    background-color: var(--background-color);
    border: 1px solid #cccccc;
    border-radius: 0.8rem;
    padding: 1.6rem;
    text-align: center;
    transition: all 0.2s ease;
    
    &:hover {
      border-color: var(--grayscale-color-200);
      box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.06);
      transform: translateY(-1px);
    }
  }

  &__lock-message {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
    color: var(--grayscale-color-500);
    font-size: 1.4rem;
    margin-bottom: 0.8rem;

    .Summary__lock-icon {
      font-size: 1.2rem;
    }
  }

  &__preview-text {
    color: var(--grayscale-color-600);
    font-size: 1.4rem;
    margin: 0;
  }

  &__quiz-item {
    padding: 1.6rem;
    background-color: var(--background-color);
    border-radius: 0.8rem;
    border: 1px solid var(--grayscale-color-200);
    transition: all 0.2s ease;
    
    &:hover {
      border-color: var(--grayscale-color-300);
      transform: translateY(-1px);
      box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.06);
    }
  }

  &__quiz-header {
    display: flex;
    align-items: flex-start;
    gap: 1.2rem;
    padding-bottom: 1rem;
    border-bottom: 1px solid var(--grayscale-color-100);
    margin-bottom: 1.2rem;
  }

  &__quiz-number {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 2.4rem;
    height: 2.4rem;
    background-color: rgb(147 51 234 / 0.1);
    color: rgb(147 51 234);
    border-radius: 0.6rem;
    font-weight: 600;
    flex-shrink: 0;
  }

  &__quiz-title {
    flex: 1;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    gap: 1rem;

    h3 {
      font-size: 1.5rem;
      font-weight: 600;
      color: var(--grayscale-color-900);
      margin: 0;
      line-height: 1.4;
    }
  }

  &__quiz-difficulty {
    font-size: 1.2rem;
    font-weight: 500;
    padding: 0.3rem 0.8rem;
    border-radius: 0.4rem;
    text-transform: capitalize;
    flex-shrink: 0;

    &--easy {
      background-color: rgb(16 185 129 / 0.1);
      color: rgb(16 185 129);
    }

    &--medium {
      background-color: rgb(37 99 235 / 0.1);
      color: rgb(37 99 235);
    }

    &--hard {
      background-color: rgb(244 63 94 / 0.1);
      color: rgb(244 63 94);
    }
  }

  &__quiz-options {
    display: flex;
    flex-direction: column;
    gap: 0.8rem;
    margin-left: 3.6rem;
    margin-bottom: 1.6rem;
  }

  &__quiz-option {
    width: 100%;
    text-align: left;
    padding: 1rem 1.2rem;
    background-color: var(--background-color);
    border: 1px solid var(--grayscale-color-200);
    border-radius: 0.6rem;
    font-size: 1.4rem;
    color: var(--grayscale-color-700);
    transition: all 0.2s ease;
    cursor: pointer;

    &:hover {
      border-color: rgb(147 51 234 / 0.5);
      background-color: rgb(147 51 234 / 0.05);
    }

    &--correct {
      border-color: rgb(16 185 129);
      background-color: rgb(16 185 129 / 0.05);
      color: rgb(16 185 129);
      font-weight: 500;

      &:hover {
        border-color: rgb(16 185 129);
        background-color: rgb(16 185 129 / 0.1);
      }
    }
  }

  &__quiz-explanation {
    margin-left: 3.6rem;
    padding: 1rem 1.2rem;
    background-color: rgb(37 99 235 / 0.05);
    backdrop-filter: blur(8px);
    border: 1px solid rgb(37 99 235 / 0.1);
    border-radius: 0.6rem;

    p {
      font-size: 1.4rem;
      line-height: 1.5;
      color: var(--grayscale-color-700);
      margin: 0;
    }
  }

  &__quiz-count {
    background-color: rgb(147 51 234 / 0.1);
    color: rgb(147 51 234);
    padding: 0.5rem 1rem;
    border-radius: 0.5rem;
    margin-bottom: 1rem;
    font-weight: 500;
    display: inline-block;
  }

  &__abstract {
    background-color: var(--background-color);
    border: 1px solid #cccccc;
    border-radius: 0.8rem;
    padding: 1.6rem;
    margin-bottom: 2rem;
    
    &:hover {
      border-color: var(--grayscale-color-200);
      box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.06);
      transform: translateY(-1px);
    }

    &-title {
      font-size: 1.8rem;
      font-weight: 600;
      color: var(--grayscale-color-900);
      margin-bottom: 1.6rem;
    }

    &-content {
      font-size: 1.5rem;
      line-height: 1.6;
      color: var(--grayscale-color-600);
      margin: 0;
    }
  }

  &__section {
    background-color: var(--background-color);
    border: 1px solid #cccccc;
    border-radius: 0.8rem;
    padding: 1.6rem;
    margin-bottom: 2rem;
    transition: all 0.2s ease;
    
    &:hover {
      border-color: var(--grayscale-color-200);
      box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.06);
      transform: translateY(-1px);
    }

    &-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 1.2rem;
      cursor: pointer;
    }

    &-title {
      font-size: 1.6rem;
      font-weight: 600;
      color: var(--grayscale-color-900);
      margin: 0;
      flex: 1;
    }

    &-content {
      margin-top: 1.6rem;
      font-size: 1.5rem;
      line-height: 1.6;
      color: var(--grayscale-color-600);

      p {
        margin: 0;
      }
    }
  }

  &__toggle {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 2.4rem;
    height: 2.4rem;
    border-radius: 0.6rem;
    background-color: var(--grayscale-color-50);
    color: var(--grayscale-color-500);
    transition: all 0.2s ease;

    &:hover {
      background-color: var(--grayscale-color-100);
      color: var(--grayscale-color-700);
    }

    &-icon {
      font-size: 1.6rem;
      transition: transform 0.2s ease;

      &--expanded {
        transform: rotate(180deg);
      }
    }
  }

  &__preview-fade {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 8rem;
    background: linear-gradient(to bottom, transparent, var(--background-color) 70%);
    display: flex;
    align-items: flex-end;
    justify-content: center;
    padding-bottom: 1rem;
  }

  &__content-truncated {
    margin-top: 2rem;
    padding: 2rem;
    background-color: rgb(239 246 255);
    border: 1px solid rgb(191 219 254);
    border-radius: 0.8rem;
    transition: all 0.3s ease;

    &:hover {
      border-color: rgb(37 99 235);
      box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1);
    }
  }

  &__premium-banner {
    &-content {
      text-align: center;
    }

    &-header {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 0.8rem;
      margin-bottom: 0.8rem;

      h4 {
        font-size: 1.6rem;
        font-weight: 600;
        color: rgb(51 65 85);
        margin: 0;
      }
    }

    &-icon {
      width: 2rem;
      height: 2rem;
      color: rgb(37 99 235);
    }

    p {
      font-size: 1.4rem;
      color: rgb(71 85 105);
      margin: 0;
    }
  }
} 
.ReferralBanner {
  background: linear-gradient(135deg, var(--primary-color-400) 0%, var(--primary-color-500) 100%);
  color: white;
  width: 100%;
  z-index: 1000; // Assicura che sia sopra gli altri elementi
  position: relative;
  cursor: pointer;
  overflow: hidden; // Necessario per l'effetto shine

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: -100%;
    width: 100%;
    height: 100%;
    background: linear-gradient(
      90deg,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 0.2) 50%, 
      rgba(255, 255, 255, 0) 100%
    );
    animation: banner-shine 6s infinite;
  }
  
  @keyframes banner-shine {
    0% {
      left: -100%;
    }
    15% {
      left: 100%;
    }
    100% {
      left: 100%;
    }
  }
  
  &__content {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 20px;
    position: relative;
  }

  &__text-container {
    overflow: hidden;
  }

  // Stili per versione desktop e mobile
  &__text-desktop {
    font-weight: 500;
    font-size: 14px;
    margin: 0;
    color: white;
    display: block;
  }

  &__text-mobile {
    display: none; // Nascondi su desktop
  }

  // Aggiungo stile e animazione per l'icona ChevronRight
  .chevron-animation {
    animation: bounceRight 1.8s infinite;
    display: inline-block;
  }

  @keyframes bounceRight {
    0%, 100% {
      transform: translateX(0);
    }
    50% {
      transform: translateX(5px);
    }
  }

  // Aggiungo animazione per il testo scorrevole
  @keyframes marquee {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(calc(-50% - 25px)); // Calibrata con precisione per evitare scatti
    }
  }

  &__icon {
    margin-right: 8px;
    display: flex;
    align-items: center;
  }

  &__text {
    font-weight: 500;
    font-size: 14px;
    margin: 0;
    color: white;
    display: inline-block; // Assicura che gli span si comportino correttamente
  }

  &__button {
    border: none;
    background-color: white;
    color: var(--primary-color-500);
    cursor: pointer;
    font-size: 14px;
    padding: .4rem .8rem;
    border-radius: 5px;
    margin-left: .8rem;
    font-weight: 500;
    position: relative;
    overflow: hidden;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
    transition: all 0.2s ease;

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: -100%;
      width: 100%;
      height: 100%;
      background: linear-gradient(
        90deg,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0.05) 50%,
        rgba(0, 0, 0, 0) 100%
      );
      animation: button-shine 4s infinite;
    }
    
    @keyframes button-shine {
      0% {
        left: -100%;
      }
      20% {
        left: 100%;
      }
      100% {
        left: 100%;
      }
    }

    &:hover {
      background-color: white;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
      transform: translateY(-2px);
      color: var(--primary-color-600);
    }
  }

  &__close {
    background: none;
    border: none;
    color: white;
    cursor: pointer;
    font-size: 16px;
    padding: 0;
    margin-left: 16px;
    line-height: 1;
    opacity: 0.8;
    transition: opacity 0.2s;
    position: absolute;
    right: 20px;
    top: 50%;
    transform: translateY(-50%);
    z-index: 1001;
    
    &:hover {
      opacity: 1;
    }
  }

  // Responsive styles
  @media (max-width: 576px) {
    &__content {
      overflow: hidden;
      white-space: nowrap;
      padding-right: 35px; // Spazio extra per il pulsante di chiusura
    }

    &__text-container {
      overflow: hidden;
      max-width: calc(100% - 140px); // Spazio per icone, pulsanti e controlli
    }

    // Inverti visibilità tra versione desktop e mobile
    &__text-desktop {
      display: none; // Nascondi su mobile
    }

    &__text-mobile {
      display: block; // Mostra su mobile
      overflow: hidden;
      -webkit-mask-image: linear-gradient(to right, black 85%, transparent 100%);
      mask-image: linear-gradient(to right, black 85%, transparent 100%);
    }

    &__text {
      font-size: 12px;
      display: inline-block;
      white-space: nowrap;
      padding-right: 0;
    }

    // Creo un wrapper per l'animazione del testo
    &__text-marquee {
      display: inline-block;
      white-space: nowrap;
      animation: marquee 25s linear infinite; // Animazione più lenta e continua
      width: max-content;
      will-change: transform; // Ottimizza l'animazione usando l'accelerazione hardware
      transform: translateZ(0); // Forza l'accelerazione hardware per animazioni più fluide
    }

    &__icon {
      width: 30px;
      display: flex;
      align-items: center;
      flex-shrink: 0;
    }

    .chevron-animation {
      flex-shrink: 0;
    }
  }
}
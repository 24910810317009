.page-faculty {
    min-height: 100vh;
    background-color: var(--grayscale-color-50);
  }
  
  .faculty-page {
    padding-top: 20rem;
  
    &__hero {
      position: relative;
      padding: 0.1rem 0;
      text-align: center;
      overflow: hidden;
    }
  
    &__background-wrapper {
      position: absolute;
      inset: 0;
      width: 100vw;
      height: 100vh;
      left: 0;
      top: 0;
      z-index: 0;
      pointer-events: none;
      display: none;
  
      @media (min-width: 1024px) {
        display: block;
      }
    }
  
    &__background {
      position: absolute;
      inset: 0;
      z-index: -1;
  
      &-blur {
        position: absolute;
        width: 40rem;
        height: 40rem;
        border-radius: 50%;
        filter: blur(8rem);
        opacity: 0.3;
  
        &--1 {
          top: 25%;
          left: 25%;
          background-color: var(--primary-color-100);
        }
  
        &--2 {
          bottom: 25%;
          right: 25%;
          background-color: var(--secondary-color-100);
        }
      }
    }
  
    &__title {
      font-size: 4.8rem;
      font-weight: 700;
      color: var(--grayscale-color-800);
      margin-bottom: 2rem;
      line-height: 1.2;
      max-width: 80rem;
      margin-left: auto;
      margin-right: auto;
  
      @media (max-width: 768px) {
        font-size: 3.6rem;
      }
  
      &-brand {
        color: var(--primary-color-400);
      }
  
      &-highlight {
        color: #75b477;
      }
  
      &-accent {
        color: #30844F;
      }
  
      &-secondary {
        color: #30844F;
      }
    }
  
    &__subtitle {
      font-size: 2rem;
      color: var(--grayscale-color-600);
      max-width: 60rem;
      margin: 0 auto;
      line-height: 1.5;
  
      @media (max-width: 768px) {
        font-size: 1.8rem;
      }
    }
  
    &__content {
      padding: 6rem 2rem;
      position: relative;
      z-index: 1;
    }
  
    &__category-buttons {
      display: flex;
      justify-content: center;
      gap: 1.2rem;
      margin-bottom: 2rem;
  
      @media (max-width: 575px) {
        flex-direction: column;
        align-items: center;
        gap: 1rem;
      }
    }
  
    &__category-button {
      display: flex;
      align-items: center;
      gap: 0.8rem;
      padding: 1.2rem 1.8rem;
      border-radius: 10rem;
      font-size: 1.6rem;
      font-weight: 500;
      border: none;
      cursor: pointer;
      transition: all 0.3s ease;
      background-color: var(--grayscale-color-50);
      color: var(--grayscale-color-600);
  
      @media (max-width: 575px) {
        width: 100%;
        max-width: 30rem;
        justify-content: center;
      }
  
      &:hover {
        background-color: var(--grayscale-color-100);
      }
  
      &--active {
        background-color: var(--primary-color-500);
        color: white;
        box-shadow: 0 2px 8px rgba(104, 185, 132, 0.2);
  
        &:hover {
          background-color: var(--primary-color-600);
        }
      }
  
      &:last-child {
        padding-left: 1.6rem;
        padding-right: 1.6rem;
      }
    }
  
    &__grid {
      display: grid;
      gap: 3.2rem;
      padding: 2rem;
      
      &--university {
        grid-template-columns: repeat(3, 1fr);
  
        @media (max-width: 1200px) {
          grid-template-columns: repeat(2, 1fr);
        }
  
        @media (max-width: 768px) {
          grid-template-columns: 1fr;
        }
      }
  
      &--school {
        grid-template-columns: repeat(3, 1fr);
  
        @media (max-width: 1200px) {
          grid-template-columns: repeat(2, 1fr);
        }
  
        @media (max-width: 768px) {
          grid-template-columns: 1fr;
        }
      }
    }
  
    &__form-container {
      max-width: 600px;
      margin: 0 auto;
      background: var(--background-secondary);
      padding: 2rem;
      border-radius: 1rem;
      box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    }
  
    &__form-title {
      font-size: 1.5rem;
      font-weight: 600;
      margin-bottom: 2rem;
      text-align: center;
      color: var(--text-primary);
    }
  
    &__form {
      display: flex;
      flex-direction: column;
      gap: 1.5rem;
  
      .form-group {
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
  
        label {
          font-weight: 500;
          color: var(--text-primary);
        }
  
        input, select, textarea {
          padding: 0.75rem;
          border: 1px solid var(--border-color);
          border-radius: 0.5rem;
          background: var(--input-background);
          color: var(--text-primary);
          font-size: 1rem;
          transition: border-color 0.3s ease;
  
          &:focus {
            outline: none;
            border-color: var(--primary-color);
          }
  
          &::placeholder {
            color: var(--text-secondary);
          }
        }
  
        textarea {
          resize: vertical;
          min-height: 100px;
        }
      }
    }
  
    &__submit-button {
      background: var(--primary-color);
      color: white;
      padding: 1rem;
      border: none;
      border-radius: 0.5rem;
      font-size: 1rem;
      font-weight: 600;
      cursor: pointer;
      transition: background-color 0.3s ease;
  
      &:hover {
        background: var(--primary-color-dark);
      }
    }
  
    &__partner-section {
      margin-top: 6rem;
      padding: 4rem 0;
    }
  
    &__partner-container {
      max-width: 60rem;
      margin: 4rem auto;
      background: white;
      border-radius: 2rem;
      padding: 4rem;
      box-shadow: 0 4px 20px rgba(0, 0, 0, 0.05);
  
      @media (max-width: 768px) {
        padding: 2rem;
        margin: 2rem auto;
      }
    }
  
    &__partner-title {
      font-size: 3.2rem;
      font-weight: 700;
      text-align: center;
      color: var(--grayscale-color-800);
      margin-bottom: 4rem;
      line-height: 1.3;
  
      @media (max-width: 768px) {
        font-size: 2.4rem;
      }
    }
  
    &__partner-highlight {
      color: var(--primary-color-400);
    }
  
    &__partner-form {
      display: flex;
      flex-direction: column;
      gap: 2.4rem;
  
      .form-group {
        display: flex;
        flex-direction: column;
        gap: 0.8rem;
  
        label {
          font-size: 1.4rem;
          font-weight: 600;
          color: var(--grayscale-color-700);
          text-transform: uppercase;
          letter-spacing: 0.05em;
        }
  
        input, textarea {
          padding: 1.6rem;
          border: 1px solid var(--grayscale-color-200);
          border-radius: 1rem;
          font-size: 1.6rem;
          background: var(--grayscale-color-50);
          color: var(--grayscale-color-800);
          transition: all 0.3s ease;
  
          &:focus {
            outline: none;
            border-color: var(--primary-color-400);
            background: white;
          }
  
          &::placeholder {
            color: var(--grayscale-color-400);
          }
  
          &:disabled {
            opacity: 0.7;
            cursor: not-allowed;
          }
        }
  
        textarea {
          resize: vertical;
          min-height: 12rem;
        }
      }
    }
  
    &__partner-submit {
      width: 100%;
      padding: 1.6rem;
      font-size: 1.6rem;
      font-weight: 600;
      color: white;
      background: var(--primary-color-400);
      border: none;
      border-radius: 1rem;
      cursor: pointer;
      transition: all 0.3s ease;
  
      &:hover:not(:disabled) {
        background: var(--primary-color-500);
        transform: translateY(-2px);
      }
  
      &:disabled {
        opacity: 0.7;
        cursor: not-allowed;
      }
    }
  
    .form-status {
      padding: 1.6rem;
      border-radius: 1rem;
      font-size: 1.4rem;
      font-weight: 500;
      text-align: center;
  
      &--success {
        background: var(--success-light);
        color: var(--success);
        border: 1px solid var(--success);
      }
  
      &--error {
        background: var(--error-light);
        color: var(--error);
        border: 1px solid var(--error);
      }
    }
  }
  
  .faculty-card {
    position: relative;
  
    &__inner {
      position: relative;
      height: 100%;
      padding: 3.2rem;
      background-color: rgba(255, 255, 255, 0.8);
      backdrop-filter: blur(8px);
      border: 1px solid var(--primary-color-100);
      border-radius: 1.6rem;
      transition: all 0.3s ease;
      box-shadow: 0 4px 16px rgba(104, 185, 132, 0.1);
  
      &:hover {
        transform: translateY(-4px);
        box-shadow: 0 8px 24px rgba(104, 185, 132, 0.15);
      }
    }
  
    &__content {
      position: relative;
      z-index: 1;
    }
  
    &__icon {
      display: inline-flex;
      padding: 1.6rem;
      border-radius: 1.2rem;
      background-color: var(--primary-color-50);
      color: var(--primary-color-600);
      margin-bottom: 2.4rem;
    }
  
    &__title {
      font-size: 2.4rem;
      font-weight: 700;
      color: var(--grayscale-color-800);
      margin-bottom: 1.6rem;
  
      @media (max-width: 575px) {
        font-size: 2rem;
      }
    }
  
    &__description {
      font-size: 1.6rem;
      line-height: 1.6;
      color: var(--grayscale-color-600);
      margin-bottom: 2.4rem;
      min-height: 8rem;
  
      @media (max-width: 575px) {
        font-size: 1.4rem;
        min-height: auto;
      }
    }
  
    &__subjects {
      margin-bottom: 0;
    }
  
    &__subjects-title {
      font-size: 1.6rem;
      font-weight: 600;
      color: var(--grayscale-color-700);
      margin-bottom: 1.6rem;
  
      @media (max-width: 575px) {
        font-size: 1.4rem;
      }
    }
  
    &__subjects-list {
      display: flex;
      flex-wrap: wrap;
      gap: 1rem;
    }
  
    &__subject-tag {
      font-size: 1.4rem;
      font-weight: 500;
      padding: 0.8rem 1.6rem;
      border-radius: 10rem;
      background-color: var(--primary-color-50);
      color: var(--primary-color-700);
  
      @media (max-width: 575px) {
        font-size: 1.2rem;
        padding: 0.6rem 1.2rem;
      }
    }
  }
  
  // Utility class
  .faculty-container {
    width: 100%;
    max-width: 120rem;
    margin: 0 auto;
  }
  
  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateY(10px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  } 
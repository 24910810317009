.FilteredDocument {
  background: none;
  padding: 2.8rem 2.8rem;

  &__wrapper {
    transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
    
    &--selected {
      margin: .4rem;
      border-radius: 1.8rem;
      background: rgba(var(--background-color-rgb), 0.98);
      box-shadow: 0 0 0 2px #019d01,
                 0 12px 24px -4px rgba(0, 0, 0, 0.12),
                 0 6px 12px -4px rgba(0, 0, 0, 0.08);

      @media (max-width: 575px) {
        margin: .4rem;
        box-shadow: 0 0 0 2px #019d01,
                   0 12px 20px -4px rgba(0, 0, 0, 0.12),
                   0 6px 12px -4px rgba(0, 0, 0, 0.08);
      }
    }
    
    &--unselected {
      border-radius: 1.8rem;
      margin: .4rem;
      background: rgba(var(--background-color-rgb), 0.98);
      border: 1px solid var(--grayscale-color-200);
      box-shadow: 0 4px 8px -2px rgba(0, 0, 0, 0.08);
      
      &:hover {
        border-color: var(--grayscale-color-300);
        box-shadow: 0 12px 24px -4px rgba(0, 0, 0, 0.12),
                   0 6px 12px -4px rgba(0, 0, 0, 0.08);
      }

      @media (max-width: 575px) {
        margin: .4rem;
        box-shadow: 0 4px 8px -2px rgba(0, 0, 0, 0.08);
        
        &:hover {
          box-shadow: 0 6px 12px -4px rgba(0, 0, 0, 0.12);
        }
      }
    }
  }

  padding: 2.4rem 2.8rem;

  @media (max-width: 575px) {
    padding: 2.2rem;
  }

  &__inner {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    gap: 2.8rem;

    @media (max-width: 575px) {
      flex-direction: row;
      align-items: flex-start;
      gap: 1.6rem;
    }
  }

  &__left {
    display: flex;
    column-gap: 2.4rem;
    flex: 1;
    align-items: flex-start;
    padding-top: 0;

    &-icon {
      color: var(--primary-color-600);
      width: 44px;
      height: 44px;
      flex-shrink: 0;
      margin-top: 0.2rem;

      @media (max-width: 575px) {
        display: none;
      }
    }

    &-image {
      width: 44px;
      height: 44px;
      flex-shrink: 0;
      margin-top: 0.2rem;

      @media (max-width: 575px) {
        display: none;
      }
    }
  }

  &__left-icon {
    @media (max-width: 575px) {
      display: none;
    }
  }

  &__box {
    position: relative;
    flex: 1;
    padding-top: 0;

    h5 {
      font-size: 1.7rem;
      font-weight: 600;
      color: var(--grayscale-color-900);
      margin-bottom: 0.8rem;
      line-height: 1.5;
      transition: color 0.2s ease;

      @media (max-width: 575px) {
        font-size: 1.6rem;
        margin-bottom: 0.8rem;
        line-height: 1.5;
      }

      .FilteredDocument__wrapper--selected & {
        color: var(--grayscale-color-950);
      }
    }

    &-text {
      font-family: roboto;
      font-size: 1.5rem;
      color: var(--grayscale-color-600);
      margin: 0;
      max-width: 52rem;
      line-height: 1.6;

      @media (max-width: 575px) {
        font-size: 1.4rem;
        line-height: 1.6;
        -webkit-line-clamp: 3;
      }
    }
  }

  &__actions {
    display: flex;
    align-items: center;
    gap: .5rem;

    @media (max-width: 575px) {
      display: flex;
      padding-right: 0;
    }
  }

  &__mobile-wrapper {
    display: flex;
    gap: 1.4rem;
    padding-left: .4rem;

    @media (max-width: 575px) {
      padding-left: 0;
      margin-top: 1rem;
      gap: 1.2rem;
    }
  }
  
  &__mobile-inner {
    display: flex;
    align-items: center;
    gap: 1rem;
    
    @media (max-width: 575px) {
      display: flex;
      align-items: center;
      gap: 0.8rem;
    }
  }

  &__delete-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--background-color);
    border: 1.5px solid var(--grayscale-color-200);
    border-radius: 1rem;
    padding: 0.6rem;
    min-width: 2.8rem;
    min-height: 2.8rem;
    color: var(--grayscale-color-600);
    cursor: pointer;
    transition: all 0.2s ease;
    
    &:hover {
      border-color: var(--danger-color-500);
      background-color: var(--danger-color-50);
      color: var(--danger-color-600);
    }
    
    &:active {
      transform: scale(0.95);
      background-color: var(--danger-color-100);
    }
    
    @media (max-width: 575px) {
      display: none;
      padding: 0.6rem;
    }
    
    &--mobile {
      display: none;
      padding: 0.6rem;
      min-width: 2.4rem;
      min-height: 2.4rem;
      
      @media (max-width: 575px) {
        display: flex;
        order: -1; /* Assicura che venga prima dell'input */
      }
    }

    &__delete-btn-icon {
      color: var(--danger-color-600);
    }
  }


  &__field {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    gap: .2rem;
    
  }

  &__input {
    width: fit-content;
    height: fit-content;
    border: 1.5px solid var(--grayscale-color-200);
    border-radius: 1rem;
    padding: 1.6rem;
    position: relative;
    cursor: pointer;
    transition: all 0.2s ease;
    background: var(--background-color);
    min-width: 2.8rem;
    min-height: 2.8rem;
    display: flex;
    align-items: center;
    justify-content: center;
    
    @media (max-width: 575px) {
      padding: 1.6rem;
      min-width: 2.6rem;
      min-height: 2.6rem;
    }

    &:checked {
      border-color: var(--primary-color-500);
      background-color: var(--primary-color-500);
    }
    
    &:is(:checked) {
      &::after {
        content: "";
        position: absolute;
        width: 0.6rem;
        height: 1.1rem;
        border: solid white;
        border-width: 0 2.2px 2.2px 0;
        transform: rotate(45deg);
        margin-top: -1px;

        @media (max-width: 575px) {
          width: 0.6rem;
          height: 1.1rem;
          border-width: 0 2.2px 2.2px 0;
          margin-top: -1px;
        }
      }
    }

    &:hover:not(:checked) {
      border-color: var(--grayscale-color-400);
      background-color: var(--grayscale-color-50);
    }
  }

  &__mobile-checkbox {
    display: none;
    text-align: center;
    padding-top: 1rem;

    @media (max-width: 575px) {
      display: none; // Nascondiamo il checkbox mobile duplicato
    }
  }
  
  &__label {
    margin-block: 0;
  }

  &__tags {
    display: flex;
    gap: 1rem;
    align-items: center;
    margin-block: 1.8rem 0.2rem;
    padding-left: 6.5rem;
    padding-right: 6rem;
    overflow-x: auto;
    overflow-y: hidden;
    scrollbar-width: none;
    -webkit-overflow-scrolling: touch;
    position: relative;
    padding-bottom: 1.2rem;
    
    @media (max-width: 575px) {
      margin-block: 0.6rem 0;
      padding-left: 0;
      padding-right: 1.6rem;
      padding-bottom: 1rem;
      gap: 0.8rem;
    }
    
    &::-webkit-scrollbar {
      display: none;
    }
    
    &::after {
      content: '';
      position: absolute;
      left: 4.8rem;
      right: 6rem;
      bottom: 0;
      height: 3px;
      background: var(--grayscale-color-100);
      border-radius: 1.5px;
      opacity: 0;
      transition: opacity 0.3s ease;
      
      @media (max-width: 700px) {
        left: 0;
        right: 0;
      }
    }
    
    &::before {
      content: '';
      position: absolute;
      left: 4.8rem;
      bottom: 0;
      height: 3px;
      width: 20%;
      background: var(--primary-color-200);
      border-radius: 1.5px;
      opacity: 0;
      transition: opacity 0.3s ease;
      z-index: 1;
      
      @media (max-width: 700px) {
        left: 0;
      }
    }
    
    &:active::after,
    &:active::before {
      opacity: 1;
    }
    
    @media (hover: none) {
      &::-webkit-scrollbar {
        display: none;
      }
      
      &.scrolling::after,
      &.scrolling::before {
        opacity: 1;
      }
    }

    @media (max-width: 700px) {
      padding-left: 0rem;
      padding-right: 0rem;
    }
  }

  &__tag {
    display: inline-flex;
    white-space: nowrap;
    align-items: center;
    gap: 0.5rem;
    padding: 0.6rem 1.6rem;
    font-size: 1.4rem;
    font-weight: 500;
    border-radius: 0.9rem;
    transition: all 0.2s ease;
    border: 1px solid;

    @media (max-width: 575px) {
      padding: 0.5rem 1.4rem;
      font-size: 1.35rem;
      border-radius: 0.8rem;
      gap: 0.5rem;
    }
  }
}
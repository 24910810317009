.Chat {
  position: relative;


  .container {
    max-width: 12000px;
    margin: 0 auto;
  }

  .row {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }

  .justify-center {
    justify-content: center;
}
}

.CreateSubfolderTile {
  cursor: pointer;
  transition: transform 0.2s ease-in-out;
  margin-bottom: 16px;
  
  &:hover {
    transform: translateY(-2px);
  }
  
  &__card {
    background-color: var(--card-background);
    border-radius: 8px;
    padding: 16px;
    height: 100%;
    min-height: 150px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    box-shadow: var(--card-shadow);
    border: 1px dashed var(--border-color);
    
    &:hover {
      border-color: var(--primary-color);
      background-color: var(--card-background-hover);
    }
  }
  
  &__icon-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50px;
    height: 50px;
    background-color: rgba(var(--primary-rgb), 0.1);
    border-radius: 50%;
    margin-bottom: 16px;
  }
  
  &__icon {
    color: var(--primary-color);
  }
  
  &__text {
    font-size: 1rem;
    font-weight: 500;
    color: var(--text-color);
    margin: 0;
  }
} 
.NavItemIcon {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-column-gap: 0.5rem;
  -moz-column-gap: 0.5rem;
  column-gap: 0.7rem;

  &--active {
    color: inherit !important;
    background-color: var(--primary-color-400) !important;
  }
}

.SubscriptionModes {
  &__sub-mode-container {
    margin-bottom: 4rem;
    &__inner-container {
      display: flex;
      column-gap: 1rem;
      font-size: 1.7rem;
      font-weight: 500;
      font-family: 'Roboto';
      padding-block: .4rem;
      padding-inline: .5rem;
      border-radius: .7rem;
      background: #e2e5e5;
      margin-bottom: 1rem;
      max-width: fit-content;
      margin: auto;

      &__mode {
        border-radius: .6rem;
        padding-inline: 1rem;
        padding-block: .8rem;
        cursor: pointer;
        
        &:hover {
          background-color: #f8f8f8;
          transition: ease-in-out;
          transition-duration: .2s;
          box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1019607843), 0 2px 4px -1px rgba(0, 0, 0, 0.0588235294);
        }
        
        &-active {
          box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1019607843), 0 2px 4px -1px rgba(0, 0, 0, 0.0588235294);
          background-color: white;
        }
      }

      &__annual-sales {
        margin-left: 1rem;
        background: var(--primary-color-500);
        padding-inline: 1.3rem;
        color: white;
        font-family: 'Roboto';
        border-radius: .6rem;
        padding-block: .2rem;
      }
    }
  }
}
.Logo {
  max-width: 180px;
  user-select: none;
  pointer-events: none;

  &__image {
    width: 100%;
    height: 100%;
  }
}

.UploadNotes {
  padding-block: 6.4rem;

  &__wrapper {
    display: flex;
    column-gap: 4rem;

    @media (max-width:1000px) {
      flex-direction: column;
      justify-content: center;
      align-items: center;
      row-gap: 4rem;
    }
  }
}

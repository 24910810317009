.MaterialsSummary {
  display: flex;
  flex-direction: column;
  gap: 1.2rem;
  margin-top: 1rem;
  
  @media (max-width: 768px) {
    gap: 1rem;
  }

  &__wrapper {
    display: flex;
    flex-wrap: wrap;
    gap: 1.6rem;
    min-height: 100vh;
    
    @media (max-width: 768px) {
      flex-direction: column;
      gap: 1rem;
      min-height: auto;
    }
  }

  &__sidebar {
    width: 32rem;
    display: flex;
    flex-direction: column;
    gap: 1.6rem;
    position: sticky;
    top: 2rem;
    align-self: flex-start;
    max-height: calc(100vh - 4rem);
    
    @media (max-width: 768px) {
      width: 100%;
      gap: 1.2rem;
      position: static;
      max-height: none;
    }
  }

  &__container {
    flex: 1;
    min-width: 0;
    border: 1px solid rgb(218, 218, 218);
    border-radius: 1.2rem;
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.05);
    overflow: hidden;
    background-color: var(--background-color);
    transition: box-shadow 0.3s ease;
    position: relative;

    &:hover {
      box-shadow: 0 6px 12px -2px rgba(0, 0, 0, 0.1);
    }

    &--free {
      &::after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 20rem;
        background: linear-gradient(
          to bottom,
          transparent,
          var(--background-color) 90%
        );
        pointer-events: none;
        opacity: 0.9;
        transition: opacity 0.3s ease;
      }

      &:hover::after {
        opacity: 1;
      }
    }
  }

  &__content {
    padding: 1.6rem;

    @media (max-width: 768px) {
      padding: 1.2rem;
    }
  }

  &__stats {
    display: inline-flex;
    padding: 1.2rem;
    background-color: var(--grayscale-color-50);
    border-radius: 0.8rem;
    border: 1px solid var(--grayscale-color-200);
    align-self: flex-start;
    transition: all 0.3s ease;
    margin-bottom: 0.8rem;
    margin-top: 1rem;

    @media (max-width: 768px) {
      width: 100%;
      padding: 1rem;
      margin-bottom: 0.6rem;
      margin-top: 0.8rem;
    }

    &:hover {
      box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1);
      border-color: rgb(37 99 235);
    }

    &-header {
      display: flex;
      align-items: center;
      gap: 1.6rem;
      width: 100%;

      @media (max-width: 768px) {
        gap: 1.2rem;
      }
    }

    &-icon {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 3.8rem;
      height: 3.8rem;
      border-radius: 0.8rem;
      background-color: var(--background-color);
      color: rgb(37 99 235);
      flex-shrink: 0;
      cursor: pointer;
      transition: all 0.2s ease;
      border: 1px solid var(--grayscale-color-200);
      position: relative;
      overflow: hidden;

      &--disabled {
        color: var(--grayscale-color-400);
        cursor: not-allowed;
        
        &:hover {
          border-color: var(--grayscale-color-300);
          
          &::before {
            transform: translateY(100%);
          }
          
          svg {
            color: var(--grayscale-color-400);
          }
        }
      }

      &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgb(37 99 235);
        transform: translateY(100%);
        transition: transform 0.2s ease;
      }

      svg {
        position: relative;
        z-index: 1;
        transition: all 0.2s ease;
      }

      &:hover:not(&--disabled) {
        border-color: rgb(37 99 235);

        &::before {
          transform: translateY(0);
        }

        svg {
          color: white;
        }
      }
    }

    &-info {
      display: flex;
      align-items: center;
      gap: 1.4rem;
      flex: 1;
      min-width: 0;

      @media (max-width: 768px) {
        gap: 1rem;
        overflow-x: auto;
        scrollbar-width: none;
        -ms-overflow-style: none;
        &::-webkit-scrollbar {
          display: none;
        }
      }
    }

    &-details {
      display: flex;
      align-items: center;
      gap: 1.6rem;
      flex-wrap: nowrap;
      flex: 1;

      @media (max-width: 768px) {
        gap: 1.2rem;
      }
    }

    &-item {
      display: flex;
      align-items: center;
      gap: 0.6rem;
      font-family: roboto;
      font-size: 1.5rem;
      font-weight: 600;
      color: var(--grayscale-color-950);
      white-space: nowrap;
      padding-right: 1.6rem;
      border-right: 1px solid var(--grayscale-color-200);
      flex-shrink: 0;

      @media (max-width: 768px) {
        font-size: 1.3rem;
        padding-right: 1.2rem;
        gap: 0.4rem;
      }

      &:first-child {
        font-weight: 600;
      }

      &:last-child {
        padding-right: 0;
        border-right: none;
      }

      &--premium {
        color: rgb(37 99 235);
        font-weight: 500;
      }

      svg {
        color: var(--grayscale-color-600);
        @media (max-width: 768px) {
          width: 1.6rem;
          height: 1.6rem;
        }
      }
    }
  }

  &__stats-item {
    display: flex;
    align-items: center;
    gap: 0.8rem;
    font-family: roboto;
    font-size: 1.4rem;
    color: var(--grayscale-color-600);
    
    strong {
      color: var(--grayscale-color-950);
      font-weight: 600;
    }

    svg {
      color: var(--grayscale-color-950);
      @media (max-width: 768px) {
        width: 1.6rem;
        height: 1.6rem;
      }
    }

    &--premium {
      color: rgb(37 99 235);
      font-weight: 500;
      
      strong {
        font-weight: 600;
        color: rgb(37 99 235);
      }
    }
  }

  &__abstract {
    margin-bottom: 2rem;
    padding: 2rem;
    background-color: var(--grayscale-color-50);
    border-radius: 1.2rem;
    border: 1px solid var(--grayscale-color-200);
    transition: all 0.3s ease;

    @media (max-width: 768px) {
      margin-bottom: 1.6rem;
      padding: 1.4rem;
    }

    &:hover {
      box-shadow: 0 6px 12px -2px rgba(0, 0, 0, 0.1);
      border-color: rgb(37 99 235);
    }

    &--partial {
      position: relative;
      background-color: var(--background-color);
      border: 1px solid var(--grayscale-color-200);
      transition: all 0.25s ease;
      
      &:hover {
        box-shadow: 0 6px 12px -2px rgba(0, 0, 0, 0.1);
        border-color: rgb(37 99 235);
      }
      
      &::after {
        display: none;
      }

      .MaterialsSummary__section-title {
        color: var(--grayscale-color-950);
      }
      
      .MaterialsSummary__text {
        color: var(--grayscale-color-800);
      }
    }
  }

  &__section {
    margin-bottom: 2rem;
    padding: 2rem;
    background-color: var(--background-color);
    border-radius: 1.2rem;
    border: 1px solid var(--grayscale-color-200);
    transition: all 0.3s ease;
    position: relative;

    @media (max-width: 768px) {
      margin-bottom: 1.2rem;
      padding: 1.4rem;
    }

    &:hover {
      box-shadow: 0 6px 12px -2px rgba(0, 0, 0, 0.1);
      border-color: rgb(37 99 235);
    }

    &:last-child {
      margin-bottom: 0;
    }

    &--locked {
      background-color: rgb(240 249 255 / 75%);
      border: 1px dashed rgb(59 130 246 / 55%);
      position: relative;
      transition: all 0.25s ease;

      &:hover {
        background-color: rgb(240 249 255 / 90%);
        border-color: rgb(59 130 246 / 70%);
        box-shadow: 0 4px 6px -1px rgba(37, 99, 235, 0.1);
      }

      .MaterialsSummary__section-title {
        color: var(--grayscale-color-800);
        display: flex;
        align-items: center;
        gap: 1.2rem;
      }

      .MaterialsSummary__text {
        color: var(--grayscale-color-700);
      }
    }

    &--partial {
      position: relative;
      background-color: var(--background-color);
      border: 1px solid var(--grayscale-color-200);
      transition: all 0.25s ease;
      
      &:hover {
        box-shadow: 0 6px 12px -2px rgba(0, 0, 0, 0.1);
        border-color: rgb(37 99 235);
      }

      &::after {
        display: none;
      }

      .MaterialsSummary__section-title {
        color: var(--grayscale-color-950);
      }
      
      .MaterialsSummary__text {
        color: var(--grayscale-color-800);
      }
    }
  }

  &__section-title {
    font-family: roboto;
    font-size: 2.2rem;
    font-weight: 600;
    color: var(--grayscale-color-950);
    margin-bottom: 1.6rem;
    padding-bottom: 1rem;
    border-bottom: 2px solid var(--grayscale-color-200);
    position: relative;

    @media (max-width: 768px) {
      font-size: 1.8rem;
      margin-bottom: 1.2rem;
      padding-bottom: 0.8rem;
    }

    &::after {
      content: '';
      position: absolute;
      bottom: -2px;
      left: 0;
      width: 30%;
      height: 2px;
      background-color: rgb(37 99 235);
    }
  }

  &__text {
    font-family: roboto;
    font-size: 1.7rem;
    line-height: 1.8;
    color: var(--grayscale-color-800);
    white-space: pre-wrap;
    letter-spacing: 0.02em;
    position: relative;

    @media (max-width: 768px) {
      font-size: 1.5rem;
      line-height: 1.6;
    }
  }

  &__visuals {
    margin-top: 2rem;
    padding: 1.5rem;
    background-color: var(--grayscale-color-50);
    border-radius: 0.8rem;
    border: 1px solid var(--grayscale-color-200);
    transition: all 0.3s ease;

    &:hover {
      border-color: rgb(37 99 235);
      background-color: var(--grayscale-color-100);
    }

    p {
      font-family: roboto;
      font-size: 1.5rem;
      color: var(--grayscale-color-700);
      margin: 0;
      font-style: italic;
      line-height: 1.6;
    }

    &--locked {
      opacity: 0.9;
      background-color: rgb(240 249 255 / 75%);
      border: 1px dashed rgb(59 130 246 / 55%);
      transition: all 0.25s ease;
      padding: 1.2rem;
      margin-top: 1.6rem;

      &:hover {
        background-color: rgb(240 249 255 / 90%);
        border-color: rgb(59 130 246 / 70%);
      }

      .MaterialsSummary__content-truncated {
        margin-top: 0;
        background-color: transparent;
        border: none;
        padding: 0;
      }
      
      .MaterialsSummary__preview-fade {
        padding: 0;
        margin-top: 0;
      }
    }
  }

  &__title {
    font-family: roboto;
    padding: 2rem;
    border-bottom: 1px solid rgb(218, 218, 218);
    margin: 0;
  }

  &__loading {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 40rem;
    width: 100%;
    gap: 1rem;

    p {
      font-family: roboto;
      font-size: 1.4rem;
      color: var(--grayscale-color-600);
      margin: 0;
    }
  }

  &__no-content {
    text-align: center;
    padding: 4rem;
    font-family: roboto;
    font-size: 1.4rem;
    color: var(--grayscale-color-600);
    font-style: italic;
    margin: 0;
    min-height: 40rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__premium-banner {
    display: flex;
    align-items: center;
    margin-bottom: 1.6rem;
    background: linear-gradient(135deg, rgb(219 234 254 / 50%) 0%, rgb(219 234 254 / 80%) 100%);
    border: 1px solid rgb(147 197 253 / 60%);
    border-radius: 1.2rem;
    backdrop-filter: blur(8px);
    transition: all 0.3s ease;
    overflow: hidden;
    
    &:hover {
      border-color: rgb(147 197 253 / 80%);
      box-shadow: 0 8px 24px -4px rgba(37, 99, 235, 0.1);
      transform: translateY(-1px);
    }
    
    &-content {
      flex: 1;
      display: flex;
      align-items: center;
      gap: 2rem;
      padding: 1.4rem 2rem;

      @media (max-width: 768px) {
        flex-direction: column;
        align-items: flex-start;
        gap: 1.2rem;
        padding: 1.6rem;
      }
    }
    
    &-header {
      display: flex;
      align-items: center;
      gap: 1rem;
      margin: 0;
      min-width: max-content;
      
      h6 {
        font-family: roboto;
        font-size: 1.6rem;
        font-weight: 600;
        color: rgb(37 99 235);
        margin: 0;
      }
    }
    
    &-icon {
      font-size: 2rem;
      color: rgb(37 99 235);
    }
    
    p {
      font-family: roboto;
      font-size: 1.4rem;
      line-height: 1.5;
      color: rgb(30 58 138);
      margin: 0;
      flex: 1;
    }
    
    &-button {
      display: inline-flex;
      align-items: center;
      gap: 0.8rem;
      padding: 1rem 2rem;
      background-color: rgb(37 99 235);
      border: none;
      border-radius: 2.4rem;
      cursor: pointer;
      transition: all 0.3s ease;
      white-space: nowrap;
      min-width: max-content;
      position: relative;
      overflow: hidden;
      
      &::before {
        content: '';
        position: absolute;
        top: 0;
        left: -100%;
        width: 100%;
        height: 100%;
        background: linear-gradient(
          90deg,
          rgba(255, 255, 255, 0) 0%,
          rgba(255, 255, 255, 0.2) 50%,
          rgba(255, 255, 255, 0) 100%
        );
        animation: button-shine 6s infinite;
      }
      
      @keyframes button-shine {
        0% {
          left: -100%;
        }
        15% {
          left: 100%;
        }
        100% {
          left: 100%;
        }
      }
      
      &:hover {
        background-color: rgb(29 78 216);
        transform: translateY(-1px);
        box-shadow: 0 4px 12px -2px rgba(37, 99, 235, 0.3);
      }
      
      &:active {
        transform: translateY(0);
      }
      
      span {
        font-family: roboto;
        font-size: 1.4rem;
        font-weight: 500;
        color: white;
      }
      
      &-icon {
        font-size: 1.6rem;
        color: white;
        transition: transform 0.3s ease;
      }
      
      &:hover &-icon {
        transform: translateX(4px);
      }
    }
    
    @media (max-width: 768px) {
      margin-bottom: 2rem;
      
      &-button {
        width: 100%;
        justify-content: center;
      }
    }
  }

  &__section-lock {
    color: rgb(37 99 235);
    font-size: 2rem;
    flex-shrink: 0;
  }

  &__content-truncated {
    display: flex;
    align-items: center;
    gap: 1rem;
    margin-top: 2rem;
    padding: 1.2rem 1.6rem;
    background-color: var(--grayscale-color-50);
    border-radius: 0.8rem;
    border: 1px dashed var(--grayscale-color-300);
    transition: all 0.3s ease;

    svg {
      color: var(--grayscale-color-400);
      font-size: 1.8rem;
      flex-shrink: 0;
    }

    span {
      font-family: roboto;
      font-size: 1.4rem;
      color: var(--grayscale-color-600);
      font-style: italic;
    }

    &:hover {
      background-color: var(--grayscale-color-100);
      border-color: var(--grayscale-color-400);

      svg {
        color: var(--grayscale-color-500);
      }

      span {
        color: var(--grayscale-color-700);
      }
    }
  }

  &__preview-fade {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 1.6rem 0 0 0;
    margin-top: 1.6rem;
    border-top: 1px dashed var(--grayscale-color-200);
  }

  &__preview-text {
    display: inline-flex;
    align-items: center;
    gap: 0.8rem;
    padding: 0.8rem 1.2rem;
    background-color: rgb(240 249 255 / 75%);
    border: 1px dashed rgb(59 130 246 / 55%);
    border-radius: 0.8rem;
    transition: all 0.25s ease;
    font-family: roboto;
    font-size: 1.4rem;
    color: rgb(37 99 235);
    font-style: italic;
    
    &:hover {
      background-color: rgb(240 249 255 / 90%);
      border-color: rgb(59 130 246 / 70%);
    }
  }

  &__audio-container {
    display: none;
  }

  // Stili per il banner inline
  &__inline-banner {
    display: flex;
    align-items: center;
    margin-bottom: 1.6rem;
    background: linear-gradient(135deg, rgb(219 234 254 / 50%) 0%, rgb(219 234 254 / 80%) 100%);
    border: 1px solid rgb(147 197 253 / 60%);
    border-radius: 1.2rem;
    backdrop-filter: blur(8px);
    transition: all 0.3s ease;
    overflow: hidden;
    
    &:hover {
      border-color: rgb(147 197 253 / 80%);
      box-shadow: 0 8px 24px -4px rgba(37, 99, 235, 0.1);
      transform: translateY(-1px);
    }
    
    &-content {
      flex: 1;
      display: flex;
      align-items: center;
      gap: 2rem;
      padding: 1.4rem 2rem;

      @media (max-width: 768px) {
        flex-direction: column;
        align-items: flex-start;
        gap: 1.2rem;
        padding: 1.6rem;
      }
    }
    
    &-header {
      display: flex;
      align-items: center;
      gap: 1rem;
      margin: 0;
      min-width: max-content;
      
      h6 {
        font-family: roboto;
        font-size: 1.6rem;
        font-weight: 600;
        color: rgb(37 99 235);
        margin: 0;
      }
    }
    
    &-icon {
      font-size: 2rem;
      color: rgb(37 99 235);
    }
    
    p {
      font-family: roboto;
      font-size: 1.4rem;
      line-height: 1.5;
      color: rgb(30 58 138);
      margin: 0;
      flex: 1;
    }
    
    &-button {
      display: inline-flex;
      align-items: center;
      gap: 0.8rem;
      padding: 1rem 2rem;
      background-color: rgb(37 99 235);
      border: none;
      border-radius: 2.4rem;
      cursor: pointer;
      transition: all 0.3s ease;
      white-space: nowrap;
      min-width: max-content;
      
      &:hover {
        background-color: rgb(29 78 216);
        transform: translateY(-1px);
        box-shadow: 0 4px 12px -2px rgba(37, 99, 235, 0.3);
      }
      
      &:active {
        transform: translateY(0);
      }
      
      span {
        font-family: roboto;
        font-size: 1.4rem;
        font-weight: 500;
        color: white;
      }
      
      &-icon {
        font-size: 1.6rem;
        color: white;
        transition: transform 0.3s ease;
      }
      
      &:hover &-icon {
        transform: translateX(4px);
      }
    }
    
    @media (max-width: 768px) {
      margin-bottom: 2rem;
      
      &-button {
        width: 100%;
        justify-content: center;
      }
    }
  }

  &__unified-content {
    padding: 2.5rem;
    background-color: var(--background-color);
    border-radius: 1.2rem;
    border: 1px solid var(--grayscale-color-200);
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.05);
    transition: all 0.3s ease;
    position: relative;
    margin-bottom: 2rem;

    @media (max-width: 768px) {
      padding: 1.8rem;
      margin-bottom: 1.6rem;
    }

    &:hover {
      box-shadow: 0 6px 12px -2px rgba(0, 0, 0, 0.1);
      border-color: rgb(37 99 235);
    }

    &--free {
      &::after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 20rem;
        background: linear-gradient(
          to bottom,
          transparent,
          var(--background-color) 90%
        );
        pointer-events: none;
        opacity: 0.9;
        transition: opacity 0.3s ease;
        z-index: 1;
      }

      &:hover::after {
        opacity: 1;
      }
    }
  }

  &__main-title {
    font-family: roboto;
    font-size: 2.6rem;
    font-weight: 700;
    color: var(--grayscale-color-950);
    margin-bottom: 2.4rem;
    padding-bottom: 1.2rem;
    border-bottom: 2px solid var(--grayscale-color-200);
    position: relative;

    @media (max-width: 768px) {
      font-size: 2.2rem;
      margin-bottom: 2rem;
      padding-bottom: 1rem;
    }

    &::after {
      content: '';
      position: absolute;
      bottom: -2px;
      left: 0;
      width: 15%;
      height: 2px;
      background-color: rgb(37 99 235);
    }
  }

  &__section-heading {
    font-family: roboto;
    font-size: 2.2rem;
    font-weight: 600;
    color: var(--grayscale-color-950);
    margin: 3rem 0 1.6rem;
    position: relative;
    scroll-margin-top: 2rem;

    @media (max-width: 768px) {
      font-size: 1.8rem;
      margin: 2.4rem 0 1.2rem;
    }

    &:first-of-type {
      margin-top: 0;
    }
  }

  &__paragraph {
    font-family: roboto;
    font-size: 1.7rem;
    line-height: 1.8;
    color: var(--grayscale-color-800);
    margin-bottom: 1.6rem;
    letter-spacing: 0.02em;

    @media (max-width: 768px) {
      font-size: 1.5rem;
      line-height: 1.6;
      margin-bottom: 1.4rem;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__premium-indicator {
    display: flex;
    align-items: center;
    gap: 1rem;
    margin-top: 2.5rem;
    padding-top: 2rem;
    border-top: 1px dashed var(--grayscale-color-300);
    position: relative;
    z-index: 2;
  }

  @keyframes highlight-pulse {
    0% {
      background-color: rgba(37, 99, 235, 0.05);
    }
    50% {
      background-color: rgba(37, 99, 235, 0.1);
    }
    100% {
      background-color: transparent;
    }
  }

  .highlight-section {
    animation: highlight-pulse 1.5s ease-out;
    border-radius: 0.4rem;
  }

  &__locked-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 2.5rem;
    margin-bottom: 1.6rem;
    background-color: rgb(240 249 255 / 75%);
    border: 1px dashed rgb(59 130 246 / 55%);
    border-radius: 0.8rem;
    text-align: center;
    transition: all 0.25s ease;

    &:hover {
      background-color: rgb(240 249 255 / 90%);
      border-color: rgb(59 130 246 / 70%);
    }

    p {
      font-family: roboto;
      font-size: 1.5rem;
      color: rgb(37 99 235);
      font-style: italic;
      margin-top: 1rem;
    }

    @media (max-width: 768px) {
      padding: 2rem;
      margin-bottom: 1.4rem;

      p {
        font-size: 1.4rem;
      }
    }
  }

  &__locked-icon {
    font-size: 2.4rem;
    color: rgb(37 99 235);
  }
} 

.FunctionalityItem {
  background-color: var(--grayscale-color-100);
  border-radius: 2rem;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.03);

  @media (min-width: 992px) {
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }

  @media (max-width: 991px) {
    min-height: 650px;
    display: flex;
    flex-direction: column;
    margin: 0.8rem;
  }

  &__content {
    padding: 2rem 2.4rem 0.8rem;

    @media (max-width: 991px) {
      flex: 0 0 auto;
      padding: 2.4rem 2.4rem 0.2rem;
      display: flex;
      flex-direction: column;
      background: linear-gradient(180deg, var(--grayscale-color-100) 0%, rgba(248, 250, 252, 0.95) 100%);
    }
  }

  &__text {
    max-width: 100%;
    
    @media (max-width: 991px) {
      flex: 1;
      display: flex;
      flex-direction: column;
    }
  }

  &__title {
    margin-bottom: 1.6rem;
    font-size: 2.4rem;
    color: var(--text-color);
    font-weight: 700;
    letter-spacing: -0.02em;

    @media (max-width: 575px) {
      font-size: 2.2rem;
      margin-bottom: 1.2rem;
    }
  }

  &__description {
    font-size: 1.6rem;
    line-height: 1.6;
    color: var(--text-color-light);
    opacity: 0.95;

    @media (max-width: 575px) {
      font-size: 1.5rem;
      margin-bottom: 1.2rem;
      line-height: 1.5;
    }
  }

  &__image-container {
    width: 100%;
    padding: 0 3.6rem 3.6rem;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    
    @media (max-width: 991px) {
      flex: 1;
      padding: 0.8rem;
      height: 400px;
      min-height: 400px;
      max-height: 400px;
      display: flex;
      align-items: center;
      justify-content: center;
      overflow: hidden;
      margin-top: -0.5rem;
      background: linear-gradient(0deg, var(--grayscale-color-100) 0%, rgba(248, 250, 252, 0.98) 100%);
    }
  }

  &__image {
    max-width: 100%;
    height: auto;
    object-fit: contain;
    filter: drop-shadow(0 4px 12px rgba(0, 0, 0, 0.05));

    @media (min-width: 992px) {
      transition: opacity 0.3s ease, transform 0.3s ease;
    }

    @media (max-width: 991px) {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 94%;
      height: 94%;
      object-fit: contain;
      margin: 0;
      padding: 0;
    }

    @media (max-width: 575px) {
      width: 96%;
      height: 96%;
    }

    &.blurImage {
      opacity: 0;
    }
  }

  @media (min-width: 992px) {
    flex-direction: row;
    align-items: center;

    &:hover {
      transform: translateY(-2px);
      box-shadow: 0 6px 24px rgba(0, 0, 0, 0.06);
    }

    &__content {
      flex: 1;
      padding: 3.6rem 3.6rem 0.8rem;
    }

    &__image-container {
      flex: 1;
      padding: 3.6rem;
    }
  }
}
.Header {
  padding-block: 1.6rem;
  background-color: var(--secondary-color-100);
  background-color: var(--grayscale-color-100);
  border-bottom: 1px solid var(--grayscale-color-200);

  &__row {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__left {
    display: flex;
    align-items: center;
    gap: 1.6rem;
    position: relative;
  }

  &__logo {
    margin-right: 0;
  }

  &__navbar {
    align-self: center;
    color: var(--grayscale-color-50);
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 2.4rem;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);

    &-navItem {
      height: fit-content;
      padding: 0.8rem 1.2rem;
      font-family: var(--font-family);
      font-size: 1.6rem;
      color: var(--grayscale-color-950);
      background-color: transparent;
      border-radius: 1.6rem;
      position: relative;
      overflow: hidden;
      transition: background 0.3s ease, transform 0.3s ease, box-shadow 0.3s ease;

      &:hover {
        color: var(--grayscale-color-50);
        background: linear-gradient(135deg, var(--primary-color-400) 0%, var(--primary-color-500) 100%);
        transform: translateY(-2px);
        box-shadow: 0 4px 8px -2px rgba(0, 0, 0, 0.1);
      }
    }

    @media (max-width: 991px) {
      display: none !important;
    }
  }

  .NavItemIcon--active {
    color: var(--grayscale-color-50) !important;
    background: linear-gradient(135deg, var(--primary-color-400) 0%, var(--primary-color-500) 100%) !important;
    position: relative;
    overflow: hidden;
    
    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: -100%;
      width: 100%;
      height: 100%;
      background: linear-gradient(
        90deg,
        rgba(255, 255, 255, 0) 0%,
        rgba(255, 255, 255, 0.2) 50%,
        rgba(255, 255, 255, 0) 100%
      );
      animation: nav-shine 6s infinite;
    }
    
    @keyframes nav-shine {
      0% {
        left: -100%;
      }
      15% {
        left: 100%;
      }
      100% {
        left: 100%;
      }
    }
  }

  &__rightBox {
    display: flex;
    column-gap: 1.6rem;
    align-items: center;

    @media (max-width: 575px) {
      &-upgradePlan {
        display: none;
      }
    }
  }

  // Stili per il componente NotificationBell
  .NotificationBell {
    position: relative;

    &__dropdown {
      position: absolute;
      top: 100%;
      left: 0;
      margin-top: 0.8rem;
      min-width: 320px;
      background: var(--grayscale-color-50);
      border-radius: 1.2rem;
      box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
      z-index: 1000;

      @media (max-width: 575px) {
        left: -100%;
        min-width: 280px;
      }
    }

    &__icon {
      cursor: pointer;
      font-size: 2.4rem;
      color: var(--grayscale-color-950);
      transition: color 0.2s ease;

      &:hover {
        color: var(--primary-color-500);
      }
    }
  }
}

.FolderList {
  width: 100%;
  
  &__list {
    list-style: none;
    padding: 0;
    margin: 0;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
    gap: 16px;
  }
  
  &__empty {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 200px;
    text-align: center;
    padding: 20px;
    background-color: var(--card-background-light);
    border-radius: 8px;
    border: 1px dashed var(--border-color);
    
    p {
      margin: 8px 0;
      
      &:first-child {
        font-size: 1.2rem;
        font-weight: 500;
      }
    }
  }
  
  &__empty-hint {
    color: var(--text-color-secondary);
    font-size: 0.9rem;
  }
}
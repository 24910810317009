.PriceOption {
  display: flex;
  align-items: flex-start;
  font-size: 1.5rem;
  font-family: 'Roboto';
  font-weight: 500;
  line-height: 1.4;
  
  &:first-child.option-universitario {
    color: #22683d;
    font-weight: 600;
    font-size: 1.7rem;
  }
  
  &:first-child.option-scolastico {
    color: rgb(101, 0, 141);
    font-weight: 600;
    font-size: 1.7rem;
  }
  
  &__icon {
    width: 18px;
    height: 18px;
    margin-top: 3px;
    -webkit-margin-end: 1rem;
    margin-inline-end: 1rem;
    color: #349d5c;
    flex-shrink: 0;
  }
  
  &__icon__scolastico {
    width: 18px;
    height: 18px;
    margin-top: 3px;
    -webkit-margin-end: 1rem;
    margin-inline-end: 1rem;
    color: rgb(131, 3, 182);
    flex-shrink: 0;
  }

  &__text {
    flex: 1;
  }
}

.Document {
  padding-inline: 0.6rem;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  min-width: 28rem;
  cursor: pointer;
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  box-sizing: border-box;
  
  // Variabili CSS per i colori personalizzati
  --document-active-border-color: var(--primary-color-500);
  --document-active-bg-color: rgba(var(--primary-color-rgb), 0.05);
  --document-hover-border-color: var(--primary-color-300);
  --document-hover-bg-color: rgba(var(--primary-color-rgb), 0.02);
  --document-selected-icon-color: var(--primary-color-500);
  --document-selected-icon-bg-color: rgba(var(--primary-color-rgb), 0.1);
  
  &:hover {
    transform: translateY(-2px);
  }
  
  &:first-child {
    padding-inline-start: 0;
  }
  
  &--active > &__card {
    border: 1px solid var(--grayscale-color-300);
    background-color: rgba(var(--background-color-rgb), 0.98);
    box-shadow: 0 8px 16px -2px rgba(0, 0, 0, 0.08),
                0 4px 8px -2px rgba(0, 0, 0, 0.06),
                0 0 0 1px rgba(var(--primary-color-rgb), 0.06),
                0 0 8px 2px rgba(var(--primary-color-rgb), 0.04);

    .Document__editIcon,
    .Document__deleteIcon {
      color: var(--grayscale-color-900);
      opacity: 1;
    }
  }
  
  // Stile per la modalità di selezione documenti
  &--selectable {
    &:hover {
      transform: translateY(-2px);
      
      .Document__card {
        border-color: var(--document-hover-border-color);
        background-color: var(--document-hover-bg-color);
      }
    }
    
    &.Document--active > .Document__card {
      border: 2px solid var(--document-active-border-color);
      background-color: var(--document-active-bg-color);
    }
  }

  &__card {
    padding: 2.2rem;
    border-radius: 1.4rem;
    background-color: rgba(var(--background-color-rgb), 0.98);
    border: 1px solid var(--grayscale-color-200);
    transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
    min-height: 200px;
    display: flex;
    flex-direction: column;
    height: 100%;

    &:hover {
      background-color: rgba(var(--background-color-rgb), 1);
      box-shadow: 0 8px 16px -2px rgba(0, 0, 0, 0.08),
                  0 4px 8px -2px rgba(0, 0, 0, 0.06),
                  0 0 0 1px rgba(var(--primary-color-rgb), 0.03);
      border-color: var(--grayscale-color-300);
    }
  }

  @media (max-width: 768px) {
    padding-inline: 0.6rem;
    width: 100%;
    
    &:first-child {
      padding-inline-start: 0;
    }
  }

  &__row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1.8rem;
  }

  &__actions {
    display: flex;
    gap: 0.6rem;
    align-items: center;
    opacity: 0.7;
    transition: opacity 0.2s ease;

    .Document__card:hover & {
      opacity: 1;
    }
  }

  &__editIcon, &__deleteIcon, &__saveIcon, &__selectedIcon {
    width: 32px;
    height: 32px;
    cursor: pointer;
    transition: all 0.2s ease;
    padding: 6px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
      transform: scale(1.05);
    }

    &:active {
      transform: scale(0.95);
    }
  }

  &__editIcon {
    color: var(--grayscale-color-600);
    background-color: rgba(var(--grayscale-color-950-rgb), 0.06);

    &:hover {
      color: var(--grayscale-color-900);
      background-color: rgba(var(--grayscale-color-950-rgb), 0.09);
    }
  }

  &__deleteIcon {
    color: var(--grayscale-color-600);
    background-color: rgba(239, 68, 68, 0.06);

    &:hover {
      color: rgb(239, 68, 68);
      background-color: rgba(239, 68, 68, 0.09);
    }
  }

  &__saveIcon {
    color: rgb(16, 185, 129);
    background-color: rgba(16, 185, 129, 0.06);

    &:hover {
      background-color: rgba(16, 185, 129, 0.09);
    }
  }
  
  &__selectedIcon {
    color: var(--document-selected-icon-color);
    background-color: var(--document-selected-icon-bg-color);
    padding: 6px;
  }

  Input {
    padding: 0.4rem 0.8rem;
    margin-top: -0.2rem;
    margin-bottom: 0;
    height: auto;
    border-radius: 0.5rem;
    border: 1px solid var(--grayscale-color-200);
    font-size: 1.5rem;
    width: 100%;
    transition: all 0.2s ease;
    background-color: var(--background-color);
    color: var(--grayscale-color-900);
    font-weight: normal;
    line-height: 1.5;

    &:focus {
      outline: none;
      border-color: var(--primary-color-400);
      box-shadow: 0 0 0 3px rgba(var(--primary-color-rgb), 0.12);
    }
  }
  
  &_titleInput {
    padding: 0.4rem 0.8rem;
    margin-top: -0.2rem;
    margin-bottom: 0;
    height: auto;
    border-radius: 0.5rem;
    border: 1px solid var(--grayscale-color-200);
    font-size: 1.5rem;
    width: 100%;
    transition: all 0.2s ease;
    background-color: var(--background-color);
    color: var(--grayscale-color-900);
    font-weight: normal;
    line-height: 1.5;

    &:focus {
      outline: none;
      border-color: var(--primary-color-400);
      box-shadow: 0 0 0 3px rgba(var(--primary-color-rgb), 0.12);
    }
  }

  &__box {
    position: relative;

    &-text {
      margin-top: 0.8rem;
      font-size: 1.4rem;
      color: var(--grayscale-color-600);
      font-weight: 400;
    }
  }

  &__tags {
    display: flex;
    gap: 0.6rem;
    align-items: center;
    margin-top: 1rem;
    overflow: auto;
    padding-bottom: 0.2rem;
    margin-top: auto;

    &::-webkit-scrollbar {
      height: 4px;
    }

    &::-webkit-scrollbar-track {
      background: rgba(0, 0, 0, 0.05);
      border-radius: 100px;
    }

    &::-webkit-scrollbar-thumb {
      background: rgba(0, 0, 0, 0.2);
      border-radius: 100px;
    }
  }

  &__tag {
    display: inline-flex;
    white-space: nowrap;
    align-items: center;
    gap: 0.4rem;
    padding: 0.4rem 1rem;
    background-color: var(--primary-color-50);
    color: var(--primary-color-700);
    font-size: 1.2rem;
    font-weight: 500;
    border-radius: 0.6rem;
    transition: all 0.2s ease;
    border: 1px solid rgba(0, 0, 0, 0.05);
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
  }
}

.Assistance {
  padding-block: 5.6rem;

  @media (max-width: 767px) {
    padding-block: 4.2rem;
  }

  @media (max-width: 575px) {
    padding-block: 3.2rem;
  }
}

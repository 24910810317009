.DocumentsQAInfo {
  padding: 2rem;
  padding-inline: 3rem;
  background-color: rgba(var(--grayscale-color100-rgb), 0.25); // Assicurati che var(--grayscale-color100-rgb) sia definito correttamente
  border: 1px dashed var(--grayscale-color-200);
  border-radius: 1.6rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  height: fit-content;

  h5 {
    margin-bottom: 30px;
    font-size: 1.5rem;
    color: var(--grayscale-color-900);
  }

  &__list {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
    width: 100%;
    padding: 0;

    div, &__item {
      display: flex;
      align-items: center;
      font-size: 1.2rem;
      background-color: #FFFFFF;
      border-radius: 8px;
      padding: 10px 20px;
      box-shadow: 0 1px 3px rgba(0,0,0,0.05);
      justify-content: flex-start;

      img, svg {
        margin-right: 10px;
        width: 20px; // Dimensione base per icone esistenti
        height: auto;
      }

      // Stili specifici per le nuove voci con icone SVG
      &.DocumentsQAInfo__item {
        .DocumentsQAInfo__icon {
          width: 24px; // Dimensione maggiore per le nuove icone
          height: 24px;
          fill: var(--chisiamo-green); // Cambia il colore delle icone SVG
        }
      }
    }
  }
}
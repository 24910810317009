.UserAvatarDropdown {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;

  &__box {
    &:hover {
      scale: 1.1;
    }
  }

  @media (max-width: 991px) {
    display: none;
  }
}

.UploadNoteFile {
  display: flex;
  background: rgba(var(--background-color-rgb), 0.98);
  border: 1px solid var(--grayscale-color-200);
  border-radius: 1.2rem;
  overflow: hidden;
  margin-bottom: 1rem;
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  
  &__inner {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 1rem;

    @media (min-width: 768px) {
      padding: 1.6rem;
    }
  }

  &__left {
    display: flex;
    align-items: center;
    gap: 1rem;
    flex: 1;
    min-width: 0; 
  }

  &__boxImage {
    flex-shrink: 0;
    padding: 0.6rem;
    background-color: rgba($color: var(--grayscale-color100-rgb), $alpha: 0.5);
    border-radius: 0.7rem;

    @media (min-width: 768px) {
      padding: 0.8rem;
    }

    &-icon {
      color: #4885c7;
    }

    &-image {
      width: 2.4rem;
      height: 2.4rem;
      object-fit: cover;

      @media (min-width: 768px) {
        width: 3rem;
        height: 3rem;
      }
    }
  }

  &__content {
    flex: 1;
    min-width: 0;
    overflow: hidden;
  }

  &__title {
    font-size: 1.4rem;
    font-weight: 600;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-bottom: 0.2rem;

    @media (min-width: 768px) {
      font-size: 1.6rem;
    }
  }

  &__h6 {
    font-size: .3rem;
    
  }

  &__text {
    max-width: 100%;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
    text-overflow: ellipsis;
    line-clamp: 2;
    color: var(--grayscale-color-600);
    font-size: 12px;
    margin-top:.3rem;
    font-family: 'Roboto';
  
    @media (min-width: 768px) {
      font-size: 1.4rem;
      max-width: 36rem;
    }
  }

  &__actions {
    display: flex;
    align-items: center;
    margin-left: 1.2rem;
  }

  &__actions-delete {
    width: 5.2rem;
    height: 4.2rem;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    border-radius: 50%;
    transition: background-color 0.2s ease, transform 0.2s ease;
    cursor: pointer;
    position: relative;
    background-color: transparent;

    @media (min-width: 768px) {
      width: 3.6rem;
      height: 3.6rem;
    }

    &:hover {
      background-color: rgba(239, 68, 68, 0.1);
    }

    &:active {
      background-color: rgba(239, 68, 68, 0.2);
      transform: scale(0.95);
    }

    &:disabled {
      opacity: 0.5;
      cursor: not-allowed;
    }
  }

  &__delete-icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 1.6rem;
    color: rgb(150, 150, 150);
    transition: all 0.4s cubic-bezier(0.4, 0, 0.2, 1);

    @media (min-width: 768px) {
      font-size: 1.8rem;
    }

    .UploadNoteFile__actions-delete:hover &:not(:disabled) {
      color: rgb(239, 68, 68);
      transform: translate(-50%, -50%) rotate(90deg);
    }

    .UploadNoteFile__actions-delete:active &:not(:disabled) {
      color: rgb(220, 38, 38);
    }
  }
}
.ContentIndex {
  width: 100%;
  
  &__container {
    width: 100%;
    border: 1px solid rgb(218, 218, 218);
    border-radius: 1.2rem;
    background-color: var(--background-color);
    position: relative;
    transition: border-color 0.3s ease;

    &:hover {
      border-color: rgb(37 99 235);
    }

    @media (max-width: 768px) {
      width: 100%;
      position: static;
    }
  }

  &__content {
    padding: 2rem;
    max-height: calc(50vh - 4rem);
    overflow-y: auto;
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none; /* IE and Edge */
    padding-right: 2rem;

    @media (max-width: 768px) {
      max-height: 40rem;
      padding: 1.6rem;
      padding-right: 1.6rem;
      border-radius: 1.2rem;
      background-color: var(--background-color);
      border: 1px solid var(--grayscale-color-200);
    }

    &::-webkit-scrollbar {
      width: 0;
      height: 0;
      display: none;
    }
    
    &::-webkit-scrollbar-track {
      display: none;
    }
    
    &::-webkit-scrollbar-thumb {
      display: none;
    }
  }

  &__title {
    font-family: roboto;
    font-size: 1.8rem;
    font-weight: 600;
    color: var(--grayscale-color-950);
    margin-bottom: 1.6rem;
    padding-bottom: 1rem;
    border-bottom: 2px solid var(--grayscale-color-200);
    position: relative;

    &::after {
      content: '';
      position: absolute;
      bottom: -2px;
      left: 0;
      width: 50%;
      height: 2px;
      background-color: rgb(37 99 235);
    }
  }

  &__list {
    display: flex;
    flex-direction: column;
    gap: 0.8rem;
  }

  &__item {
    font-family: roboto;
    font-size: 1.4rem;
    color: var(--grayscale-color-700);
    cursor: pointer;
    padding: 1rem 1.2rem;
    border-radius: 0.8rem;
    transition: all 0.2s ease;
    border: 1px solid transparent;
    position: relative;
    padding-left: 2.4rem;

    &::before {
      content: '';
      position: absolute;
      left: 1rem;
      top: 50%;
      transform: translateY(-50%);
      width: 0.4rem;
      height: 0.4rem;
      border-radius: 50%;
      background-color: var(--grayscale-color-400);
      transition: all 0.2s ease;
    }

    &:hover {
      background-color: var(--grayscale-color-50);
      color: var(--grayscale-color-950);
      border-color: var(--grayscale-color-200);

      &::before {
        background-color: rgb(37 99 235);
        transform: translateY(-50%) scale(1.2);
      }
    }

    &--active {
      background-color: var(--grayscale-color-50);
      color: var(--grayscale-color-950);
      font-weight: 500;
      border-color: var(--grayscale-color-200);

      &::before {
        background-color: rgb(37 99 235);
        transform: translateY(-50%) scale(1.2);
      }
    }

    &--locked, &--partial {
      opacity: 0.8;
      background-color: rgb(240 249 255 / 75%);
      border: 1px dashed rgb(59 130 246 / 55%);
      cursor: not-allowed;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-right: 1.6rem;
      transition: all 0.25s ease;

      &:hover {
        background-color: rgb(240 249 255 / 90%);
        border-color: rgb(59 130 246 / 70%);
      }

      &::before {
        background-color: rgb(59 130 246 / 55%);
      }
    }

    &--partial {
      opacity: 1;
      cursor: pointer;

      &:hover {
        background-color: rgb(240 249 255 / 90%);
        border-color: rgb(59 130 246 / 70%);
      }
    }

    &-lock {
      color: rgb(37 99 235);
      font-size: 1.6rem;
      flex-shrink: 0;
    }
  }

  &__premium-indicator {
    margin-top: 1.6rem;
    padding: 1.2rem;
    background-color: rgb(240 249 255 / 75%);
    border: 1px dashed rgb(59 130 246 / 55%);
    border-radius: 0.8rem;
    transition: all 0.25s ease;
    text-align: center;
    
    &:hover {
      background-color: rgb(240 249 255 / 90%);
      border-color: rgb(59 130 246 / 70%);
    }
    
    span {
      font-family: roboto;
      font-size: 1.3rem;
      color: rgb(37 99 235);
      font-style: italic;
    }
  }
} 
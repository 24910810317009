.Heading {
  font-family: var(--font-family);
  line-height: 1.1;
  color: var(--grayscale-color-950);
  font-weight:bold;

  &[data-id="h1"] {
    font-size: 7.2rem;

    @media (max-width: 991px) {
      font-size: 4.8rem;
    }

    @media (max-width: 575px) {
      font-size: 3.2rem;
    }
  }

  &[data-id="h2"] {
    font-size: 4.8rem;

    @media (max-width: 991px) {
      font-size: 3.6rem;
    }

    @media (max-width: 575px) {
      font-size: 3.5rem;
    }
  }

  &[data-id="h3"] {
    font-size: 4rem;

    @media (max-width: 991px) {
      font-size: 3.2rem;
    }
  }

  &[data-id="h4"] {
    font-size: 3.2rem;
  }

  &[data-id="h5"] {
    font-size: 2rem;
  }

  &[data-id="h6"] {
    font-size: 1.6rem;
  }
}

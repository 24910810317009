.LandingMenu {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--grayscale-color-50);
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  z-index: 999;

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1.6rem;
    background-color: var(--grayscale-color-50);
    position: sticky;
    top: 0;
    z-index: 2;
    border-bottom: 1px solid var(--grayscale-color-200);

    &-box {
      width: 4rem;
      height: 4rem;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      background-color: var(--grayscale-color-100);
      border-radius: 1.2rem;
      transition: background-color 0.2s ease;

      & > svg {
        font-size: 2.4rem;
        color: var(--grayscale-color-900);
      }

      &:active {
        background-color: var(--grayscale-color-200);
      }
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    padding: 1.6rem;
    padding-top: 3.2rem;
  }

  &__nav-items {
    display: flex;
    flex-direction: column;
    gap: 2.4rem;
  }

  &__nav-item {
    font-size: 2.8rem;
    font-weight: 400;
    color: var(--grayscale-color-900);
    cursor: pointer;
    transition: color 0.2s ease;

    &--dropdown {
      display: flex;
      align-items: center;
      gap: 0.8rem;
      color: var(--primary-color-500);
    }
  }

  &__chevron {
    width: 2.4rem;
    height: 2.4rem;
    color: currentColor;
  }

  &__dropdown-content {
    display: flex;
    flex-direction: column;
    gap: 2.4rem;
    padding: 2.4rem 2rem;
    margin-top: 0.8rem;
    margin-left: -1.6rem;
    margin-right: -1.6rem;
    background-color: var(--grayscale-color-100);
  }

  &__auth {
    margin-top: auto;
    padding-top: 4rem;
    display: flex;
    flex-direction: column;
    gap: 1.2rem;
  }

  &__auth-button {
    width: 100%;
    padding: 1.6rem;
    font-size: 2rem;
    font-weight: 400;
    text-align: center;
    border-radius: 1.2rem;
    transition: all 0.2s ease;
    border: none;
    cursor: pointer;

    &--login {
      background-color: var(--grayscale-color-50);
      color: var(--grayscale-color-900);
      border: 1px solid var(--grayscale-color-200);

      &:active {
        background-color: var(--grayscale-color-100);
      }
    }

    &--register {
      background-color: var(--primary-color-500);
      color: var(--grayscale-color-50);

      &:active {
        background-color: var(--primary-color-600);
      }
    }
  }
}

.hide-copilot {
  display: none !important;
}

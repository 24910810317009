.FolderContent {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 20px 14px 0;
    margin-bottom: 16px;
    
    @media (max-width: 768px) {
      padding: 16px 8px 12px 8px;
      flex-direction: row;
      align-items: center;
      gap: 10px;
      flex-wrap: wrap;
    }
    
    @media (max-width: 420px) {
      flex-direction: row;
      flex-wrap: wrap;
      gap: 8px;
      padding-left: 0;
    }
  }
  
  &__headerLeft {
    display: flex;
    align-items: center;
    gap: 16px;
    margin-left: 0;
    
    @media (max-width: 768px) {
      gap: 10px;
      flex: 1;
      max-width: 70%;
      margin-left: 0;
    }
    
    @media (max-width: 420px) {
      gap: 6px;
      max-width: 100%;
      margin-bottom: 0;
      align-items: center;
      margin-left: 0;
    }
  }
  
  &__headerRight {
    display: flex;
    align-items: center;
    gap: 8px;
    flex-wrap: wrap;
    
    @media (max-width: 768px) {
      justify-content: flex-end;
      gap: 6px;
      flex: 0 0 auto;
      margin-left: 10px;
      
      button {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0;
        position: relative;
        
        svg {
          margin: 0 auto;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }
    }
    
    @media (max-width: 420px) {
      margin-left: auto;
      justify-content: flex-end;
    }
  }
  
  &__title {
    font-size: 26px;
    font-weight: 600;
    margin: 0;
    display: flex;
    align-items: center;
    gap: 12px;
    color: var(--grayscale-color-900);
    
    @media (max-width: 768px) {
      font-size: 24px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 220px;
      gap: 6px;
      font-weight: 700;
    }
    
    @media (max-width: 420px) {
      font-size: 20px;
      max-width: calc(100% - 50px);
    }
  }
  
  &__titleIcon {
    margin-right: 4px;
    
    @media (max-width: 768px) {
      width: 36px;
      height: 36px;
      margin-right: 2px;
    }
    
    @media (max-width: 420px) {
      width: 36px;
      height: 36px;
      margin-right: 4px;
      flex-shrink: 0;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  
  &__count {
    font-size: 14px;
    font-weight: 400;
    color: var(--grayscale-color-600);
    background-color: var(--grayscale-color-100);
    padding: 4px 8px;
    border-radius: 12px;
    
    @media (max-width: 768px) {
      font-size: 12px;
    }
  }
  
  &__backButton {
    height: 40px;
    width: 40px;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    margin-left: 0;
    
    &.Button--outline {
      border-color: var(--grayscale-color-300);
      color: var(--grayscale-color-700);
      
      &:hover, &:focus {
        border-color: var(--grayscale-color-400);
        background-color: var(--grayscale-color-50);
      }
      
      &:active {
        background-color: var(--grayscale-color-100);
      }
    }
    
    svg {
      width: 24px;
      height: 24px;
      display: block;
      margin: auto;
    }
    
    @media (max-width: 768px) {
      margin-left: 5px;
      height: 36px;
      width: 36px;
      display: flex;
      align-items: center;
      justify-content: center;
      
      svg {
        width: 20px;
        height: 20px;
        margin: 0;
      }
    }
    
    @media (max-width: 420px) {
      margin-left: 0;
    }
  }
  
  &__deleteButton {
    color: var(--danger-color, #e74c3c);
    
    &:hover, &:focus {
      color: var(--danger-color, #e74c3c) !important;
      border-color: var(--danger-color-light, rgba(231, 76, 60, 0.4)) !important;
      background-color: var(--danger-color-bg, rgba(231, 76, 60, 0.05)) !important;
    }
  }
  
  &__actions {
    display: flex;
    gap: 8px;
    margin-bottom: 24px;
    flex-wrap: wrap;
    
    @media (max-width: 768px) {
      gap: 6px;
      justify-content: flex-end;
    }
  }
  
  &__action-button {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    height: 40px;
    padding: 0 1rem;
    font-size: 1.4rem;
    white-space: nowrap;
    transition: all 0.2s ease;
    border-radius: 8px;
    
    @media (max-width: 768px) {
      font-size: 1.2rem;
      padding: 0;
      width: 36px;
      height: 36px;
      justify-content: center;
      min-width: unset;
      display: flex;
      align-items: center;
      
      svg {
        margin: 0;
        width: 20px;
        height: 20px;
      }
      
      span {
        display: none;
      }
    }
    
    /* Stile comune per tutti i pulsanti outline */
    &.Button--outline {
      border-color: var(--grayscale-color-300);
      color: var(--grayscale-color-700);
      
      &:hover, &:focus {
        border-color: var(--grayscale-color-400);
        background-color: var(--grayscale-color-50);
      }
      
      &:active {
        background-color: var(--grayscale-color-100);
      }
    }
    
    /* Stile per il pulsante attivo (ricerca) */
    &--active {
      background-color: rgba(var(--primary-color-rgb), 0.1) !important;
      color: var(--primary-color) !important;
      border-color: var(--primary-color) !important;
      
      &.Button--outline {
        border-color: var(--primary-color) !important;
      }
      
      &:hover, &:focus {
        background-color: rgba(var(--primary-color-rgb), 0.15) !important;
        color: var(--primary-color) !important;
        border-color: var(--primary-color) !important;
      }
    }
  }
  
  &__searchButton, &__searchInput {
    transition: all 0.2s ease;
    
    &:focus {
      outline: none;
      border-color: var(--primary-color);
      box-shadow: 0 0 0 2px rgba(var(--primary-rgb), 0.2);
    }
  }
  
  &__searchContainer {
    width: 100%;
    margin-bottom: 1.5rem;
    display: flex;
    transition: all 0.3s ease;
  }
  
  &__searchInputWrapper {
    width: 100%;
    position: relative;
    display: flex;
    align-items: center;
  }
  
  &__searchInput {
    width: 100%;
    padding: 1rem 1.5rem;
    border-radius: 0.8rem;
    border: 1px solid var(--grayscale-color-300);
    font-size: 1.4rem;
    background-color: var(--background-color);
    transition: all 0.3s ease;
    color: var(--grayscale-color-900);
    
    &:focus {
      border-color: var(--primary-color);
      box-shadow: 0 0 0 2px rgba(var(--primary-color-rgb), 0.15);
      outline: none;
    }
    
    &::placeholder {
      color: var(--grayscale-color-500);
    }
  }
  
  &__subtitle {
    font-size: 1.2rem;
    font-weight: 500;
    margin: 0 0 16px 0;
    color: var(--grayscale-color-800);
  }
  
  &__subfolders {
    margin-bottom: 24px;
  }
  
  &__subfoldersGrid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
    gap: 16px;
    
    @media (max-width: 768px) {
      display: flex;
      overflow-x: auto;
      -webkit-overflow-scrolling: touch;
      scrollbar-width: thin;
      gap: 1.2rem;
      padding-bottom: 1.5rem;
      
      & > * {
        min-width: 280px;
        max-width: 85%;
      }
    }
  }
  
  &__documents {
    margin-bottom: 24px;
  }
  
  &__documentsGrid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
    gap: 24px;
    
    @media (max-width: 768px) {
      display: flex;
      overflow-x: auto;
      -webkit-overflow-scrolling: touch;
      scrollbar-width: thin;
      gap: 1.2rem;
      padding-bottom: 1.5rem;
      
      & > * {
        min-width: 280px;
        max-width: 85%;
        margin-right: 16px;
      }
      
      & > *:last-child {
        margin-right: 0;
      }
    }
    
    /* Migliora lo scorrimento su macOS */
    -webkit-overflow-scrolling: touch;
    scrollbar-width: thin;
    
    &::-webkit-scrollbar {
      height: 8px;
    }
    
    &::-webkit-scrollbar-track {
      background: var(--grayscale-color-100);
      border-radius: 8px;
    }
    
    &::-webkit-scrollbar-thumb {
      background: var(--grayscale-color-300);
      border-radius: 8px;
      
      &:hover {
        background: var(--grayscale-color-400);
      }
    }
  }
  
  &__content-row {
    display: flex;
    flex-wrap: nowrap;
    gap: 16px;
    margin-bottom: 20px;
    width: 100%;
    overflow-x: auto;
    padding-bottom: 16px;
    padding-top: 10px;
    -webkit-overflow-scrolling: touch;
    scrollbar-width: thin;
    
    /* Stili specifici per le sottocartelle */
    &[data-is-subfolder="true"] {
      display: flex !important;
      flex-wrap: nowrap !important;
      overflow-x: auto !important;
      white-space: nowrap !important;
      width: 100% !important;
      padding-top: 10px !important;
      
      /* Assicuriamoci che gli elementi figli siano visualizzati correttamente */
      & > * {
        flex-shrink: 0 !important;
        min-width: 280px !important;
      }
    }
    
    /* Rimuovo i margin-right perché ora usiamo gap */
    & > * {
      flex-shrink: 0;
    }
    
    &::-webkit-scrollbar {
      height: 8px;
    }
    
    &::-webkit-scrollbar-track {
      background: var(--grayscale-color-100);
      border-radius: 8px;
    }
    
    &::-webkit-scrollbar-thumb {
      background: var(--grayscale-color-300);
      border-radius: 8px;
      
      &:hover {
        background: var(--grayscale-color-400);
      }
    }
  }
  
  &__subfolder-item,
  &__document-item {
    flex: 0 0 auto;
    min-width: 280px;
    max-width: 320px;
    margin-right: 0;
    
    /* Proprietà aggiuntive per sottocartelle */
    [data-is-subfolder="true"] & {
      flex: 0 0 auto !important;
      min-width: 280px !important;
      max-width: 320px !important;
      width: auto !important;
      display: inline-block !important;
      vertical-align: top !important;
    }
    
    @media (max-width: 768px) {
      min-width: 260px;
      max-width: 85%;
    }
  }
  
  &__emptyState {
    width: 100%;
    text-align: center;
    padding: 32px;
    color: var(--grayscale-color-600);
    font-size: 1rem;
    background-color: var(--grayscale-color-50);
    border-radius: 8px;
    margin-bottom: 24px;
  }
  
  &__footer {
    display: flex;
    justify-content: flex-end;
    gap: 12px;
    margin-top: 24px;
    padding-top: 16px;
    border-top: 1px solid var(--grayscale-color-200);
  }
  
  &__subfolder-form {
    margin-top: 24px;
    padding: 20px;
    background-color: var(--grayscale-color-50);
    border-radius: 8px;
    border: 1px solid var(--grayscale-color-300);
    
    h3 {
      font-size: 1.2rem;
      font-weight: 500;
      margin: 0 0 16px 0;
      color: var(--grayscale-color-900);
    }
  }
  
  &__form-row {
    margin-bottom: 16px;
    
    label {
      display: block;
      margin-bottom: 8px;
      font-weight: 500;
      color: var(--grayscale-color-800);
    }
  }
  
  &__input {
    width: 100%;
    padding: 10px 16px;
    border-radius: 8px;
    border: 1px solid var(--grayscale-color-300);
    background-color: var(--grayscale-color-50);
    color: var(--grayscale-color-900);
    font-size: 1rem;
    
    &:focus {
      outline: none;
      border-color: var(--primary-color);
      box-shadow: 0 0 0 2px rgba(var(--primary-rgb), 0.2);
    }
  }
  
  &__color-picker {
    display: flex;
    gap: 12px;
    flex-wrap: wrap;
  }
  
  &__color-option {
    width: 36px;
    height: 36px;
    border-radius: 50%;
    cursor: pointer;
    transition: transform 0.2s ease, box-shadow 0.2s ease;
    
    &:hover {
      transform: scale(1.1);
    }
    
    &.active {
      box-shadow: 0 0 0 3px var(--grayscale-color-50), 0 0 0 5px currentColor;
    }
  }
  
  &__form-footer {
    display: flex;
    justify-content: flex-end;
    gap: 12px;
    margin-top: 24px;
  }
  
  &__selection-header {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 2rem;
    padding: 1.8rem 2rem;
    border-radius: 1rem;
    background-color: rgba(var(--primary-color-rgb), 0.05);
    border: 2px solid;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
    
    /* Aggiungo stile specifico per il pulsante Rimuovi documenti */
    .MaterialsDocuments__save-button {
      font-weight: 600;
      position: relative;
      overflow: hidden;
      transition: all 0.3s ease;
      
      &:not(:disabled)::before {
        content: '';
        position: absolute;
        top: 0;
        left: -100%;
        width: 100%;
        height: 100%;
        background: linear-gradient(
          90deg,
          rgba(255, 255, 255, 0) 0%,
          var(--button-shine-color, rgba(255, 255, 255, 0.2)) 50%,
          rgba(255, 255, 255, 0) 100%
        );
        animation: button-shine 6s infinite;
      }
      
      &:hover:not(:disabled) {
        transform: translateY(-2px);
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
      }
      
      &:active:not(:disabled) {
        transform: translateY(0);
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
      }
    }
    
    @media (min-width: 980px) {
      flex-direction: row;
      align-items: center;
    }
    
    @media (max-width: 979px) {
      gap: 1.6rem;
    }
  }
  
  &__selection-container {
    display: flex;
    flex-direction: column;
    gap: 1.4rem;
    
    @media (min-width: 980px) {
      flex-direction: row;
      align-items: center;
      width: auto;
      flex: 1;
      gap: 2.5rem;
    }
  }
  
  &__document-selection {
    @media (min-width: 980px) {
      min-width: 240px;
    }
  }
  
  &__selection-text {
    font-size: 1.6rem;
    font-weight: 600;
    color: var(--grayscale-color-900);
    margin-bottom: 0.4rem;
    margin-top: 0;
  }
  
  &__selection-count {
    font-size: 1.4rem;
    font-weight: 500;
    color: var(--grayscale-color-700);
    display: block;
  }
  
  &__color-section {
    @media (min-width: 980px) {
      border-left: 1px solid var(--grayscale-color-200);
      margin-top: 0;
      padding-top: 0;
      padding-left: 2.5rem;
      border-top: none;
    }
    
    @media (max-width: 979px) {
      margin-top: 0.6rem;
      padding-top: 1.4rem;
      border-top: 1px solid var(--grayscale-color-200);
      width: 100%;
    }
  }
  
  &__color-selector {
    display: flex;
    align-items: center;
    gap: 1.4rem;
    
    @media (max-width: 580px) {
      flex-direction: column;
      align-items: flex-start;
      gap: 0.8rem;
    }
  }
  
  &__color-label {
    font-size: 1.5rem;
    font-weight: 600;
    color: var(--grayscale-color-800);
    white-space: nowrap;
  }
  
  &__color-options {
    display: flex;
    gap: 1rem;
    flex-wrap: wrap;
  }
  
  &__color-option {
    width: 2.8rem;
    height: 2.8rem;
    border-radius: 50%;
    cursor: pointer;
    transition: all 0.2s ease;
    
    &:hover {
      transform: scale(1.15);
      box-shadow: 0 3px 6px rgba(0, 0, 0, 0.15);
    }
    
    &--selected {
      transform: scale(1.15);
      box-shadow: 0 3px 6px rgba(0, 0, 0, 0.15);
    }
  }
  
  &__buttons {
    display: flex;
    align-items: center;
    gap: 1.2rem;
    
    @media (max-width: 768px) {
      margin-top: 1rem;
      width: 100%;
      flex-wrap: wrap;
      justify-content: flex-end;
    }
  }
  
  &__save-button, &__cancel-button {
    display: flex;
    align-items: center;
    gap: 0.6rem;
    font-size: 1.4rem;
    padding: 0.8rem 1.6rem;
    justify-content: center;
    min-width: 10rem;
    
    @media (max-width: 768px) {
      min-width: auto;
      width: 36px;
      padding: 0;
      height: 36px;
      font-size: 1.2rem;
      display: flex;
      align-items: center;
      justify-content: center;
      
      span {
        display: none;
      }
      
      svg {
        margin: 0;
        width: 20px;
        height: 20px;
      }
    }
  }
  
  /* Classi per la visualizzazione condizionale desktop/mobile */
  .desktop-only {
    @media (max-width: 576px) {
      display: none;
    }
  }
  
  .mobile-only {
    display: none;
    
    @media (max-width: 576px) {
      display: inline-flex;
    }
  }
  
  &__buttons {
    display: flex;
    gap: 1rem;
    margin-top: 0;
    justify-content: flex-end;
  }
}

/* Override styles per garantire la visualizzazione orizzontale sempre */
.FolderContent[data-is-subfolder="true"] {
  .FolderContent__header {
    margin-bottom: 12px;
  }

  .FolderContent__content-row {
    margin-top: 0;
  }
}

.FolderContent[data-is-subfolder="true"] .FolderContent__content-row,
.FolderContent .FolderContent__content-row[data-is-subfolder="true"] {
  display: flex !important;
  flex-direction: row !important;
  flex-wrap: nowrap !important;
  overflow-x: auto !important;
  width: 100% !important;
  gap: 16px !important;
  padding-top: 10px !important; /* Aggiungo padding superiore per evitare clipping durante l'hover */
  
  & > * {
    flex: 0 0 auto !important;
    min-width: 280px !important;
    margin-right: 0 !important;  /* Rimuovo margin-right e uso solo gap */
    width: auto !important;
  }
  
  /* Rimuovo il margin-right per l'ultimo elemento, era necessario solo quando usavo margin-right invece di gap */
  & > *:last-child {
    margin-right: 0 !important;
  }
}

/* Stile universale per garantire che tutti gli elementi di documento e cartella
   abbiano una dimensione e spaziatura coerente indipendentemente dal contesto */
.FolderContent__subfolder-item,
.FolderContent__document-item {
  display: block !important;
  width: auto !important;
  min-width: 280px !important;
  max-width: 320px !important;
}

/* Animazione per lo shine effect dei pulsanti */
@keyframes button-shine {
  0% {
    left: -100%;
  }
  15% {
    left: 100%;
  }
  100% {
    left: 100%;
  }
}
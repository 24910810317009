.PremiumBanner {
  display: flex;
  align-items: center;
  margin-bottom: 1.6rem;
  background: linear-gradient(135deg, rgb(219 234 254 / 50%) 0%, rgb(219 234 254 / 80%) 100%);
  border: 1px solid rgb(147 197 253 / 60%);
  border-radius: 1.2rem;
  backdrop-filter: blur(8px);
  transition: all 0.3s ease;
  overflow: hidden;
  
  &:hover {
    border-color: rgb(147 197 253 / 80%);
    box-shadow: 0 8px 24px -4px rgba(37, 99, 235, 0.1);
    transform: translateY(-1px);
  }
  
  &__content {
    flex: 1;
    display: flex;
    align-items: center;
    gap: 2rem;
    padding: 1.4rem 2rem;

    @media (max-width: 768px) {
      flex-direction: column;
      align-items: flex-start;
      gap: 1.2rem;
      padding: 1.6rem;
    }
  }
  
  &__header {
    display: flex;
    align-items: center;
    gap: 1rem;
    margin: 0;
    min-width: max-content;
    
    h6 {
      font-family: roboto;
      font-size: 1.6rem;
      font-weight: 600;
      color: rgb(37 99 235);
      margin: 0;
    }
  }
  
  &__icon {
    font-size: 2rem;
    color: rgb(37 99 235);
  }
  
  p {
    font-family: roboto;
    font-size: 1.4rem;
    line-height: 1.5;
    color: rgb(30 58 138);
    margin: 0;
    flex: 1;
  }
  
  &__button {
    display: inline-flex;
    align-items: center;
    gap: 0.8rem;
    padding: 1rem 2rem;
    background-color: rgb(37 99 235);
    border: none;
    border-radius: 2.4rem;
    cursor: pointer;
    transition: all 0.3s ease;
    white-space: nowrap;
    min-width: max-content;
    position: relative;
    overflow: hidden;

    &.animate-button::before {
      content: '';
      position: absolute;
      top: 0;
      left: -100%;
      width: 100%;
      height: 100%;
      background: linear-gradient(
        90deg,
        rgba(255, 255, 255, 0) 0%,
        rgba(255, 255, 255, 0.2) 50%,
        rgba(255, 255, 255, 0) 100%
      );
      animation: button-shine 6s infinite;
    }
    
    @keyframes button-shine {
      0% {
        left: -100%;
      }
      15% {
        left: 100%;
      }
      100% {
        left: 100%;
      }
    }
    
    &:hover {
      background-color: rgb(29 78 216);
      transform: translateY(-1px);
      box-shadow: 0 4px 12px -2px rgba(37, 99, 235, 0.3);
    }
    
    &:active {
      transform: translateY(0);
    }
    
    span {
      font-family: roboto;
      font-size: 1.4rem;
      font-weight: 500;
      color: white;
    }
    
    &-icon {
      font-size: 1.6rem;
      color: white;
      transition: transform 0.3s ease;
    }
    
    &:hover &-icon {
      transform: translateX(4px);
    }
  }
  
  @media (max-width: 768px) {
    margin-bottom: 2rem;
    
    &__button {
      width: 100%;
      justify-content: center;
    }
  }
} 
.LeftBanner {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 100%;

  &__image {
    -o-object-fit: cover;
    object-fit: cover;
    border-radius: 32px;

    @media (max-width: 1199px) {
      height: 100%;
    }
  }

  @media (max-width: 1199px) {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }
}

.ReferralCode { 
  padding-block: 6.5rem;
  padding-inline: 0.5rem;
  background-color: #fdfdfd;
  min-height: 100vh;

  &__container {
    max-width: 420px;
    margin: 0 auto;

    @media (max-width: 400px) {
      max-width: 320px;
    }

    &__giftcards-container {
      background-color: white;
      box-shadow: 0 2px 12px rgba(0, 0, 0, 0.08);
      border-radius: 1.6rem;
      margin-top: 2rem;
      padding: 2rem;
      transition: transform 0.3s ease;

      &:hover {
        transform: translateY(-2px);
        box-shadow: 0 4px 16px rgba(0, 0, 0, 0.12);
      }

      &__title {
        font-size: 2.4rem;
        color: #1a1a1a;
        font-weight: 700;
        margin-bottom: 1rem;
      }

      &__subtitle {
        font-size: 1.4rem;
        color: #666;
        margin-bottom: 1.5rem;
        display: block;
        text-align: center;
      }
      
      &__giftcards-table {
        background-color: #f9f9f9;
        border-radius: 1.2rem;
        padding: 1.5rem;
        
        &__giftcard {
          background: white;
          border: 2px dashed #e0e0e0;
          border-radius: 1.2rem;
          padding: 3rem 2rem;
          text-align: center;
          color: #888;
          font-size: 1.5rem;
          font-weight: 500;
          transition: all 0.3s ease;

          &:hover {
            border-color: var(--primary-color-400);
            color: var(--primary-color-400);
            box-shadow: 0 2px 8px rgba(0, 0, 0, 0.06);
          }
        }
      }
    }
  }
  
  &__card {
    background-color: white;
    box-shadow: 0 2px 12px rgba(0, 0, 0, 0.08);
    border-radius: 1.6rem;
    transition: all 0.4s cubic-bezier(0.165, 0.84, 0.44, 1);
    position: relative;
    overflow: visible;
    padding: 2.5rem 2rem;
    border: 1px solid rgba(0, 0, 0, 0.1);

    &:hover {
      box-shadow: 0 4px 16px rgba(0, 0, 0, 0.12);
      border-color: rgba(0, 0, 0, 0.15);
    }

    &__top-card {
      padding: 1rem 0.5rem; 
      position: relative;

      .icon-badge {
        position: absolute;
        top: -1rem;
        right: -1rem;
        background-color: var(--primary-color-400);
        color: white;
        padding: 0.8rem;
        border-radius: 50%;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        z-index: 10;
        transition: transform 0.3s ease;

        &:hover {
          transform: scale(1.1) rotate(15deg);
        }

        svg {
          width: 1.8rem;
          height: 1.8rem;
        }
      }
    }

    &__title {
      font-size: 2.6rem;
      font-weight: 800;
      color: #1a1a1a;
      line-height: 1.3;
      margin-bottom: 1.5rem;
      text-align: center;
    }

    .value-proposition {
      background-color: #f9f9f9;
      border-radius: 1.6rem;
      padding: 2rem;
      margin-top: 2rem;

      &__item {
        display: flex;
        align-items: center;
        gap: 1.5rem;
        padding: 1.5rem;
        background: white;
        border-radius: 1.2rem;
        transition: all 0.3s ease;
        box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05);

        &:not(:last-child) {
          margin-bottom: 1rem;
        }

        &:hover {
          transform: translateX(4px);
          box-shadow: 0 2px 8px rgba(0, 0, 0, 0.08);
          background-color: rgba(var(--primary-color-rgb), 0.02);
        }

        svg {
          width: 2.4rem;
          height: 2.4rem;
          color: var(--primary-color-400);
          flex-shrink: 0;
        }

        p {
          font-size: 1.5rem;
          color: #444;
          line-height: 1.4;
          font-weight: 500;
        }
      }
    }

    &__statistics-card {
      background-color: var(--primary-color-400);
      color: white;
      border-radius: 1.6rem;
      padding: 3rem 2rem; 
      margin-top: 2.5rem;
      text-align: center;
      box-shadow: 0 4px 16px rgba(0, 0, 0, 0.12);
      border: 1px solid rgba(255, 255, 255, 0.2);

      &__referral-label {
        font-size: 2.2rem;
        font-weight: 700;
        margin-bottom: 2rem; 
        color: white;
      }

      &__ongoing-row {
        display: flex;
        justify-content: space-between;
        gap: 2rem;
        margin-bottom: 2.5rem;

        &__container {
          flex: 1;
          background-color: #ffffff;
          border: 1px solid rgba(0, 0, 0, 0.08);
          border-radius: 1.2rem;
          padding: 1.8rem 1.2rem;
          text-align: center;
          box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
          transition: transform 0.3s ease;

          &:hover {
            transform: translateY(-4px);
            box-shadow: 0 6px 16px rgba(0, 0, 0, 0.15);
            border-color: rgba(0, 0, 0, 0.12);
          }

          &__subtitle {
            color: #666;
            font-size: 1.2rem;
            font-weight: 600;
            text-transform: uppercase;
            letter-spacing: 0.05em;
            margin-bottom: 0.8rem; 
            display: block;
          }

          &__amount {
            font-size: 3rem; 
            font-weight: 800;
            color: var(--grayscale-color-700);
            line-height: 1;
            display: block; 
            margin-top: 0.3rem; 
          }
        }
      }

      &__referralCode-container {
        padding: 0 1rem;

        &__referral-label {
          font-size: 2rem;
          font-weight: 700;
          margin-bottom: 1.5rem;
          color: white;
          text-align: center;
        }

        .referral-code-input-group {
          display: flex;
          align-items: stretch;
          gap: 1rem;
          background: #ffffff;
          padding: 0.5rem;
          border-radius: 1.2rem;
          box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
          border: 1px solid rgba(0, 0, 0, 0.08);

          &:hover {
            border-color: rgba(0, 0, 0, 0.12);
          }

          input {
            flex: 1;
            font-size: 1.6rem;
            font-weight: 600;
            color: var(--grayscale-color-700);
            background: transparent;
            border: none;
            padding: 1rem 1.5rem;
            border-radius: 0.8rem;
            text-align: center;
            
            &:focus {
              outline: none;
              background-color: white;
            }
          }

          .share-button {
            background-color: var(--primary-color-400);
            color: white;
            border: none;
            padding: 1rem 1.2rem;
            border-radius: 0.8rem;
            cursor: pointer;
            transition: all 0.3s ease;
            display: flex;
            align-items: center;
            justify-content: center;

            &:hover {
              transform: translateY(-2px);
              background-color: var(--primary-color-600);
            }

            svg {
              width: 2rem;
              height: 2rem;
            }
          }
        }
      }

      &__upgrade-container {
        text-align: center;
        padding: 2rem;

        &__upgrade-label {
          font-size: 2rem;
          font-weight: 700;
          margin-bottom: 1.5rem;
          color: white;
        }

        &__upgrade-btn {
          background-color: white;
          color: #1a1a1a;
          border: none;
          padding: 1.2rem 2.4rem;
          border-radius: 1rem;
          font-size: 1.6rem;
          font-weight: 600;
          cursor: pointer;
          transition: all 0.3s ease;
          box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);

          &:hover {
            transform: translateY(-2px);
            box-shadow: 0 6px 16px rgba(0, 0, 0, 0.15);
            background-color: #f8f8f8;
          }
        }
      }
    }
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.fade-in {
  animation: fadeIn 0.6s ease-out forwards;
}

.pulse {
  animation: pulse 2s infinite;
}

.shadow-hover {
  transition: box-shadow 0.3s ease;

  &:hover {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
}

@media (max-width: 400px) {
  .ReferralCode {
    padding-block: 7rem;
    
    &__card {
      &__title {
        font-size: 2rem;
      }

      &__statistics-card {
        padding: 2rem 1.5rem;

        &__ongoing-row {
          &__container {
            padding: 1rem;
            &__amount {
              font-size: 2.2rem;
            }
          }
        }

        .referral-code-input-group {
          flex-direction: column;
          gap: 0.8rem;
          padding: 0.8rem;

          input {
            width: 100%;
            text-align: center;
            padding: 1.2rem;
            font-size: 1.4rem;
          }

          .share-button {
            width: 100%;
            padding: 1.2rem;
            
            svg {
              width: 1.8rem;
              height: 1.8rem;
            }
          }
        }
      }
    }
  }
}

// Stile per la pagina di referral
.referral-page {
  padding: 1.5rem 1.5rem;
  background-color: #fdfdfd;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.referral-container {
  max-width: 1000px;
  width: 100%;
  margin: 0 auto;
  padding: 2.5rem;
}

.referral-title {
  font-size: 3.2rem;
  font-weight: 800;
  text-align: center;
  color: #333;
  margin-bottom: 1rem;
}

.referral-subtitle {
  font-size: 1.4rem;
  text-align: center;
  color: #666;
  margin-bottom: 3rem;
}

// Stile per la card del codice referral
.referral-code-card {
  background-color: rgba(var(--primary-color-400-rgb), 0.1);
  border-radius: 16px;
  padding: 2.5rem;
  margin-bottom: 3rem;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.06);
  border: 1px solid rgba(var(--primary-color-400-rgb), 0.2);
  transition: transform 0.3s ease, box-shadow 0.3s ease;

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 25px rgba(0, 0, 0, 0.1);
  }
}

.referral-code-header {
  color: black;
  font-size: 1.6rem;
  font-weight: 600;
  margin-bottom: 1.5rem;
  text-align: center;
}

.referral-code-content {
  display: flex;
  align-items: center;
  justify-content: center;
}

.code-display {
  align-items: center;
  background-color: #fff;
  border: 1px solid rgba(var(--primary-color-400-rgb), 0.2);
  border-radius: 12px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.04);
  color: #333;
  cursor: pointer;
  display: flex;
  font-size: 2.4rem;
  font-weight: 700;
  gap: 1rem;
  justify-content: space-between;
  padding: 1rem 1.5rem;
  text-align: center;
  transition: all .3s ease;
  width: 100%;
  
  .code-text {
    flex-grow: 1;
    text-align: center;

    @media (min-width: 1068px) {
      padding-left: 9.4rem;
    }
  }
}

.copy-button-inline {
  padding: 1.2rem 2rem;
  background: linear-gradient(135deg, var(--primary-color-400) 0%, var(--primary-color-500) 100%);
  color: white;
  border: none;
  border-radius: 10px;
  font-size: 1.1rem;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: 0 4px 12px rgba(var(--primary-color-400-rgb), 0.3);
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    background: linear-gradient(135deg, var(--primary-color-500) 0%, var(--primary-color-600) 100%);
    transform: translateY(-0.5px);
    box-shadow: 0 6px 15px rgba(var(--primary-color-400-rgb), 0.4);
  }

  &__copy-icon {
    width: 2rem;
    height: 2rem;
    margin-right: 0.5rem;
  }
}

// Stile per le statistiche
.stats-container {
  display: flex;
  gap: 2rem;
  margin-bottom: 4rem;

  @media (max-width: 768px) {
    flex-direction: column;
  }
}

.stat-card {
  flex: 1;
  background-color: white;
  border-radius: 16px;
  padding: 2.5rem 2rem;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.06);
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  border: 1px solid rgba(0, 0, 0, 0.06);

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 25px rgba(0, 0, 0, 0.1);
  }
}

.stat-icon {
  width: 80px;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(var(--primary-color-400-rgb), 0.1);
  border-radius: 50%;
  margin-bottom: 1.5rem;

  svg {
    width: 40px;
    height: 40px;
    color: var(--primary-color-400);
  }
}

.stat-value {
  font-size: 3.5rem;
  font-weight: 800;
  color: #333;
  margin-bottom: 0.5rem;
  line-height: 1;
}

.stat-label {
  font-size: 1.2rem;
  color: #666;
  font-weight: 500;
}

// Stile per la sezione "How It Works"
.how-it-works-title {
  font-size: 2.2rem;
  font-weight: 700;
  text-align: center;
  margin-bottom: 3rem;
  color: #333;
}

.steps-container {
  display: flex;
  gap: 2.5rem;
  
  @media (max-width: 768px) {
    flex-direction: column;
  }
}

.step {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 2rem;
  background-color: white;
  border-radius: 16px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.06);
  transition: transform 0.3s ease, box-shadow 0.3s ease;

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 25px rgba(0, 0, 0, 0.1);
  }
}

.step-number {
  width: 60px;
  height: 60px;
  background-color: rgba(var(--primary-color-400-rgb), 0.1);
  color: var(--primary-color-400);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.8rem;
  font-weight: 700;
  margin-bottom: 1.5rem;
}

.step-content {
  text-align: center;
}

.step-title {
  font-size: 1.5rem;
  font-weight: 600;
  color: #333;
  margin-bottom: 0.8rem;
}

.step-description {
  font-size: 1.1rem;
  color: #666;
  line-height: 1.5;
}

// Animazioni
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes slideDown {
  from {
    opacity: 0;
    transform: translateY(-30px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes slideUp {
  from {
    opacity: 0;
    transform: translateY(30px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 rgba(76, 175, 80, 0.4);
  }
  70% {
    box-shadow: 0 0 0 10px rgba(76, 175, 80, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(76, 175, 80, 0);
  }
}


@keyframes countUp {
  from {
    transform: scale(0.8);
    opacity: 0;
  }
  to {
    transform: scale(1);
    opacity: 1;
  }
}

.animate-slide-down {
  animation: slideDown 0.8s ease forwards;
}

.animate-slide-up {
  animation: slideUp 0.8s ease forwards;
}

.animate-pulse {
  animation: pulse 2s infinite;
}

.animate-count-up {
  animation: countUp 1s ease-out forwards;
}

// Media queries per responsività
@media (max-width: 768px) {
  .referral-title {
    font-size: 2.8rem;
    margin-bottom: 1.5rem;
    line-height: 1.2;
  }
  
  .referral-subtitle {
    font-size: 1.4rem;
    line-height: 1.5;
    margin-bottom: 3.5rem;
    max-width: 90%;
    margin-left: auto;
    margin-right: auto;
  }
  
  .code-display {
    font-size: 1.8rem;
    padding: 0.8rem;
    flex-direction: column;
    gap: 0.8rem;
    
    .code-text {
      margin-bottom: 0.5rem;
    }
  }
  
  .copy-button-inline {
    width: 100%;
    padding: 0.7rem;
  }
  
  .step {
    margin-bottom: 1.5rem;
  }
}

@media (max-width: 480px) {
  .referral-container {
    padding: 1.5rem;
  }
  
  .referral-title {
    font-size: 2.8rem;
  }
  
  .referral-subtitle {
    font-size: 1.3rem;
    margin-bottom: 3rem;
  }
  
  .code-display {
    font-size: 1.6rem;
  }
  
  .stat-value {
    font-size: 2.8rem;
  }
}

// Mantieni le classi vecchie nascoste per compatibilità, ma le nuove avranno la precedenza
.ReferralCode {
  display: none;
}

// Nascondi il vecchio pulsante
.copy-button {
  display: none;
}

.code-generator {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  width: 100%;
  padding: 16px;
  
  .code-generator-text {
    font-size: 1.4rem;
    color: #555;
    text-align: center;
    margin-bottom: 8px;
  }
  
  .generate-code-button {
    background-color: var(--primary-color-400);
    color: white;
    border: none;
    border-radius: 8px;
    padding: 12px 24px;
    font-size: 1.5rem;
    font-weight: 600;
    cursor: pointer;
    transition: all 0.3s ease;
    width: 100%;
    max-width: 240px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    
    &:hover {
      background-color: var(--primary-color-500);
      transform: translateY(-2px);
      box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
    }
    
    &:disabled {
      background-color: #ccc;
      cursor: not-allowed;
      transform: none;
      box-shadow: none;
    }
  }
}

// Aggiungo stile per l'indicatore di caricamento
.code-verifying {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 20px 16px;
  text-align: center;
  
  p {
    font-size: 1.4rem;
    color: #666;
    margin-bottom: 12px;
    position: relative;
    
    &::after {
      content: '';
      display: inline-block;
      width: 12px;
      height: 12px;
      border: 2px solid var(--primary-color-400);
      border-radius: 50%;
      margin-left: 10px;
      border-top-color: transparent;
      animation: spin 1s linear infinite;
    }
  }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

// Aggiungo stili per il messaggio di errore e lo spinner di caricamento
.code-generation-error {
  color: #e53935;
  font-size: 1.4rem;
  margin-bottom: 1rem;
  background-color: rgba(229, 57, 53, 0.1);
  padding: 0.8rem 1.2rem;
  border-radius: 0.8rem;
  border-left: 3px solid #e53935;
}

.generate-code-button {
  // ... existing code ...
  
  &.loading {
    position: relative;
    background-color: #6b7280;
    cursor: not-allowed;
    
    &:hover {
      background-color: #6b7280;
    }
  }
  
  .loading-spinner {
    display: inline-block;
    width: 1.6rem;
    height: 1.6rem;
    margin-right: 0.8rem;
    border: 2px solid rgba(255, 255, 255, 0.3);
    border-radius: 50%;
    border-top-color: #ffffff;
    animation: spin 1s ease-in-out infinite;
    vertical-align: middle;
  }
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

.QuestionsSummary {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 3rem;

  &__section {
    display: flex;
    justify-content: space-between;
    text-align: center;
    padding-inline: 6rem;
    padding-bottom: 1.5rem;
    border-bottom: 1px solid #e5e7eb;

    &-item {
      p {
        font-family: Roboto;
        font-size: 1.2rem;
        font-weight: bold;
        margin: 0;
        color: var(--text-color);
      }

      .value {
        font-size: 1.8rem;
        font-weight: bold;
        &--green {
          color: #16a34a;
        }

        &--partial {
          color: var(--partial-color);
        }

        &--red {
          color: #dc2626;
        }

        &--default {
          color: var(--text-color);
        }
      }
    }
  }

  &__summary {
    padding-top: 1.5rem;
    border-top: 1px solid var(--border-color);

    &-title {
      font-size: 2rem;
      font-weight: 600;
      margin-bottom: 1rem;
      color: var(--text-color);
    }

    &-description {
      font-size: 1.3rem;
      color: #6b7280;
      margin-bottom: 1rem;
    }

    &-filter {
      width: 100%;
      position: relative;

      select {
        display: flex;
        width: 100%;
        padding: 0.75rem;
        border: 1px solid #e5e7eb;
        font-size: 1.3rem;
        font-weight: 700;
        border-radius: 0.7rem;
        cursor: pointer;
        appearance: none;
        margin-bottom: 1rem;
      }

      .icon {
        position: absolute;
        right: 1rem;
        top: 50%;
        transform: translateY(-50%);
        pointer-events: none;
        color: #6b7280;
      }
    }

    &-stats {
      font-size: 1.3rem;
      font-family: Roboto;
      color: #6b7280;
      margin-bottom: 1rem;
    }
  }
}

@import "./base/reset";
@import "./module/module";
@import "./layout/index";

/* @tailwind base;
@tailwind components;
@tailwind utilities; */

/* light mode */
:root {
  /* primary */
  --primary-color-50: #f0f9f2;
  --primary-color-100: #daf1de;
  --primary-color-200: #b7e3c2;
  --primary-color-300: #88cd9e;
  --primary-color-400: #68b984;
  --primary-color-500: #349559;
  --primary-color-600: #247745;
  --primary-color-700: #1d5f39;
  --primary-color-800: #194c2f;
  --primary-color-900: #163e28;
  --primary-color-950: #0b2316;

  /* secondary */
  --secondary-color-50: #fffbeb;
  --secondary-color-100: #fff2c6;
  --secondary-color-200: #fee589;
  --secondary-color-300: #fed049;
  --secondary-color-400: #fdbc22;
  --secondary-color-500: #f89a08;
  --secondary-color-600: #db7304;
  --secondary-color-700: #b65007;
  --secondary-color-800: #943d0c;
  --secondary-color-900: #79330e;
  --secondary-color-950: #461902;

  /* grayscale */
  --grayscale-color-50: #f5f8f7;
  --grayscale-color-100: #dee9e7;
  --grayscale-color-200: #bdd2d0;
  --grayscale-color-300: #95b3b1;
  --grayscale-color-400: #6e9391;
  --grayscale-color-500: #547877;
  --grayscale-color-600: #425f5f;
  --grayscale-color-700: #3d5656;
  --grayscale-color-800: #2f4040;
  --grayscale-color-900: #2a3737;
  --grayscale-color-950: #151d1e;

  /* feedback green */
  --feedbackGreen-color-50: #ebffe5;
  --feedbackGreen-color-100: #d2ffc7;
  --feedbackGreen-color-200: #a7ff95;
  --feedbackGreen-color-300: #83ff70;
  --feedbackGreen-color-400: #3ef625;
  --feedbackGreen-color-500: #1bdd05;
  --feedbackGreen-color-600: #0eb100;
  --feedbackGreen-color-700: #0d8605;
  --feedbackGreen-color-800: #11690b;
  --feedbackGreen-color-900: #10590e;
  --feedbackGreen-color-950: #013201;

  /* feedback red */
  --feedbackRed-color-50: #fff3f1;
  --feedbackRed-color-100: #ffe5e1;
  --feedbackRed-color-200: #ffcec7;
  --feedbackRed-color-300: #ffaba0;
  --feedbackRed-color-400: #ff8170;
  --feedbackRed-color-500: #f8513b;
  --feedbackRed-color-600: #e5351d;
  --feedbackRed-color-700: #c12914;
  --feedbackRed-color-800: #a02514;
  --feedbackRed-color-900: #842518;
  --feedbackRed-color-950: #480f07;

  /* font family */
  --font-family: "Roboto", sans-serif;

  /* input */
  --input-colorRgb-error: 248, 81, 59;
  --input-colorRgb-focus: 254, 208, 73;

  /* others */
  --dropdown-shadowColor-rgb: 222, 233, 231;
  --grayscale-color100-rgb: 222, 233, 231;
  --primary-color100-rgb: 255, 242, 198;
  --primary-color-400-rgb: 104, 185, 132;
  --primary-color-500-rgb: 52, 149, 89;
}

// /* define variables dark mode by adding data-theme  */
// [data-theme='dark'] {
// }

/* set background color website */
body {
  background-color: var(--grayscale-color-50);

  @media (max-width: 575px) {
    min-height: -webkit-fill-available;
  }
}

/* modify selection text style */
::selection {
  background-color: var(--grayscale-color-100);
}

/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
}

.DocumentsQA {
  width: 100%;
  padding: 1.6rem;
  border: 1px solid rgb(225, 225, 226);
  border-radius: 1.6rem;

  &__list {
    position: relative;
  }

  &__footer {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-margin-before: 1.6rem;
    margin-block-start: 2.5rem;

    &-content {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      gap: 1.6rem;

      // Stile per i controlli di navigazione (frecce)
      nav {
        display: flex;
        gap: 0.8rem;
      }
    }
  }
}
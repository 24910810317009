.Footer {
  padding-block: 6.4rem 3.2rem;
  background-color: var(--grayscale-color-100);
  box-shadow: 0px -2px 4px rgba(0, 0, 0, 0.05);
  position: relative;
  overflow: hidden;

  & > div:first-child:not(.container) {
    position: absolute;
    inset: 0;
    pointer-events: none;
  }

  & > .container {
    position: relative;
    z-index: 1;
  }

  & > div:first-child {
    z-index: 0;
  }

  @media (max-width: 768px) {
    padding-block: 3.2rem 2.4rem;
  }

  &__content {
    display: flex;
    flex-direction: column;
    gap: 4.8rem;
    max-width: 120rem;
    margin-inline: auto;
    padding-inline: 4rem;

    @media (max-width: 768px) {
      gap: 3.2rem;
      padding-inline: 1.6rem;
    }
  }

  &__main {
    display: flex;
    justify-content: space-between;
    gap: 2rem;
    padding-inline: 0;

    @media (max-width: 768px) {
      flex-direction: column-reverse;
      gap: 3.2rem;
      padding-inline: 0;
    }
  }

  &__brand {
    max-width: 28rem;
    flex-shrink: 0;
    position: relative;
    order: -1;
    margin-right: 4rem;

    @media (max-width: 768px) {
      max-width: 100%;
      margin-right: 0;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 3.2rem;
      order: 0;
    }
  }

  &__logo-wrapper {
    margin-bottom: 2.4rem;
    width: 20rem;
    margin-left: -2rem;

    @media (max-width: 768px) {
      width: 18rem;
      margin-bottom: 2rem;
      margin-left: 0;
      align-self: center;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  &__description {
    font-size: 1.6rem;
    color: var(--grayscale-color-600);
    margin-bottom: 2.4rem;
    line-height: 1.5;
    max-width: 26rem;

    @media (max-width: 768px) {
      font-size: 1.6rem;
      line-height: 1.5;
      margin-bottom: 0;
      text-align: center;
      padding-inline: 1.6rem;
      max-width: 100%;
    }
  }

  &__sections {
    display: flex;
    gap: 7rem;
    margin-right: 4rem;

    @media (min-width: 769px) {
      flex-wrap: nowrap;
      justify-content: flex-end;
      align-items: flex-start;
    }

    @media (max-width: 768px) {
      flex-direction: column;
      gap: 3.2rem;
      width: 100%;
      margin-right: 0;
    }
  }

  &__section {
    min-width: 13rem;
    
    @media (max-width: 768px) {
      text-align: center;
      padding-bottom: 0;
      border-bottom: none;
    }
  }

  &__section-title {
    font-size: 2rem;
    font-weight: 700;
    color: var(--grayscale-color-900);
    margin-bottom: 1.8rem;
    letter-spacing: -0.01em;

    @media (max-width: 768px) {
      font-size: 2.4rem;
      margin-bottom: 1.6rem;
    }
  }

  &__section-links {
    display: flex;
    flex-direction: column;
    gap: 1.4rem;
    list-style: none;

    @media (min-width: 769px) {
      li {
        white-space: nowrap;
      }
    }

    @media (max-width: 768px) {
      gap: 1.6rem;
      align-items: center;
    }
  }

  &__section-link {
    font-size: 1.6rem;
    color: var(--grayscale-color-600);
    text-decoration: none;
    transition: color 0.2s ease;
    padding: 0.2rem 0;

    @media (max-width: 768px) {
      font-size: 2rem;
      display: block;
      padding: 0.8rem;
    }

    &:hover {
      color: var(--grayscale-color-900);
    }
  }

  &__divider {
    width: 60%;
    height: 0.5px;
    background-color: var(--grayscale-color-200);
    margin-block: 2rem;
    margin-inline: auto;
    opacity: 0.7;

    @media (max-width: 768px) {
      width: 40%;
      margin-block: 1.6rem;
    }
  }

  &__bottom {
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 60%;
    margin-inline: auto;

    @media (max-width: 768px) {
      max-width: 100%;
      flex-direction: column;
      gap: 1.6rem;
    }
  }

  &__copyright {
    font-size: 1.4rem;
    color: var(--grayscale-color-600);
    text-align: center;

    @media (max-width: 768px) {
      font-size: 1.6rem;
      order: 1;
    }
  }

  &__socials-desktop {
    @media (max-width: 768px) {
      display: none;
    }
  }

  &__socials-mobile {
    display: none;

    @media (max-width: 768px) {
      display: block;
    }
  }
}

.Socials {
  @media (min-width: 769px) {
    margin-top: 0;
    padding-left: 0;
    
    &__list {
      justify-content: flex-start;
      gap: 2rem;
    }

    &__item {
      font-size: 2rem;
      color: var(--grayscale-color-600);
    }
  }

  @media (max-width: 768px) {
    margin-top: 0;
    order: 0;
    
    &__list {
      justify-content: center;
      gap: 2rem;
    }

    &__item {
      font-size: 2.4rem;
    }
  }
}


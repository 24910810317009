.VerifyEmailForm {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-block-start: 2rem;

  button {
    background-color: #30844F; // Colore verde di StudierAI
    color: #fff;
    border: none;
    padding: 1rem 2rem;
    font-size: 1rem;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;

    &:disabled {
      background-color: #a9a9a9; // Grigio per pulsante disabilitato
    }

    &:hover:not(:disabled) {
      background-color: #75b477; // Colore verde più scuro per hover
    }
  }
}

.VerifyEmail__contactSupport {
  text-align: center;
  color: var(--grayscale-color-600);
  margin-block-start: 2rem;

  &-link {
    text-decoration: underline;
    color: var(--secondary-color-500);
  }
}

@media (max-width: 768px) {
  .VerifyEmailForm {
    margin-block-start: 3rem;

    button {
      padding: 0.8rem 1.6rem;
      font-size: 1rem;
    }
  }

  .VerifyEmail__contactSupport {
    font-size: 0.9rem;
  }
}

@media (max-width: 480px) {
  .VerifyEmailForm {
    margin-block-start: 2rem;

    button {
      padding: 0.6rem 1.2rem;
      font-size: 0.9rem;
    }
  }

  .VerifyEmail__contactSupport {
    font-size: 0.8rem;
  }
}

.FolderCard {
  padding-inline: 0.6rem;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  min-width: 28rem;
  cursor: pointer;
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  box-sizing: border-box;
  margin-bottom: 0;
  
  &:hover {
    transform: translateY(-2px);
  }
  
  &:first-child {
    padding-inline-start: 0;
  }
  
  &--active > &__card {
    border: 1px solid var(--grayscale-color-300);
    background-color: rgba(var(--background-color-rgb), 0.98);
    box-shadow: 0 8px 16px -2px rgba(0, 0, 0, 0.08),
                0 4px 8px -2px rgba(0, 0, 0, 0.06),
                0 0 0 1px rgba(var(--primary-color-rgb), 0.06),
                0 0 8px 2px rgba(var(--primary-color-rgb), 0.04);
  }
  
  &__card {
    padding: 2.2rem;
    border-radius: 1.4rem;
    background-color: rgba(var(--background-color-rgb), 0.98);
    border: 1px solid var(--grayscale-color-200);
    transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
    min-height: 200px;
    display: flex;
    flex-direction: column;
    height: 100%;

    &:hover {
      background-color: rgba(var(--background-color-rgb), 1);
      box-shadow: 0 8px 16px -2px rgba(0, 0, 0, 0.08),
                  0 4px 8px -2px rgba(0, 0, 0, 0.06),
                  0 0 0 1px rgba(var(--primary-color-rgb), 0.03);
      border-color: var(--grayscale-color-300);
    }
  }
  
  @media (max-width: 768px) {
    padding-inline: 0.6rem;
    width: 100%;
    
    &:first-child {
      padding-inline-start: 0;
    }
  }
  
  &__row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1.8rem;
  }
  
  &__icon {
    color: var(--primary-color);
  }
  
  &__actions {
    display: flex;
    gap: 0.6rem;
    align-items: center;
    opacity: 0.7;
    transition: opacity 0.2s ease;
    
    .FolderCard__card:hover & {
      opacity: 1;
    }
    
    svg {
      width: 32px;
      height: 32px;
      cursor: pointer;
      transition: all 0.2s ease;
      padding: 6px;
      border-radius: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      
      &:hover {
        transform: scale(1.05);
      }
      
      &:active {
        transform: scale(0.95);
      }
    }
  }
  
  &__box {
    margin-bottom: 0;
  }
  
  &__name {
    font-weight: 600;
    margin-bottom: 4px;
    font-size: 1.5rem;
  }
  
  &__box-text {
    font-size: 1.4rem;
    color: var(--grayscale-color-600);
    font-weight: 400;
    margin: 0.8rem 0 1rem 0;
  }
  
  &__tags {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-top: auto;
  }
  
  &__document-count {
    display: inline-flex;
    white-space: nowrap;
    align-items: center;
    gap: 0.4rem;
    padding: 0.4rem 1rem;
    background-color: var(--primary-color-50);
    color: var(--primary-color-700);
    font-size: 1.2rem;
    font-weight: 500;
    border-radius: 0.6rem;
    transition: all 0.2s ease;
    border: 1px solid rgba(0, 0, 0, 0.05);
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
  }

  // Stile per Document_titleInput identico a quello dei documenti
  .Document_titleInput {
    padding: 0.4rem 0.8rem;
    margin-top: -0.2rem;
    margin-bottom: 0;
    height: auto;
    border-radius: 0.5rem;
    border: 1px solid var(--grayscale-color-200);
    font-size: 1.5rem;
    width: 100%;
    transition: all 0.2s ease;
    background-color: var(--background-color);
    color: var(--grayscale-color-900);
    font-weight: normal;
    line-height: 1.5;

    &:focus {
      outline: none;
      border-color: var(--primary-color-400);
      box-shadow: 0 0 0 3px rgba(var(--primary-color-rgb), 0.12);
    }
  }

  // Stili per componenti Document all'interno della FolderCard
  .Document__card {
    padding: 2.2rem;
    border-radius: 1.4rem;
    background-color: rgba(var(--background-color-rgb), 0.98);
    border: 1px solid var(--grayscale-color-200);
    transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
    min-height: 200px;
    display: flex;
    flex-direction: column;
    height: 100%;

    &:hover {
      background-color: rgba(var(--background-color-rgb), 1);
      box-shadow: 0 8px 16px -2px rgba(0, 0, 0, 0.08),
                  0 4px 8px -2px rgba(0, 0, 0, 0.06),
                  0 0 0 1px rgba(var(--primary-color-rgb), 0.03);
      border-color: var(--grayscale-color-300);
    }
  }

  .Document__row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1.8rem;
  }

  .Document__box {
    position: relative;

    &-text {
      margin-top: 0.8rem;
      font-size: 1.4rem;
      color: var(--grayscale-color-600);
      font-weight: 400;
    }
  }

  .Document__actions {
    display: flex;
    gap: 0.6rem;
    align-items: center;
    opacity: 0.7;
    transition: opacity 0.2s ease;

    .Document__card:hover & {
      opacity: 1;
    }
  }

  .Document__editIcon, .Document__deleteIcon, .Document__saveIcon {
    width: 32px;
    height: 32px;
    cursor: pointer;
    transition: all 0.2s ease;
    padding: 6px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
      transform: scale(1.05);
    }

    &:active {
      transform: scale(0.95);
    }
  }

  .Document__editIcon {
    color: var(--grayscale-color-600);
    background-color: rgba(var(--grayscale-color-950-rgb), 0.06);

    &:hover {
      color: var(--grayscale-color-900);
      background-color: rgba(var(--grayscale-color-950-rgb), 0.09);
    }
  }

  .Document__deleteIcon {
    color: var(--grayscale-color-600);
    background-color: rgba(239, 68, 68, 0.06);

    &:hover {
      color: rgb(239, 68, 68);
      background-color: rgba(239, 68, 68, 0.09);
    }
  }

  .Document__saveIcon {
    color: rgb(16, 185, 129);
    background-color: rgba(16, 185, 129, 0.06);

    &:hover {
      background-color: rgba(16, 185, 129, 0.09);
    }
  }
}
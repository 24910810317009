.VideoContainer {
  width: 100%;
  padding-left: 20px;

  @media (max-width: 768px) {
    width: 100%;
    padding-left: 0;
    padding-top: 20px;
  }

  .video-wrapper {
    position: relative;
    border-radius: 16px;
    overflow: hidden;
    background: #000;
    box-shadow: 0 12px 48px rgba(0, 0, 0, 0.12);
    transition: transform 0.3s ease;

    &:hover {
      transform: translateY(-5px);
    }
  }

  .play-button-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: rgba(0, 0, 0, 0.6);
    cursor: pointer;
    transition: background 0.3s ease;
    border-radius: 16px;

    @media (max-width: 768px) {
      gap: 1rem;
    }

    &:hover {
      background: rgba(0, 0, 0, 0.7);

      .play-button {
        transform: scale(1.1);
        background: rgba(255, 255, 255, 0.2);
      }

      .play-text {
        transform: translateY(5px);
      }
    }
  }

  .play-button {
    width: 80px;
    height: 80px;
    background: rgba(255, 255, 255, 0.15);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.3s ease;
    margin-bottom: 20px;
    backdrop-filter: blur(4px);
    position: relative;

    @media (max-width: 768px) {
      width: 60px;
      height: 60px;
      margin-bottom: 12px;
    }
  }

  .play-icon {
    color: white;
    font-size: 30px;
    position: absolute;
    left: 53%;
    top: 52%;
    transform: translate(-50%, -50%);
    filter: drop-shadow(0 2px 4px rgba(0, 0, 0, 0.2));

    @media (max-width: 768px) {
      font-size: 24px;
      top: 53%;
    }
  }

  .play-text {
    color: white;
    font-size: 1.8rem;
    font-weight: 600;
    text-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
    transition: transform 0.3s ease;
    text-align: center;

    @media (max-width: 768px) {
      font-size: 1.6rem;
      padding: 0 1rem;
    }
  }

  iframe {
    width: 100%;
    border-radius: 16px;
    display: block;
    aspect-ratio: 16/9;
    background: #000;

    @media (min-width: 1149px) {
      height: 350px;
    }

    @media (max-width: 1149px) {
      height: 300px;
    }

    @media (max-width: 768px) {
      height: 250px;
    }

    @media (max-width: 575px) {
      height: 200px;
    }

    @media (max-width: 400px) {
      height: 230px;
    }
  }
}
.UserDropdown {
  inset-block-start: calc(100% + 1rem);
  min-width: 24rem;
  overflow: hidden;
  border-radius: 1.2rem;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.08);

  &__item {
    padding: 1rem 1.6rem;
    margin-block: 0.2rem;
    line-height: 100%;
    cursor: pointer;
    transition: all 0.2s ease;
    border-radius: 0.8rem;
    margin-inline: 0.4rem;
    text-decoration: none;
    color: var(--grayscale-color-950);

    & > h5 {
      white-space: nowrap;
      margin-bottom: 0.4rem;
    }

    &--profile {
      padding-block: 1.6rem;
      margin-block-start: 0;
      border-block-end: 1px solid var(--grayscale-color-200);
      background: linear-gradient(to right, rgba(var(--primary-color-rgb), 0.03), rgba(var(--primary-color-rgb), 0.01));
    }

    &-icon {
      font-size: 2rem;
      color: var(--grayscale-color-950);
      transition: transform 0.2s ease;
    }

    &-text {
      font-size: 1.5rem;
      color: inherit;
      font-weight: 500;
    }

    &:has(> &-icon) {
      display: flex;
      align-items: center;
      column-gap: 1.2rem;
    }

    &--logout {
      margin-block-end: 0;
      color: #dc3545;
      
      .UserDropdown__item-icon {
        color: #dc3545;
      }
      
      .UserDropdown__item-text {
        color: #dc3545;
      }

      &:hover,
      &:active {
        background-color: rgba(220, 53, 69, 0.08);
        .UserDropdown__item-icon,
        .UserDropdown__item-text {
          color: #dc3545;
        }
      }
    }

    &:hover,
    &:active {
      background-color: rgba(var(--success-color-rgb), 0.08);
      transform: translateX(4px);
      color: var(--success-color);

      .UserDropdown__item-icon {
        transform: scale(1.1);
        color: var(--success-color);
      }

      .UserDropdown__item-text {
        color: var(--success-color);
      }
    }
  }

  &__body {
    padding-block: 0.8rem;
  }

  &__footer {
    border-block-start: 1px solid var(--grayscale-color-200);
    margin-block: 0.4rem 0;
    padding-block-start: 0.4rem;
  }
}

.ChatModalFreeSubscription {


  &__header {
    padding: 1.6rem;
    -webkit-border-after: 1px solid var(--grayscale-color-100);
    border-block-end: 1px solid var(--grayscale-color-100);
    border-inline: 1px solid var(--grayscale-color-100);
  }
  
  &__alert-icon {
    color: #e19b0e;
  }

  &__content {
    padding: 1.6rem;
    font-family: 'Roboto';

    &-wrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: .8rem;
      background: #f8f8f8;
      border-radius: 1rem;
      padding-block: 1.3rem;
      padding-inline: 1.3rem;
      margin-top:1rem;
    }

    &-text-wrapper {
      display: flex;
      align-items: center;
      gap: .8rem;
    }

    &-span {
      font-family: 'Roboto';
      font-size: 1.4rem;
      font-weight: 500;
      background: #e0dfdf;
      border-radius: 1.5rem;
      padding-inline: 1.5rem;
      padding-block: .7rem;
    }

    &-text {
      font-family: 'Roboto';
    }
  }
  
  &__link:visited {
    color: var(--primary-color-500);
    text-decoration: underline;
  }
  
  &__button {
    margin-block-start: 1.6rem;
    width: 100%;
  }
  
}



.chat-container-inactive {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  border: 1.5rem solid #eff0f2;
  border-radius: 1.2rem;
  
  border-bottom-left-radius: 0.75rem;
  border-bottom-left-radius: 0.75rem;
  border-bottom-right-radius: 0.75rem;
  border-bottom-right-radius: 0.75rem;
  width: 100%;
  background-color: #ffffff;
  height: 0;
  z-index: -1;
}

.chat-container {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  margin-top: 1rem;
  padding: 1rem;
  border: 1.5rem solid #eff0f2;
  border-radius: 1.2rem;
  
  border-bottom-left-radius: 0.75rem;
  border-bottom-left-radius: 0.75rem;
  border-bottom-right-radius: 0.75rem;
  border-bottom-right-radius: 0.75rem;
  width: 100%;
  background-color: #ffffff;
  
  
    opacity: 1;
    transition: ease-in-out;
    transition-duration: .3s;
  
  .chat-messages {
    display: flex;
    flex-direction: column;
    align-items: start;
    height: 12rem;
    overflow-y: auto;
    margin-bottom: 1rem;
    font-size: 1.4rem;
    
    @media(min-width: 740px) and (max-height: 1030px) {
      height: 9rem;
    }

    .message {
      max-width: 60%;
      margin-bottom: 0.5rem;
      padding: 0.7rem;
      border-radius: 0.5rem;
      word-wrap: break-word; 
      word-break: break-word; 

      &.user {
        background-color: #bfdbfe;
        align-self: flex-end;
      }
    }
  }

  .input-container {
    display: flex;
    align-items: center;

    .chat-input {
      height: 100%;
      flex: 1;
      padding: 1rem;
      border: 1px  solid #1d4ed8;
      border-radius: 0.5rem;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      font-size: 1.3rem;
    }
    
    .send-button {
      padding: .82rem;
      background-color: #1d4ed8;
      color: #ffffff;
      border-radius: 0.3rem;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      border: none;
      transition: background-color 0.3s;
      cursor: pointer;

      @media(max-width: 991px) {
        padding: .83rem;
      }

      @media(max-height: 1050px) {
        padding: .84rem;
      }

      &:hover {
        background-color: #1944ba;
      }

      &.inactive-btn {
        pointer-events: none;

        &:hover {
          background-color: #1d4ed8;
        }
      }
    }
  }

  @media (max-width: 534px) {
    .chat-messages {
      height: 7rem;
    }   
  }
}

.DropMenudown {
  position: relative;
  display: inline-block;
  touch-action: manipulation;
  -webkit-tap-highlight-color: transparent;

  // Variabili tema
  --primary-green: #74B094;
  --hover-green: #5C8F77;
  --dropdown-bg: #FFFFFF;
  --text-color: #333333;
  --transition-speed: 200ms;
  --menu-font-size: 1.7rem;
  --dropdown-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);

  // Comportamento desktop (non-touch)
  @media (hover: hover) and (pointer: fine) {
    &:hover {
      .DropMenudown__content {
        opacity: 1;
        visibility: visible;
        pointer-events: auto;
        transform: translateX(-45%) translateY(0);
      }
    }
  }

  &__trigger {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    padding: 0.8rem 1.2rem;
    font-size: var(--menu-font-size);
    color: var(--text-color);
    font-family: var(--font-family);
    font-weight: bold;
    background: transparent;
    border: none;
    cursor: pointer;
    transition: color var(--transition-speed) ease;
    -webkit-tap-highlight-color: transparent;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    outline: none;

    &:hover,
    &--active {
      color: var(--primary-green);
      text-decoration: underline;
      text-underline-offset: 0.5rem;
    }

    // Stile specifico per dispositivi touch
    @media (hover: none) and (pointer: coarse) {
      padding: 1.5rem;
      min-height: 44px; // Dimensione minima per target touch
      
      &:active,
      &--active {
        color: var(--primary-green);
      }
    }
  }

  &__chevron {
    transition: transform var(--transition-speed) ease;
    color: currentColor;
    margin-left: 0.2rem;
    pointer-events: none;

    &--rotated {
      transform: rotate(180deg);
    }
  }

  &__content {
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateX(-45%) translateY(0);
    min-width: 22rem;
    background: var(--dropdown-bg);
    border-radius: 0.8rem;
    box-shadow: var(--dropdown-shadow);
    opacity: 0;
    visibility: hidden;
    transition: opacity 200ms ease, visibility 200ms ease, transform 200ms ease;
    z-index: 1000;
    padding: 0.8rem 0;
    pointer-events: none;

    // Stile specifico per dispositivi touch
    @media (hover: none) and (pointer: coarse) {
      padding: 1.2rem 0;
      -webkit-overflow-scrolling: touch;
      touch-action: pan-y;
      transform: translateX(-45%) translateY(0.5rem);
    }

    &--visible {
      opacity: 1;
      visibility: visible;
      pointer-events: auto;
      transform: translateX(-45%) translateY(0);
    }
  }

  &__items {
    display: flex;
    flex-direction: column;
  }

  &__item {
    width: 100%;
    text-align: left;
    padding: 1.2rem 1.5rem;
    font-size: 1.6rem;
    color: var(--text-color);
    background: transparent;
    border: none;
    cursor: pointer;
    transition: background-color var(--transition-speed) ease;
    font-family: var(--font-family);
    font-weight: bold;
    -webkit-tap-highlight-color: transparent;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    min-height: 44px; // Dimensione minima per target touch
    outline: none;

    &:hover {
      background-color: rgba(0, 0, 0, 0.05);
      color: var(--primary-green);
    }

    // Stile specifico per dispositivi touch
    @media (hover: none) and (pointer: coarse) {
      padding: 1.5rem;
      font-size: 1.8rem;
      
      &:active {
        background-color: rgba(0, 0, 0, 0.05);
        color: var(--primary-green);
      }
    }
  }
}
  
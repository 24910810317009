.ChatCardForm {

  &--disabled {
    opacity: 0.5;
    pointer-events: none;
    
  }

  &__field {
    -webkit-margin-after: 2.8rem;
    margin-block-end: 2.8rem;
  }

  &__label {
    position: absolute;
  }

  &__group {
    position: relative;
  }
}

.chat-inactive {
  margin-bottom: 2rem;
}

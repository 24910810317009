.SignUp {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  padding-block: 3.2rem;
  min-height: 100vh;

  &__leftBanner {
    width: 100%; 
    background-size: contain; 
    background-repeat: no-repeat;
    background-position: center center; 
    min-height: 52rem; // Altezza minima per desktop
  }

  @media (min-width: 992px) {
    .col-lg-6 {
      margin-left: -12rem;
    }
  }

  &__leftBanner {
    @media (max-width: 991px) {
      min-height: auto;
      max-height: 42rem;
    }

    @media (max-width: 757px) {
      max-height: 32rem;
    }
  }
}

.feedback-container {  
  display: flex;
  justify-content: center;
  background-color: #ffffff;
  border-radius: 0.75rem;
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
  padding: 1.5rem;
  overflow: auto;
  
  .feedback-content {
    position: relative;
    max-width: 35rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .feedback-title {
      font-size: 2.8rem;
      font-weight: 600;
      color: #000000;
      margin-bottom: 1.3rem;
    }
    
    .noFeedback-container {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 100%;
    }

    .noFeedback {
      color: #9ca3af;
      @media (max-width: 991px) {
        margin-bottom: 3rem;
      }
    }

      .positives {
        background-color: #1ab85434;
        border-radius: .8rem;
        padding: 1rem;
        margin-bottom: 1.5rem;
        
        .positives-row {
          display: flex;
          align-items: center;
          column-gap: 1rem;
        }

        .checkCircle {
          color: #16a34a;
          height: 30px;
        }

        .positive-feedback-subtitle {
          color: #16a34a;
          font-weight: 600;
        }

        ul {
          list-style: disc;  
          padding-left: 2.2rem;
          font-size: 1.6rem;
          margin-top: .7rem;
          line-height: 2.6rem;
        }
      }

      .negatives {
        margin-bottom: 1.5rem;
        background-color: #dc262639;
        border-radius: .8rem;
        padding: 1rem;

        .negatives-row {
          display: flex;
          align-items: center;
          column-gap: 1rem;
        }

        .circleX {
          color: #dc2626;
        }

        .negative-feedback-subtitle {
          font-weight: 600;
          color: #dc2626;
        }

        ul {
          list-style-type: disc;
          margin-left: 2.2rem;
          font-size: 1.6rem;
          margin-top: .7rem;
          line-height: 2.6rem;
        }
      }

      .suggestions {
        background-color: #bfdbfe;
        border-radius: .8rem;
        padding: 1rem;

        .suggestions-row {
          display: flex;
          align-items: center;
          column-gap: 1rem;
          color: #2563eb;
          margin-bottom: .7rem
        }

        .suggestions-title {
          color: #2563eb;
          font-weight: 600;
        }
      }
    

    .feedback-unavailable {
      color: #6b7280;
    }

    .btns-container {
      display: flex;
      flex-direction: column;
      row-gap: 1rem;
      padding-top: 2rem;


      @media (max-width: 991px) {
       display: block;
       padding: 0;
      }
    }
  }

  .finish-exam-btn {
    width: 100%;
    margin: 0 auto;
    display: flex;
    gap: 1rem;
    align-items: center;
    justify-content: center;
    padding-block: 1rem;
    color: rgb(139, 139, 139);
    background-color: hsl(220, 10%, 83%);
    border: none;
    border-radius: 2rem;
    cursor: auto;

    &.active {
      background-color: #dc2626;
      color: #ffffff;
      cursor: pointer;

      
      &:hover {
        background-color: #bb2020;
      }
    }

    &.exam-finished-btn {
      background-color:#16a34a;

      &:hover {
        background-color: #149343;
      }
    }

    &.finish-btn-disabled {
      pointer-events: none;
      color: rgb(139, 139, 139);
      background-color: hsl(220, 10%, 83%);
    }

    .btn-text {
      font-size: 1.6rem;
      font-weight: 500;
    }
    
    @media (max-width: 991px) {
      position: static;
      margin-top: 1rem;
    }
  }
  
  .retry-btn {
    width: 100%;
    margin: 0 auto;
    display: flex;
    gap: 1rem;
    align-items: center;
    justify-content: center;
    padding-block: 1rem;
    color: #ffffff;
    border: none;
    border-radius: 2rem;
    cursor: pointer;

    .btn-text {
      font-size: 1.6rem;
      font-weight: 500;
    } 

    &.active {
      background-color: #0073CE;

      &:hover {
        background-color: #006bc2;
      }
    }

    &.retry-btn-disabled {
      pointer-events: none;
      color: rgb(139, 139, 139);
      background-color: hsl(220, 10%, 83%);
    }
  
    @media (max-width: 991px) {
      position: static;
      margin-top: 1rem;
    }
  }

  .next-btn {
    width: 100%;
    margin: 0 auto;
    display: flex;
    gap: 1rem;
    align-items: center;
    justify-content: center;
    padding-block: 1rem;
    color: #ffffff;
    border: none;
    border-radius: 2rem;
    cursor: pointer;

    .btn-text {
      font-size: 1.6rem;
      font-weight: 500;
    } 

    &.active {
      background-color: #28A72D;

      &:hover {
        background-color: #269e2a;
      }
    }

    &.next-btn-disabled {
      pointer-events: none;
      color: rgb(139, 139, 139);
      background-color: hsl(220, 10%, 83%);
    }

    @media (max-width: 991px) {
      position: static;
      margin-top: 3rem;
    }
  }
}
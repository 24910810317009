.DocumentsQAActions {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-column-gap: 0.8rem;
  -moz-column-gap: 0.8rem;
  column-gap: 0.8rem;

  &__btn {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    width: 3.6rem;
    height: 3.6rem;
    padding: 0;

    & > svg {
      -webkit-margin-end: 0 !important;
      margin-inline-end: 0 !important;
      font-size: 1.6rem;
      color: var(--grayscale-color-600);

      &:hover,
      &:active {
        color: var(--grayscale-color-950);
      }
    }
  }
}

.DocumentsQALoader {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  row-gap: 1.6rem;
  -webkit-margin-before: 5.6rem;
  margin-block-start: 5.6rem;

  &__loading-process {
    text-align: center;
  }

  &__progressBar-container {
    width: 16rem;
    background-color: #e5e7eb;
    border-radius: 9999px;
    height: 0.625rem;

    &__progress {
      background-color: #68b984;
      height: 0.625rem;
      border-radius: 9999px;
      transition: all 0.5s ease-out;
    }
  }
}

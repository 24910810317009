.hint-button-container {
  position: relative;

  .hint-button {
    background: #F5B800;
    border: #F5B800;
    color: white;
    padding: 8px;
    border-radius: 50%;
    transition: all 0.3s ease;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover:not(:disabled) {
      background: #F5B800;
      color: white;
    }

    &.active {
      color: white;
      background: #F5B800;
    }

    &:disabled {
      cursor: not-allowed;
      opacity: 0.5;
    }
  }

  .hint-popup {
    position: absolute;
    bottom: 100%;
    transform: translateX(-50%);
    background: #F5B800;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    padding: 1rem;
    min-width: 250px;
    margin-bottom: .5rem;
    z-index: 1000;
    border: 1px solid #F5B800;

    .hint-content {
      display: flex;
      align-items: center;
      gap: 0.5rem;
      margin-bottom: 1rem;
      color: white;

      .hint-icon {
        flex-shrink: 0;
        color: white;
      }
    }

    .hint-navigation {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .hint-nav-button {
        background: #e1e1e16e;
        border: none;
        padding: 0.25rem;
        cursor: pointer;
        color: #F5B800;

        &:hover:not(:disabled) {
          color: white;
        }

        &:disabled {
          opacity: 0.5;
          cursor: not-allowed;
        }
      }

      .hint-indicator {
        display: flex;
        gap: 0.25rem;

        span {
          width: 6px;
          height: 6px;
          border-radius: 50%;
          background: #00000033;

          &.active {
            background: #ffeeba;
          }
        }
      }
    }
  }
} 
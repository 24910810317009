.ExamsInProgress {
  margin-block: 4.4rem;

  &__title {
    font-size: 2.4rem !important;
    -webkit-margin-after: 1.6rem;
    margin-block-end: 1.6rem;
  }

  &__text {
    -webkit-margin-before: 1.6rem;
    margin-block-start: 1.6rem;
  }

  &__list {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    row-gap: 1.6rem;
    max-height: 26.4rem;
    overflow-y: scroll;
  }
}

.PasswordToggle {
  position: absolute;
  top: 1.6rem;
  right: 1.6rem;
  font-size: 2rem;
  color: var(--primary-color-500);
  cursor: pointer;

  &:hover {
    color: var(--primary-color-600);
  }
}

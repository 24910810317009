.MenuExamMode {
  background: white;
  border-radius: 1rem;
  padding: 2rem;
  padding-inline: 2.5rem;
  max-width: 60rem;
  
  @media (max-width: 575px) {
    margin-inline: 1rem;
  }
  
  @media (max-width: 380px) and (max-height: 700px) {
    padding: 1.5rem;
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: start;
    margin-bottom: 3rem;

    @media (max-width: 380px) and (max-height: 700px) {
      margin-bottom: 1.1rem;
    }

    &__title {
      font-size: 2.5rem;
      font-weight: 700;
      margin-bottom: 0.8rem;
    }

    &__subtitle {
      font-size: 1.5rem;
      font-weight: 400;
      font-family: Roboto;
    }

    &__close-button {
      color: #6b7280;
      transition: all 0.3s ease;
      margin-top: 1.1rem;
      cursor: pointer;
      width: auto;
      height: auto;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 0;
      background-color: transparent;
      
      @media (max-width: 380px) {
        display: none;
      }

      &:hover {
        color: #e11d48;
        background-color: transparent;
        transform: rotate(-90deg);
      }
    }
  }

  &__review-mode {
    box-shadow: 0 0 0 2px #05862e5e;
    border-radius: 1rem;
    padding: 1.5rem;
    margin-bottom: 2.5rem;
    font-family: Roboto;
    cursor: pointer;

    @media (max-width: 380px) and (max-height: 700px) {
      margin-bottom: 1.1rem;
    }

    &--active {
      box-shadow: 0 0 0 3px #059669;
    }


    &-title-container {
      display: flex;
      align-items: center;
      column-gap: .7rem;
    }

    &-icon {
      color: #019d01;
    }

    &-title {
      font-size: 1.8rem;
      font-weight: 700;
    }

    &-list {
      list-style: none;
      padding: 0;
      margin-top: 1rem;
      font-size: 1.4rem;
      display: flex;
      flex-direction: column;
      row-gap: .9rem;

      &-item {

        &:before {
          content: "·";
          font-size: 6.1rem;
          vertical-align: middle;
          line-height: 6px;
          color: green;
          margin-right: .5rem;
      }
      }
    }
  }

  &__interrogation-mode {
    box-shadow: 0 0 0 2px #b0d0f9;
    border-radius: 1rem;
    padding: 1.5rem;
    margin-bottom: 2rem;
    font-family: Roboto;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    transition: all 0.3s ease;

    @media (max-width: 380px) and (max-height: 700px) {
      margin-bottom: 1.1rem;
    }

    &--active {
      box-shadow: 0 0 0 3px #2563eb;
    }

    &--locked {
      opacity: 0.95;
      box-shadow: 0 0 0 2px #b0d0f9;
      cursor: not-allowed;
      position: relative;
      background: linear-gradient(135deg, rgb(240 249 255 / 35%) 0%, rgb(240 249 255 / 65%) 100%);
      border: 1px solid rgb(59 130 246 / 45%);
      
      .MenuExamMode__interrogation-mode-title-container,
      .MenuExamMode__interrogation-mode-list {
        filter: none;
        opacity: 1;
      }
      
      &:hover {
        box-shadow: 0 0 0 2px #93c5fd;
        border-color: rgb(59 130 246 / 65%);
        transform: none;
      }
    }

    &-title-container {
      display: flex;
      align-items: center;
      column-gap: .7rem;
    }

    &-title-wrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
    }

    &-icon {
      color: #2563eb;
    }

    &-title {
      font-size: 1.8rem;
      font-weight: 700;
      display: flex;
      align-items: center;
    }

    &-beta {
      font-size: 1.2rem;
      font-weight: 600;
      color: #ffffff;
      margin-left: 0.8rem;
      background: linear-gradient(135deg, #2563eb 0%, #2563eb 100%);
      padding: 0.3rem 0.7rem;
      border-radius: 0.8rem;
      position: relative;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      text-transform: uppercase;
      letter-spacing: 0.05rem;
      box-shadow: 0 2px 4px rgba(37, 99, 235, 0.3);
      overflow: hidden;
      transition: all 0.3s ease;
      transform: translateY(-1px);
      
      &::before {
        content: '';
        position: absolute;
        top: 0;
        left: -100%;
        width: 100%;
        height: 100%;
        background: linear-gradient(
          90deg,
          rgba(255, 255, 255, 0) 0%,
          rgba(255, 255, 255, 0.2) 50%,
          rgba(255, 255, 255, 0) 100%
        );
        animation: shine 3s infinite;
      }
      
      @keyframes shine {
        0% {
          left: -100%;
        }
        20% {
          left: 100%;
        }
        100% {
          left: 100%;
        }
      }
      
      &:hover {
        transform: translateY(-2px);
        box-shadow: 0 4px 8px rgba(59, 130, 246, 0.4);
      }
      
      &-icon {
        font-size: 1rem;
        margin-right: 0.4rem;
        animation: wobble 5s infinite;
      }
      
      @keyframes wobble {
        0%, 100% {
          transform: rotate(0deg);
        }
        25% {
          transform: rotate(-5deg) scale(1.1);
        }
        75% {
          transform: rotate(5deg) scale(1.1);
        }
      }
    }

    &-lock {
      display: none;
    }

    &-list {
      list-style: none;
      padding: 0;
      margin-top: 1rem;
      font-size: 1.4rem;
      display: flex;
      flex-direction: column;
      row-gap: .9rem;

      &-item {

        &:before {
          content: "·";
          font-size: 6.1rem;
          vertical-align: middle;
          line-height: 6px;
          color: #2563eb;
          margin-right: .5rem;
        }
      }
    }
  }

  &__warning-container {
    background: #fff7ed;
    border: 1px solid #fb923c;
    border-radius: .8rem;
    padding: 1.5rem 1.5rem;
    display: flex;
    align-items: start;
    column-gap: .8rem;
    margin-bottom: 2.5rem;
    box-shadow: 0 1px 3px rgba(249, 115, 22, 0.1);
    transition: all 0.3s ease;

    @media (max-width: 380px) and (max-height: 700px) {
      margin-bottom: 1.1rem;
    }

    &:hover {
      box-shadow: 0 2px 5px rgba(249, 115, 22, 0.2);
      transform: translateY(-1px);
    }

    &-icon {
      color: #f97316;
      min-width: 25px;
    }

    &-text {
      font-size: 1.3rem;
      font-weight: 500;
      font-family: Roboto;
      line-height: 1.5;
      color: #000000;
    }
  }

  &__button-container {
    display: flex;
    justify-content: end;
    align-items: center;
    column-gap: 1.2rem;
    margin-top: 1rem;

    &__button {
      border: none;
      border-radius: 0.8rem;
      padding: 1.1rem 2.5rem;
      transition: all 0.3s ease;
      cursor: pointer;
      font-weight: 600;
      font-size: 1.5rem;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      overflow: hidden;
      gap: 0.8rem;
      
      &-icon-cancel {
        transition: all 0.3s ease;
      }
      
      &-icon-start {
        display: none;
      }
      
      &:disabled {
        opacity: 0.6;
        cursor: not-allowed;
        transform: translateY(0) !important;
        box-shadow: none !important;
      }
      
      &:active {
        transform: scale(0.98);
      }
    }

    &--cancel {
      color: #4b5563;
      background: transparent;
      border: 2px solid #e5e7eb;
      box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
      
      .MenuExamMode__button-container__button-icon-cancel {
        color: #6b7280;
      }
      
      &:hover {
        background: #f9fafb;
        color: #1f2937;
        border-color: #d1d5db;
        transform: translateY(-2px);
        box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1);
        
        .MenuExamMode__button-container__button-icon-cancel {
          color: #4b5563;
          transform: rotate(-90deg);
        }
      }
      
      &:active {
        background: #f3f4f6;
      }
    }

    &--start {
      color: white;
      background: linear-gradient(135deg, #059669 0%, #059669 100%);
      box-shadow: 0 4px 6px -1px rgba(5, 150, 105, 0.2), 0 2px 4px -1px rgba(5, 150, 105, 0.1);
      position: relative;
      
      &::before {
        content: '';
        position: absolute;
        top: 0;
        left: -100%;
        width: 100%;
        height: 100%;
        background: linear-gradient(
          90deg,
          rgba(255, 255, 255, 0) 0%,
          rgba(255, 255, 255, 0.2) 50%,
          rgba(255, 255, 255, 0) 100%
        );
        animation: button-shine 6s infinite;
      }
      
      @keyframes button-shine {
        0% {
          left: -100%;
        }
        15% {
          left: 100%;
        }
        100% {
          left: 100%;
        }
      }
      
      .MenuExamMode__button-container__button-icon-start {
        display: none;
      }
      
      &:hover {
        background: linear-gradient(135deg, #047857 0%, #047857 100%);
        transform: translateY(-2px);
        box-shadow: 0 10px 15px -3px rgba(5, 150, 105, 0.3), 0 4px 6px -2px rgba(5, 150, 105, 0.1);
        
        .MenuExamMode__button-container__button-icon-start {
          display: none;
        }
      }
      
      &-blue {
        background: linear-gradient(135deg, #2563eb 0%, #2563eb 100%);
        box-shadow: 0 4px 6px -1px rgba(37, 99, 235, 0.2), 0 2px 4px -1px rgba(37, 99, 235, 0.1);
        
        &::before {
          background: linear-gradient(
            90deg,
            rgba(255, 255, 255, 0) 0%,
            rgba(255, 255, 255, 0.2) 50%,
            rgba(255, 255, 255, 0) 100%
          );
          animation: button-shine 6s infinite;
        }
        
        &:hover {
          background: linear-gradient(135deg, #1d4ed8 0%, #1d4ed8 100%);
          box-shadow: 0 10px 15px -3px rgba(37, 99, 235, 0.3), 0 4px 6px -2px rgba(37, 99, 235, 0.1);
          
          .MenuExamMode__button-container__button-icon-start {
            display: none;
          }
        }
        
        &:active {
          background: linear-gradient(135deg, #1d4ed8 0%, #1d4ed8 100%);
          transform: translateY(0);
          box-shadow: 0 4px 6px -1px rgba(37, 99, 235, 0.2);
        }
      }
    }
  }

  &__premium-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(
      to bottom,
      transparent 0%,
      rgb(240 249 255 / 65%) 90%
    );
    backdrop-filter: blur(1.5px);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;
    border-radius: 0.9rem;
    transition: all 0.3s ease;
  }

  &__premium-content {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background-color: rgb(240 249 255 / 75%);
    padding: 0.8rem 1.2rem;
    border-radius: 0.8rem;
    border: 1px dashed rgb(59 130 246 / 55%);
    gap: 0.8rem;
    transition: all 0.25s ease;
    z-index: 1;
    
    &:hover {
      background-color: rgb(240 249 255 / 90%);
      border-color: rgb(59 130 246 / 70%);
    }

    &--upgrade {
      background-color: #2563eb;
      border: none;
      padding: 0;
      border-radius: 0.8rem;
      transition: all 0.3s ease;
      box-shadow: 0 4px 6px -1px rgba(37, 99, 235, 0.2), 0 2px 4px -1px rgba(37, 99, 235, 0.1);
      width: auto;
      margin: 0 auto;
      display: flex;
      justify-content: center;
      position: relative;
      overflow: hidden;

      &::before {
        content: '';
        position: absolute;
        top: 0;
        left: -100%;
        width: 100%;
        height: 100%;
        background: linear-gradient(
          90deg,
          rgba(255, 255, 255, 0) 0%,
          rgba(255, 255, 255, 0.2) 50%,
          rgba(255, 255, 255, 0) 100%
        );
        animation: premium-button-shine 6s infinite;
      }
      
      @keyframes premium-button-shine {
        0% {
          left: -100%;
        }
        15% {
          left: 100%;
        }
        100% {
          left: 100%;
        }
      }

      &:hover {
        background-color: #1d4ed8;
        transform: translateY(-1px);
        box-shadow: 0 6px 10px -1px rgba(37, 99, 235, 0.3), 0 4px 6px -2px rgba(37, 99, 235, 0.1);
      }

      &:active {
        transform: translateY(0);
        box-shadow: 0 4px 6px -1px rgba(37, 99, 235, 0.2);
      }
    }
  }

  &__premium-lock-icon {
    font-size: 1.5rem;
    color: rgb(37 99 235);
    transition: all 0.2s ease;

    &--white {
      color: white;
      font-size: 1.7rem;
      margin-left: 1.2rem;
    }
  }

  &__premium-text {
    font-family: roboto;
    font-size: 1.35rem;
    color: rgb(37 99 235);
    font-style: italic;
    font-weight: 500;
    letter-spacing: 0.02rem;
    margin: 0;
    transition: all 0.2s ease;
  }
  
  &__premium-upgrade-button {
    background-color: transparent;
    border: none;
    color: white;
    font-family: roboto;
    font-size: 1.5rem;
    font-weight: 600;
    padding: 1rem 2rem;
    cursor: pointer;
    transition: all 0.3s ease;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0.7rem;
    outline: none;
    text-align: center;
    width: 100%;

    &:hover {
      background-color: rgba(255, 255, 255, 0.1);
    }
    
    &:active {
      background-color: rgba(255, 255, 255, 0.15);
    }
  }
  
  &__premium-subtext {
    display: none;
  }
}
.PriceCard {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
  padding: 2rem;
  background-color: var(--grayscale-color-100);
  border-radius: 0.8rem;
  transition: all 0.4s cubic-bezier(0.165, 0.84, 0.44, 1);
  position: relative;
  overflow: visible;
  cursor: pointer;
  max-width: 400px;
  margin: auto;
  height: 100%;

  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 0.8rem;
    opacity: 0;
    box-shadow: 0 15px 30px rgba(131, 52, 52, 0.1), 0 10px 10px rgba(0, 0, 0, 0.05);
    transition: all 0.4s cubic-bezier(0.165, 0.84, 0.44, 1);
    z-index: -1;
  }

  @media (hover: hover) and (min-width: 768px) {
    &:hover {
      transform: scale(1.05) translateY(-10px);
      background-color: var(--grayscale-color-50);

      &::after {
        opacity: 1;
      }

      .PriceCard__header-title {
        transform: translateY(-5px);
      }

      .PriceCard__header-cost {
        transform: scale(1.1);
      }

      .PriceCard__button {
        transform: translateY(5px);
        font-family: 'Roboto';
      }
    }

    &--free:hover {
      background-color: var(--grayscale-color-200);
    }
  }

  &--suggest {
    background-color: #e9fbec;
    box-shadow: 0 0 0 2px var(--primary-color-400);

    &:hover {
      box-shadow: 0 0 0 3px var(--primary-color-500);
      background-color: var(--primary-color-50);
    }
  }

  &--suggest-scolastico {
    background-color: #f8f1fc;
    border: 2px solid rgb(131, 3, 182);
    box-shadow: 0 8px 20px rgba(131, 3, 182, 0.15);

    &:hover {
      box-shadow: 0 12px 25px rgba(131, 3, 182, 0.2);
      background-color: #f5ebfa;
    }
  }

  &--suggest-universitario {
    background-color: #e9fbec;
    box-shadow: 0 0 0 2px var(--primary-color-400);

    &:hover {
      box-shadow: 0 0 0 3px var(--primary-color-500);
      background-color: var(--primary-color-50);
    }
  }

  &__header {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 1.2rem;
    margin-bottom: 2.4rem;

    &-title {
      font-size: 2.7rem;
      transition: transform 0.3s ease;
    }

    &-cost {
      display: flex;
      align-items: baseline;
      font-size: 4rem;
      font-weight: bold;
      transition: transform 0.3s ease;
    }  
  }

  &__original-cost {
    text-decoration: line-through;
    color: #999;
    font-size: 2rem;
    margin-right: .4rem;
    font-weight: 500;
  }

  &__method {
    font-size: 1.6rem;
    font-weight: 400;
    color: #000;
  }

  &__access-info {
    margin-top: 1rem;
    background-color: #d5f1d5;
    padding-inline: 1rem;
    padding-block: .5rem;
    border-radius: 1rem;
    color: green;
    font-weight: 500;
  }

  &__access-info-scolastico {
    margin-top: 1rem;
    background-color: #e7d5f1;
    padding-inline: 1rem;
    padding-block: .5rem;
    border-radius: 1rem;
    color: rgb(75, 0, 128);
    font-weight: 500;
  }
  
  &__access-info-text {
    font-family: 'Roboto';
    font-size: 1.4rem;
  }

  &__options {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    row-gap: 1.6rem;
    padding-bottom: 1.2rem;
    margin-bottom: 2.4rem;
    text-align: left;
  }

  &__text {
    margin-top: 1.6rem;
    font-size: 1.2rem;
    text-align: center;
  }

  &__final-text {
    margin-top: 1.6rem;
    font-size: 1.4rem;
    font-weight: 500;
    text-align: center;
    color: rgb(86, 86, 86);
  }

  &__button {
    display: flex;
    flex-direction: column;
    transition: transform 0.3s ease;
    
    .Btn--primary {
      position: relative;
      overflow: hidden;
      
      &.animate-button::before {
        content: '';
        position: absolute;
        top: 0;
        left: -100%;
        width: 100%;
        height: 100%;
        background: linear-gradient(
          90deg,
          rgba(255, 255, 255, 0) 0%,
          rgba(255, 255, 255, 0.2) 50%,
          rgba(255, 255, 255, 0) 100%
        );
        animation: button-shine 6s infinite;
      }
      
      @keyframes button-shine {
        0% {
          left: -100%;
        }
        15% {
          left: 100%;
        }
        100% {
          left: 100%;
        }
      }
    }
  }
  
  &__button-scolastico {
    background-color: rgb(131, 3, 182);
    
    &:hover {
      background-color: rgb(109, 1, 151);
    }
  }

  @media (max-width: 767px) {
    transition: transform 0.3s ease;

    &.scrolled {
      transform: scale(1.02);
      box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
    }
  }

  @media (max-width: 575px) {
    padding: 1.5rem;

    &__header {
      &-title {
        font-size: 2.5rem;
      }

      &-cost {
        font-size: 2.8rem;
      }
    }

    &__original-cost {
      font-size: 1.8rem;
    }

    &__method {
      font-size: 1.4rem;
    }

    &__options {
      row-gap: 1.2rem;
    }

    &__text {
      font-size: 1.1rem;
    }
  }

  &__countdown {
    margin-top: 1.5rem;
    text-align: center;
    
    .Countdown-container {
      margin-top: 0;
      display: flex;
      justify-content: center;
      gap: 1rem;

      &__card {
        background-color: var(--primary-color-400);
        color: white;
        padding: 0.8rem;
        border-radius: 0.5rem;
        min-width: 4rem;
      }

      &__time {
        font-size: 1.8rem;
        font-weight: bold;
      }

      &__label {
        font-size: 1rem;
        margin-top: 0.5rem;
        color: var(--grayscale-color-800);
      }
    }
  }
}
.Field {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  position: relative;
}

/* old field*/
.Field2 {
  -webkit-margin-after: 2.4rem;
  margin-block-end: 2.4rem;

  &__label {
    display: block;
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 1.4rem;
    line-height: 1.5;
    color: var(--grayscale-color-950);
    -webkit-margin-after: 0.8rem;
    margin-block-end: 0.8rem;
  }

  &__box {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }
}

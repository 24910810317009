.SignUpForm {
  width: 100%;
  max-width: 450px;
  margin: 3.2rem auto 0;

  &__field {
    margin-block-end: 1.6rem;
    width: 100%;

    .Field__label {
      font-weight: 700;
      font-size: 1.5rem;

      &.required::after {
        font-weight: 700;
      }
    }

    input {
      padding: 1.4rem;
      line-height: 1.2;
    }

    &--password {
      margin-block-end: 2rem;
    }

    &--select {
      width: 100%;
      padding: 1.4rem;
      border: 1px solid var(--grayscale-color-300);
      border-radius: 8px;
      background-color: var(--background-color);
      color: var(--grayscale-color-900);
      font-size: 1.5rem;
      cursor: pointer;

      &:focus {
        outline: none;
        border-color: var(--primary-color-500);
      }
    }

    &-box {
      position: relative;
      display: flex;
      flex-direction: column;
      width: 100%;
    }
  }

  &__box {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin-bottom: 2rem;

    label {
      display: flex;
      align-items: center;
      gap: 0.8rem;
      font-size: 1.4rem;
      color: var(--grayscale-color-600);
      line-height: 1.4;
      cursor: pointer;
      transition: color 0.2s ease;

      &:has(input:checked) {
        color: var(--primary-color-500);
      }

      a {
        color: var(--primary-color-500);
        text-decoration: underline;
        font-weight: 500;
        transition: color 0.2s ease;

        &:hover {
          color: var(--primary-color-600);
        }
      }
    }
  }

  &__checkbox {
    &-wrapper {
      position: relative;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      flex-shrink: 0;
    }

    &-label {
      display: flex;
      align-items: flex-start;
    }

    &-icon {
      position: absolute;
      color: green;
      pointer-events: none;
      opacity: 0;
      transform: scale(0.8);
      transition: all 0.2s ease-in-out;
    }

    &.Input {
      width: 1.6rem;
      height: 1.6rem;
      cursor: pointer;
      appearance: none;
      border: 2px solid var(--grayscale-color-300);
      border-radius: 4px;
      position: relative;
      transition: all 0.2s ease;
      margin-top: 0;
      flex-shrink: 0;
      background-color: var(--background-color);

      &:checked {
        background-color: var(--primary-color-500);
        border-color: var(--primary-color-500);

        & + .SignUpForm__checkbox-icon {
          opacity: 1;
          transform: scale(1.4);
        }
      }

      &:hover:not(:checked) {
        border-color: var(--primary-color-500);
        background-color: var(--primary-color-50);
      }
    }
  }

  &__createUserButton {
    width: 100%;
    padding: 1.4rem !important;
    font-size: 1.5rem !important;
    font-weight: 500 !important;
    height: auto !important;
    margin-top: 0.8rem;
  }

  @media screen and (max-width: 768px) {
    margin: 2rem auto 0;
    padding: 0 1.6rem;

    &__field {
      margin-block-end: 1.4rem;

      &--password {
        margin-block-end: 1.8rem;
      }

      &--select {
        font-size: 1.4rem;
        padding: 1.2rem;
      }
    }

    &__box {
      margin-bottom: 1.8rem;
      gap: 0.8rem;
      
      label {
        font-size: 1.3rem;
      }
    }

    &__createUserButton {
      padding: 1.2rem !important;
      font-size: 1.4rem !important;
    }
  }

  @media screen and (max-width: 480px) {
    padding: 0 1rem;

    &__field {
      margin-block-end: 1.2rem;

      &--password {
        margin-block-end: 1.6rem;
      }
    }

    &__box {
      margin-bottom: 1.6rem;
      
      label {
        font-size: 1.2rem;
      }
    }
  }
}

.AuthButtons {
  display: flex;
  flex-direction: column;
  gap: 1.6rem;

  &__divider {
    position: relative;
    text-align: center;
    margin: 0.8rem 0;

    &::before,
    &::after {
      content: "";
      position: absolute;
      top: 50%;
      width: calc(50% - 3rem);
      height: 1px;
      background-color: var(--grayscale-color-300);
    }

    &::before {
      left: 0;
    }

    &::after {
      right: 0;
    }

    &-text {
      display: inline-block;
      padding: 0 1rem;
      color: var(--grayscale-color-600);
      font-size: 1.2rem;
      background-color: var(--background-color);
    }
  }

  &--googleButton {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    background-color: #ffffff !important;
    color: #000000 !important;
    border: 1px solid var(--grayscale-color-300) !important;

    &:hover {
      background-color: #f8f9fa !important;
    }

    svg {
      color: #34A853;
    }
  }
}
.introjs-helperLayer {
  margin-top: 0px 
}

.introjs-tooltiptext {
  padding-top: 0px;
}

.introjs-bullets {
  display: none;
}

.AudioRecorder {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;

  &__box {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-column-gap: 2.4rem;
    -moz-column-gap: 2.4rem;
    column-gap: 2.4rem;
    -webkit-padding-end: 1.6rem;
    padding-inline-end: 1.6rem;
  }

  &__iconAnimation {
    width: 6.4rem;
    height: 6.4rem;
  }

  &__timer {
    font-size: 1.6rem;
  }

  &__info {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-column-gap: 0.8rem;
    -moz-column-gap: 0.8rem;
    column-gap: 0.8rem;
  }

  &__stop {
    cursor: pointer;

    &-icon {
      font-size: 2.8rem;
      color: var(--feedbackRed-color-500);
    }
  }

  &__sendBox {
    -webkit-border-start: 1px solid var(--grayscale-color-300);
    border-inline-start: 1px solid var(--grayscale-color-300);

    &-box {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
      width: 4rem;
      height: 4rem;
      -webkit-margin-start: 1.6rem;
      margin-inline-start: 1.6rem;
      padding: 0.8rem;
      border-radius: 50%;
      background-color: var(--primary-color-100);
      cursor: pointer;

      &:hover,
      &:active {
        background-color: var(--primary-color-200);
      }

      &:hover > svg,
      &:active > svg {
        color: var(--primary-color-500);
      }

      &--disabled {
        opacity: 0.5;
        pointer-events: none;
      }
    }

    &-icon {
      font-size: 1.6rem;
      color: var(--primary-color-500);
    }
  }
}

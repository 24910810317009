:root {
  --grayscale-color-200: #cccccc;
  --grayscale-color-600: #333333;
  --grayscale-color-800: #333333;
  --font-family: sans-serif;
  --sticky-header-background-color: var(--grayscale-color-100);  
  --transition-speed: 0.5s;
  --header-height: 5rem; 
  --header-shrink-height: 3rem; 
}

.LandingHeader {
  position: absolute;
  width: 100%;
  padding-block: 1.6rem;
  border-bottom: 1px solid var(--grayscale-color-200);
  background-color: var(--header-background-color);
  transition: padding-block var(--transition-speed) ease-in-out, background-color var(--transition-speed) ease-in-out;
  top: 0;
  z-index: 10;

  &--sticky {
    position: fixed; 
    top: 0;
    z-index: 9999;
    background-color: var(--sticky-header-background-color); 
    padding-block: 0.8rem; 
  }

  &__content {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__navbar {
    display: flex;
    gap: 2rem;
    margin-inline-start: 1.6rem;
    align-items: center; // Aggiunto per allineare verticalmente il dropdown

    & > * {
      font-family: var(--font-family);
      font-size: 1.7rem;
      color: var(--grayscale-color-600);
      font-weight: bold;

      &:hover {
        color: var(--primary-color-500);
        text-decoration: underline;
        text-underline-offset: 0.5rem;
      }
    }

    // Eccezione per il DropMenudown per evitare la sottolineatura al hover
    .DropMenudown {
      &:hover {
        text-decoration: none;
      }

      button {
        &:hover {
          text-decoration: none;
        }
      }
    }

    @media (max-width: 991px) {
      display: none;
    }
  }

  &__right {
    display: flex;
    gap: 0.8rem;

    & > *:not(:first-child) {
      min-width: 12rem;

      @media (max-width: 575px) {
        display: none;
      }
    }

    & > *:first-child {
      border: none;
      align-self: center;
    }

    .LandingHeader__sign-up-mobile {
      display: none;

      @media (max-width: 575px) {
        display: block;
        font-size: 1.4rem;
        padding: 0.8rem 1.4rem;
        border-radius: 8px;
      }

      @media (max-width: 325px) {
        font-size: 1.2rem;
        padding: 0.6rem 1rem;
        border-radius: 6px;
      }
    }
  }
}

// MainContent spacing
.MainContent {
  padding-top: var(--header-height);
  transition: padding-top var(--transition-speed) ease-in-out;

  .LandingHeader--sticky & {
    padding-top: var(--header-shrink-height);
  }
}

@media (max-width: 575px) {
  .LandingHeader {
    padding-block: 0.8rem;
    border-bottom: 0;

    &__content {
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      .LandingHeader__navbar {
        display: none;
      }

      .LandingHeader__right {
        display: flex;
        gap: 0.6rem;

        & > *:first-child {
          display: flex;
          align-items: center;
        }

        .LandingHeader__sign-up-mobile {
          font-size: 1.4rem;
          padding: 0.6rem 1rem;
          border-radius: 8px;
        }

        .HamburgerMenu {
          order: 1;
          width: 3.8rem;
          height: 3.8rem;
          padding: 0.8rem;
        }

        & > *:not(.LandingHeader__sign-up-mobile):not(.HamburgerMenu) {
          display: none;
        }

        & > *:first-child {
          border: none;
          align-self: center;
        }
      }
    }

    &--sticky {
      padding-block: 1.2rem;
      border-bottom: 0;
    }
  }

  .MainContent {
    padding-top: 0;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .LandingHeader {
    &__navbar {
      gap: 1.5rem;
      
      & > * {
        font-size: 1.8rem;
        
        &:hover {
          text-decoration: none;
        }
        
        &:active {
          text-decoration: underline;
          text-underline-offset: 0.5rem;
        }
      }
    }

    &__right {
      gap: 1rem;

      & > *:not(:first-child) {
        min-width: 13rem;
      }
    }
  }
}
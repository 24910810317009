.SocialsCustom {
    margin-top: 4rem;
    padding: 2rem 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  
    &__icons {
      display: flex;
      justify-content: center;
      gap: 10px;
  
      .SocialsCustom__link {
        color: inherit;
        font-size: 24px;
        transition: color 0.3s ease;
  
        &:hover {
          color: var(--secondary-color-600); // Cambia colore al passaggio del mouse
        }
      }
    }
  
    &__info {
      margin-top: 1rem;
      font-size: 14px;
      color: #999;
  
      a {
        color: var(--grayscale-color-600);
        text-decoration: none;
  
        &:hover {
          text-decoration: underline;
        }
      }
    }
  
    @media (max-width: 768px) {
      &__icons {
        gap: 5px;
  
        .SocialsCustom__link {
          font-size: 20px;
        }
      }
  
      &__info {
        font-size: 12px;
      }
    }
  
    @media (max-width: 480px) {
      margin-top: 2rem;
      padding: 1rem 0;
  
      &__icons {
        gap: 3px;
  
        .SocialsCustom__link {
          font-size: 18px;
        }
      }
  
      &__info {
        font-size: 10px;
      }
    }
  }
  
.MaterialsFlashcards {
  &__stats {
    display: inline-flex;
    padding: 1.6rem;
    background-color: var(--grayscale-color-50);
    border-radius: 0.8rem;
    border: 1px solid var(--grayscale-color-200);
    align-self: flex-start;
    transition: all 0.3s ease;
    margin-bottom: 2rem;
    margin-top: 1.6rem;

    @media (max-width: 768px) {
      width: 100%;
      padding: 1.2rem;
      margin-bottom: 1.6rem;
      margin-top: 1.4rem;
    }

    &:hover {
      box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1);
      border-color: rgb(5 150 105);
    }

    &-header {
      display: flex;
      align-items: center;
      gap: 1.6rem;
      width: 100%;

      @media (max-width: 768px) {
        gap: 1.2rem;
      }
    }

    &-icon {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 3.8rem;
      height: 3.8rem;
      border-radius: 0.8rem;
      background-color: var(--background-color);
      color: rgb(5 150 105);
      flex-shrink: 0;
      cursor: pointer;
      transition: all 0.2s ease;
      border: 1px solid var(--grayscale-color-200);
      position: relative;
      overflow: hidden;

      @media (max-width: 768px) {
        width: 3.4rem;
        height: 3.4rem;
      }

      &--disabled {
        color: var(--grayscale-color-400);
        cursor: not-allowed;
        
        &:hover {
          border-color: var(--grayscale-color-300);
          
          &::before {
            transform: translateY(100%);
          }
          
          svg {
            color: var(--grayscale-color-400);
          }
        }
      }

      &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgb(5 150 105);
        transform: translateY(100%);
        transition: transform 0.2s ease;
      }

      svg {
        position: relative;
        z-index: 1;
        transition: all 0.2s ease;
      }

      &:hover:not(&--disabled) {
        border-color: rgb(5 150 105);

        &::before {
          transform: translateY(0);
        }

        svg {
          color: white;
        }
      }
    }

    &-info {
      display: flex;
      align-items: center;
      gap: 1.4rem;
      flex: 1;
      min-width: 0;

      @media (max-width: 768px) {
        gap: 1rem;
        overflow-x: auto;
        scrollbar-width: none;
        -ms-overflow-style: none;
        &::-webkit-scrollbar {
          display: none;
        }
      }
    }

    &-details {
      display: flex;
      align-items: center;
      gap: 1.6rem;
      flex-wrap: nowrap;
      flex: 1;

      @media (max-width: 768px) {
        gap: 1.2rem;
      }
    }

    &-item {
      display: flex;
      align-items: center;
      gap: 0.6rem;
      font-family: roboto;
      font-size: 1.5rem;
      font-weight: 600;
      color: var(--grayscale-color-950);
      white-space: nowrap;
      padding-right: 1.6rem;
      border-right: 1px solid var(--grayscale-color-200);
      flex-shrink: 0;

      @media (max-width: 768px) {
        font-size: 1.3rem;
        padding-right: 1.2rem;
        gap: 0.4rem;
      }

      &:last-child {
        padding-right: 0;
        border-right: none;
      }

      &--premium {
        color: rgb(5 150 105);
        font-weight: 500;
      }
    }
  }

  &__premium-banner {
    display: flex;
    align-items: center;
    margin-bottom: -0.4rem;
    background: linear-gradient(135deg, rgb(220 252 231 / 50%) 0%, rgb(220 252 231 / 80%) 100%);
    border: 1px solid rgb(34 197 94 / 60%);
    border-radius: 1.2rem;
    backdrop-filter: blur(8px);
    transition: all 0.3s ease;
    overflow: hidden;

    &:hover {
      border-color: rgb(34 197 94 / 80%);
      box-shadow: 0 8px 24px -4px rgba(22, 163, 74, 0.1);
      transform: translateY(-1px);
    }

    &-content {
      flex: 1;
      display: flex;
      align-items: center;
      gap: 2.4rem;
      padding: 1.6rem 2.4rem;
    }

    &-header {
      display: flex;
      align-items: center;
      gap: 1rem;
      margin: 0;
      min-width: max-content;

      h6 {
        font-family: roboto;
        font-size: 1.6rem;
        font-weight: 600;
        color: rgb(5 150 105);
        margin: 0;
      }
    }

    &-icon {
      font-size: 2rem;
      color: rgb(5 150 105);
    }

    p {
      font-family: roboto;
      font-size: 1.4rem;
      line-height: 1.5;
      color: rgb(20 83 45);
      margin: 0;
      flex: 1;
    }

    &-button {
      display: inline-flex;
      align-items: center;
      gap: 0.8rem;
      padding: 1rem 2rem;
      background-color: rgb(5 150 105);
      border: none;
      border-radius: 2.4rem;
      cursor: pointer;
      transition: all 0.3s ease;
      white-space: nowrap;
      min-width: max-content;
      margin-left: auto;
      position: relative;
      overflow: hidden;

      &.animate-button::before {
        content: '';
        position: absolute;
        top: 0;
        left: -100%;
        width: 100%;
        height: 100%;
        background: linear-gradient(
          90deg,
          rgba(255, 255, 255, 0) 0%,
          rgba(255, 255, 255, 0.2) 50%,
          rgba(255, 255, 255, 0) 100%
        );
        animation: button-shine 6s infinite;
      }
      
      @keyframes button-shine {
        0% {
          left: -100%;
        }
        15% {
          left: 100%;
        }
        100% {
          left: 100%;
        }
      }

      &:hover {
        background-color: rgb(4 120 87);
        transform: translateY(-1px);
        box-shadow: 0 4px 12px -2px rgba(5, 150, 105, 0.3);
      }

      &:active {
        transform: translateY(0);
      }
      
      span {
        font-family: roboto;
        font-size: 1.4rem;
        font-weight: 500;
        color: white;
      }

      &-icon {
        font-size: 1.6rem;
        color: white;
        transition: transform 0.3s ease;
      }
      
      &:hover &-icon {
        transform: translateX(4px);
      }
    }
    
    @media (max-width: 768px) {
      margin-bottom: 2rem;
      
      &-content {
        flex-direction: column;
        align-items: flex-start;
        gap: 1.2rem;
        padding: 1.6rem;
      }
      
      &-header {
        margin-bottom: 0;
      }
      
      p {
        margin-bottom: 0;
      }
      
      &-button {
        width: 100%;
        justify-content: center;
        margin-left: 0;
      }
    }
  }

  &__container {
    margin: 0;
    padding: 0;
    margin-left: -1.6rem;
    margin-right: -1.6rem;
    margin-top: 0.4rem;

    &-skeleton {
      display: flex;
      flex-wrap: wrap;
      gap: 1rem;
      margin-top: 4rem;
    }

    [class*="col-"] {
      padding: 1.6rem;
    }

    .Flashcard {
      margin: 0;
    }

    @media (max-width: 768px) {
      margin-left: -0.8rem;
      margin-right: -0.8rem;

      [class*="col-"] {
        padding: 0.8rem;
      }
    }
  }

  &__loading {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 40rem;
    width: 100%;
    gap: 1rem;

    p {
      font-family: roboto;
      font-size: 1.4rem;
      color: var(--grayscale-color-600);
      margin: 0;
    }
  }

  &__stats-skeleton {
    padding: 1.6rem;
    background: var(--grayscale-color-50);
    border-radius: 8px;
    margin-bottom: 1rem;
    max-width: 18rem;

    .stats-header-skeleton {
      display: flex;
      align-items: center;

      .stats-info-skeleton {
        flex: 1;
        
        .stats-details-skeleton {
          height: 20px;
          width: 100%;
          background: var(--grayscale-color-100);
          border-radius: 4px;
        }
      }
    }
  }

  .flashcard-skeleton {
    background: white;
    border-radius: 8px;
    padding: 1.5rem;
    margin-bottom: 1rem;
    box-shadow: 0 2px 4px rgba(0,0,0,0.05);
    width: 25rem;
    height: 15rem;

    .question-skeleton {
      height: 20px;
      background: var(--grayscale-color-100);
      margin-bottom: 1rem;
      border-radius: 4px;
    }

    .answer-skeleton {
      height: 16px;
      width: 60%;
      background: var(--grayscale-color-100);
      border-radius: 4px;
    }
  }

  @media (max-width: 575px) {
    .MaterialsFlashcards__container-skeleton {
      justify-content: center;
    }

    .flashcard-skeleton {
      padding: 1rem;
      width: 100%;
      
      .question-skeleton {
        height: 18px;
      }
      
      .answer-skeleton {
        height: 14px;
      }
    }
  }
}

.AudioPlayer {
  width: 100%;
  margin: 1rem 0;
  
  &__container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 3rem;
    padding: 0.8rem 1.2rem;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    background-color: rgb(37 99 235);
    border: none;
    position: relative;
    overflow: hidden;
    
    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: -100%;
      width: 100%;
      height: 100%;
      background: linear-gradient(
        90deg,
        rgba(255, 255, 255, 0) 0%,
        rgba(255, 255, 255, 0.2) 50%,
        rgba(255, 255, 255, 0) 100%
      );
      animation: player-shine 6s infinite;
    }
    
    @keyframes player-shine {
      0% {
        left: -100%;
      }
      15% {
        left: 100%;
      }
      100% {
        left: 100%;
      }
    }
    
    @media (max-width: 768px) {
      padding: 0.6rem 1rem;
    }
  }
  
  &__play-button {
    background: none;
    border: none;
    cursor: pointer;
    width: 3.6rem;
    height: 3.6rem;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;
    color: rgb(37 99 235);
    font-size: 1.8rem;
    margin-right: 1rem;
    transition: all 0.2s ease;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    z-index: 1;
    
    &:hover {
      background-color: #f8f9fa;
      box-shadow: 0 4px 6px rgba(0, 0, 0, 0.25);
      transform: translateY(-1px);
    }
    
    &:active {
      transform: translateY(0);
    }
    
    @media (max-width: 768px) {
      width: 3.2rem;
      height: 3.2rem;
      font-size: 1.6rem;
    }
  }
  
  &__time-display {
    font-size: 1.4rem;
    font-weight: 500;
    color: white;
    min-width: 9rem;
    text-align: center;
    z-index: 1;
    
    @media (max-width: 768px) {
      font-size: 1.2rem;
      min-width: 7.5rem;
    }
  }
  
  &__progress-container {
    flex: 1;
    cursor: pointer;
    padding: 0.8rem 0;
    margin: 0 1rem;
    z-index: 1;
  }
  
  &__progress-bar {
    height: 0.6rem;
    background-color: rgba(255, 255, 255, 0.3);
    border-radius: 0.3rem;
    position: relative;
    overflow: hidden;
  }
  
  &__progress-fill {
    height: 100%;
    background-color: white;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 0.3rem;
    transition: width 0.1s ease-out;
  }
  
  &__volume-container {
    display: flex;
    align-items: center;
    position: relative;
    margin: 0 1rem;
  }
  
  &__volume-button {
    background: none;
    border: none;
    cursor: pointer;
    width: 3rem;
    height: 3rem;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #4b5563;
    font-size: 1.8rem;
    transition: color 0.2s ease;
    
    &:hover {
      color: #2563eb;
    }
  }
  
  &__volume-slider {
    position: absolute;
    top: 50%;
    left: 100%;
    transform: translateY(-50%);
    width: 8rem;
    height: 2.4rem;
    background-color: white;
    border-radius: 1.2rem;
    display: flex;
    align-items: center;
    padding: 0 1rem;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
    z-index: 10;
  }
  
  &__volume-bar {
    height: 0.4rem;
    background-color: #e5e7eb;
    border-radius: 0.2rem;
    width: 100%;
    position: relative;
    overflow: hidden;
  }
  
  &__volume-fill {
    height: 100%;
    background-color: #2563eb;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 0.2rem;
  }
  
  &__options {
    position: relative;
  }
  
  &__options-button {
    background: none;
    border: none;
    cursor: pointer;
    width: 3rem;
    height: 3rem;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #4b5563;
    font-size: 1.8rem;
    transition: color 0.2s ease;
    
    &:hover {
      color: #2563eb;
    }
  }
  
  &__options-menu {
    position: absolute;
    top: 100%;
    right: 0;
    width: 16rem;
    background-color: white;
    border-radius: 0.8rem;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
    z-index: 10;
    overflow: hidden;
    margin-top: 0.8rem;
  }
  
  &__option-item {
    width: 100%;
    padding: 1rem 1.5rem;
    text-align: left;
    background: none;
    border: none;
    font-size: 1.4rem;
    color: #374151;
    cursor: pointer;
    transition: background-color 0.2s ease;
    
    &:hover {
      background-color: #f3f4f6;
      color: #2563eb;
    }
    
    &:not(:last-child) {
      border-bottom: 1px solid #e5e7eb;
    }
  }
  
  // Supporto per il tema scuro custom
  .dark-mode & {
    &__container {
      background-color: rgb(29 78 216);
    }
    
    &__play-button {
      background-color: #1f2937;
      color: #60a5fa;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.4);
      
      &:hover {
        background-color: #111827;
      }
    }
    
    &__time-display {
      color: #e5e7eb;
    }
    
    &__progress-bar {
      background-color: rgba(255, 255, 255, 0.2);
    }
    
    &__progress-fill {
      background-color: #e5e7eb;
    }
  }
} 
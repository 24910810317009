@import './VideoContainer/VideoContainer';

.VideoSection {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 5rem 20px 8rem;
  background: linear-gradient(180deg, var(--grayscale-color-100) 0%, rgba(116, 176, 148, 0.02) 100%);
  border-radius: 20px;
  overflow: hidden;
  max-width: 1200px;
  margin: 0 auto;
  position: relative;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 1px;
    background: linear-gradient(90deg, transparent, var(--primary-color-500), transparent);
    opacity: 0.2;

    @media (max-width: 991px) {
      opacity: 0.1;
    }
  }

  @media (min-width: 769px) {
    flex-direction: row;
    padding: 8rem 40px 12rem;
  }

  .VideoSection__content {
    max-width: 100%;
    text-align: center;
    margin-bottom: 4rem;
    animation: slideUp 0.6s ease-out;

    @media (min-width: 769px) {
      max-width: 45%;
      text-align: left;
      margin-bottom: 0;
      padding-right: 4rem;
    }
  }

  .VideoSection__header {
    font-size: 2rem;
    color: var(--primary-color-500);
    margin-bottom: 1.6rem;
    text-transform: uppercase;
    letter-spacing: 2px;
    font-weight: 600;

    @media (max-width: 768px) {
      font-size: 1.8rem;
      opacity: 0.9;
    }
  }

  .VideoSection__title {
    font-size: 3.2rem;
    color: var(--grayscale-color-900);
    margin-bottom: 2rem;
    line-height: 1.2;
    font-weight: 700;

    @media (max-width: 768px) {
      font-size: 2.8rem;
    }

    @media (max-width: 575px) {
      font-size: 2.4rem;
    }
  }

  .VideoSection__text {
    font-size: 1.8rem;
    color: var(--grayscale-color-800);
    margin-bottom: 3rem;
    line-height: 1.6;

    @media (max-width: 768px) {
      font-size: 1.6rem;
      padding: 0 1rem;
      opacity: 0.95;
    }
  }

  .VideoSection__buttons {
    display: flex;
    gap: 2rem;
    justify-content: center;
    margin-top: 3rem;

    @media (min-width: 769px) {
      justify-content: flex-start;
    }

    .VideoSection__button {
      padding: 1.6rem 3.2rem;
      border: none;
      border-radius: 12px;
      font-size: 1.6rem;
      font-weight: 600;
      cursor: pointer;
      transition: all 0.3s ease;
      text-decoration: none;
      display: inline-flex;
      align-items: center;
      justify-content: center;

      &.simulate {
        background-color: var(--primary-color-500);
        color: #fff;
        box-shadow: 0 4px 20px rgba(52, 149, 89, 0.15);

        @media (max-width: 991px) {
          box-shadow: 0 4px 16px rgba(52, 149, 89, 0.12);
        }

        &:hover {
          background-color: var(--primary-color-700);
          transform: translateY(-2px);
          box-shadow: 0 6px 24px rgba(52, 149, 89, 0.25);
        }
      }

      &.instagram {
        background-color: transparent;
        color: var(--primary-color-500);
        border: 2px solid var(--primary-color-500);
        opacity: 0.9;

        @media (max-width: 991px) {
          border-width: 1.5px;
          opacity: 0.85;
        }

        &:hover {
          background-color: var(--primary-color-500);
          color: #fff;
          transform: translateY(-2px);
          opacity: 1;
        }
      }

      @media (max-width: 768px) {
        padding: 1.4rem 2.8rem;
        font-size: 1.5rem;
      }
    }
  }

  .VideoSection__video {
    width: 100%;
    max-width: 600px;
    animation: slideUp 0.6s ease-out 0.3s both;

    @media (min-width: 769px) {
      max-width: 55%;
    }
  }
}

@keyframes slideUp {
  from {
    opacity: 0;
    transform: translateY(30px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
.UploadNotesFiles {
  -webkit-margin-before: 3.2rem;
  margin-block-start: 3.2rem;
  max-height: 24rem;
  overflow-y: scroll;

  &__list {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }
}

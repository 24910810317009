.FieldLabel {
  display: inline-block;
  font-weight: 700;
  color: var(--grayscale-color-800);
  font-size: 1.5rem;
  line-height: 1.5;
  -webkit-margin-after: 0.5rem;
  margin-block-end: 0.8rem;

  &__required {
    color: var(--feedbackRed-color-500);
    font-weight: 700;
    margin-left: 0.2rem;
  }
}

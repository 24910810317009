.Examcard-container {
  height: 100vh;
  max-width: 140rem;
  color: #000000;
  padding: 1.5rem;
  margin: 0 auto;
  font-family: Roboto;
  
  &__examcard-main {
    display: flex;
    gap: 1.5rem;
    flex: 1;
    height: 100%;
    padding-bottom: 5.5rem;
    overflow: auto;
    
    @media (max-width: 991px) {
      flex-direction: column;
    }
    
    @media (max-width: 802px) and (max-height: 1200px) {
      height: auto; 
    }
    // Ipad dimension 
    @media (width: 1149px) and (max-height: 716px) {
      height: 100%; 
      padding-bottom: .7rem;
    }
  }

  &__examcard-main-interrogation {
    display: flex;
    gap: 1.5rem;
    flex: 1;
    height: 100%;
    padding-bottom: 5.5rem;
    overflow: auto;
    
    @media (max-width: 991px) {
      flex-direction: column;
    }
    
    // Ipad dimension 
    @media (width: 1149px) and (max-height: 716px) {
      height: 100%; 
      padding-bottom: .7rem;
    }
  }
  
  &__exam-container {
    display: flex;
    flex-direction: column;
    flex: 1;
    background-color: #ffffff;
    border-radius: 0.75rem;
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
    overflow: hidden;
    position: relative;
    height: 100%;
    
    .ExamFinishToastContainer {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      bottom: 2.5rem;
      margin-inline: auto;
      background-color: var(--grayscale-color-100) !important;
      border-radius: 12px !important;
      box-shadow: 0 4px 12px #00000026 !important;
      max-width: 400px !important;
      width: 100% !important;
      height: fit-content;
      z-index: 10;
      
      &__examFinishToast {
        color: var(--grayscale-color-900);
        padding: 16px;
      }
      
      &__title {
        font-size: 18px;
        font-weight: 600;
        margin-bottom: 12px;
        text-align: center;
      }
      
      &__message {
        font-size: 16px;
        margin-bottom: 16px;
        text-align: center;
      }
      
      &__buttons {
        display: flex;
        gap: 12px;
        justify-content: center;
      }
    }
  }
}


.ExamReportAnswers {
  &__scroll-area {
    overflow-y: auto;
    max-height: 200px;
    max-width: 570px;
    margin-top:1rem;

    @media (min-width: 1536px) {
      max-height: 200px;
    }
  }

  &__question-list {
    column-gap: 1rem;
    list-style: none;
    margin: 0;
    padding: 0;
  }

  &__scroll-button {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 1rem;
    transition: opacity 0.2s;

    &--hidden {
      opacity: 0;
    }

    button {
      display: flex;
      align-items: center;
      padding: .7rem 1.5rem;
      border: 1px solid #e5e7eb;
      font-family: Roboto;
      border-radius: 0.6rem;
      background: transparent;
      gap: 0.5rem;
      font-size: 1.2rem;
      font-weight: 500;
      line-height: 10px;
      cursor: pointer;
      transition: all 0.3s ease;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

      &:hover {
        background-color: #f0f0f0;
        transform: translateY(-2px);
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
      }

      svg {
        width: 15px;
        height: 15px;
      }
    }
  }

  &__action-buttons {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 1rem;

    &--interrogation {
      justify-content: end;
    }

    button {
      display: flex;
      align-items: center;
      font-family: Roboto;
      padding: .7rem 1.5rem;
      border: 1px solid #e5e7eb;
      border-radius: 0.6rem;
      background: transparent;
      gap: 0.5rem;
      font-size: 1.2rem;
      font-weight: 500;
      line-height: 10px;
      cursor: pointer;
      transition: all 0.3s ease;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

      &:hover {
        transform: translateY(-2px);
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
      }

      &.button--next-exam {
        background-color: var(--primary-color-500);
        color: white;

        &:hover {
          background-color: #3a3a3a;
        }
      }

      svg {
        width: 15px;
        height: 15px;
      }
    }
  }
}
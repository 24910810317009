.Materials {
  padding-block: 3.2rem;

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1.6rem;
    
    @media (max-width: 480px) {
      flex-direction: row;
      align-items: center;
      width: 100%;
      padding-right: 0.5rem;
    }
  }

  &__actions {
    display: flex;
    gap: 1rem;
    
    @media (max-width: 480px) {
      align-items: center;
      margin-top: 0;
    }
  }

  &__action-button {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--background-color);
    border: 1px solid var(--grayscale-color-300);
    border-radius: 50%;
    width: 40px;
    height: 40px;
    color: var(--grayscale-color-700);
    cursor: pointer;
    transition: all 0.3s ease;

    &:hover {
      background-color: rgba(var(--grayscale-color-950-rgb), 0.05);
      color: var(--grayscale-color-900);
      border-color: var(--grayscale-color-400);
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    }

    &:active {
      transform: scale(0.95);
    }

    &--active {
      background-color: rgba(var(--primary-color-rgb), 0.1);
      color: var(--primary-color);
      border-color: var(--primary-color);
      
      &:hover {
        background-color: rgba(var(--primary-color-rgb), 0.15);
        color: var(--primary-color);
        border-color: var(--primary-color);
      }
    }
    
    @media (max-width: 480px) {
      width: 36px;
      height: 36px;
    }
  }

  &__search-container {
    margin-bottom: 1.5rem;
  }

  &__search-input {
    width: 100%;
    padding: 1rem 1.5rem;
    border-radius: 0.8rem;
    border: 1px solid var(--grayscale-color-300);
    font-size: 1.4rem;
    background-color: var(--background-color);
    transition: all 0.3s ease;
    
    &:focus {
      border-color: var(--primary-color);
      box-shadow: 0 0 0 2px rgba(var(--primary-color-rgb), 0.15);
    }

    &::placeholder {
      color: var(--grayscale-color-500);
    }
  }

  &__loading-docs {
    -webkit-padding-after: 1.6rem;
    padding-block-end: 1.6rem;
    height: 50vh;
  }

  &__loader-section {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;

    &__inner-container {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
    }

    &__text {
      margin-left: 1rem;
      margin-top: 1rem;
      font-size: 1.7rem;
      font-weight: 500;
    }
  }

  &__documents {
    -webkit-padding-after: 1.6rem;
    padding-block-end: 1.6rem;
  }

  &__title {
    font-size: 2rem;
    font-weight: 600;
    color: var(--grayscale-color-950);
    margin-bottom: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;

    &--bold {
      font-weight: 700;
    }

    &--full {
      @media (max-width: 768px) {
        max-width: 100% !important;
      }
      
      @media (max-width: 480px) {
        max-width: 100% !important;
      }
    }

    @media (max-width: 768px) {
      font-size: 1.8rem;
      line-height: 1.3;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      display: block;
    }
    
    @media (max-width: 480px) {
      font-size: 1.6rem;
      max-width: 75%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      display: block;
    }
  }

  /* Quando si è dentro una cartella, mostra il titolo completo */
  &__header:has(.Materials__actions:empty) .Materials__title,
  &__header:has(.Materials__actions > *:not(:visible)) .Materials__title {
    @media (max-width: 768px) {
      max-width: 100%;
    }
    
    @media (max-width: 480px) {
      max-width: 100%;
    }
  }

  &__menu {
    display: flex;
    gap: 2.5rem;
    align-items: center;
    overflow-x: auto;
    margin-block: 2.5em;
    color: var(--grayscale-color-700);
    font-size: 1.5rem;
    font-family: Roboto;
    font-weight: 500;
    white-space: nowrap;
    padding-bottom: 1rem;
    
    &::-webkit-scrollbar {
      height: 4px;
    }
    
    &::-webkit-scrollbar-track {
      background: var(--grayscale-color-100);
      border-radius: 4px;
    }
    
    &::-webkit-scrollbar-thumb {
      background: var(--grayscale-color-300);
      border-radius: 4px;
      
      &:hover {
        background: var(--grayscale-color-400);
      }
    }
    
    @media (max-width: 700px) {
      gap: 1.6rem;
      margin-block: 2em;
    }

    &__icon-bg {
      background-color: var(--grayscale-color-100);
      padding: 1rem;
      border-radius: 0.8rem;
      max-height: fit-content;
      color: var(--grayscale-color-600);
      transition: all 0.3s ease;
    }

    &__summary-icon-bg {
      background-color: rgba(37, 99, 235, 0.1);
      padding: 1rem;
      border-radius: 0.8rem;
      max-height: fit-content;
      color: rgb(37 99 235);
    }

    &__flashcards-icon-bg {
      background-color: rgba(5, 150, 105, 0.1);
      padding: 1rem;
      border-radius: 0.8rem;
      max-height: fit-content;
      color: rgb(5 150 105);
    }

    &__questions-icon-bg {
      background-color: rgba(234, 88, 12, 0.1);
      padding: 1rem;
      border-radius: 0.8rem;
      max-height: fit-content;
      color: rgb(234 88 12);
    }

    &__quiz-icon-bg {
      background-color: rgba(147, 51, 234, 0.1);
      padding: 1rem;
      border-radius: 0.8rem;
      max-height: fit-content;
      color: rgb(147 51 234);
    }
    
    &__title {
      font-weight: 600;
      font-size: 1.6rem;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      margin-bottom: 0.4rem;
      color: var(--grayscale-color-900);
    }

    &__subtitle {
      font-weight: 400;
      color: var(--grayscale-color-600);
      font-size: 1.3rem;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &__summary-link,
    &__flashcards-link,
    &__questions-link,
    &__quiz-link {
      position: relative;
      border: 1px solid var(--grayscale-color-200);
      border-radius: 1.2rem;
      color: var(--grayscale-color-900);
      display: flex;
      align-items: center;
      column-gap: 1.6rem;
      padding: 1.4rem 2rem;
      line-height: 1.8rem;
      width: auto;
      min-width: 26rem;
      height: 7.6rem;
      flex-shrink: 0;
      cursor: pointer;
      transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
      background-color: var(--background-color);
      box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
      
      &:hover {
        box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
      }
      
      @media (max-width: 700px) {
        min-width: 24rem;
        padding: 1.2rem 1.8rem;
        height: 7rem;
      }
    }

    &__questions-link,
    &__quiz-link {
      min-width: 32rem;
      
      @media (max-width: 700px) {
        min-width: 30rem;
      }
    }

    &__summary-link:hover {
      border-color: rgb(37 99 235);
      background-color: rgba(37, 99, 235, 0.02);
      color: rgb(37 99 235);

      .Materials__menu__icon-bg {
        background-color: rgba(37, 99, 235, 0.1);
        color: rgb(37 99 235);
      }
    }

    &__flashcards-link:hover {
      border-color: rgb(5 150 105);
      background-color: rgba(5, 150, 105, 0.02);
      color: rgb(5 150 105);

      .Materials__menu__icon-bg {
        background-color: rgba(5, 150, 105, 0.1);
        color: rgb(5 150 105);
      }
    }

    &__questions-link:hover {
      border-color: rgb(234 88 12);
      background-color: rgba(234, 88, 12, 0.02);
      color: rgb(234 88 12);

      .Materials__menu__icon-bg {
        background-color: rgba(234, 88, 12, 0.1);
        color: rgb(234 88 12);
      }
    }

    &__quiz-link:hover {
      border-color: rgb(147 51 234);
      background-color: rgba(147, 51, 234, 0.02);
      color: rgb(147 51 234);

      .Materials__menu__icon-bg {
        background-color: rgba(147, 51, 234, 0.1);
        color: rgb(147 51 234);
      }
    }

    &__summary-link__summary-active {
      @extend .Materials__menu__summary-link;
      background-color: rgba(37, 99, 235, 0.02);
      color: rgb(37 99 235);
      border: 1px solid rgb(37 99 235);
      box-shadow: 0 4px 6px -1px rgba(37, 99, 235, 0.1);

      &:hover {
        background-color: rgba(37, 99, 235, 0.05);
      }
    }

    &__flashcards-link__flashcards-active {
      @extend .Materials__menu__flashcards-link;
      background-color: rgba(5, 150, 105, 0.02);
      color: rgb(5 150 105);
      border: 1px solid rgb(5 150 105);
      box-shadow: 0 4px 6px -1px rgba(5, 150, 105, 0.1);

      &:hover {
        background-color: rgba(5, 150, 105, 0.05);
      }
    }

    &__questions-link__questions-active {
      @extend .Materials__menu__questions-link;
      background-color: rgba(234, 88, 12, 0.02);
      color: rgb(234 88 12);
      border: 1px solid rgb(234 88 12);
      box-shadow: 0 4px 6px -1px rgba(234, 88, 12, 0.1);

      &:hover {
        background-color: rgba(234, 88, 12, 0.05);
      }
    }

    &__quiz-link__quiz-active {
      @extend .Materials__menu__quiz-link;
      background-color: rgba(147, 51, 234, 0.02);
      color: rgb(147 51 234);
      border: 1px solid rgb(147 51 234);
      box-shadow: 0 4px 6px -1px rgba(147, 51, 234, 0.1);

      &:hover {
        background-color: rgba(147, 51, 234, 0.05);
      }
    }

    &__summary-circle-icon,
    &__flashcards-circle-icon,
    &__questions-circle-icon,
    &__quiz-circle-icon {
      position: absolute;
      right: -4px;
      top: 50%;
      transform: translateY(-50%);
      opacity: 0.8;
    }
  }

  &__questions-container,
  &__quiz-container {
    border: 1px solid rgb(218, 218, 218);
    padding: 2rem;
    border-radius: 1rem;
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
    margin-top: 4rem;
    text-align: center;

    &__coming-soon {
      font-family: roboto;
      font-size: 1.6rem;
      color: #666;
      font-style: italic;
    }
  }

  &__summary-container {
    border: 1px solid rgb(218, 218, 218);
    border-radius: 1rem;
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
    margin-top: 4rem;
    overflow: hidden;

    &__title {
      font-family: roboto;
      padding: 2rem;
      border-bottom: 1px solid rgb(218, 218, 218);
      margin: 0;
    }

    &__content {
      padding: 2rem;
    }

    &__subtitle {
      font-family: roboto;
      font-size: 1.4rem;
      color: #374151;
      margin-bottom: 1rem;
    }

    &__text {
      font-family: roboto;
      font-size: 1.4rem;
      line-height: 1.6;
      color: #4B5563;
      background-color: rgb(226, 236, 248);
      border: 1px solid rgb(219, 234, 254);
      border-radius: 0.5rem;
      padding: 1.5rem;
      margin-bottom: 2rem;
    }

    &__section {
      margin-bottom: 2rem;

      &:last-child {
        margin-bottom: 0;
      }
    }

    &__section-title {
      font-family: roboto;
      font-size: 1.3rem;
      color: #374151;
      margin-bottom: 1rem;
    }

    &__no-content {
      font-family: roboto;
      font-size: 1.4rem;
      color: #6B7280;
      text-align: center;
      font-style: italic;
    }
  }
}
.Searchbar {
  position: relative;
  display: flex;
  align-items: center;
  column-gap: 1rem;

  &__icon {
    position: absolute;
    inset-block-start: 1.2rem;
    inset-inline-start: 1.6rem;
    font-size: 2.4rem;
    color: rgb(165, 165, 165);
  }

  &__input {
    width: 100%;
    border-radius: 3.2rem;
    border: 1px solid rgb(207, 206, 206);
    padding-block: 1.3rem;
    padding-left: 4.3rem;
    
    &:focus-visible {
      outline: none;
      padding-block: 1.3rem;
      padding-left: 4.3rem;
      box-shadow: none;
      border: 1px solid rgb(207, 206, 206);
    }

    &::placeholder {
      color: rgb(144, 144, 144);
      font-family: 'Roboto';
    }
  }
}

.truncate {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.skeleton-animation {
  animation: skeleton-loading 1s linear infinite alternate;

  @keyframes skeleton-loading {
    0% {
      background-color: hsl(200, 20%, 80%);
    }
    100% {
      background-color: hsl(200, 20%, 95%);
    }
  }
}

.full-height {
  height: 100%;
}

.mx-auto {
  margin-inline: auto;
}

.blurImage {
  -webkit-filter: blur(8px);
  filter: blur(8px);
}

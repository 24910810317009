.Questions {
  &__container {
    padding: 1.6rem;
    background-color: var(--background-color);
    border-radius: 0.8rem;
    border: 1px solid var(--grayscale-color-200);
    transition: all 0.2s ease;
    margin-bottom: 1.2rem;
    
    &:hover {
      border-color: var(--grayscale-color-300);
      transform: translateY(-1px);
      box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.06);
    }

    &--locked {
      background: linear-gradient(135deg, rgb(255 237 213 / 50%) 0%, rgb(254 215 170 / 80%) 100%);
      border: 1px solid rgb(234 88 12 / 60%);
      cursor: not-allowed;
      
      &:hover {
        transform: none;
        box-shadow: none;
        border-color: rgb(234 88 12 / 80%);
      }

      .Questions__preview {
        color: rgb(234 88 12);
      }

      .Questions__lock-message {
        color: rgb(234 88 12);
      }
    }

    &--expanded {
      .Questions__toggle-icon {
        transform: rotate(180deg);
      }
    }
  }

  &__header {
    display: flex;
    align-items: center;
    gap: 1.2rem;
    width: 100%;
    cursor: pointer;

    @media (max-width: 400px) {
      gap: 0.8rem;
    }
  }

  &__question {
    flex: 1;
    display: flex;
    gap: 1.2rem;
  }

  &__number {
    font-size: 1.4rem;
    font-weight: 700;
    color: rgb(234 88 12);
    margin-right: .6rem;
  }

  &__text {
    font-size: 1.4rem;
    line-height: 1.5;
    color: var(--grayscale-color-900);
    margin: 0;
    font-weight: 700;
  }

  &__toggle {
    padding-top: 0.2rem;

    &-icon {
      color: var(--grayscale-color-400);
      transition: transform 0.2s;
      width: 2rem;
      height: 2rem;

      &--expanded {
        transform: rotate(180deg);
      }
    }
  }

  &__answer {
    padding: 1.6rem 0 0 3.2rem;
    color: var(--grayscale-color-600);
    line-height: 1.5;
    font-size: 1.4rem;
    font-weight: 400;
  }

  &__preview {
    padding: 1.6rem;
    text-align: center;
  }

  &__lock-message {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
    color: var(--grayscale-color-500);
    font-size: 1.4rem;

    .Questions__lock-icon {
      font-size: 1.2rem;
    }
  }

  &__feedback {
    display: flex;
    gap: 0.8rem;
    padding: 1.6rem 0 0 3.2rem;
    margin-top: 1rem;
    border-top: 1px solid var(--grayscale-color-200);

    &-button {
      padding: 0.8rem 1.2rem;
      border-radius: 0.6rem;
      font-size: 1.4rem;
      font-weight: 500;
      transition: all 0.2s;
      border: none;
      cursor: pointer;

      &--helpful {
        background-color: rgb(234 88 12 / 0.1);
        color: rgb(234 88 12);
        border: 1px solid transparent;

        &:hover {
          background-color: rgb(234 88 12 / 0.2);
        }

        &.Questions__feedback-button--active {
          background-color: rgb(234 88 12);
          color: white;
          border-color: rgb(234 88 12);
        }
      }

      &--not-helpful {
        background-color: rgb(239 68 68 / 0.1);
        color: rgb(239 68 68);
        border: 1px solid transparent;

        &:hover {
          background-color: rgb(239 68 68 / 0.2);
        }

        &.Questions__feedback-button--active {
          background-color: rgb(239 68 68);
          color: white;
          border-color: rgb(239 68 68);
        }
      }
    }
  }

  &__summary {
    background-color: var(--background-color);
    border: 1px solid #cccccc;
    border-radius: 0.8rem;
    padding: 1.6rem;
    margin-bottom: 2rem;
    font-family: Roboto;
    transition: all 0.2s ease;
    
    &:hover {
      border-color: var(--grayscale-color-200);
      box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.06);
      transform: translateY(-1px);
    }

    &-content {
      display: flex;
      align-items: center;
      gap: 1.2rem;
    }

    &-icon {
      color: rgb(234 88 12);
      font-size: 2rem;
    }

    &-info {
      flex: 1;

      @media (max-width: 400px) {
        margin-left: -0.2rem;
      }
    }

    &-total {
      font-size: 1.4rem;
      font-weight: 600;
      color: var(--grayscale-color-900);
    }
  }

  &__stats {
    display: flex;
    padding: 1.2rem 1.6rem;
    background: linear-gradient(135deg, rgb(255 237 213 / 50%) 0%, rgb(254 215 170 / 80%) 100%);
    border-radius: 0.8rem;
    border: 1px solid rgb(234 88 12 / 60%);
    margin-bottom: 2rem;
    width: 100%;
    backdrop-filter: blur(8px);
    transition: all 0.3s ease;

    @media (max-width: 1110px) {
      padding: 1.2rem;
    }

    &:hover {
      border-color: rgb(234 88 12);
      box-shadow: 0 4px 12px -2px rgba(234, 88, 12, 0.1);
      transform: translateY(-1px);
    }

    &-header {
      display: flex;
      align-items: center;
      gap: 1.2rem;
      width: 100%;
    }

    &-icon {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 3.2rem;
      height: 3.2rem;
      border-radius: 0.8rem;
      color: rgb(234 88 12);
      flex-shrink: 0;

      @media (max-width: 1110px) {
        width: 2.8rem;
        height: 2.8rem;
      }

      svg {
        width: 1.8rem;
        height: 1.8rem;
        
        @media (max-width: 1110px) {
          width: 1.6rem;
          height: 1.6rem;
        }
      }
    }

    &-info {
      flex: 1;
    }

    &-details {
      display: flex;
      align-items: center;
      gap: 1.6rem;

      @media (max-width: 768px) {
        flex-direction: column;
        align-items: flex-start;
        gap: 0.4rem;
      }
    }

    &-item {
      font-family: roboto;
      font-size: 1.4rem;
      color: rgb(154 52 18);
      display: flex;
      align-items: center;
      gap: 0.6rem;
      white-space: nowrap;

      @media (max-width: 768px) {
        font-size: 1.3rem;
        white-space: normal;
      }

      &--premium {
        &:first-child {
          font-weight: 600;
          font-size: 1.5rem;
          color: rgb(234 88 12);
          
          @media (max-width: 768px) {
            font-size: 1.4rem;
            margin-bottom: 0.2rem;
          }
        }

        &:not(:first-child) {
          font-size: 1.4rem;
          color: rgb(154 52 18);
          
          @media (max-width: 768px) {
            font-size: 1.3rem;
          }
        }

        span.premium {
          font-weight: 600;
          background: linear-gradient(135deg, rgb(234 88 12) 0%, rgb(194 65 12) 100%);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        }
      }
    }
  }
} 
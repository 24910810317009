.SignInForm {
  width: 100%;
  max-width: 450px;
  margin: 3.2rem auto 0;

  &__container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-column-gap: 1.6rem;
    -moz-column-gap: 1.6rem;
    column-gap: 1.6rem;
    width: 100%;

    & > * {
      -webkit-box-flex: 1;
      -ms-flex: 1 1 0px;
      flex: 1 1 0;
    }
  }

  &__field {
    -webkit-margin-after: 2rem;
    margin-block-end: 2rem;
    width: 100%;

    &--password {
      -webkit-margin-after: 2.4rem;
      margin-block-end: 2.4rem;
    }

    &-box {
      position: relative;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-direction: column;
      flex-direction: column;
      width: 100%;
    }
  }

  &__forgetPassword {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-column-gap: 1.6rem;
    -moz-column-gap: 1.6rem;
    column-gap: 1.6rem;
    color: var(--grayscale-color-600);
    width: 100%;

    & > p {
      -webkit-margin-before: 0.8rem;
      margin-block-start: 0.8rem;
      text-align: right;
      font-size: 1.4rem;
    }

    &-link {
      text-decoration: underline;
      color: var(--primary-color-500);
      -webkit-margin-before: 0.8rem;
      margin-block-start: 0.8rem;
      font-size: 1.4rem;

      &:hover {
        color: var(--primary-color-600);
      }
    }
  }

  &__submit {
    width: 100%;
  }

  @media screen and (max-width: 768px) {
    margin: 2.4rem auto 0;
    padding: 0 1.6rem;

    &__field {
      -webkit-margin-after: 1.6rem;
      margin-block-end: 1.6rem;

      &--password {
        -webkit-margin-after: 2rem;
        margin-block-end: 2rem;
      }
    }

    &__forgetPassword {
      flex-direction: column;
      align-items: flex-start;
      gap: 0.8rem;

      & > p {
        width: 100%;
        text-align: left;
        font-size: 1.3rem;
      }

      &-link {
        font-size: 1.3rem;
      }
    }
  }
}

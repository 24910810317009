.Testimonials {
    text-align: center;
    margin-top:20px;  // Aumenta il margin-top per aggiungere spazio sotto il bottone
    padding: 3px;
    background-color: #f8f8f8;
    border-radius: 0px;
  }
  
  .Testimonials__stars {
    display: flex;
    justify-content: center;
    color: #28a745; // Colore verde come il bottone
    font-size: 24px;
  }
  
  .Testimonials__text {
    margin-top: 10px;
    font-size: 16px;
    color: #333;
  }
  
.SignInIntro {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  h3 {
    width: 100%;
    max-width: 450px;
    text-align: left;
    margin: 0;
    font-size: 3.2rem;
    line-height: 1.2;
    white-space: nowrap;
  }

  &__text {
    -webkit-margin-before: 0.4rem;
    margin-block-start: 0.4rem;
    font-size: 1.6rem;
    line-height: 1.5;
    color: var(--grayscale-color-600);
    width: 100%;
    text-align: left;
    max-width: 450px;
  }

  &__signUpLink {
    text-align: center;
    color: var(--grayscale-color-600);
    margin-block-start: 2.4rem;
    font-size: 1.5rem;
    width: 100%;
    max-width: 450px;

    &-link {
      text-decoration: underline;
      color: var(--primary-color-500);

      &:hover {
        color: var(--primary-color-600);
      }
    }
  }

  .highlight {
    color: #30844F;
  }

  @media screen and (max-width: 768px) {
    h3 {
      padding: 0 1.6rem;
      font-size: 2.4rem;
      white-space: nowrap;
      letter-spacing: -0.02em;
    }

    &__text {
      font-size: 1.5rem;
      line-height: 1.4;
      padding: 0 1.6rem;
    }

    &__signUpLink {
      margin-block-start: 2rem;
      font-size: 1.25rem;
      padding: 0 1.6rem;
    }
  }

  @media screen and (max-width: 480px) {
    h3 {
      font-size: 2.2rem;
      padding: 0 1rem;
      text-align: center;
    }
  }
}

.Review {
  padding-block: 6.4rem;

  &__exam-mode-background {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1000000;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__button {
    -webkit-margin-before: 3.2rem;
    margin-block-start: 3.2rem;
    background: linear-gradient(135deg, var(--primary-color-400) 0%, var(--primary-color-500) 100%) !important;
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06) !important;
    position: relative;
    overflow: hidden;
    transition: all 0.3s ease !important;
    color: white !important;
    
    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: -100%;
      width: 100%;
      height: 100%;
      background: linear-gradient(
        90deg,
        rgba(255, 255, 255, 0) 0%,
        rgba(255, 255, 255, 0.2) 50%,
        rgba(255, 255, 255, 0) 100%
      );
      animation: button-shine 6s infinite;
    }
    
    @keyframes button-shine {
      0% {
        left: -100%;
      }
      15% {
        left: 100%;
      }
      100% {
        left: 100%;
      }
    }
    
    &:hover {
      background: linear-gradient(135deg, var(--primary-color-500) 0%, var(--primary-color-600) 100%) !important;
      transform: translateY(-2px);
      box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05) !important;
      color: white !important;
    }
    
    &:active {
      background: linear-gradient(135deg, var(--primary-color-600) 0%, var(--primary-color-700) 100%) !important;
      transform: translateY(0);
      box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1) !important;
      color: white !important;
    }
  }
}

.character {
  font-family: sans-serif;
  font-size: 7.25rem;
  margin-right: 0.1rem;
  color: #000;
  font-weight: bold;

  &.character--special-light {
    color: #75b477 !important;
  }

  &.character--special-dark {
    color: #30844F !important;
  }

  @media (max-width: 1033px) {
    font-size: 6rem;
    padding-block: 1.6rem;
  }

  @media (max-width: 767px) {
    font-size: 5rem;
  }

  @media (max-width: 575px) {
    font-size: 4rem;
  }

  @media (max-width: 380px) {
    font-size: 4rem;
  }
}

.cursor {
  display: inline-block;
  background-color: #000000;
  width: 0.2ch;
  height: auto;
  vertical-align: bottom;
  margin-left: 0.2rem;
  animation: blink 0.4s step-end infinite;
}

.mobile-break {
  display: none;
  
  @media (max-width: 767px) {
    display: block;
  }
}

@keyframes blink {
  50% {
    opacity: 0;
  }
}

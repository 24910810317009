.ExamResult-container__exam-result {
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  font-family: 'Roboto', sans-serif;
  padding-top: 1rem;
  padding-bottom: 1rem;

  &-spinner-container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 1rem;
    height: 100vh;

    &__text {
      font-size: 1.5rem;
      font-weight: bold;
      color: #333333;
    }
  }

  &__content {
    padding: 1.5rem;
    border-radius: 1rem;
    max-width: 550px;
    border: 1px solid #D1D5DB; // Equivalent to border-x-gray-300
    border-top-width: 4px;

    // Adjust widths for different breakpoints
    @media (max-width: 640px) {
      max-width: 350px; // 2sm:max-w-[350px]
    }
    @media (min-width: 641px) and (max-width: 768px) {
      max-width: 500px; // sm:max-w-[500px]
    }
    @media (min-width: 769px) {
      max-width: 100%; // md:max-w-full
    }
  }

  &__heading {
    margin-top: 1.5rem;
    text-align: center;

    h2 {
      display: flex;
      align-items: baseline;
      justify-content: center;
      font-size: 2.5rem;
      font-weight: bold;
      gap: 0.5rem;
      color: #333333; // Text color from colors.text

      svg {
        color: inherit; // Inherits border color logic for the icon
      }
    }
  }
}

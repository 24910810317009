.ErrorNoteFile {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-column-gap: 0.4rem;
  -moz-column-gap: 0.4rem;
  column-gap: 0.4rem;
  width: 3.6rem;
  height: 3.6rem;
  padding-inline: 0.8rem;
  background-color: var(--feedbackRed-color-100);
  border-radius: 0.8rem;
  cursor: pointer;

  &__icon {
    font-size: 2rem;
    color: var(--feedbackRed-color-500);
  }

  &__box {
    padding: 1.2rem;
    -webkit-margin-before: 0.8rem;
    margin-block-start: 0.8rem;
    background-color: var(--grayscale-color-50);
    border: 1px solid var(--grayscale-color-200);
    border-radius: 0.8rem;
    -webkit-box-shadow: 0 0.8rem 1.2rem rgba(var(--dropdown-shadowColor-rgb), 0.15);
    box-shadow: 0 0.8rem 1.2rem rgba(var(--dropdown-shadowColor-rgb), 0.15);
    overflow: hidden;
  }

  &__text {
    max-width: 24rem;
  }
}

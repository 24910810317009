.Error {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  height: 100vh;

  &__box {
    -webkit-padding-before: 4rem;
    padding-block-start: 4rem;

    @media (max-width: 757px) {
      -webkit-padding-before: 0;
      padding-block-start: 0;
    }
  }

  &__title[data-id='h2'] {
    margin-bottom: 0.8rem;

    @media (max-width: 991px) {
      text-align: center;
    }

    @media (max-width: 757px) {
      font-size: 4.8rem;
    }

    @media (max-width: 575px) {
      font-size: 3.2rem;
    }
  }

  &__text {
    font-family: var(--font-family);
    font-size: 2rem;
    line-height: 1.5;
    color: var(--grayscale-color-700);

    @media (max-width: 991px) {
      text-align: center;
    }

    @media (max-width: 575px) {
      font-size: 1.6rem;
    }
  }

  &__btn {
    -webkit-margin-before: 3.2rem;
    margin-block-start: 3.2rem;

    @media (max-width: 991px) {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
    }

    @media (max-width: 575px) {
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-direction: column;
      flex-direction: column;
    }
  }

  &__image {
    width: 100%;
  }

  @media (max-width: 991px) {
    height: auto;
    min-height: 100vh;
  }

  @media (max-width: 757px) {
    -webkit-padding-before: 5.6rem;
    padding-block-start: 5.6rem;
  }

  @media (max-width: 575px) {
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
  }
}

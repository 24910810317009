.Modal {
  position: relative;
  margin: auto;
  border: none;
  border-radius: 12px;
  z-index: 1000;
  outline: none;

  &::-webkit-backdrop {
    background-color: rgba($color: #0b2316, $alpha: 0.2);
  }

  &::backdrop {
    background-color: rgba($color: #0b2316, $alpha: 0.2);
  }

  &__inner {
    width: 100vh;
    max-width: 46rem;
    background-color: var(--white-color);

    @media (max-width: 575px) {
      width: 100%;
      max-width: 100%;
    }
  }
}

// modal header
.ModalHeader {
  position: relative;

  &:has(> .Heading) {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }

  &__icon {
    padding: 0.8rem;
    border-radius: 4px;
    cursor: pointer;

    &-close {
      font-size: 2rem;
      color: gray;
    }
  }
}

.Flashcard {
  perspective: 1000px;
  margin-bottom: 2rem;

  &__inner {
    position: relative;
    height: 20rem;
    width: 100%;
    padding: 1.8rem;
    background-color: var(--grayscale-color-50);
    border-radius: 1.2rem;
    box-shadow: 0 0.4rem 0.8rem rgba($color: #262624, $alpha: 0.15);
    transition: all 0.25s cubic-bezier(0.4, 0, 0.2, 1);
    transform-style: preserve-3d;
    cursor: pointer;
    overflow: hidden;

    @media (max-width: 1150px) and (min-width: 769px) {
      height: 18rem;
      padding: 1.4rem;
    }

    @media (max-width: 768px) {
      height: 16rem;
    }
  }

  &__content {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 1.2rem;
    height: 100%;
  }

  &__header {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 0.4rem 0;
    transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);

    &--revealed {
      transform: rotate(360deg);
    }
  }

  &__main {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1.2rem;
    overflow: auto;
  }

  &__text-wrapper {
    flex: 1;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.4rem;
    overflow-y: auto;
    max-height: 100%;
    
    /* Stile scrollbar di base */
    scrollbar-width: thin;
    scrollbar-color: transparent transparent;
    
    &::-webkit-scrollbar {
      width: 2px !important;
    }
    
    &::-webkit-scrollbar-track {
      background: transparent !important;
    }
    
    &::-webkit-scrollbar-thumb {
      background-color: transparent !important;
    }
  }

  &__text {
    font-weight: 600;
    text-align: center;
    color: var(--grayscale-color-950);
    font-size: 1.65rem;
    line-height: 1.5;
    margin: 0;
    opacity: 1;
    transition: opacity 0.2s cubic-bezier(0.4, 0, 0.2, 1);
    height: 100%;

    @media (max-width: 1150px) and (min-width: 769px) {
      font-size: 1.35rem;
      line-height: 1.4;
    }
  }

  &__icon {
    font-size: 2.4rem !important;
    color: rgb(22 163 74) !important;
    opacity: 0.85;
    transition: all 0.2s ease;

    &:hover {
      opacity: 1;
      transform: scale(1.1);
    }
  }

  &--flip {
    .Flashcard__inner {
      background: linear-gradient(135deg, rgba(134, 239, 172, 0.12) 0%, rgba(187, 247, 208, 0.2) 100%);
      border: 2px solid rgba(134, 239, 172, 0.8);
      box-shadow: 0 0.4rem 1rem rgba($color: #262624, $alpha: 0.12);
    }
    
    .Flashcard__text-wrapper {
      justify-content: flex-start;
      align-items: flex-start;
      overflow-y: auto !important;
      -webkit-overflow-scrolling: touch;
      scrollbar-width: thin !important;
      scrollbar-color: rgba(134, 239, 172, 0.8) transparent !important;
      
      /* Stile scrollbar per il retro */
      &::-webkit-scrollbar {
        width: 2px !important;
        height: 30px !important;
      }
      
      &::-webkit-scrollbar-track {
        background: transparent !important;
      }
      
      &::-webkit-scrollbar-thumb {
        background-color: rgba(134, 239, 172, 0.8) !important;
        border-radius: 20px !important;
        height: 30px !important;
        min-height: 30px !important;
        max-height: 30px !important;
      }
    }
    
    .Flashcard__text {
      padding-right: 4px;
      padding-bottom: 10px;
      width: 100%;
    }
    
    .Flashcard__main {
      overflow: hidden;
      width: 100%;
      height: 100%;
    }
  }

  // Hover effects
  &:hover:not(&--locked) {
    .Flashcard__inner {
      transform: translateY(-3px);
      box-shadow:
        0 0.6rem 1.8rem rgba($color: #262624, $alpha: 0.12),
        0 0 0 2px rgba($color: #262624, $alpha: 0.06);
    }
  }

  // Locked state
  &--locked {
    .Flashcard__inner {
      background: linear-gradient(135deg, rgb(220 252 231 / 35%) 0%, rgb(220 252 231 / 65%) 100%);
      border: 1px solid rgb(34 197 94 / 45%);
      cursor: not-allowed;
      position: relative;
      overflow: hidden;
      box-shadow: 0 0.4rem 0.8rem rgba(22, 163, 74, 0.06);
      height: 20rem;
      width: 100%;

      @media (max-width: 1150px) and (min-width: 769px) {
        height: 18rem;
      }

      @media (max-width: 768px) {
        height: 16rem;
      }

      &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(
          to bottom,
          transparent 0%,
          rgb(220 252 231 / 65%) 90%
        );
        opacity: 0.9;
        transition: opacity 0.25s ease;
        pointer-events: none;
      }

      &:hover {
        transform: scale(1.01);
        border-color: rgb(34 197 94 / 65%);
        box-shadow: 0 0.6rem 1.6rem rgba(22, 163, 74, 0.1);

        &::after {
          opacity: 1;
        }
      }
    }

    .Flashcard__preview {
      position: relative;
      height: 100%;
      width: 100%;
      display: flex;
      flex-direction: column;
      padding: 0.4rem;
    }

    .Flashcard__preview-text {
      font-family: roboto;
      font-size: 1.35rem;
      color: rgb(20 83 45);
      margin: 0;
      line-height: 1.4;
      font-style: italic;
      padding: 0.8rem;
      background-color: transparent;
      border-radius: 0.8rem;
      border: none;
      overflow-y: auto;
      max-height: 30%;
    }

    .Flashcard__preview-fade {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      height: 70%;
      background: linear-gradient(
        to bottom,
        transparent,
        rgb(220 252 231 / 75%) 60%
      );
      display: flex;
      align-items: flex-end;
      justify-content: center;
      padding-bottom: 1.4rem;
    }

    .Flashcard__content-truncated {
      display: flex;
      align-items: center;
      gap: 0.8rem;
      padding: 0.8rem 1.2rem;
      background-color: rgb(220 252 231 / 75%);
      border: 1px dashed rgb(34 197 94 / 55%);
      border-radius: 0.8rem;
      transition: all 0.25s ease;
      z-index: 1;

      &:hover {
        background-color: rgb(220 252 231 / 90%);
        border-color: rgb(34 197 94 / 70%);
      }

      span {
        font-family: roboto;
        font-size: 1.35rem;
        color: rgb(5 150 105);
        font-style: italic;
      }

      .Flashcard__lock-icon {
        color: rgb(5 150 105);
        font-size: 1.5rem;
      }
    }
  }
}
.Socials {
  display: flex;
  align-items: center;
  gap: 2rem;

  &__item {
    list-style: none;
  }

  &__link {
    color: var(--grayscale-color-600);
    font-size: 2.4rem;
    transition: color 0.2s ease;
    display: flex;
    align-items: center;

    &:hover,
    &:active {
      color: var(--primary-color-600) !important;
    }
  }
}

.page-chisiamo {
  --chisiamo-green: #74B094;      // Verde accent per elementi interattivi
  --chisiamo-bg-green: #dee9e7;   // Verde chiaro per background hero e cta
  --chisiamo-title: #333333;      // Colore titoli
  --chisiamo-text: #666666;       // Colore testo
  --chisiamo-bg: #f8f9fa;         // Background sezioni alternate
  --section-padding: 8rem;
  --border-radius: 1.6rem;
  
  .chisiamo-section {
    &__hero {
      position: relative;
      background: var(--chisiamo-bg-green);
      padding: calc(var(--section-padding) + 8rem) 0 var(--section-padding);
      text-align: center;
      overflow: hidden;

      .chisiamo-container {
        position: relative;
        z-index: 2;
        max-width: 800px;
        margin: 0 auto;
        padding: 4rem;
      }
    }

    &__title {
      font-size: 4.8rem;
      font-weight: 800;
      color: var(--chisiamo-title);
      margin-bottom: 2rem;
      line-height: 1.2;
    }

    &__subtitle {
      font-size: 2rem;
      color: var(--chisiamo-title);
      line-height: 1.6;
      max-width: 700px;
      margin: 0 auto;
    }

    &__mission {
      padding: 8rem 0;
      background: white;

      .mission-card {
        background: var(--chisiamo-bg-green);
        border-radius: var(--border-radius);
        box-shadow: 0 10px 30px rgba(0, 0, 0, 0.08);
        padding: 6rem 4rem;
        margin: 0 auto;
        position: relative;
        z-index: 3;
        max-width: 1000px;
        
        h2 {
          font-size: 3.2rem;
          color: var(--chisiamo-title);
          margin-bottom: 3rem;
          text-align: center;
          font-weight: 700;
        }

        p {
          font-size: 1.8rem;
          line-height: 1.8;
          color: var(--chisiamo-text);
          text-align: center;
          max-width: 700px;
          margin: 0 auto;
        }
      }
    }

    &__team {
      padding: var(--section-padding) 0;
      background-color: white;

      h2 {
        font-size: 3.2rem;
        color: var(--chisiamo-title);
        margin-bottom: 4rem;
        text-align: center;
        font-weight: 700;
      }
    }

    &__team-grid {
      max-width: 1200px;
      margin: 0 auto;
      display: flex;
      flex-wrap: wrap;
      gap: 3rem;
      padding: 0 2rem;
    }

    &__team-member {
      flex: 1 1 calc(33.333% - 2rem);
      min-width: 300px;
      background: var(--chisiamo-bg);
      border-radius: var(--border-radius);
      box-shadow: 0 4px 20px rgba(0, 0, 0, 0.06);
      overflow: hidden;
      transition: transform 0.3s ease, box-shadow 0.3s ease;

      &:hover {
        transform: translateY(-5px);
        box-shadow: 0 8px 30px rgba(0, 0, 0, 0.12);
      }

      .member-content {
        padding: 3rem;
      }

      h3 {
        font-size: 2.4rem;
        color: var(--chisiamo-title);
        margin-bottom: 1rem;
        font-weight: 700;
      }

      &-role {
        color: var(--chisiamo-green);
        font-size: 1.8rem;
        margin-bottom: 2rem;
        font-weight: 500;
      }

      &-desc {
        font-size: 1.6rem;
        color: var(--chisiamo-text);
        line-height: 1.8;
      }
    }

    &__timeline {
      padding: var(--section-padding) 0;
      background: var(--chisiamo-bg);

      h2 {
        font-size: 3.2rem;
        color: var(--chisiamo-title);
        margin-bottom: 4rem;
        text-align: center;
        font-weight: 700;
      }
    }

    &__timeline-grid {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
      gap: 3rem;
      max-width: 1200px;
      margin: 0 auto;
      padding: 0 2rem;
    }

    &__timeline-item {
      background: white;
      border-radius: var(--border-radius);
      overflow: hidden;
      position: relative;

      &::before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        width: 4px;
        background: var(--chisiamo-green);
      }

      .milestone-content {
        padding: 3rem;
      }

      h3 {
        font-size: 2.2rem;
        color: var(--chisiamo-title);
        margin-bottom: 1.5rem;
        font-weight: 600;
      }

      p {
        font-size: 1.6rem;
        color: var(--chisiamo-text);
        line-height: 1.7;
      }
    }

    &__timeline-year {
      font-size: 2rem;
      font-weight: bold;
      color: var(--chisiamo-green);
      margin-bottom: 1rem;
      display: inline-block;
      padding: 0.5rem 1.5rem;
      background: rgba(116, 176, 148, 0.1);
      border-radius: 2rem;
    }
  }

  // Responsive styles
  @media (max-width: 1024px) {
    --section-padding: 6rem;

    .chisiamo-section {
      &__team-member {
        flex: 1 1 calc(50% - 2rem);
      }
    }
  }

  @media (max-width: 768px) {
    --section-padding: 5rem;

    .chisiamo-section {
      &__title {
        font-size: 3.6rem;
      }

      &__subtitle {
        font-size: 1.8rem;
      }

      &__team-member {
        flex: 1 1 100%;
      }

      &__timeline-grid {
        grid-template-columns: 1fr;
      }

      .mission-card {
        margin: 0 2rem;
        padding: 3rem;
      }
    }
  }

  @media (max-width: 480px) {
    --section-padding: 4rem;

    .chisiamo-section {
      &__hero {
        .chisiamo-container {
          padding: 2rem;
        }
      }

      &__title {
        font-size: 3rem;
      }

      .mission-card {
        margin: 0 1.5rem;
        padding: 2rem;

        h2 {
          font-size: 2.6rem;
        }

        p {
          font-size: 1.6rem;
        }
      }
    }
  }
}

// Utility class
.chisiamo-container {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 2rem;
}
.MaterialsQuestions {
  padding-top: 1.6rem;

  &__stats {
    display: inline-flex;
    padding: 1.6rem;
    background-color: var(--grayscale-color-50);
    border-radius: 0.8rem;
    border: 1px solid var(--grayscale-color-200);
    align-self: flex-start;
    transition: all 0.3s ease;
    margin-bottom: 2rem;

    @media (max-width: 768px) {
      width: 100%;
      padding: 1.2rem;
      margin-bottom: 1.6rem;
    }

    &:hover {
      box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1);
      border-color: rgb(234 88 12);
    }

    &-header {
      display: flex;
      align-items: center;
      gap: 1.6rem;
      width: 100%;

      @media (max-width: 768px) {
        gap: 1.2rem;
      }
    }

    &-icon {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 3.8rem;
      height: 3.8rem;
      border-radius: 0.8rem;
      background-color: var(--background-color);
      color: rgb(234 88 12);
      flex-shrink: 0;
      cursor: pointer;
      transition: all 0.2s ease;
      border: 1px solid var(--grayscale-color-200);
      position: relative;
      overflow: hidden;

      @media (max-width: 768px) {
        width: 3.4rem;
        height: 3.4rem;
      }

      &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgb(234 88 12);
        transform: translateY(100%);
        transition: transform 0.2s ease;
      }

      svg {
        position: relative;
        z-index: 1;
        transition: all 0.2s ease;
        font-size: 2.4rem;

        @media (max-width: 768px) {
          font-size: 2.2rem;
        }
      }

      &:hover {
        border-color: rgb(234 88 12);

        &::before {
          transform: translateY(0);
        }

        svg {
          color: white;
          transform: scale(1.1);
        }
      }

      &--disabled {
        color: var(--grayscale-color-400);
        cursor: not-allowed;

        &:hover {
          border-color: var(--grayscale-color-300);

          &::before {
            transform: translateY(100%);
          }

          svg {
            color: var(--grayscale-color-400);
            transform: none;
          }
        }
      }
    }

    &-info {
      display: flex;
      align-items: center;
      gap: 1.4rem;
      flex: 1;
      min-width: 0;

      @media (max-width: 768px) {
        gap: 1rem;
        overflow-x: auto;
        scrollbar-width: none;
        -ms-overflow-style: none;
        &::-webkit-scrollbar {
          display: none;
        }
      }
    }

    &-details {
      display: flex;
      align-items: center;
      gap: 1.6rem;
      flex-wrap: nowrap;
      flex: 1;

      @media (max-width: 768px) {
        gap: 1.2rem;
      }
    }

    &-item {
      display: flex;
      align-items: center;
      gap: 0.6rem;
      font-family: roboto;
      font-size: 1.5rem;
      font-weight: 600;
      color: var(--grayscale-color-950);
      white-space: nowrap;
      padding-right: 1.6rem;
      border-right: 1px solid var(--grayscale-color-200);
      flex-shrink: 0;

      @media (max-width: 768px) {
        font-size: 1.3rem;
        padding-right: 1.2rem;
        gap: 0.4rem;
      }

      &:last-child {
        padding-right: 1.6rem;
        border-right: none;
        margin-right: 0.4rem;
      }

      &-icon {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 2.4rem;
        height: 2.4rem;
        border-radius: 0.4rem;
        flex-shrink: 0;

        &--not-evaluated {
          background-color: rgb(234 88 12 / 10%);
          color: rgb(234 88 12);
        }

        &--review {
          background-color: rgb(220 38 38 / 10%);
          color: rgb(220 38 38);
        }

        &--known {
          background-color: rgb(34 197 94 / 10%);
          color: rgb(34 197 94);
        }

        svg {
          font-size: 1.4rem;
        }
      }

      &--clickable {
        cursor: pointer;
        transition: all 0.2s ease;

        &:not(.MaterialsQuestions__stats-item--selected):hover {
          transform: translateY(-1px);
        }

        &:active {
          transform: translateY(0);
        }
      }

      &-close {
        font-size: 1.6rem;
        color: var(--grayscale-color-400);
        margin-left: 0.4rem;
        transition: all 0.2s ease;

        &:hover {
          color: var(--grayscale-color-600);
        }
      }

      &--selected {
        background-color: var(--grayscale-color-50);
        padding: 0.8rem 1.2rem;
        border: 1px solid var(--grayscale-color-200);
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);

        @media (max-width: 768px) {
          padding: 0.6rem 1rem;
        }
      }
    }
  }

  &__premium-banner {
    display: flex;
    align-items: center;
    margin-bottom: 1.2rem;
    background: linear-gradient(135deg, rgb(255 237 213 / 50%) 0%, rgb(255 237 213 / 80%) 100%);
    border: 1px solid rgb(234 88 12 / 60%);
    border-radius: 1.2rem;
    backdrop-filter: blur(8px);
    transition: all 0.3s ease;
    overflow: hidden;
    width: 100%;

    &:hover {
      border-color: rgb(234 88 12 / 80%);
      box-shadow: 0 8px 24px -4px rgba(234, 88, 12, 0.1);
      transform: translateY(-1px);
    }

    &-content {
      flex: 1;
      display: flex;
      align-items: center;
      gap: 2.4rem;
      padding: 1.6rem 2.4rem;

      @media (max-width: 768px) {
        flex-direction: column;
        align-items: flex-start;
        gap: 1.6rem;
        padding: 1.6rem;
      }
    }

    &-header {
      display: flex;
      align-items: center;
      gap: 1rem;
      margin: 0;
      min-width: max-content;

      h6 {
        font-family: roboto;
        font-size: 1.6rem;
        font-weight: 600;
        color: rgb(234 88 12);
        margin: 0;
      }
    }

    &-icon {
      font-size: 2rem;
      color: rgb(234 88 12);
    }

    p {
      font-family: roboto;
      font-size: 1.4rem;
      line-height: 1.5;
      color: rgb(154 52 18);
      margin: 0;
      flex: 1;

      @media (max-width: 768px) {
        margin-bottom: 0.8rem;
      }
    }

    &-button {
      display: inline-flex;
      align-items: center;
      gap: 0.8rem;
      padding: 1rem 2rem;
      background-color: rgb(234 88 12);
      border: none;
      border-radius: 2.4rem;
      cursor: pointer;
      transition: all 0.3s ease;
      white-space: nowrap;
      min-width: max-content;
      margin-left: auto;
      position: relative;
      overflow: hidden;

      &.animate-button::before {
        content: '';
        position: absolute;
        top: 0;
        left: -100%;
        width: 100%;
        height: 100%;
        background: linear-gradient(
          90deg,
          rgba(255, 255, 255, 0) 0%,
          rgba(255, 255, 255, 0.2) 50%,
          rgba(255, 255, 255, 0) 100%
        );
        animation: button-shine 6s infinite;
      }
      
      @keyframes button-shine {
        0% {
          left: -100%;
        }
        15% {
          left: 100%;
        }
        100% {
          left: 100%;
        }
      }

      @media (max-width: 768px) {
        width: 100%;
        justify-content: center;
        margin-left: 0;
      }

      &:hover {
        background-color: rgb(194 65 12);
        transform: translateY(-1px);
        box-shadow: 0 4px 12px -2px rgba(234, 88, 12, 0.3);
      }

      &:active {
        transform: translateY(0);
      }

      span {
        font-family: roboto;
        font-size: 1.4rem;
        font-weight: 500;
        color: white;
      }

      &-icon {
        color: white;
        font-size: 1.8rem;
        transition: transform 0.2s ease;
      }

      &:hover &-icon {
        transform: translateX(0.4rem);
      }
    }
  }

  &__container {
    margin: 0;
    padding: 0;
    margin-left: -1.6rem;
    margin-right: -1.6rem;

    &-skeleton {
      display: flex;
      flex-wrap: wrap;
      gap: 1rem;
      margin-top: 4rem;
    }

    [class*="col-"] {
      padding: 1.2rem 1.6rem;

      @media (max-width: 1150px) and (min-width: 769px) {
        width: calc(25% - 0.4rem);
        padding: 0.8rem;
        flex: 0 0 calc(25% - 0.4rem);
        max-width: calc(25% - 0.4rem);
      }
    }

    .Question {
      margin: 0;

      @media (max-width: 1150px) and (min-width: 769px) {
        &__inner {
          min-height: 15rem;
          padding: 1.4rem;
        }

        &__text {
          font-size: 1.35rem;
          line-height: 1.4;
        }

        &__confidence-meter {
          width: 160px;
          padding-top: 1.2rem;
        }

        &__confidence-button {
          padding: 0.8rem 1.2rem;
          min-width: 75px;
          max-width: 80px;
          font-size: 1.25rem;
          border-radius: 0.6rem;
        }

        &__confidence-buttons {
          gap: 0.8rem;
        }

        &__confidence-label {
          font-size: 1.2rem;
          margin-bottom: 1rem;
        }
      }
    }

    @media (max-width: 768px) {
      margin-left: -0.8rem;
      margin-right: -0.8rem;

      [class*="col-"] {
        width: 100%;
        padding: 0.8rem;
      }
    }
  }

  &__loading {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 40rem;
    width: 100%;
    gap: 1rem;

    p {
      font-family: roboto;
      font-size: 1.4rem;
      color: var(--grayscale-color-600);
      margin: 0;
    }
  }

  &__stats-skeleton {
    padding: 1.6rem;
    background: var(--grayscale-color-50);
    border-radius: 8px;
    margin-bottom: 1rem;
    max-width: 18rem;

    .stats-header-skeleton {
      display: flex;
      align-items: center;

      .stats-info-skeleton {
        flex: 1;

        .stats-details-skeleton {
          height: 20px;
          width: 100%;
          background: var(--grayscale-color-100);
          border-radius: 4px;
        }
      }
    }
  }

  .questions-skeleton {
    background: white;
    border-radius: 8px;
    padding: 1.5rem;
    margin-bottom: 1rem;
    box-shadow: 0 2px 4px rgba(0,0,0,0.05);
    width: 25rem;
    height: 15rem;

    .question-skeleton {
      height: 20px;
      background: var(--grayscale-color-100);
      margin-bottom: 1rem;
      border-radius: 4px;
    }

    .answer-skeleton {
      height: 16px;
      width: 60%;
      background: var(--grayscale-color-100);
      border-radius: 4px;
    }
  }

  @media (max-width: 575px) {
    .MaterialsQuestions__container-skeleton {
      justify-content: center;
    }

    .questions-skeleton {
      padding: 1rem;
      width: 100%;

      .question-skeleton {
        height: 18px;
      }

      .answer-skeleton {
        height: 14px;
      }
    }
  }

  @media (max-width: 768px) {
    padding-top: 2.4rem;
  }

  &__filter-reset {
    display: inline-flex;
    align-items: center;
    gap: 0.8rem;
    padding: 1.2rem 2rem;
    border: 1px solid var(--grayscale-color-200);
    border-radius: 0.8rem;
    background-color: white;
    font-family: roboto;
    font-size: 1.5rem;
    color: var(--grayscale-color-950);
    cursor: pointer;
    transition: all 0.2s ease;
    margin-bottom: 2rem;

    svg {
      font-size: 1.8rem;
      color: var(--grayscale-color-400);
    }

    &:hover {
      background-color: var(--grayscale-color-50);
      border-color: var(--grayscale-color-300);
      transform: translateY(-1px);
    }

    &:active {
      transform: translateY(0);
    }

    @media (max-width: 768px) {
      width: 100%;
      justify-content: center;
    }
  }

  &__no-results {
    width: 100%;
    text-align: center;
    padding: 4rem 2rem;
    background-color: var(--grayscale-color-50);
    border-radius: 1.2rem;
    border: 1px solid var(--grayscale-color-200);

    p {
      font-family: roboto;
      font-size: 1.6rem;
      color: var(--grayscale-color-600);
      margin-bottom: 1.6rem;
    }
  }

  &__reset-filters {
    display: inline-flex;
    align-items: center;
    padding: 1rem 2rem;
    background-color: white;
    border: 1px solid var(--grayscale-color-200);
    border-radius: 0.8rem;
    font-family: roboto;
    font-size: 1.4rem;
    color: var(--grayscale-color-950);
    cursor: pointer;
    transition: all 0.2s ease;

    &:hover {
      background-color: var(--grayscale-color-50);
      border-color: var(--grayscale-color-300);
      transform: translateY(-1px);
    }

    &:active {
      transform: translateY(0);
    }
  }

  &__stats-item {
    display: flex;
    align-items: center;
    gap: 0.6rem;
    font-family: roboto;
    font-size: 1.5rem;
    font-weight: 600;
    color: var(--grayscale-color-950);
    white-space: nowrap;
    padding-right: 1.6rem;
    flex-shrink: 0;
    transition: all 0.2s ease;

    &:not(:last-child) {
      margin-right: 0;
      padding-right: 1.6rem;
      border-right: 1px solid var(--grayscale-color-200);
    }

    @media (max-width: 768px) {
      font-size: 1.3rem;
      padding-right: 1.2rem;
      gap: 0.4rem;

      &:not(:last-child) {
        margin-right: 0;
        padding-right: 1.2rem;
      }
    }

    &--clickable {
      cursor: pointer;
      background-color: var(--background-color);
      border: 1px solid var(--grayscale-color-100);
      border-radius: 0.8rem;
      transition: all 0.2s ease;
      padding: 0.8rem 1.2rem;

      @media (max-width: 768px) {
        padding: 0.6rem 1rem;
      }

      &:not(.MaterialsQuestions__stats-item--selected):hover {
        transform: translateY(-1px);
        background-color: var(--grayscale-color-50);
        border-color: var(--grayscale-color-200);
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);

        .MaterialsQuestions__stats-item-icon {
          transform: scale(1.05);
        }
      }

      &:active {
        transform: translateY(0);
        background-color: var(--grayscale-color-100);
      }
    }

    &-icon {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 2.4rem;
      height: 2.4rem;
      border-radius: 0.6rem;
      flex-shrink: 0;
      transition: all 0.2s ease;

      &--not-evaluated {
        background-color: rgb(234 88 12 / 10%);
        color: rgb(234 88 12);
      }

      &--review {
        background-color: rgb(220 38 38 / 10%);
        color: rgb(220 38 38);
      }

      &--known {
        background-color: rgb(34 197 94 / 10%);
        color: rgb(34 197 94);
      }

      svg {
        font-size: 1.4rem;
      }
    }

    &--selected {
      background-color: var(--grayscale-color-50);
      padding: 0.8rem 1.2rem;
      border: 1px solid var(--grayscale-color-200);
      border-radius: 0.6rem;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);

      @media (max-width: 768px) {
        padding: 0.6rem 1rem;
      }
    }

    // Stili specifici per ogni tipo di stato quando selezionato
    &--selected.MaterialsQuestions__stats-item--not-evaluated {
      background-color: rgb(234 88 12 / 5%);
      border-color: rgb(234 88 12 / 30%);
    }

    &--selected.MaterialsQuestions__stats-item--review {
      background-color: rgb(220 38 38 / 5%);
      border-color: rgb(220 38 38 / 30%);
    }

    &--selected.MaterialsQuestions__stats-item--known {
      background-color: rgb(34 197 94 / 5%);
      border-color: rgb(34 197 94 / 30%);
    }
  }
}
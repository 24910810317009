.ExamFeedback {
  
  &__list {
    font-size: 1.2rem;
    max-width: 570px;
    font-weight: 500;

    &__strengths, &__weaknesses, &__advice {
      border-radius: 0.8rem;
      padding: 1rem;
      margin-bottom: 1rem;
    }

    &__strengths {
      background-color: #1ab85434;
    }

    &__weaknesses {
      background-color: #dc262639;
    }

    &__advice {
      background-color: #bfdbfe;
      margin-bottom: 0;
    }
  }
}
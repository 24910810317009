.VerifyEmailInfo {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  &__gif {
    max-width: 200px;  // Aumenta la dimensione della GIF
    margin-bottom: -2rem;
  }

  &__box {
    margin-block-start: 1.6rem;
    max-width: 500px;

    &-text {
      margin-block-start: 2rem;
      color: #555;
      font-size: 1.2rem;  // Aumenta la dimensione del testo
    }
  }

  &__divider {
    width: 50%;
    margin: 1rem auto;
    border: none;
    border-top: 1px solid #ccc;  // Aggiungi un trattino
  }
}

.Btn {
  border: 0px;
  border-radius: 8px;
  outline: 0px;
  font-family: var(--font-family);
  font-weight: 700;
  line-height: 150%;
  cursor: pointer;
  -webkit-transition: all 250ms;
  -o-transition: all 250ms;
  transition: all 250ms;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;

  /* variants */
  &--primary {
    background: var(--primary-color-500);
    color: var(--grayscale-color-50);

    &:hover,
    &:active {
      background: var(--primary-color-600);
    }

    &:disabled {
      background: var(--primary-color-200);
      color: var(--primary-color-700);
    }
  }

  &--secondary {
    background: var(--secondary-color-500);
    color: var(--grayscale-color-50);

    &:hover,
    &:active {
      background: var(--secondary-color-600);
    }
  }

  &--tertiary {
    background-color: transparent;
    border: 1px solid var(--primary-color-500);
    color: var(--primary-color-600);

    &:hover {
      border: 1px solid var(--primary-color-600);
      color: var(--primary-color-700);
    }
  }
  
  &--outline {
    background-color: transparent;
    border: 1px solid var(--grayscale-color-300);
    color: var(--grayscale-color-800);

    &:hover {
      border-color: var(--button-hover-border, var(--grayscale-color-400));
      background-color: var(--button-hover-bg, var(--grayscale-color-100));
      color: var(--button-hover-color, var(--grayscale-color-800));
    }

    &:active {
      border-color: var(--grayscale-color-500);
      background-color: var(--grayscale-color-200);
    }

    &:disabled {
      border-color: var(--grayscale-color-200);
      color: var(--grayscale-color-400);
      background-color: transparent;
    }
  }
  
  &--custom {
    background-color: var(--button-bg, var(--primary-color-500));
    color: var(--grayscale-color-50);
    
    &:hover {
      background-color: var(--button-hover-bg, var(--primary-color-600));
    }
    
    &:active {
      background-color: var(--button-active-bg, var(--primary-color-700));
    }
    
    &:disabled {
      opacity: 0.6;
      cursor: not-allowed;
    }
  }

  /* sizes */
  &--sm {
    padding: 0.8rem 1.4rem;
    font-size: 1.20rem;
  }

  &--sm-2 {
    padding: 0.8rem 1.4rem;
    font-size: 1.4rem;
  }

  &--md {
    height: 4.8rem;
    padding-inline: 1.6rem;
    font-size: 1.4rem;
  }

  &--lg {
    height: 5.2rem;
    padding-inline: 2.4rem;
    font-size: 1.6rem;
  }

  /* when has icon */
  &:has(> svg) {
    & > svg {
      display: inline-block;
      vertical-align: middle;
      -webkit-margin-end: 0.8rem;
      margin-inline-end: 0.8rem;
    }
  }

  &:disabled {
    opacity: 0.5;
    pointer-events: none;
  }
}
.MaterialsDocuments {
  width: 100%;
  
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 2rem;
    padding-bottom: 1rem;
    border-bottom: 1px solid var(--grayscale-color-200);
    
    @media (max-width: 768px) {
      flex-direction: column;
      align-items: flex-start;
      gap: 1rem;
    }
  }
  
  &__title-section {
    display: flex;
    align-items: center;
  }
  
  &__section-title {
    font-size: 1.8rem;
    font-weight: 600;
    color: var(--grayscale-color-900);
    margin: 0;
  }
  
  &__controls {
    display: flex;
    align-items: center;
    gap: 1rem;
  }
  
  &__create-button {
    height: 40px;
    font-size: 1.4rem;
    
    svg {
      margin-right: 0.5rem;
    }
  }
  
  &__selection-controls {
    display: flex;
    align-items: center;
    gap: 1rem;
    
    @media (max-width: 768px) {
      flex-wrap: wrap;
    }
  }
  
  &__selection-header {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 2rem;
    padding: 1.8rem 2rem;
    border-radius: 1rem;
    background-color: rgba(var(--primary-color-rgb), 0.05);
    border: 2px solid;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
    
    @media (min-width: 980px) {
      flex-direction: row;
      align-items: center;
    }
    
    @media (max-width: 979px) {
      gap: 1.6rem;
    }
  }
  
  &__selection-container {
    display: flex;
    flex-direction: column;
    gap: 1.4rem;
    
    @media (min-width: 980px) {
      flex-direction: row;
      align-items: center;
      width: auto;
      flex: 1;
      gap: 2.5rem;
    }
  }
  
  &__document-selection {
    @media (min-width: 980px) {
      min-width: 240px;
    }
  }
  
  &__selection-text {
    font-size: 1.6rem;
    font-weight: 600;
    color: var(--grayscale-color-900);
    margin-bottom: 0.4rem;
    
    &--empty {
      color: var(--grayscale-color-600);
      font-style: italic;
    }
  }
  
  &__selection-count {
    font-size: 1.4rem;
    font-weight: 500;
    color: var(--grayscale-color-700);
  }
  
  &__color-section {
    @media (min-width: 980px) {
      border-left: 1px solid var(--grayscale-color-200);
      margin-top: 0;
      padding-top: 0;
      padding-left: 2.5rem;
      border-top: none;
    }
    
    @media (max-width: 979px) {
      margin-top: 0.6rem;
      padding-top: 1.4rem;
      border-top: 1px solid var(--grayscale-color-200);
      width: 100%;
    }
  }
  
  &__color-selector {
    display: flex;
    align-items: center;
    gap: 1.4rem;
    
    @media (max-width: 580px) {
      flex-direction: column;
      align-items: flex-start;
      gap: 0.8rem;
    }
  }
  
  &__color-label {
    font-size: 1.5rem;
    font-weight: 600;
    color: var(--grayscale-color-800);
    white-space: nowrap;
  }
  
  &__color-options {
    display: flex;
    gap: 1rem;
    flex-wrap: wrap;
  }
  
  &__color-option {
    width: 2.8rem;
    height: 2.8rem;
    border-radius: 50%;
    border: 2px solid transparent;
    cursor: pointer;
    transition: all 0.2s ease;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12);

    &:hover {
      transform: scale(1.15);
      box-shadow: 0 3px 6px rgba(0, 0, 0, 0.15);
    }

    &--selected {
      border-color: var(--grayscale-color-900);
      transform: scale(1.15);
      box-shadow: 0 3px 6px rgba(0, 0, 0, 0.15);
    }
  }
  
  &__buttons {
    display: flex;
    align-items: center;
    gap: 1.2rem;
    
    @media (max-width: 768px) {
      margin-top: 1rem;
      width: 100%;
      flex-wrap: wrap;
      justify-content: flex-end;
    }
  }
  
  &__save-button, &__cancel-button, &__back-button {
    min-width: 110px;
    height: 40px;
    font-size: 1.4rem;
    padding: 0 1.4rem;
    white-space: nowrap;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.6rem;
    
    svg {
      flex-shrink: 0;
      margin-left: 0;
      margin-right: 0;
    }
    
    /* Correzione per il posizionamento delle icone quando sono a destra */
    &--icon-right {
      flex-direction: row-reverse;
      
      svg {
        margin-left: 0.4rem;
        margin-right: -0.2rem;
      }
    }
  }
  
  &__save-button {
    font-weight: 600;
    position: relative;
    overflow: hidden;
    transition: all 0.3s ease;
    
    &:not(:disabled)::before {
      content: '';
      position: absolute;
      top: 0;
      left: -100%;
      width: 100%;
      height: 100%;
      background: linear-gradient(
        90deg,
        rgba(255, 255, 255, 0) 0%,
        var(--button-shine-color, rgba(255, 255, 255, 0.2)) 50%,
        rgba(255, 255, 255, 0) 100%
      );
      animation: button-shine 6s infinite;
    }
    
    @keyframes button-shine {
      0% {
        left: -100%;
      }
      15% {
        left: 100%;
      }
      100% {
        left: 100%;
      }
    }
    
    &:hover:not(:disabled) {
      transform: translateY(-2px);
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
    }
    
    &:active:not(:disabled) {
      transform: translateY(0);
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    }
  }
  
  &__cancel-button, &__back-button {
    font-weight: 500;
    transition: all 0.3s ease;
    
    // Variabili CSS per i colori personalizzati
    --button-hover-bg: rgba(var(--grayscale-color-950-rgb), 0.05);
    --button-hover-color: var(--grayscale-color-900);
    --button-hover-border: var(--grayscale-color-400);
    
    &:hover {
      background-color: var(--button-hover-bg);
      color: var(--button-hover-color);
      border-color: var(--button-hover-border);
      transform: translateY(-2px);
    }
    
    &:active {
      transform: translateY(0);
    }
  }
  
  &__folders-section, &__documents-section {
    margin-bottom: 3rem;
  }
  
  &__subsection-title {
    display: flex;
    align-items: center;
    font-size: 1.6rem;
    font-weight: 600;
    color: var(--grayscale-color-900);
    margin-bottom: 1.5rem;
    
    svg {
      margin-right: 0.8rem;
    }
  }
  
  &__unified-list {
    display: flex;
    flex-wrap: nowrap;
    gap: 16px !important;
    padding: 1.2rem 0;
    padding-top: 10px;
    margin: 0;
    list-style: none;
    overflow-x: auto;
    width: 100%;
    align-items: stretch;
    
    /* Migliora lo scorrimento su macOS */
    -webkit-overflow-scrolling: touch;
    scrollbar-width: thin;
    
    &::-webkit-scrollbar {
      height: 8px;
    }
    
    &::-webkit-scrollbar-track {
      background: var(--grayscale-color-100);
      border-radius: 8px;
    }
    
    &::-webkit-scrollbar-thumb {
      background: var(--grayscale-color-300);
      border-radius: 8px;
      
      &:hover {
        background: var(--grayscale-color-400);
      }
    }
    
    @media (max-width: 768px) {
      padding-bottom: 1.5rem;
      padding-top: 10px;
      gap: 16px !important;
    }
    
    /* Assicura che tutti gli elementi della lista abbiano la stessa altezza */
    & > li {
      height: 100%;
      padding-inline: 0;
      margin-right: 0;
      
      &:first-child {
        padding-inline-start: 0;
      }
      
      @media (max-width: 768px) {
        padding-inline: 0;
        
        &:first-child {
          padding-inline-start: 0;
        }
      }
    }
  }
  
  &__naming-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 1.5rem;
  }
  
  &__naming-input-group {
    display: flex;
    align-items: center;
    gap: 1rem;
    flex: 1;
    max-width: 500px;
  }
  
  &__folder-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    flex-shrink: 0;
  }
  
  &__folder-name-input {
    width: 100%;
    height: 48px;
    border-radius: 8px;
    font-size: 1.6rem;
    transition: all 0.2s ease;
    
    &:focus {
      border-color: var(--primary-color);
      box-shadow: 0 0 0 2px rgba(var(--current-folder-color-rgb), 0.15);
    }
  }
  
  @media (max-width: 768px) {
    &__selection-header {
      padding: 0.8rem 1rem;
      gap: 0.6rem;
      flex-direction: row;
      flex-wrap: wrap;
      align-items: center;
    }
    
    &__selection-container {
      flex-direction: row;
      align-items: center;
      flex-wrap: wrap;
      gap: 0.8rem;
      justify-content: flex-start;
      margin-right: 0.8rem;
      flex: 1;
      width: auto;
    }
    
    &__document-selection {
      flex: 0 0 auto;
      margin-right: 0.8rem;
    }
    
    &__selection-text {
      font-size: 1.3rem;
      margin-bottom: 0.2rem;
    }
    
    &__selection-count {
      font-size: 1.1rem;
    }
    
    &__color-section {
      flex: 0 0 auto;
      margin-top: 0;
      padding-top: 0;
      border-top: none;
      padding-left: 0;
      margin-left: 0;
      width: auto;
      border-left: none;
    }
    
    &__color-selector {
      flex-direction: row;
      align-items: center;
      gap: 0.6rem;
    }
    
    &__color-label {
      font-size: 1.3rem;
      margin-right: 0.3rem;
    }
    
    &__color-options {
      display: flex;
      flex-wrap: nowrap;
      gap: 0.5rem;
    }
    
    &__color-option {
      width: 1.8rem;
      height: 1.8rem;
    }
    
    &__buttons {
      margin-top: 0;
      width: auto;
      flex-wrap: nowrap;
      justify-content: flex-end;
      gap: 0.6rem;
    }
    
    &__save-button, &__cancel-button, &__back-button {
      min-width: auto;
      width: 34px;
      padding: 0;
      height: 34px;
      font-size: 1.2rem;
      display: flex;
      align-items: center;
      justify-content: center;
      
      span {
        display: none;
      }
      
      svg {
        margin: 0 auto;
      }
    }
  }
  
  /* Classi per la visualizzazione condizionale desktop/mobile */
  .desktop-only {
    display: inline-block;
  }
  
  .mobile-only {
    display: none;
  }
  
  @media (max-width: 768px) {
    .desktop-only {
      display: none;
    }
    
    .mobile-only {
      display: inline-block;
    }
    
    &__save-button, &__cancel-button, &__back-button {
      min-width: auto;
      width: 34px;
      padding: 0;
      height: 34px;
      font-size: 1.2rem;
      display: flex;
      align-items: center;
      justify-content: center;
      
      span {
        display: none;
      }
      
      svg {
        margin: 0 auto;
      }
    }
  }
}
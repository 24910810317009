.Functionality {
  padding-block: 0rem 5rem;
  margin-top: -3rem;

  &__row {
    margin-block-end: 0;

    @media (max-width: 991px) {
      margin-block-end: 0;
    }
  }

  .slick-slider {
    position: relative;

    @media (max-width: 991px) {
      height: 700px;
    }

    .slick-list, .slick-track {
      @media (max-width: 991px) {
        height: 100%;
      }
    }

    .slick-slide {
      @media (max-width: 991px) {
        height: 700px;
        > div {
          height: 100%;
        }
      }
    }

    .slick-prev, .slick-next {
      z-index: 1;
      width: 5rem;
      height: 5rem;
      background-color: var(--primary-color-100);
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      transition: background-color 0.3s;
      top: 50%;
      transform: translateY(-50%);
      margin: 0;

      &:hover {
        background-color: var(--primary-color-500);
      }

      &::before {
        font-size: 2rem;
        color: #fff;
      }
    }

    .slick-prev {
      left: 13rem;
    }

    .slick-next {
      right: 13rem;
    }
  }

  .Functionality__cta {
    display: flex;
    justify-content: center;
    margin-top: 3.3rem;
  }

  .Functionality__button {
    background-color: var(--primary-color-500);
    color: #fff;
    font-size: 1.5rem;
    padding: 1.6rem 1.6rem;
    border: none;
    border-radius: 0.5rem;
    cursor: pointer;
    transition: background-color 0.3s;
    font-weight: bold;

    &:hover {
      background-color: var(--primary-color-700);
    }
  }

  @media (min-width: 992px) {
    .slick-list {
      width: 60%;
      margin: 0 auto;
    }

    .slick-prev {
      left: -2rem;
    }

    .slick-next {
      right: -2rem;
    }
  }

  @media (max-width: 991px) {
    padding-block: 0 9.2rem;
    text-align: center;

    .slick-prev, .slick-next {
      width: 3rem;
      height: 3rem;

      &::before {
        font-size: 1.5rem;
      }
    }

    .slick-prev {
      left: -1rem;
    }

    .slick-next {
      right: -1.5rem;
    }
  }

  @media (max-width: 575px) {
    padding-block-end: 2rem;
    margin-top: -5rem;

    .slick-prev, .slick-next {
      width: 2.5rem;
      height: 2.5rem;

      &::before {
        font-size: 1.2rem;
      }
    }

    .slick-prev {
      left: -1rem;
    }

    .slick-next {
      right: -1rem;
    }
  }
}
.HelpMenu {
  position: fixed;
  bottom: 2rem;
  right: 2rem;
  z-index: 999;
  transition: opacity 0.2s ease, visibility 0.2s ease;

  @media screen and (max-width: 768px) {
    bottom: 0.1rem;
    right: 1.2rem;
  }

  &--hidden {
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
  }

  &__icon {
    background: none;
    border: none;
    cursor: pointer;
    padding: 0.1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: transform 0.2s ease;
    -webkit-tap-highlight-color: transparent;
    
    @media screen and (max-width: 768px) {
      padding: 1rem;
    }
    
    svg {
      width: 3.4rem;
      height: 3.4rem;
      color: var(--grayscale-color-950);
      transition: all 0.2s ease;

      @media screen and (max-width: 768px) {
        width: 3.2rem;
        height: 3.2rem;
      }
    }

    .help-circle-icon {
      stroke-width: 0.8;
    }

    &:hover {
      transform: scale(1.1);
      
      svg {
        color: var(--primary-color-500);
      }
    }
  }

  &__dropdown {
    position: absolute;
    bottom: 100%;
    right: 0;
    margin-bottom: 1.2rem;
    background: var(--grayscale-color-50);
    border-radius: 1.2rem;
    overflow: hidden;
    min-width: 24rem;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.08);
    opacity: 0;
    visibility: hidden;
    transform: translateY(1rem);
    transition: all 0.2s ease;

    &--visible {
      opacity: 1;
      visibility: visible;
      transform: translateY(0);
    }

    @media screen and (max-width: 768px) {
      min-width: 20rem;
      right: 0;
      margin-bottom: 0.1rem;
    }
  }

  &__content {
    padding: 0.4rem;
    background: linear-gradient(to right, rgba(var(--primary-color-rgb), 0.03), rgba(var(--primary-color-rgb), 0.01));

    button {
      width: calc(100% - 0.8rem);
      margin: 0.4rem;
      padding: 1.2rem;
      text-align: left;
      background: none;
      border: none;
      border-radius: 0.8rem;
      color: var(--grayscale-color-950);
      font-size: 1.4rem;
      font-weight: 500;
      cursor: pointer;
      transition: all 0.2s ease;
      display: flex;
      align-items: center;
      gap: 1rem;
      line-height: 100%;

      svg {
        width: 1.8rem;
        height: 1.8rem;
        color: var(--grayscale-color-950);
        transition: color 0.2s ease;
      }

      &:hover {
        background-color: var(--primary-color-400);
        color: var(--grayscale-color-50);

        svg {
          color: var(--grayscale-color-50);
        }
      }

      &:not(:last-child) {
        margin-bottom: 0.4rem;
      }
    }
  }
}

:global(.authenticated) {
  .HelpMenu {
    z-index: 1001;
  }
} 
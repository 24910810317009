.CheckVerifyEmailForm {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-block-start: 2rem;

  button {
    background-color: #30844F; // Colore verde di StudierAI
    color: #fff;
    border: none;
    padding: 1rem 2rem;
    font-size: 1rem;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;

    &:disabled {
      background-color: #a9a9a9; // Grigio per pulsante disabilitato
    }

    &:hover:not(:disabled) {
      background-color: #75b477; // Colore verde più scuro per hover
    }
  }
}

.AssistanceInfo {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-margin-after: 3.2rem;
  margin-block-end: 3.2rem;

  &__title {
    -webkit-margin-before: 3.2rem;
    margin-block-start: 3.2rem;
  }
}

.PricingPage {
  padding-top: 7rem;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background: linear-gradient(180deg, var(--grayscale-color-50) 0%, rgba(116, 176, 148, 0.05) 100%);
  position: relative;
  overflow: hidden;

  &__background-wrapper {
    position: absolute;
    inset: 0;
    width: 100vw;
    height: 100vh;
    left: 0;
    top: 0;
    z-index: 0;
    pointer-events: none;
    display: none;

    @media (min-width: 1024px) {
      display: block;
    }
  }

  &__content {
    position: relative;
    z-index: 1;
    flex: 1;
    padding-top: 6rem;
  }
} 
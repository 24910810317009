.QuestionWithDocument {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  background-color: var(--grayscale-color-50);
  border-radius: 1.2rem;
  overflow: hidden;
  box-shadow: 0 0.4rem 2rem rgba(0, 0, 0, 0.15);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;
  padding: 1.2rem;
  animation: fadeIn 0.25s ease-in-out;
  
  @media (min-width: 576px) {
    padding: 1.6rem;
  }
  
  @media (min-width: 768px) {
    padding: 2.4rem;
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 1.2rem;
    border-bottom: 1px solid var(--grayscale-color-200);
    margin-bottom: 1.6rem;
    
    @media (min-width: 768px) {
      padding-bottom: 1.6rem;
      margin-bottom: 2rem;
    }

    &__back-button, &__close-button {
      display: flex;
      align-items: center;
      background: none;
      border: none;
      color: var(--grayscale-color-900);
      font-size: 1.4rem;
      cursor: pointer;
      padding: 0.6rem;
      border-radius: 0.6rem;
      transition: all 0.2s ease;

      &:hover {
        background-color: rgba(234, 88, 12, 0.1);
        color: rgb(234, 88, 12);
      }

      svg {
        font-size: 2rem;
        margin-right: 0;
        
        @media (min-width: 576px) {
          font-size: 2.2rem;
        }
      }
      
      span {
        display: none;
        
        @media (min-width: 576px) {
          display: inline;
          margin-left: 0.6rem;
        }
      }
    }

    &__close-button svg {
      margin-right: 0;
    }

    &__title {
      display: flex;
      align-items: center;
      font-size: 1.6rem;
      font-weight: 600;
      color: var(--grayscale-color-900);
      margin: 0;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 50%;
      
      @media (min-width: 576px) {
        font-size: 1.8rem;
        max-width: 60%;
      }
      
      @media (min-width: 768px) {
        font-size: 2rem;
        max-width: 70%;
      }

      svg {
        margin-right: 0.6rem;
        color: var(--grayscale-color-900);
        flex-shrink: 0;
        
        @media (min-width: 576px) {
          margin-right: 1rem;
        }
      }
    }
  }

  &__container {
    display: flex;
    flex-direction: column;
    gap: 1.6rem;
    flex: 1;
    overflow: hidden;
    
    @media (min-width: 768px) {
      gap: 2rem;
    }

    @media (min-width: 992px) {
      flex-direction: row;
      gap: 2.4rem;
    }
  }

  &__question {
    background-color: white;
    border-radius: 1rem;
    padding: 1.6rem;
    box-shadow: 0 0.2rem 0.6rem rgba(0, 0, 0, 0.08);
    flex: 1;
    overflow-y: auto;
    border: 1px solid var(--grayscale-color-200);
    transition: box-shadow 0.3s ease;
    max-height: 30vh;
    
    @media (min-width: 576px) {
      padding: 1.8rem;
    }
    
    @media (min-width: 768px) {
      padding: 2rem;
      max-height: 35vh;
    }
    
    @media (min-width: 992px) {
      max-height: none;
      flex: 0.8;
    }

    &:hover {
      box-shadow: 0 0.3rem 1rem rgba(0, 0, 0, 0.12);
    }

    h4 {
      font-size: 1.6rem;
      font-weight: 600;
      color: rgb(234 88 12);
      margin-top: 0;
      margin-bottom: 1rem;
      display: flex;
      align-items: center;
      
      @media (min-width: 576px) {
        font-size: 1.7rem;
        margin-bottom: 1.1rem;
      }
      
      @media (min-width: 768px) {
        font-size: 1.8rem;
        margin-bottom: 1.2rem;
      }

      &::before {
        content: '';
        display: inline-block;
        width: 0.6rem;
        height: 1.6rem;
        background-color: rgb(234 88 12);
        margin-right: 0.8rem;
        border-radius: 0.3rem;
        
        @media (min-width: 768px) {
          height: 1.8rem;
          margin-right: 1rem;
        }
      }
    }

    p {
      font-size: 1.4rem;
      line-height: 1.6;
      color: var(--grayscale-color-800);
      margin: 0;
      white-space: pre-wrap;
      
      @media (min-width: 576px) {
        font-size: 1.45rem;
        line-height: 1.65;
      }
      
      @media (min-width: 768px) {
        font-size: 1.5rem;
        line-height: 1.7;
      }
    }
  }

  &__answer {
    margin-top: 1.8rem;
    padding-top: 1.4rem;
    border-top: 1px dashed var(--grayscale-color-200);
    
    @media (min-width: 768px) {
      margin-top: 2.4rem;
      padding-top: 1.6rem;
    }

    h4 {
      color: var(--grayscale-color-900);

      &::before {
        background-color: var(--grayscale-color-900);
      }
    }
  }

  &__content {
    background-color: white;
    border-radius: 1rem;
    padding: 1.6rem;
    box-shadow: 0 0.2rem 0.6rem rgba(0, 0, 0, 0.08);
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    border: 1px solid var(--grayscale-color-200);
    transition: box-shadow 0.3s ease;
    
    @media (min-width: 576px) {
      padding: 1.8rem;
    }
    
    @media (min-width: 768px) {
      padding: 2rem;
    }
    
    @media (min-width: 992px) {
      flex: 1.2;
    }

    &:hover {
      box-shadow: 0 0.3rem 1rem rgba(0, 0, 0, 0.12);
    }

    h4 {
      font-size: 1.6rem;
      font-weight: 600;
      color: var(--grayscale-color-900);
      margin-top: 0;
      margin-bottom: 1.2rem;
      display: flex;
      align-items: center;
      
      @media (min-width: 576px) {
        font-size: 1.7rem;
        margin-bottom: 1.4rem;
      }
      
      @media (min-width: 768px) {
        font-size: 1.8rem;
        margin-bottom: 1.6rem;
      }

      &::before {
        content: '';
        display: inline-block;
        width: 0.6rem;
        height: 1.6rem;
        background-color: var(--grayscale-color-900);
        margin-right: 0.8rem;
        border-radius: 0.3rem;
        
        @media (min-width: 768px) {
          height: 1.8rem;
          margin-right: 1rem;
        }
      }
    }

    &__search {
      margin-bottom: 1rem;
      
      @media (min-width: 768px) {
        margin-bottom: 1.2rem;
      }
      
      .search-controls {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        gap: 0.6rem;
        background-color: var(--grayscale-color-100);
        border: 1px solid var(--grayscale-color-200);
        border-radius: 0.6rem;
        padding: 0.6rem;
        
        @media (min-width: 576px) {
          flex-wrap: nowrap;
          padding: 0.6rem 1rem;
          gap: 0.8rem;
        }
        
        input {
          flex: 1;
          min-width: 0; // Necessario per evitare overflow su flex
          border: 1px solid var(--grayscale-color-200);
          border-radius: 0.5rem;
          padding: 0.6rem 0.8rem;
          font-size: 1.3rem;
          outline: none;
          background-color: white;
          width: 100%;
          
          @media (min-width: 576px) {
            padding: 0.7rem 1rem;
            font-size: 1.4rem;
            width: auto;
          }
          
          &:focus {
            border-color: rgb(37 99 235);
            box-shadow: 0 0 0 2px rgba(37, 99, 235, 0.1);
          }
        }
        
        .search-buttons-group {
          display: flex;
          align-items: center;
          gap: 0.4rem;
          
          @media (min-width: 576px) {
            gap: 0.6rem;
          }
        }
        
        button {
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: white;
          border: 1px solid var(--grayscale-color-200);
          color: var(--grayscale-color-700);
          font-size: 1.5rem;
          width: 3.2rem;
          height: 3.2rem;
          border-radius: 0.5rem;
          cursor: pointer;
          transition: all 0.2s ease;
          padding: 0;
          flex-shrink: 0;
          
          @media (min-width: 576px) {
            font-size: 1.6rem;
            width: 3.6rem;
            height: 3.6rem;
          }
          
          &:hover {
            background-color: var(--grayscale-color-100);
            color: rgb(37 99 235);
            border-color: rgb(37 99 235);
          }
        }
        
        span {
          font-size: 1.3rem;
          color: var(--grayscale-color-700);
          font-weight: 500;
          white-space: nowrap;
          padding: 0 0.3rem;
          
          @media (min-width: 576px) {
            font-size: 1.4rem;
            padding: 0 0.5rem;
          }
        }
      }
    }

    &__text {
      overflow-y: auto;
      flex: 1;
      border: 1px solid var(--grayscale-color-200);
      border-radius: 0.6rem;
      padding: 1.4rem;
      background-color: var(--grayscale-color-50);
      
      @media (min-width: 576px) {
        padding: 1.6rem;
      }
      
      @media (min-width: 768px) {
        padding: 1.8rem;
      }

      pre {
        font-family: inherit;
        font-size: 1.4rem;
        line-height: 1.6;
        color: var(--grayscale-color-800);
        margin: 0;
        white-space: pre-wrap;
        
        @media (min-width: 576px) {
          font-size: 1.45rem;
          line-height: 1.65;
        }
        
        @media (min-width: 768px) {
          font-size: 1.5rem;
          line-height: 1.7;
        }
      }

      .highlighted-text {
        background-color: rgba(234, 88, 12, 0.15);
        border-radius: 0.2rem;
        padding: 0.1rem 0;
        
        &.current {
          background-color: rgba(234, 88, 12, 0.35);
          font-weight: 500;
          box-shadow: 0 0 0 1px rgba(234, 88, 12, 0.5);
        }
      }
    }

    &__pdf {
      display: flex;
      flex-direction: column;
      flex: 1;
      overflow: hidden;

      &-controls {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;
        gap: 0.6rem;
        padding: 0.8rem;
        background-color: var(--grayscale-color-100);
        border-radius: 0.6rem 0.6rem 0 0;
        margin-bottom: 0.5rem;
        border: 1px solid var(--grayscale-color-200);
        border-bottom: none;
        
        @media (min-width: 576px) {
          flex-wrap: nowrap;
          padding: 0.8rem 1.2rem;
        }
        
        @media (min-width: 768px) {
          padding: 1rem 1.4rem;
        }

        button {
          background-color: white;
          border: 1px solid var(--grayscale-color-200);
          color: var(--grayscale-color-700);
          font-size: 1.2rem;
          padding: 0.4rem 0.6rem;
          border-radius: 0.5rem;
          cursor: pointer;
          transition: all 0.2s ease;
          display: flex;
          align-items: center;
          gap: 0.4rem;
          flex: 1;
          justify-content: center;
          
          @media (min-width: 576px) {
            font-size: 1.25rem;
            padding: 0.45rem 0.8rem;
            flex: 0 1 auto;
          }
          
          @media (min-width: 768px) {
            font-size: 1.3rem;
            padding: 0.5rem 1rem;
            gap: 0.5rem;
          }

          svg {
            font-size: 1.5rem;
            
            @media (min-width: 768px) {
              font-size: 1.6rem;
            }
          }

          &:hover:not(:disabled) {
            background-color: var(--grayscale-color-100);
            color: rgb(37 99 235);
            border-color: rgb(37 99 235);
          }

          &:disabled {
            opacity: 0.5;
            cursor: not-allowed;
          }
          
          // Nascondi il testo su schermi molto piccoli
          span {
            display: none;
            
            @media (min-width: 400px) {
              display: inline;
            }
          }
        }

        span {
          font-size: 1.2rem;
          color: var(--grayscale-color-700);
          font-weight: 500;
          text-align: center;
          width: 100%;
          order: -1;
          
          @media (min-width: 576px) {
            font-size: 1.25rem;
            width: auto;
            order: 0;
          }
          
          @media (min-width: 768px) {
            font-size: 1.3rem;
          }
        }
      }

      .react-pdf__Document {
        flex: 1;
        overflow-y: auto;
        display: flex;
        justify-content: center;
        background-color: var(--grayscale-color-100);
        border: 1px solid var(--grayscale-color-200);
        border-radius: 0 0 0.6rem 0.6rem;
        padding: 1rem 0;
      }

      .react-pdf__Page {
        margin: 0.5rem 0;
        box-shadow: 0 0.2rem 0.8rem rgba(0, 0, 0, 0.15);
        border-radius: 0.4rem;
        overflow: hidden;
        max-width: 100%;
        
        canvas {
          max-width: 100%;
          height: auto !important;
        }
      }

      .highlight-match {
        background-color: rgba(234, 88, 12, 0.3);
        border-radius: 0.2rem;
      }
    }

    &__empty {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 200px;
      border: 1px dashed var(--grayscale-color-300);
      border-radius: 0.6rem;
      background-color: var(--grayscale-color-50);

      p {
        font-size: 1.4rem;
        color: var(--grayscale-color-500);
        
        @media (min-width: 768px) {
          font-size: 1.5rem;
        }
      }
    }
  }

  &__loading {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex: 1;
    padding: 2rem;
    
    @media (min-width: 768px) {
      padding: 3rem;
    }

    p {
      margin-top: 1.4rem;
      font-size: 1.4rem;
      color: var(--grayscale-color-500);
      
      @media (min-width: 768px) {
        margin-top: 1.6rem;
        font-size: 1.5rem;
      }
    }
  }

  &__index-indicators {
    display: flex;
    flex-wrap: wrap;
    gap: 0.6rem;
    margin-bottom: 1.2rem;
    
    @media (min-width: 576px) {
      gap: 0.7rem;
      margin-bottom: 1.4rem;
    }
    
    @media (min-width: 768px) {
      gap: 0.8rem;
      margin-bottom: 1.6rem;
    }

    .index-tag {
      background-color: rgba(234, 88, 12, 0.1);
      color: rgb(234 88 12);
      border: 1px solid rgba(234, 88, 12, 0.2);
      border-radius: 0.5rem;
      padding: 0.4rem 0.6rem;
      font-size: 1.2rem;
      cursor: pointer;
      transition: all 0.2s ease;
      display: flex;
      align-items: center;
      gap: 0.4rem;
      max-width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      
      @media (min-width: 576px) {
        padding: 0.45rem 0.8rem;
        font-size: 1.25rem;
        gap: 0.45rem;
      }
      
      @media (min-width: 768px) {
        padding: 0.5rem 1rem;
        font-size: 1.3rem;
        gap: 0.5rem;
      }

      &:hover {
        background-color: rgba(234, 88, 12, 0.15);
      }

      &.active {
        background-color: rgba(234, 88, 12, 0.2);
        border-color: rgba(234, 88, 12, 0.3);
      }

      svg {
        font-size: 1.5rem;
        flex-shrink: 0;
        
        @media (min-width: 768px) {
          font-size: 1.6rem;
        }
      }
    }
  }
}

@keyframes fadeIn {
  from { opacity: 0; transform: translateY(10px); }
  to { opacity: 1; transform: translateY(0); }
} 
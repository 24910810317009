.BetaTestimonialSlider {
  padding-block: 4rem;
  background: #ffffff;
  position: relative;
  
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 1px;
    background: linear-gradient(to right, transparent, rgba(128, 128, 128, 0.2), transparent);
    opacity: 0.8;
  }

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 1px;
    background: linear-gradient(to right, transparent, rgba(128, 128, 128, 0.2), transparent);
    opacity: 0.8;
  }

  .BetaTestimonialSlider__container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 20px;
    text-align: center;

    .BetaTestimonialSlider__title {
      font-size: 3.5rem;
      font-weight: 700;
      margin-bottom: 2rem;
      text-align: center;
      color: var(--text-color);
    }
  }

  .slick-prev, .slick-next {
    z-index: 1;
    width: 3rem;
    height: 3rem;
    background-color: var(--primary-color-500);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: background-color 0.3s;
    top: 50%;
    transform: translateY(-50%);
    margin: 0;
    position: absolute;

    &:hover {
      background-color: var(--primary-color-700);
    }

    &::before {
      font-size: 1.5rem;
      color: #fff;
    }
  }

  .slick-prev {
    left: -6rem;
  }

  .slick-next {
    right: -6rem;
  }

  @media (max-width: 768px) {
    padding-block: 3rem;
    
    .slick-prev, .slick-next {
      width: 2.5rem;
      height: 2.5rem;
      &::before {
        font-size: 1.2rem;
      }
    }

    .slick-prev {
      left: -1rem;
    }

    .slick-next {
      right: -1rem;
    }

    .BetaTestimonialSlider__container {
      .BetaTestimonialSlider__title {
        font-size: 2.8rem;
        margin-bottom: 2rem;
      }
    }
  }

  @media (max-width: 1149px) and (min-width: 667px) {
    .slick-prev, .slick-next {
      display: none;
    }
  }
}

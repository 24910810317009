.speaker-animation {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem;

  @media (max-height: 720px) {
    padding: 0;
  }

  &.__answer-complete {
    padding: 0;
    margin: 0;
  }
}

.toolbar {
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 1rem;

  @media(min-width: 740px) and (max-height: 1030px) {
    padding: 0;
  }
}

.animation-area {
  position: relative;
  width: 100px; 
  height: 100px; 
} 

.animation-wrapper {
  position: relative;
  width: 100%;
  height: 100%;

  .highlight {
    width: 75%;  
    height: 75%;
    top: 10%;
    left: 10%;
    padding: 5%;
  }
}

.center-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 60%;  
  height: 60%; 
}

.icon-bg,
.image-shadow,
.image-rotate {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.icon-bg {
  z-index: 1;
}

.image-shadow {
  opacity: 0.15;
  z-index: 2;
}

.image-rotate {
  z-index: 3;
  transition: all 0.2s ease-in-out;
}

.image-rotate:hover {
  transform: scale(1.05);
}

.blue-middle,
.green-right,
.green-left {
  filter: blur(15px);  
}

.bottom-pink {
  opacity: 0.25;
}

.blue-right,
.blue-middle,
.pink-left,
.green-right,
.green-left {
  width: 45%;  
  height: 45%;
  top: 30%;
  left: 30%;
}

.pink-top,
.intersect,
.bottom-pink {
  width: 60%;  
  height: 55%;
  top: 20%;
  left: 20%;
}

/* .highlight {
  width: 75%;  
  height: 75%;
  top: 10%;
  left: 10%;
  padding: 5%;
} */

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(-360deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes pulse {
  0% {
      transform: scale(.940);
  }

  50% {
      transform: scale(1);
  }

  100% {
      transform: scale(.940);
  }
}

.rotate-animation {
  animation: rotate 3s infinite linear;
}

.pulse-animation {
  animation: pulse 1s infinite linear;
}
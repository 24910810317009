.flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.justify-center {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.justify-around {
  -ms-flex-pack: distribute;
  justify-content: space-around;
}

.justify-between {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.justify-between-lg {
  @media (min-width: 991px) {
    justify-content: space-between;
  }
}

.items-center {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

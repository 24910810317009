.Banner {
  background-color: var(--grayscale-color-100); // Colore di sfondo correlato a StudierAI
  border-radius: 14px; // Bordi arrotondati
  padding: 10px 20px; // Spaziatura interna
  text-align: center; // Testo centrato
  margin-bottom: 20px; // Margine inferiore
  font-weight: bold; // Testo in grassetto
  font-size: 14px; // Dimensione del testo
  max-width: 320px; // Ridotta larghezza massima per ridurre la lunghezza del banner
  margin: 0 auto 20px auto;

  p {
    margin: 0;
    color: #333333; // Colore del testo correlato a StudierAI
  }

  @media (max-width: 991px) {
    max-width: 500px; // Riduzione della larghezza massima su dispositivi più piccoli
  }

  @media (max-width: 575px) {
    font-size: 11px; // Riduci la dimensione del testo su dispositivi mobili
    padding: 7px 14px; // Riduci la spaziatura interna su dispositivi mobili
    max-width: 350px; // Riduzione ulteriore della larghezza massima su dispositivi mobili
  }

  @media (max-width: 400px) {
    padding: 5px 9px; // Ulteriore riduzione della spaziatura interna su dispositivi molto piccoli
    max-width: 300px; // Riduzione ulteriore della larghezza massima su dispositivi molto piccoli
  }
}

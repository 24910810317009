.AudioSummary {
  width: 100%;
  
  &__box {
    padding: 2rem;
    border: 1px solid rgb(218, 218, 218);
    border-radius: 1.2rem;
    background-color: var(--background-color);
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.05);
    transition: all 0.3s ease;
    overflow-y: auto;
    max-height: calc(50vh - 4rem);

    &:hover {
      box-shadow: 0 6px 12px -2px rgba(0, 0, 0, 0.1);
      border-color: rgb(37 99 235);
    }

    &--free {
      border-color: rgb(37 99 235 / 60%);
      background: linear-gradient(135deg, rgb(240 249 255 / 35%) 0%, rgb(240 249 255 / 65%) 100%);
      
      &:hover {
        border-color: rgb(37 99 235 / 80%);
      }
    }

    @media (max-width: 768px) {
      padding: 1.6rem;
      max-height: none;
      overflow-y: visible;
    }
    
    /* Fix per iPad */
    @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
      -webkit-transform: translateZ(0);
      transform: translateZ(0);
      backface-visibility: hidden;
      overflow-y: visible;
    }
  }

  &__title {
    font-family: roboto;
    font-size: 1.8rem;
    font-weight: 600;
    color: var(--grayscale-color-950);
    margin-bottom: 1.2rem;
    display: flex;
    align-items: center;
    gap: 0.8rem;
  }

  &__beta-tag {
    font-size: 1.2rem;
    font-weight: 600;
    color: #ffffff;
    margin-left: 0.8rem;
    background: linear-gradient(135deg, #2563eb 0%, #2563eb 100%);
    padding: 0.3rem 0.7rem;
    border-radius: 0.8rem;
    position: relative;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
    letter-spacing: 0.05rem;
    box-shadow: 0 2px 4px rgba(37, 99, 235, 0.3);
    overflow: hidden;
    transition: all 0.3s ease;
    transform: translateY(-1px);
    
    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: -100%;
      width: 100%;
      height: 100%;
      background: linear-gradient(
        90deg,
        rgba(255, 255, 255, 0) 0%,
        rgba(255, 255, 255, 0.2) 50%,
        rgba(255, 255, 255, 0) 100%
      );
      animation: shine 3s infinite;
    }
    
    @keyframes shine {
      0% {
        left: -100%;
      }
      20% {
        left: 100%;
      }
      100% {
        left: 100%;
      }
    }
    
    &:hover {
      transform: translateY(-2px);
      box-shadow: 0 4px 8px rgba(59, 130, 246, 0.4);
    }
    
    &-icon {
      font-size: 1rem;
      margin-right: 0.4rem;
      animation: wobble 5s infinite;
    }
    
    @keyframes wobble {
      0%, 100% {
        transform: rotate(0deg);
      }
      25% {
        transform: rotate(-5deg) scale(1.1);
      }
      75% {
        transform: rotate(5deg) scale(1.1);
      }
    }
  }

  &__description {
    font-family: roboto;
    font-size: 1.4rem;
    color: var(--grayscale-color-700);
    margin-bottom: 1.6rem;
    line-height: 1.5;
  }

  &__saved-info {
    display: inline-block;
    margin-top: 0.5rem;
    font-weight: 500;
    color: rgb(37 99 235);
    font-style: italic;
  }

  &__local-info {
    display: inline-block;
    margin-top: 0.5rem;
    font-weight: 500;
    color: rgb(234 88 12);
    font-style: italic;
  }

  &__partial-info {
    display: inline-block;
    margin-top: 0.5rem;
    font-weight: 500;
    color: rgb(217 119 6);
    font-style: italic;
  }

  &__storage-info {
    display: inline-block;
    margin-top: 0.5rem;
    font-weight: 500;
    color: rgb(37 99 235);
    font-style: italic;
  }

  &__error-info {
    color: #e74c3c;
    font-style: italic;
  }
  
  &__error-message {
    background-color: rgba(231, 76, 60, 0.1);
    border-left: 3px solid #e74c3c;
    padding: 10px 15px;
    margin: 10px 0 20px 0;
    border-radius: 4px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.05);
    
    p {
      margin: 5px 0;
      font-size: 1rem;
      line-height: 1.5;
    }
    
    strong {
      font-weight: 600;
      color: #c0392b;
    }
  }
  
  &__error-tip {
    color: #2980b9;
    font-style: italic;
    margin-top: 8px !important;
    font-weight: 500;
    
    &::before {
      content: "💡 ";
    }
  }

  &__info-message {
    background-color: rgba(52, 152, 219, 0.1);
    border-left: 3px solid #3498db;
    padding: 10px 15px;
    margin: 10px 0 20px 0;
    border-radius: 4px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.05);
    
    p {
      margin: 5px 0;
      font-size: 1rem;
      line-height: 1.5;
    }
    
    strong {
      font-weight: 600;
      color: #2980b9;
    }
  }
  
  &__info-tip {
    color: #27ae60;
    font-style: italic;
    margin-top: 8px !important;
    font-weight: 500;
    
    &::before {
      content: "💡 ";
    }
  }

  &__buttons {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  &__button {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.8rem;
    position: relative;
    overflow: hidden;
    
    svg {
      font-size: 1.8rem;
    }

    &.Btn--primary {
      background-color: rgb(37 99 235) !important;
      color: white !important;
      border: none !important;
      
      &::before {
        content: '';
        position: absolute;
        top: 0;
        left: -100%;
        width: 100%;
        height: 100%;
        background: linear-gradient(
          90deg,
          rgba(255, 255, 255, 0) 0%,
          rgba(255, 255, 255, 0.2) 50%,
          rgba(255, 255, 255, 0) 100%
        );
        animation: button-shine 6s infinite;
      }
      
      @keyframes button-shine {
        0% {
          left: -100%;
        }
        15% {
          left: 100%;
        }
        100% {
          left: 100%;
        }
      }
      
      &:hover:not(:disabled) {
        background-color: rgb(29 78 216) !important;
        box-shadow: 0 10px 15px -3px rgba(37, 99, 235, 0.3), 0 4px 6px -2px rgba(37, 99, 235, 0.1) !important;
        transform: translateY(-2px) !important;
      }
      
      &:active:not(:disabled) {
        transform: translateY(0) !important;
        box-shadow: 0 4px 6px -1px rgba(37, 99, 235, 0.2) !important;
      }
    }

    &--secondary {
      border-color: rgb(37 99 235);
      color: rgb(37 99 235);
      
      &:hover:not(:disabled) {
        background-color: rgba(37, 99, 235, 0.05);
      }
    }

    &--download {
      background-color: #27ae60 !important;
      color: white !important;
      border: none !important;
      
      &:hover:not(:disabled) {
        background-color: #219653 !important;
        box-shadow: 0 10px 15px -3px rgba(39, 174, 96, 0.3), 0 4px 6px -2px rgba(39, 174, 96, 0.1) !important;
      }
      
      &:active:not(:disabled) {
        box-shadow: 0 4px 6px -1px rgba(39, 174, 96, 0.2) !important;
      }
    }
  }

  &__premium-feature {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.8rem;
    margin-top: 0.8rem;
    padding: 0.8rem;
    background-color: var(--grayscale-color-50);
    border-radius: 0.8rem;
    border: 1px dashed var(--grayscale-color-300);
    color: var(--grayscale-color-600);
    font-size: 1.3rem;
    font-weight: 500;
    
    svg {
      color: var(--grayscale-color-400);
      font-size: 1.6rem;
    }
  }
  
  &__premium-banner {
    display: flex;
    align-items: center;
    margin-top: 1rem;
    background: linear-gradient(135deg, rgb(240 249 255 / 50%) 0%, rgb(240 249 255 / 80%) 100%);
    border: 1px solid rgb(37 99 235 / 60%);
    border-radius: 1.2rem;
    /* Miglioramento per compatibilità iPad */
    backdrop-filter: blur(8px);
    -webkit-backdrop-filter: blur(8px);
    transition: all 0.3s ease;
    overflow: hidden;
    
    /* Fix per iPad */
    @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
      backdrop-filter: none;
      -webkit-backdrop-filter: none;
      background: rgb(240 249 255 / 95%);
      -webkit-transform: translateZ(0);
      transform: translateZ(0);
    }

    &:hover {
      border-color: rgb(37 99 235 / 80%);
      box-shadow: 0 8px 24px -4px rgba(37, 99, 235, 0.1);
      transform: translateY(-1px);
    }

    &-content {
      flex: 1;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 1.6rem;
      padding: 1.6rem;
    }

    &-header {
      display: flex;
      align-items: center;
      gap: 1rem;
      margin: 0;

      h6 {
        font-family: roboto;
        font-size: 1.6rem;
        font-weight: 600;
        color: rgb(37 99 235);
        margin: 0;
      }
    }

    &-icon {
      font-size: 2rem;
      color: rgb(37 99 235);
    }

    p {
      font-family: roboto;
      font-size: 1.4rem;
      line-height: 1.5;
      color: rgb(30 58 138);
      margin: 0;
    }

    &-button {
      display: inline-flex;
      align-items: center;
      gap: 0.8rem;
      padding: 1rem 2rem;
      background-color: rgb(37 99 235);
      border: none;
      border-radius: 0.8rem;
      cursor: pointer;
      transition: all 0.3s ease;
      white-space: nowrap;
      position: relative;
      overflow: hidden;
      width: 100%;
      justify-content: center;

      &::before {
        content: '';
        position: absolute;
        top: 0;
        left: -100%;
        width: 100%;
        height: 100%;
        background: linear-gradient(
          90deg,
          rgba(255, 255, 255, 0) 0%,
          rgba(255, 255, 255, 0.2) 50%,
          rgba(255, 255, 255, 0) 100%
        );
        animation: premium-button-shine 6s infinite;
      }
      
      @keyframes premium-button-shine {
        0% {
          left: -100%;
        }
        15% {
          left: 100%;
        }
        100% {
          left: 100%;
        }
      }

      &:hover {
        background-color: rgb(29 78 216);
        transform: translateY(-1px);
        box-shadow: 0 6px 10px -1px rgba(37, 99, 235, 0.3), 0 4px 6px -2px rgba(37, 99, 235, 0.1);
      }

      &:active {
        transform: translateY(0);
        box-shadow: 0 4px 6px -1px rgba(37, 99, 235, 0.2);
      }
      
      span {
        font-family: roboto;
        font-size: 1.4rem;
        font-weight: 500;
        color: white;
      }

      &-icon {
        color: white;
        font-size: 1.8rem;
        transition: transform 0.2s ease;
      }

      &:hover &-icon {
        transform: translateX(0.4rem);
      }
    }
  }

  &__player {
    display: flex;
    flex-direction: column;
    gap: 1.6rem;
    margin: 1rem 0;
  }
  
  &__player-actions {
    display: flex;
    gap: 1rem;
    flex-wrap: wrap;
    
    @media (max-width: 576px) {
      flex-direction: column;
    }
    
    .AudioSummary__button {
      flex: 1;
      min-width: 140px;
      
      @media (max-width: 576px) {
        width: 100%;
        flex: auto;
      }
    }
  }
  
  &__error {
    padding: 15px;
    border-radius: 8px;
    background-color: rgba(255, 0, 0, 0.05);
    border: 1px solid rgba(255, 0, 0, 0.2);
    margin-top: 15px;
    
    p {
      margin-bottom: 10px;
    }
    
    ul {
      margin-bottom: 15px;
      padding-left: 20px;
    }
    
    &-actions {
      display: flex;
      justify-content: space-between;
      gap: 10px;
      margin-top: 15px;
      
      @media (max-width: 480px) {
        flex-direction: column;
      }
    }
  }
  
  &__loading {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    padding: 2rem 0;
    
    p {
      font-size: 1.4rem;
      color: var(--grayscale-color-600);
      margin: 0;
    }
  }
  
  &__status-info {
    margin-top: 0.5rem;
    padding: 1rem;
    border-radius: 0.8rem;
    border: 1px solid var(--grayscale-color-200);
    background-color: var(--grayscale-color-50);
    font-size: 1.3rem;
    color: var(--grayscale-color-800);
    
    .dark-mode & {
      border-color: var(--grayscale-color-700);
      background-color: var(--grayscale-color-850);
      color: var(--grayscale-color-300);
    }
  }
  
  &__loaded-info {
    display: inline-block;
    font-weight: 500;
    color: rgb(16, 185, 129);
    font-style: italic;
  }
} 
.AccordionItem {
  border-bottom: 1px solid #e0e0e0;
  transition: all 0.3s ease;

  &__question {
    width: 100%;
    padding: 2rem;
    font-size: 1.8rem;
    text-align: left;
    font-weight: 600;
    color: #333;
    background: none;
    border: none;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    transition: all 0.3s ease;

    &:hover {
      background-color: #f0f0f0;
    }

    .question-title {
      display: flex;
      align-items: center;
      gap: 1.2rem;
      
      .question-icon {
        font-size: 2rem;
        color: var(--primary-color);
        transition: transform 0.3s ease;
      }
    }

    &:hover .question-icon {
      transform: scale(1.1);
    }
  }

  &__icon {
    font-size: 1.8rem;
    color: #333;
    margin-left: 1.2rem;
    flex-shrink: 0;
  }

  &__answer {
    padding: 0 2rem 2rem;
    font-size: 1.6rem;
    line-height: 1.6;
    color: #333;
  }

  &.is-open {
    background-color: #f0f0f0;
  }

  &:last-child {
    border-bottom: none;
  }

  @media (max-width: 575px) {
    &__question {
      padding: 1.5rem;
      font-size: 1.6rem;

      .question-title {
        gap: 1rem;
        
        .question-icon {
          font-size: 1.8rem;
        }
      }
    }

    &__answer {
      padding: 0 1.5rem 1.5rem;
      font-size: 1.4rem;
    }
  }
}
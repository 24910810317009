// IntroJS style

.introjs-tooltip {
  font-size: 2.4rem;
  font-weight: 500;
  color: white;
  background-color: rgb(76, 146, 116) !important;
  
  .introjs-skipbutton {
    color: white;
  }
}


// Component style

.UploadNotesForm {
  background-color: rgba($color: var(--grayscale-color100-rgb), $alpha: 0.25);
  border: 2px dashed #ccc;
  border-radius: 15px;
  padding: 40px;
  text-align: center;
  position: relative;
  
  &__free-plan {
    padding: 19px;
  }

  &__dragging {
    border-color: #34d399;
  }

  &__dropzone-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(52, 211, 153, 0.2);
    border-radius: 15px;
  }

  &--active-free {
    border: 2px dashed var(--primary-color-200);
    padding-bottom: 9rem;
    border-color: #34d399;
    background-color: #c4efdb;

    & > .UploadNotesForm__inner > * {
      color: var(--primary-color-400);
    }
  }

  &--active {
    border: 2px dashed var(--primary-color-200);
    padding-bottom: 11.1rem;
    border-color: #34d399;
    background-color: #c4efdb;

    & > .UploadNotesForm__inner > * {
      color: var(--primary-color-400);
    }
  }

  &--disabled {
    pointer-events: none;
    opacity: 0.5;
  }

  &__inner {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }

  &__icon {
    width: 4.8rem;
    height: 4.8rem;
  }

  &__icon-wrapper {
    background-color: rgb(233, 238, 237);
    padding: 1.5rem;
    border-radius: 100%;
    margin-bottom: 2rem;
  }

  &__icon {
    color: var(--grayscale-color-900);
  }

  &__title {
    text-align: center;
  }

  &__text {
    -webkit-margin-before: 0.8rem;
    margin-block-start: 0.8rem;
    text-align: center;

    -webkit-margin-before: 0.8rem;
    margin-top:1rem;
    text-align: center;
    color: rgb(101, 101, 101);
    max-width: 430px;
  }

  &__lock-icon{
    color: rgb(101, 101, 101);
    margin-top: 2rem;
  }
  
  &__field {
    position: relative;
    -webkit-margin-before: 2.4rem;
    margin-block-start: 2.4rem;
    isolation: isolate;
    background: linear-gradient(135deg, var(--primary-color-400) 0%, var(--primary-color-500) 100%);
    border: none;
    border-radius: 1.6rem;
    padding: 0.8rem 3.2rem;
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
    transition: transform 0.3s ease, box-shadow 0.3s ease, background 0.3s ease;
    overflow: hidden;
    
    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: -100%;
      width: 100%;
      height: 100%;
      background: linear-gradient(
        90deg,
        rgba(255, 255, 255, 0) 0%,
        rgba(255, 255, 255, 0.2) 50%,
        rgba(255, 255, 255, 0) 100%
      );
      animation: nav-shine 6s infinite;
    }
    
    @keyframes nav-shine {
      0% {
        left: -100%;
      }
      15% {
        left: 100%;
      }
      100% {
        left: 100%;
      }
    }
    
    &:hover {
      transform: translateY(-2px);
      box-shadow: 0 4px 8px -2px rgba(0, 0, 0, 0.1);
      background: linear-gradient(135deg, var(--primary-color-500) 0%, var(--primary-color-600) 100%);
    }

    &-label {
      margin-block: 0;
      z-index: 1;
    }

    &-label-wrapper {
      display: flex;
      column-gap: .5rem;
    }    

    &-label-icon {
      color: white;
    }    

    &-label {
      margin-block: 0;
      z-index: 1;
      color: white;
      font-weight: 500;
      padding-block: .4rem;
      font-size: 1.4rem;
    }

    &-input {
      position: absolute;
      inset-block-start: -0.9px;
      inset-inline-start: 0;
      width: 100%;
      padding-block: 1rem;
      opacity: 0;
      z-index: 99;
      /* FF, IE7+, chrome (except button) */
      /* chromes and blink button */
      &,
      &::-webkit-file-upload-button {
        cursor: pointer;
      }
    }
  }

  &__field-second-form {
    opacity: 0;
    position: absolute;
  }

  &__input-second-form {
    position: absolute;
    inset-block-start: -25px;
    inset-inline: end;
    opacity: 0;
    height: 19px;
    width: 165px;
    padding-top: 0;
  }

  &__info {
    font-size: 1.4rem;
  }

  &__textarea-container {
    width: 100%;
    margin-top: 2rem;
    position: relative;

    .UploadNotesForm__error {
      color: var(--grayscale-color-900);
      font-size: 1.4rem;
      margin-bottom: 1rem;
      line-height: 1.6rem;
    }

    .UploadNotesForm__error-title {
      color: red;
      font-weight: bold;
    }

    .UploadNotesForm__textarea {
      width: 100%;
      padding: 1rem;
      border: 1px solid var(--grayscale-color-200);
      border-radius: 0.8rem;
      resize: vertical;
    }

    .UploadNotesForm__save-button {
      margin-top: 1rem;
      padding: 0.8rem 1.6rem;
      background-color: var(--primary-color-400);
      color: var(--grayscale-color-100);
      border: none;
      border-radius: 0.8rem;
      cursor: pointer;
      font-size: 1.4rem;
      width: 100%;

      &:hover {
        background-color: var(--primary-color-500);
      }

      &:active {
        background-color: var(--primary-color-600);
      }
    }

    .UploadNotesForm__close-button {
      background: none;
      border: none;
      position: absolute;
      top: 10px;
      right: 10px;
      cursor: pointer;
      width: 24px;
      height: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: var(--grayscale-color-100);
      border-radius: 50%;
    }

    .UploadNotesForm__close-icon {
      font-size: 16px;
      color: var(--grayscale-color-900);
    }
  }

  &__form-footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 2rem;

  }

  &__form-footer-btn {
    background: linear-gradient(135deg, var(--primary-color-400) 0%, var(--primary-color-500) 100%);
    color: white;
    padding: 1rem;
    border-radius: 1rem;
    color: #fff;
    padding: 13px 20px;
    border: none;
    font-weight: bold;
    cursor: pointer;
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
    transition: transform 0.3s ease, box-shadow 0.3s ease, background 0.3s ease;
    position: relative;
    overflow: hidden;
    
    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: -100%;
      width: 100%;
      height: 100%;
      background: linear-gradient(
        90deg,
        rgba(255, 255, 255, 0) 0%,
        rgba(255, 255, 255, 0.2) 50%,
        rgba(255, 255, 255, 0) 100%
      );
      animation: nav-shine 6s infinite;
    }

    &:hover {
      transform: translateY(-2px);
      box-shadow: 0 4px 8px -2px rgba(0, 0, 0, 0.1);
      background: linear-gradient(135deg, var(--primary-color-500) 0%, var(--primary-color-600) 100%);
    }
    
    &:active {
      transform: translateY(0);
    }
  }

  &__generate-container {
    display: flex;
    justify-content: end;
    width: 100%;
    margin-top: 1.5rem;
  }

  
  &__info-container-first-form {
    margin-top: 2rem;
  }

  &__alert-wrapper {
    display: flex;
    column-gap: .6rem;
    padding-block: .5rem;
  }

  &__alert-icon {
    color: green;
  }
}

.second-form {
  padding-block: 1.7rem;
  margin-top: 1.3rem;
  border: 1px solid #dfdfdf;
  position: relative;
  background-color: #a6adbb26;
  border-radius: 1.2rem;
  cursor: pointer;
  transition: transform 0.2s ease, background-color 0.2s ease, border-color 0.2s ease, background-image 0.2s ease;
  
  &:hover {
    transform: translateY(-2px);
    border-color: #34d399;
    background-image: linear-gradient(135deg, rgba(var(--primary-color-400-rgb), 0.15) 0%, rgba(var(--primary-color-500-rgb), 0.15) 100%);
    background-color: rgba(var(--primary-color-400-rgb), 0.05);
  }
  
  &:active {
    transform: translateY(0);
  }

  &__wrapper {
    display: flex;
    align-items: center;
    column-gap: 1rem;
  }
  
  &__text {
    font-size: 1.5rem;
    font-family: roboto;
    font-weight: 500;
  }
  
  &__more-files-icon {
    font-weight: 500;
    color: var(--grayscale-color-900);
  }
}
.UpgradePlan {
  display: block;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
  padding: 1.2rem 1.6rem;
  border-radius: 3.2rem;
  background: linear-gradient(135deg, var(--primary-color-400) 0%, var(--primary-color-500) 100%);
  cursor: pointer;
  transition: all 0.3s ease;
  position: relative;
  overflow: hidden;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: -100%;
    width: 100%;
    height: 100%;
    background: linear-gradient(
      90deg,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 0.2) 50%,
      rgba(255, 255, 255, 0) 100%
    );
    animation: button-shine 6s infinite;
  }
  
  @keyframes button-shine {
    0% {
      left: -100%;
    }
    15% {
      left: 100%;
    }
    100% {
      left: 100%;
    }
  }

  &:hover {
    background: linear-gradient(135deg, var(--primary-color-500) 0%, var(--primary-color-600) 100%);
    transform: scale(1.05);
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
  }
  
  &:active {
    transform: scale(1);
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1);
  }

  &__text {
    text-align: center;
    color: var(--grayscale-color-50);
    font-weight: 400;
    font-size: 1.6rem;
    font-family: var(--font-family);
  }
  
  &__icon {
    color: var(--grayscale-color-50);
    transition: all 0.3s ease;
  }

  @media (max-width: 1000px) {
    background: linear-gradient(135deg, var(--primary-color-400) 0%, var(--primary-color-500) 100%);
  }

  @media (max-width: 575px) {
    background: linear-gradient(135deg, var(--primary-color-400) 0%, var(--primary-color-500) 100%);
  }
}

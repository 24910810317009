.MultipleChoice {
    &__summary {
      display: flex;
      padding: 1.2rem 1.6rem;
      background-color: var(--background-color);
      border-radius: 0.8rem;
      border: 1px solid #cccccc;
      margin-bottom: 2rem;
      width: 100%;
      transition: all 0.2s ease;

      @media (max-width: 1110px) {
        padding: 1.2rem;
      }

      &:hover {
        border-color: var(--grayscale-color-200);
        box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.06);
        transform: translateY(-1px);
      }

      &--locked {
        background-color: var(--background-color);
        border: 1px solid #cccccc;
        box-shadow: none;
        backdrop-filter: none;
        transition: all 0.2s ease;

        &:hover {
          border-color: var(--grayscale-color-200);
          box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.06);
          transform: translateY(-1px);
        }

        .MultipleChoice__summary-total {
          color: rgb(51 65 85);
          font-size: 1.5rem;

          @media (max-width: 768px) {
            font-size: 1.4rem;
          }
        }

        .MultipleChoice__summary-icon {
          color: rgb(147 51 234);
        }
      }

      &-content {
        display: flex;
        align-items: center;
        gap: 1.2rem;
        width: 100%;

        @media (max-width: 768px) {
          gap: 1rem;
        }
      }

      &-icon {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 3.2rem;
        height: 3.2rem;
        border-radius: 0.8rem;
        color: rgb(147 51 234);
        flex-shrink: 0;

        @media (max-width: 1110px) {
          width: 2.8rem;
          height: 2.8rem;
        }

        svg {
          width: 1.8rem;
          height: 1.8rem;
          
          @media (max-width: 1110px) {
            width: 1.6rem;
            height: 1.6rem;
          }
        }
      }

      &-info {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 2rem;
        flex: 1;
        width: 100%;

        @media (max-width: 768px) {
          flex-direction: column;
          align-items: flex-start;
          gap: 0.75rem;
        }

        @media (min-width: 769px) and (max-width: 1024px) {
          gap: 1.6rem;
        }
      }

      &-total {
        font-size: 1.5rem;
        font-weight: 600;
        color: var(--grayscale-color-900);
        white-space: nowrap;
        display: flex;
        align-items: center;
        gap: 1.6rem;

        @media (max-width: 768px) {
          font-size: 1.125rem;
          font-weight: 500;
          color: rgb(17 24 39);
          gap: 0.5rem;
        }
      }

      &-badges {
        display: flex;
        align-items: center;
        gap: 0.8rem;
        flex-wrap: wrap;
        justify-content: flex-end;
        flex: 1;

        @media (max-width: 768px) {
          justify-content: flex-start;
          width: 100%;
          gap: 0.5rem;
          margin-top: 0;
        }

        @media (min-width: 769px) and (max-width: 1024px) {
          justify-content: flex-start;
        }
      }

      &-badge {
        font-size: 1.2rem;
        padding: 0.4rem 1.2rem;
        border-radius: 0.4rem;
        font-weight: 500;
        white-space: nowrap;
        display: inline-block;
        text-align: center;
        min-width: 5rem;

        @media (max-width: 768px) {
          font-size: 1rem;
          padding: 0.25rem 0.75rem;
          min-width: auto;
          border-radius: 0.4rem;
          font-weight: 400;
        }

        &--easy {
          background: rgba(34, 197, 94, 0.1);
          color: #15803d;
          border: 1.5px solid rgb(34 197 94);

          @media (max-width: 768px) {
            background: rgb(220 252 231);
            color: rgb(22 101 52);
            border: 1.5px solid rgb(34 197 94);
          }
        }

        &--medium {
          background: rgba(234, 179, 8, 0.1);
          color: #a16207;
          border: 1.5px solid rgb(234 179 8);

          @media (max-width: 768px) {
            background: rgb(254 243 199);
            color: rgb(146 64 14);
            border: 1.5px solid rgb(234 179 8);
          }
        }

        &--hard {
          background: rgba(239, 68, 68, 0.1);
          color: #b91c1c;
          border: 1.5px solid rgb(239 68 68);

          @media (max-width: 768px) {
            background: rgb(254 226 226);
            color: rgb(153 27 27);
            border: 1.5px solid rgb(239 68 68);
          }
        }
      }
    }
  
    &__container {
      background-color: var(--background-color);
      border: 1px solid #cccccc;
      border-radius: 0.8rem;
      padding: 2rem;
      display: flex;
      flex-direction: column;
      gap: 2rem;
      margin-bottom: 1.6rem;
      transition: all 0.2s ease;

      &:hover {
        border-color: var(--grayscale-color-200);
        box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.06);
        transform: translateY(-1px);
      }

      &--locked {
        background: linear-gradient(135deg, rgb(245 243 255 / 50%) 0%, rgb(245 243 255 / 80%) 100%);
        border: 1px solid rgb(147 51 234 / 60%);
        cursor: not-allowed;
        box-shadow: 0 0.4rem 0.8rem rgba(147, 51, 234, 0.08);
        backdrop-filter: blur(8px);
        transition: all 0.3s ease;
        padding: 1.2rem;

        &:hover {
          border-color: rgb(147 51 234 / 80%);
          box-shadow: 0 8px 24px -4px rgba(147, 51, 234, 0.1);
          transform: translateY(-1px);
        }

        .MultipleChoice__text {
          opacity: 0.8;
          font-size: 1.3rem;
          color: rgb(88 28 135);
        }

        .MultipleChoice__number {
          color: rgb(88 28 135);
        }

        .MultipleChoice__difficulty {
          opacity: 0.8;
        }
      }
    }
  
    &__header {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      gap: 2rem;
    }
  
    &__question {
      display: flex;
      align-items: flex-start;
      gap: 0.6rem;
      flex: 1;
    }
  
    &__number {
      font-size: 1.4rem;
      font-weight: 800;
      color: #7c3aed;
      margin-right: 0.4rem;
    }
  
    &__text {
      font-size: 1.4rem;
      font-weight: 800;
      color: var(--grayscale-color-900);
      margin: 0;
      line-height: 1.5;
      display: inline;
    }
  
    &__difficulty {
      font-size: 1.1rem;
      padding: 0.5rem 1.2rem;
      border-radius: 2rem;
      font-weight: 500;
      display: flex;
      align-items: center;
      white-space: nowrap;
      text-transform: capitalize;
  
      &--easy {
        background: rgba(34, 197, 94, 0.1);
        color: #15803d;
      }
  
      &--medium {
        background: rgba(234, 179, 8, 0.1);
        color: #a16207;
      }
  
      &--hard {
        background: rgba(239, 68, 68, 0.1);
        color: #b91c1c;
      }
    }
  
    &__options {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      padding: 0 1rem;
    }
  
    &__option {
      padding: 1.4rem 1.8rem;
      border-radius: 0.8rem;
      font-size: 1.5rem;
      line-height: 1.4;
      color: var(--grayscale-color-700);
      transition: all 0.2s ease;
      cursor: pointer;
      background: var(--background-color);
      border: 1px solid #cccccc;
      box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
  
      &:hover {
        background: var(--background-color-hover);
        border-color: var(--grayscale-color-300);
        transform: translateY(-1px);
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
      }
  
      &--correct {
        background: rgba(34, 197, 94, 0.1);
        color: #15803d;
        border: 1px solid rgba(34, 197, 94, 0.4);
  
        &:hover {
          background: rgba(34, 197, 94, 0.15);
          border-color: rgba(34, 197, 94, 0.5);
          transform: translateY(-1px);
        }
      }
    }
  
    &__explanation {
      padding: 1.6rem;
      background: rgba(147, 51, 234, 0.05);
      border-radius: 0.8rem;
      margin: 0 1rem;
      border: 1px solid rgba(147, 51, 234, 0.1);

      &-header {
        display: flex;
        gap: 1rem;
        align-items: center;
      }

      &-icon {
        color: rgb(147, 51, 234);
        width: 1.4rem;
        height: 1.4rem;
        flex-shrink: 0;
      }

      &-content {
        flex: 1;
      }
  
      &-title {
        font-size: 1.5rem;
        font-weight: 600;
        color: rgb(147, 51, 234);
        margin-bottom: 0.8rem;
        display: flex;
        align-items: center;
      }
  
      &-text {
        font-size: 1.5rem;
        color: var(--grayscale-color-600);
        line-height: 1.5;
        margin: 0;
      }
    }

    &__preview {
      position: relative;
      height: 100%;
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 0.8rem;
    }

    &__preview-text {
      font-family: roboto;
      font-size: 1.3rem;
      color: rgb(88 28 135);
      margin: 0;
      line-height: 1.4;
      font-style: italic;
      padding: 0.6rem;
      background-color: transparent;
      border-radius: 0.8rem;
      border: none;
    }

    &__lock-message {
      display: flex;
      align-items: center;
      gap: 0.8rem;
      padding: 0.8rem 1.2rem;
      background-color: rgb(245 243 255 / 90%);
      border: 1px dashed rgb(147 51 234 / 60%);
      border-radius: 0.8rem;
      transition: all 0.3s ease;
      z-index: 1;
      margin-top: 0.4rem;

      span {
        font-family: roboto;
        font-size: 1.3rem;
        color: rgb(147 51 234);
        font-style: italic;
      }

      .MultipleChoice__lock-icon {
        color: rgb(147 51 234);
        font-size: 1.4rem;
      }
    }

    &__stats {
      display: flex;
      padding: 1.2rem 1.6rem;
      background: linear-gradient(135deg, rgb(245 243 255 / 95%) 0%, rgb(237 233 254 / 95%) 100%);
      border-radius: 0.8rem;
      border: 1px solid rgb(216 180 254);
      margin-bottom: 2rem;
      width: 100%;
      backdrop-filter: blur(8px);
      transition: all 0.3s ease;

      @media (max-width: 1110px) {
        padding: 1.2rem;
      }

      &:hover {
        border-color: rgb(147 51 234);
        box-shadow: 0 4px 12px -2px rgba(147, 51, 234, 0.1);
        transform: translateY(-1px);
      }

      &-header {
        display: flex;
        align-items: center;
        gap: 1.2rem;
        width: 100%;
      }

      &-icon {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 3.2rem;
        height: 3.2rem;
        border-radius: 0.8rem;
        color: rgb(147 51 234);
        flex-shrink: 0;

        @media (max-width: 768px) {
          width: 2.8rem;
          height: 2.8rem;
        }

        svg {
          width: 1.8rem;
          height: 1.8rem;
          
          @media (max-width: 768px) {
            width: 1.6rem;
            height: 1.6rem;
          }
        }
      }

      &-info {
        flex: 1;
      }

      &-details {
        display: flex;
        align-items: center;
        gap: 1.6rem;

        @media (max-width: 768px) {
          flex-direction: column;
          align-items: flex-start;
          gap: 0.4rem;
        }
      }

      &-item {
        font-family: roboto;
        font-size: 1.4rem;
        color: rgb(71 85 105);
        display: flex;
        align-items: center;
        gap: 0.6rem;

        @media (max-width: 768px) {
          font-size: 1.3rem;
          white-space: nowrap;
        }

        &--premium {
          font-weight: 600;
          font-size: 1.5rem;
          color: rgb(147 51 234);
          
          @media (max-width: 768px) {
            font-size: 1.4rem;
            margin-bottom: 0.2rem;
          }
        }

        .premium {
          color: rgb(71 85 105);
        }
      }
    }
  }
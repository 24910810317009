.Exam-content {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
  padding: 1.5rem;

  @media(min-width: 740px) and (max-height: 1030px) {
    margin-top: 3rem;
  }

  @media(max-width: 605px) {
    padding-top: 6rem;
    margin-bottom: 4rem;
  }

  &__question-container {
    text-align: center;
    width: 100%;

    padding: 0.425rem;
    border-radius: 1rem;
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
    margin-bottom: 1rem;

    &.question-active {
      background: linear-gradient(to right, #4ade80, #3b82f6);
    }

    &.question-inactive {
      border: .4em solid #9ca3af;
      padding: 0;
    }

    &__question {
      background-color: #ffffff;
      padding: 2.5rem;
      border-radius: 0.5rem;
      font-size: 2rem;
      font-weight: bold;
      color: #111827;

      @media (max-width: 400px) {
        font-size: 1.6rem;
        padding: 2rem;
      }
    }

    @media (max-width: 534px) {
      margin-bottom: 0;
    }    
  }

  .status-message {
    text-align: center;
    font-size: 1.525rem;
    font-weight: 600;
    margin-bottom: 1.5rem;

    @media (max-height: 720px) {
      margin-bottom: 0;
    }

    &.exam-started {
      color: #4b5563;
    }
    
    &.exam-not-started {
      color: #9ca3af;
    }

    &.active {
      color: #16a34a;
    }
  }

  .status-message-interrogation {
    margin-bottom: 0;
    margin-top: 3rem;
  }

  .answered {
    text-align: center;

    .check-icon {
      width: 5rem;
      height: 5rem;
      color: #16a34a;
      margin: 0 auto 1rem auto;
    }

    .answered-message {
      font-size: 1.7rem;
      font-weight: 600;
      color: #374151;
    }
  }

  .skip-container {
    margin-top: 1.7rem;
    color: #9ca3af;
    font-weight: 500;

    &.active {
      color: #16a34a;
    }

    &.display-row {
      display: flex;
      align-items: center;
    }

    .skip-question {
      font-size: 1.7rem;
      cursor: pointer;

      &.inactive {
        pointer-events: none;
      }
    }
  }

  .completed {
    text-align: center;
    margin-bottom: 1rem;

    .check-icon {
      width: 3rem;
      height: 3rem;
      color: #16a34a;
      margin: 0 auto 1rem auto;
    }

    .completed-message {
      font-size: 1.5rem;
      font-weight: 600;
      color: #374151;
    }

    .completed-submessage {
      margin-top: 0.5rem;
      color: #374151;
    }
  }
}

.answer-complete {
  /* padding-top: 6rem; */
  margin-bottom: 2rem;
}

.user-answer-container, 
.user-prev-answer-container {
  position: relative;
  background-color: rgb(226, 226, 226);
  border-radius: 0.8rem;
  padding: 1rem;
  width: 22rem;
  height: 46px;
  cursor: pointer;
}

.user-prev-answer-container {
  margin-top: 2rem;
  width: 23rem;
}

.user-prev-answer-longer-container {
  width: 100%;
}

.user-answer-row {
  display: flex;
  align-items: center;
  column-gap: 1rem;
}

.user-prev-answer-row {
  display: flex;
  align-items: center;
  column-gap: 0.8rem;
}

.user-answer-icon, 
.user-answer-drop-icon {
  color: rgb(124, 124, 124);
}

.user-answer-drop-icon {
  position: absolute;
  right: .7rem;
}

.user-prev-answer-drop-icon {
  position: absolute;
  right: 1.2rem;
}

.user-answer-subtitle {
  color: rgb(124, 124, 124);
  font-weight: 600;
  font-size: 1.6rem;
  line-height: 1.5;
}

.user-prev-answer-subtitle {
  color: rgb(124, 124, 124);
  font-weight: 600;
  font-size: 1.6rem;
  line-height: 1.5;
}

.user-answer {
  opacity: 0;
  margin-top: 1rem;
  max-height: 30rem;
  overflow-y: auto;
  white-space: pre-wrap;
  word-wrap: break-word;
}

.active-container {
  height: fit-content;
  max-height: 40rem;
}

.answer-open {
  opacity: 1;
}

@media (max-width: 534px) {
  .exam-content {
    padding-bottom: 0;
  }   
}

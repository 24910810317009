.Price {
  padding-block: 6rem 9rem;
  text-align: center;
  opacity: 0;
  transform: translateY(20px);
  animation: fadeInUp 0.8s ease-out forwards;

  @keyframes fadeInUp {
    from {
      opacity: 0;
      transform: translateY(20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }

  &__title-primary {
    color: var(--primary-color-500);
  }

  &__title-secondary {
    color: #75b477;
  }

  &__text {
    margin-block-start:1.6rem;
    margin-bottom: 4rem;
  }

  &__card-row {
    padding-block-start: 3.2rem;
    opacity: 0;
    transform: translateY(30px);
    animation: fadeInUpCards 0.8s ease-out forwards;
    animation-delay: 0.3s;
  }

  @keyframes fadeInUpCards {
    from {
      opacity: 0;
      transform: translateY(30px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }

  .PriceCard {
    position: relative;


    &__popular-tag {
      position: absolute;
      top: -13px;
      right: 20px;
      background-color: var(--primary-color-500);
      color: white;
      padding: 5px 10px;
      border-radius: 3px;
      font-size: 1.4rem;
      z-index: 1;
    }

    &__original-cost {
      text-decoration: line-through;
      color: #666;
      font-size: 1.8rem;
    }

 

    &__text {
      font-size: 1.2rem;
      color: #000000;
      margin-top: 1rem;
      text-align: center;
    }
  }

  .Plans__annual-modes-container-landing {
    margin-top: 3rem;
    opacity: 0;
    transform: translateY(30px);
    animation: fadeInUpAnnual 0.8s ease-out forwards;
    animation-delay: 0.4s;
  }

  @keyframes fadeInUpAnnual {
    from {
      opacity: 0;
      transform: translateY(30px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }

  @media (max-width: 575px) {
    padding-block: 4.5rem;
  }
}
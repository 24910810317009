.examcard-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.5rem;

  .row-container {
    display: flex;
    align-items: center;
    gap: 1.3rem;
  }

  .title {
    font-size: 2.5rem;
    font-weight: bold;

    @media(max-width: 890px) {
      width: 36rem;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    @media(max-width: 630px) {
      width: 24rem;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      font-size: 2.3rem;
    }

    @media(max-width: 514px) {
      width: 13rem;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      font-size: 2rem; 
    }

    @media(max-width: 390px) {
      width: 11rem;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      font-size: 1.7rem; 
    }
  }

  .time-container {
    display: flex;
    align-items: center;
    gap: 1rem;

    .clock-icon {
      width: 2rem;
      height: 2rem;

      &.exam-started {
        color: #16a34a;
      }

      &.exam-not-started {
        color: #000000;
      }
    }

    .time {
      font-size: 1.125rem;
      font-weight: 600;
      font-size: 1.5rem;
    }

    .save-icon {
      align-items: center;
      background-color: #d5d5d380;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      height: 4rem;
      width: 4rem; 
      font-size: 2rem;
      cursor: pointer;

      &:hover {
        background-color: rgba($color: #d5d5d3, $alpha: 0.75);
      }

      &.btn-disabled {
        pointer-events: none;
        color: rgb(139, 139, 139);
        background-color: hsl(220, 10%, 83%);
        
        &:hover {
          background-color: hsl(220, 10%, 83%);
        }
      }
    }
  }
}

.examCardHeader {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-column-gap: 1.6rem;
  -moz-column-gap: 1.6rem;
  column-gap: 1.6rem;
  padding: 1.6rem;
  -webkit-border-after: 1px solid var(--grayscale-color-100);
  border-block-end: 1px solid var(--grayscale-color-100);
  border-inline: 1px solid var(--grayscale-color-100);

  &__box {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-column-gap: 0.4rem;
    -moz-column-gap: 0.4rem;
    column-gap: 0.4rem;
  }

  &__actions {
    display: flex;
    column-gap: 0.8rem;

    &__save-icon {
      align-items: center;
      background-color: #d5d5d380;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      height: 4rem;
      width: 4rem; 
      font-size: 2rem;
      cursor: pointer;

      &:hover {
        background-color: rgba($color: #d5d5d3, $alpha: 0.75);
      }

      &.btn-disabled {
        pointer-events: none;
        color: rgb(139, 139, 139);
        background-color: hsl(220, 10%, 83%);
        
        &:hover {
          background-color: hsl(220, 10%, 83%);
        }
      }
    }
    &-button {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
      width: 4rem;
      height: 4rem;
      background-color: rgba($color: #d5d5d3, $alpha: 0.5);
      border-radius: 50%;
      cursor: pointer;

      &:active,
      &:hover {
        background-color: rgba($color: #d5d5d3, $alpha: 0.75);
      }
      
      .goBack-icon {
        font-size: 1.6rem;
        color: var(--grayscale-color-950);
      }

      .icon-disabled {
        color: rgb(139, 139, 139);
      };

      &.btn-disabled {
        pointer-events: none;
        background-color: hsl(220, 10%, 83%);

        &:hover {
          background-color: hsl(220, 10%, 83%);
        }
      }

    }
  }
}

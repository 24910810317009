// Variabili
$green-50: #f0fdf4;
$green-100: #dcfce7;
$gray-100: #f3f4f6;
$gray-900: #111827;
$shadow-lg: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -4px rgba(0, 0, 0, 0.1);

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  backdrop-filter: blur(4px);
  z-index: 1999;
  display: flex;
  justify-content: center;
  align-items: center;
}

.tutorialPopup {
  position: relative;
  transform: translateY(0);
  opacity: 1;
  margin: auto;
  z-index: 1000;
  transition: transform 0.3s ease, opacity 0.3s ease;

  &.closing {
    opacity: 0;
    transform: translateY(1rem);
  }
}

.card {
  background: white;
  border-radius: 1rem;
  box-shadow: 0 10px 25px -5px rgba(0, 0, 0, 0.15), 0 8px 10px -6px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  width: 38rem;
  max-width: 90vw;
}

.header {
  background: linear-gradient(135deg, rgb(76, 146, 116) 0%, rgb(52, 149, 89) 100%);
  color: white;
  padding: 1.8rem 2rem;
  position: relative;
}

.closeButton {
  position: absolute;
  top: 1rem;
  right: 1rem;
  padding: 0.5rem;
  transition: all 0.2s;
  border: none;
  background: transparent;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    cursor: pointer;
    background: rgba(255, 255, 255, 0.2);
    transform: rotate(90deg);
  }
}

.closeIcon {
  width: 1.8rem;
  height: 1.8rem;
  color: white;
}

.headerContent {
  display: flex;
  align-items: center;
  gap: 1.2rem;
}

.iconWrapper {
  padding: 0.8rem;
  background: rgba(255, 255, 255, 0.2);
  border-radius: 0.8rem;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1);
}

.svgIcon {
  width: 2.2rem;
  height: 2.2rem;
  color: white;
}

.title {
  font-size: 1.8rem;
  line-height: 2rem;
  font-weight: 600;
  margin: 0;
}

.content {
  padding: 2rem;
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.contentBox {
  background-color: $green-50;
  border-radius: 0.8rem;
  padding: 1.6rem;
  border: 1px solid $green-100;
}

.description {
  color: #111827;
  font-size: 1.5rem;
  line-height: 1.5;
  margin: 0 0 1.5rem 0;
  font-weight: 500;
}

.featuresList {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.featureItem {
  display: flex;
  align-items: center;
  gap: 1rem;
  font-size: 1.3rem;
  color: #3D5656;
  
  p {
    margin: 0;
    line-height: 1.4;
  }
}

.featureIconBadge {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 2.2rem;
  height: 2.2rem;
  background-color: #349559;
  color: white;
  border-radius: 50%;
  font-weight: 600;
  font-size: 1.2rem;
}

.buttonGroup {
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
}

.primaryButton {
  width: 100%;
  padding: 1.2rem 1.5rem;
  background: linear-gradient(135deg, rgb(76, 146, 116) 0%, rgb(52, 149, 89) 100%);
  color: white;
  font-weight: 600;
  border-radius: 0.6rem;
  transition: all 0.2s;
  font-size: 1.5rem;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.8rem;

  &:hover {
    background: linear-gradient(135deg, rgb(88, 167, 134) 0%, rgb(65, 174, 106) 100%);
    cursor: pointer;
    transform: translateY(-2px);
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1);
  }
  
  &:active {
    transform: translateY(0);
  }
}

.buttonIcon {
  transition: transform 0.2s;
}

.primaryButton:hover .buttonIcon {
  transform: translateX(3px);
}

.secondaryButton {
  width: 100%;
  padding: 1.2rem 1.5rem;
  font-weight: 500;
  border-radius: 0.6rem;
  font-size: 1.4rem;
  border: 1px solid #E5E7EB;
  background-color: white;
  color: #4B5563;
  transition: all 0.2s;

  &:hover {
    background: #F9FAFB;
    cursor: pointer;
    border-color: #D1D5DB;
  }
}

@media (max-width: 480px) {
  .card {
    width: 100%;
    max-width: 90vw;
  }
  
  .header {
    padding: 1.5rem;
  }
  
  .title {
    font-size: 1.6rem;
  }
  
  .content {
    padding: 1.5rem;
  }
  
  .description {
    font-size: 1.4rem;
  }
  
  .featureItem {
    font-size: 1.2rem;
  }
  
  .primaryButton,
  .secondaryButton {
    font-size: 1.4rem;
    padding: 1rem 1.2rem;
  }
} 
.Plans {
  padding-block: 8rem;

  &__info {
    text-align: center;
    margin-bottom: 3rem;
    width: 100%;
  }

  &__plan-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__title-unselected-plan {
    text-align: center;
    font-size: 3.3rem;
    font-weight: 700;
  }

  &__text-unselected-plan {
    text-align: center;
    font-size: 1.7rem;
    font-weight: 400;
    margin-block: 2.5rem;
  }

  &__text {
    text-align: center;
    margin-block-start: 2.2rem;
    font-family: 'Roboto'
  }
  
  &__annual-modes-container {
    display: flex;
    justify-content: center;
    column-gap: 3rem;
  }

  &__annual-modes-container-landing {
    margin-top: 3rem;
  }

  &__cards {
    padding-block-start: 4.5rem;
  }

  .PriceCard {
    position: relative;
    overflow: visible;

    &__popular-tag {
      position: absolute;
      top: -15px;
      left: 50%;
      transform: translateX(-50%);
      background-color: var(--primary-color-500);
      color: white;
      padding: 5px 10px;
      border-radius: 3px;
      font-size: 1.4rem;
      z-index: 1;
      white-space: nowrap;
    }

    &__header {
      position: relative;
      z-index: 0;
    }

    &__original-cost {
      text-decoration: line-through;
      color: #666;
      font-size: 1.8rem;
    }

    &__text {
      font-size: 1.2rem;
      color: #000000;
      margin-top: 1rem;
      text-align: center;
    }
  }

  @media (max-width: 575px) {
    padding-block: 4rem;
  }

  @media (max-width: 750px) {
    &__annual-modes-container {
      flex-direction: column;
      row-gap: 3rem;
    }
  }

  &__faq {
    margin-top: 4rem;
    padding: 2rem 0;
    display: flex;
    flex-direction: column;
    align-items: center;

    &-header {
      text-align: center;
      max-width: 600px;
      margin-bottom: 1.5rem;
    }

    &-title {
      text-align: center;
      margin-bottom: 1rem;
    }

    &-text {
      color: var(--text-secondary);
      text-align: center;
      margin-bottom: 1rem;
    }

    &-content {
      width: 100%;
      max-width: 800px;
      margin: 0 auto;
    }
  }

  :global(.PricingAccordion) {
    width: 100%;
    max-width: 800px;
    margin: 0 auto;
  }
}
.Spinner {
  border-radius: 50%;
  border-style: solid;
  border-color: var(--grayscale-color-100);
  border-right-color: var(--primary-color-300);
  animation: loading 1s infinite linear;

  &--sm {
    width: 2.4rem;
    height: 2.4rem;
    border-width: 3.8px;
  }

  &--md {
    width: 3.2rem;
    height: 3.2rem;
    border-width: 6.4px;
  }

  &--lg {
    width: 4.8rem;
    height: 4.8rem;
    border-width: 9px;
  }
}

@keyframes loading {
  to {
    transform: rotate(1turn);
  }
}

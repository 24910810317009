.Loader {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding-top: 4rem; /* Aggiunto padding per distanziare il caricamento dalle scritte */

  &__spinner {
    border: 4px solid rgba(0, 0, 0, 0.1);
    border-left-color: #30844F; /* Colore verde desiderato */
    border-radius: 50%;
    width: 36px;
    height: 36px;
    animation: spin 1s linear infinite;

    @media (max-width: 575px) {
      width: 24px; /* Riduci dimensioni su dispositivi piccoli */
      height: 24px;
    }
  }

  @keyframes spin {
    to {
      transform: rotate(360deg);
    }
  }
}

.ReviewDocuments {
  position: relative;

  &__title {
    font-size: 2.4rem !important;
    margin-bottom: 1.6rem;
    display: block;
    max-width: 100%;
    overflow-wrap: break-word;
    word-wrap: break-word;
    hyphens: auto;
    
    @media (max-width: 575px) {
      font-size: 2.4rem !important;
      line-height: 1.4;
      padding-right: 1rem;
    }
  }

  &__list {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    row-gap: 1.6rem;
    max-height: 37.8rem;
    overflow-y: scroll;
  }
}


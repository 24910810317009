.ItemQA {
  font-size: 1.4rem;
  font-weight: bold;
  padding: 1.6rem;
  display: flex;
  gap: 1.6rem;
  align-items: flex-start;
  border: 1px solid #cccccc;
  border-radius: 0.8rem;
  background-color: var(--background-color);
  transition: all 0.2s ease;
  
  &:hover {
    border-color: var(--grayscale-color-200);
    box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.06);
    transform: translateY(-1px);
  }

  &--locked {
    background: linear-gradient(135deg, rgb(220 252 231 / 50%) 0%, rgb(220 252 231 / 80%) 100%);
    border: 1px solid rgb(34 197 94 / 60%);
    cursor: not-allowed;
    box-shadow: 0 0.4rem 0.8rem rgba(22, 163, 74, 0.08);
    backdrop-filter: blur(8px);
    transition: all 0.3s ease;
    padding: 1.2rem;

    &:hover {
      border-color: rgb(34 197 94 / 80%);
      box-shadow: 0 8px 24px -4px rgba(22, 163, 74, 0.1);
      transform: translateY(-1px);
    }

    .ItemQA__content {
      border: none;
      background: none;
      box-shadow: none;
      padding: 0;
    }

    .ItemQA__preview {
      position: relative;
      height: 100%;
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 0.8rem;
    }

    .ItemQA__preview-text {
      font-family: roboto;
      font-size: 1.3rem;
      color: rgb(20 83 45);
      margin: 0;
      line-height: 1.4;
      font-style: italic;
      padding: 0.6rem;
      background-color: transparent;
      border-radius: 0.8rem;
      border: none;
    }

    .ItemQA__lock-message {
      display: flex;
      align-items: center;
      gap: 0.8rem;
      padding: 0.8rem 1.2rem;
      background-color: rgb(220 252 231 / 90%);
      border: 1px dashed rgb(34 197 94 / 60%);
      border-radius: 0.8rem;
      transition: all 0.3s ease;
      z-index: 1;
      margin-top: 0.4rem;

      span {
        font-family: roboto;
        font-size: 1.3rem;
        color: rgb(5 150 105);
        font-style: italic;
      }

      .ItemQA__lock-icon {
        color: rgb(5 150 105);
        font-size: 1.4rem;
      }
    }

    .ItemQA__question {
      opacity: 0.8;
      font-size: 1.3rem;
      color: rgb(20 83 45);
    }
  }

  &__content {
    flex: 1;
  }

  &__question {
    font-size: 1.4rem;
    font-weight: 800;
    color: rgb(234 88 12);
    margin: 0 0 0.8rem 0;
  }

  &__question-number {
    color: rgb(234 88 12);
    margin-right: .6rem;
  }

  &__answer {
    font-size: 1.4rem;
    line-height: 1.5;
    color: var(--grayscale-color-600);
    margin: 0;
    font-weight: 400;
  }

  &__overview {
    display: flex;
    padding: 1.2rem 1.6rem;
    background-color: var(--background-color);
    border-radius: 0.8rem;
    border: 1px solid #cccccc;
    margin-bottom: 2rem;
    width: 100%;
    transition: all 0.2s ease;

    @media (max-width: 1110px) {
      padding: 1.2rem;
    }

    @media (max-width: 768px) {
      padding: 1.2rem;
      gap: 1.2rem;
    }

    &:hover {
      border-color: var(--grayscale-color-200);
      box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.06);
      transform: translateY(-1px);
    }

    &-icon {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 3.2rem;
      height: 3.2rem;
      border-radius: 0.8rem;
      flex-shrink: 0;

      @media (max-width: 768px) {
        width: 2.8rem;
        height: 2.8rem;
      }

      svg {
        width: 1.8rem;
        height: 1.8rem;
        
        @media (max-width: 768px) {
          width: 1.6rem;
          height: 1.6rem;
        }
      }

      &--flashcard {
        color: rgb(5 150 105);
      }

      &--question {
        color: rgb(234 88 12);
      }
    }

    &-content {
      display: flex;
      align-items: center;
      gap: 1.2rem;
      width: 100%;

      @media (max-width: 768px) {
        gap: 1rem;
        width: 100%;
      }
    }

    &-info {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 2rem;
      flex: 1;
      width: 100%;

      @media (max-width: 768px) {
        flex-direction: column;
        align-items: flex-start;
        gap: 0.75rem;
      }
    }

    &-total {
      font-size: 1.5rem;
      font-weight: 600;
      color: var(--grayscale-color-900);
      white-space: nowrap;
      display: flex;
      align-items: center;
      gap: 1.6rem;

      &[data-type="summary"] {
        font-weight: 400;
      }

      @media (max-width: 768px) {
        font-size: 1.4rem;
        font-weight: 600;
        color: var(--grayscale-color-900);
        display: flex;
        align-items: center;
        gap: 0.8rem;
        flex-wrap: wrap;
        gap: 0.6rem;
        font-size: 1.4rem;
        flex-direction: column;
        align-items: flex-start;
      }
    }

    &-details {
      color: var(--grayscale-color-600);
      font-size: 1.4rem;
      font-weight: 400;
      display: inline-flex;
      align-items: center;
      gap: 0.8rem;
      position: relative;

      &:not(:first-child)::before {
        content: '|';
        color: var(--grayscale-color-400);
        margin: 0 0.4rem;
      }

      @media (max-width: 768px) {
        font-size: 1.4rem;
        width: auto;
        margin-top: 0;
        padding-left: 0;

        &:not(:first-child) {
          margin-left: 0.8rem;
          
          &::before {
            content: '•';
            color: var(--grayscale-color-400);
            margin: 0 0.8rem 0 0;
          }
        }

        &:first-of-type {
          margin-left: 0;
          padding-left: 0;
          
          &::before {
            display: none;
          }
        }

        &:last-of-type {
          margin-bottom: 0;
          
          &::before {
            display: inline-block;
          }
        }
      }
    }

    &--locked {
      background-color: var(--background-color);
      border: 1px solid #cccccc;
      box-shadow: none;
      backdrop-filter: none;
      transition: all 0.2s ease;

      &:hover {
        border-color: var(--grayscale-color-200);
        box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.06);
        transform: translateY(-1px);
      }

      .ItemQA__overview-total {
        color: rgb(51 65 85);
        font-size: 1.5rem;

        @media (max-width: 768px) {
          font-size: 1.4rem;
        }
      }

      .ItemQA__overview-icon {
        color: rgb(5 150 105);
      }
    }
  }
}
.AssistanceForm {
  padding-block: 1.8rem;

  &__field {
    -webkit-margin-after: 2rem;
    margin-block-end: 2rem;
  }

  &__btn {
    min-width: 14rem;
  }
}

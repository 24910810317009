.DocumentQA {
  position: absolute;
  inset-block-start: 0;
  max-height: 55rem;
  border: 1px solid var(--grayscale-color-200);
  border-radius: 0.8rem;
  overflow-y: scroll;
  z-index: 0;
  background-color: var(--background-color);
  transition: transform 0.2s ease, box-shadow 0.2s ease;

  &--active {
    position: relative;
    z-index: 1;
    box-shadow: 0 4px 6px -1px #0000001a, 0 2px 4px -1px #0000000f;

    & > * {
      opacity: 1 !important;
    }
  }

  &__header {
    padding-block: 1.6rem;
    opacity: 0;
    background-color: var(--background-color);
    transition: border-color 0.2s ease;

    &:hover {
      border-color: var(--grayscale-color-200);
    }
  }

  &__body {
    padding: 1.6rem;
    padding-top: .6rem;
    opacity: 0;
    background-color: var(--background-color);
  }

  &__title-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    gap: 1.2rem;
    padding-inline: 2rem;
  }

  &__title {
    font-size: 1.8rem;
    font-weight: 600;
    color: var(--grayscale-color-900);
    margin: 0;
    padding: 0.2rem 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    flex: 1;

    @media (max-width: 463px) {
      max-width: none;
      font-size: 1.4rem;
    }
  }

  &__title-content {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 0.8rem;
    h2 {
      font-size: 1.8rem;
      line-height: 1.4;
      margin: 0;
      padding: 0.2rem 0;
    }
  }

  &__title-Input {
    padding: 1rem;
    font-size: 1.4rem;
    border-radius: 0.4rem;
    border: 1px solid var(--grayscale-color-200);
    width: 100%;
    background-color: var(--background-color);
    transition: all 0.2s ease;
    &:focus {
      border-color: var(--primary-color);
      box-shadow: 0 0 0 2px var(--primary-color-light);
    }
  }

  &__input-group {
    display: flex;
    align-items: center;
    gap: 0.8rem;
    width: 100%;
  }

  &__add-tag-button {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.6rem;
    background-color: var(--primary-color-50);
    color: var(--primary-color);
    border: none;
    border-radius: 0.6rem;
    cursor: pointer;
    transition: all 0.2s ease;

    &:hover {
      background-color: var(--primary-color-100);
    }

    svg {
      width: 2rem;
      height: 2rem;
    }
  }

  &__actions {
    display: flex;
    gap: 0.8rem;
    align-items: center;
    flex-shrink: 0;
  }

  &__saveIcon,
  &__editIcon,
  &__tagIcon {
    width: 2.8rem;
    height: 2.8rem;
    padding: 0.4rem;
    border-radius: .6rem;
    cursor: pointer;
    transition: all 0.2s ease;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
      transform: translateY(-1px);
    }

    &:active {
      transform: scale(0.95);
    }
  }

  &__editIcon {
    color: #475569;
    background-color: rgba(71, 85, 105, 0.08);

    &:hover {
      color: #334155;
      background-color: rgba(71, 85, 105, 0.12);
    }
  }

  &__saveIcon {
    color: #059669;
    background-color: rgba(5, 150, 105, 0.08);

    &:hover {
      color: #047857;
      background-color: rgba(5, 150, 105, 0.12);
    }
  }

  &__tagIcon {
    color: #475569;
    background-color: rgba(71, 85, 105, 0.08);

    &:hover {
      color: #334155;
      background-color: rgba(71, 85, 105, 0.12);
    }

    &--active {
      color: #059669;
      background-color: rgba(5, 150, 105, 0.08);
    }
  }

  &__tags-wrapper {
    border-bottom: 1px solid #cccccc;
  }

  &__tags-section {
    display: flex;
    align-items: center;
    gap: 1rem;
    margin-top: 1.6rem;
    padding-bottom: 2.8rem;
    padding-inline: 2rem;
    overflow-x: auto;
    scrollbar-width: none;
    -ms-overflow-style: none;
    scroll-behavior: smooth;
    white-space: nowrap;
    flex-wrap: nowrap;
    max-width: 100%;
    
    @media (max-width: 1024px) {
      &:has(.DocumentQA__tag-input-wrapper) {
        flex-wrap: wrap;
        gap: 0.8rem;
      }
    }
    
    &::-webkit-scrollbar {
      display: none;
    }
  }

  &__tags {
    display: flex;
    align-items: center;
    gap: 0.6rem;
    flex-shrink: 0;

    @media (max-width: 1024px) {
      &:has(+ .DocumentQA__tag-input-wrapper) {
        margin-right: 0.4rem;
        margin-bottom: 0.4rem;
      }
    }
  }

  &__tag {
    display: inline-flex;
    align-items: center;
    gap: 0.4rem;
    padding: 0.4rem 1.2rem;
    background-color: var(--primary-color-50);
    color: var(--primary-color-700);
    font-size: 1.3rem;
    font-weight: 500;
    border-radius: .6rem;
    transition: all 0.2s ease;
    border: 1px solid lightgray;
    white-space: nowrap;
    flex-shrink: 0;

    @media (max-width: 1024px) {
      font-size: 1.2rem;
      padding: 0.3rem 1rem;
    }
    
    &:hover {
      background-color: var(--primary-color-100);
    }
    
    &--editing {
      padding-right: 0.8rem;
      &:hover {
        background-color: var(--grayscale-color-50);
        border-color: var(--grayscale-color-200);
        color: var(--grayscale-color-700);
      }
    }
  }

  &__tag-remove {
    width: 1.8rem;
    height: 1.8rem;
    color: var(--grayscale-color-400);
    cursor: pointer;
    padding: 0.2rem;
    border-radius: 0.4rem;
    transition: all 0.2s ease;
    &:hover {
      color: var(--grayscale-color-700);
      background-color: var(--grayscale-color-100);
    }
  }

  &__add-tag {
    display: inline-flex;
    align-items: center;
    gap: 0.4rem;
    padding: 0.5rem 1.2rem;
    background-color: var(--grayscale-color-50);
    color: var(--grayscale-color-600);
    font-size: 1.3rem;
    font-weight: 500;
    border-radius: 0.6rem;
    line-height: 1;
    border: 1px dashed var(--grayscale-color-300);
    cursor: pointer;
    transition: all 0.2s ease;
    white-space: nowrap;
    flex-shrink: 0;
    
    @media (max-width: 1024px) {
      font-size: 1.2rem;
      padding: 0.4rem 1rem;
    }

    &:hover {
      background-color: var(--grayscale-color-100);
      border-color: var(--grayscale-color-400);
      color: var(--grayscale-color-700);
    }
  }

  &__add-icon {
    width: 1.6rem;
    height: 1.6rem;
  }

  &__tag-input-wrapper {
    animation: fadeIn 0.2s ease-out;
  }

  &__tag-input {
    padding: 0.8rem 1.2rem;
    font-size: 1.3rem;
    border-radius: 0.6rem;
    border: 1px solid var(--grayscale-color-200);
    width: 100%;
    max-width: 24rem;
    background-color: var(--background-color);
    transition: all 0.2s ease;
    &:focus {
      border-color: var(--primary-color);
      box-shadow: 0 0 0 2px var(--primary-color-light);
    }
  }

  &__menu-container {
    position: relative;
    width: 100%;
    padding: 0;
    margin: 0;
  }

  &__menu {
    display: flex;
    flex-wrap: nowrap;
    column-gap: 1.2rem;
    align-items: center;
    padding-inline: 2rem;
    color: #707070;
    font-size: 1.4rem;
    font-family: Roboto;
    font-weight: 500;
    overflow-x: auto;
    scrollbar-width: none;
    -ms-overflow-style: none;
    scroll-behavior: smooth;

    &::-webkit-scrollbar {
      display: none;
    }
    
    &__link {
      display: flex;
      justify-content: center;
      align-items: center;
      min-width: 12rem;
      flex-shrink: 0;
      column-gap: .7rem;
      padding: .7rem 1.2rem;
      cursor: pointer;
      transition: all 0.2s ease;
      border-bottom: 2px solid transparent;
      
      svg {
        color: #707070;
        flex-shrink: 0;
        width: 20px;
        height: 20px;
        transition: all 0.2s ease;
      }

      span {
        white-space: nowrap;
        overflow: visible;
        text-overflow: clip;
      }

      &:focus-visible {
        outline: none;
        background-color: var(--grayscale-color-50);
        border-radius: 0.4rem;
      }

      &--active {
        color: var(--tab-color);
        border-bottom-color: var(--tab-color);
        
        svg {
          color: var(--tab-color);
        }
      }

      &:hover:not(.DocumentQA__menu__link--active) {
        color: var(--tab-color);
        opacity: 0.8;
        
        svg {
          color: var(--tab-color);
          opacity: 0.8;
        }
      }
    }

    @media (max-width: 700px) {
      justify-content: flex-start;
      padding-bottom: .5rem;
    }
  }

  &__overview {
    background-color: var(--background-color);
    border: 1px solid #cccccc;
    border-radius: 0.8rem;
    padding: 1.6rem;
    margin-bottom: 2rem;
    display: flex;
    align-items: center;
    gap: 1.6rem;
    transition: all 0.2s ease;

    &:hover {
      border-color: var(--grayscale-color-200);
      box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.06);
      transform: translateY(-1px);
    }

    &--locked {
      background-color: var(--background-color);
      border: 1px solid #cccccc;
      box-shadow: none;
      backdrop-filter: none;
      transition: all 0.2s ease;

      &:hover {
        border-color: var(--grayscale-color-200);
        box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.06);
        transform: translateY(-1px);
      }

      .DocumentQA__overview-total {
        color: rgb(51 65 85);
        font-size: 1.5rem;
      }

      .DocumentQA__overview-icon {
        color: rgb(5 150 105);
      }
    }

    &-icon {
      display: flex;
      align-items: center;
      justify-content: center;

      &--flashcard {
        color: rgb(5 150 105);
      }

      &--question {
        color: rgb(234 88 12);
      }
    }

    &-content {
      flex: 1;
    }

    &-total {
      font-size: 1.5rem;
      font-weight: 600;
      color: var(--grayscale-color-900);
    }

    &-details {
      color: var(--grayscale-color-600);
      font-size: 1.4rem;
      font-weight: 500;
    }
  }
}

.ItemQA {
  font-size: 1.4rem;
  font-weight: bold;
  padding: 1.6rem;
  display: flex;
  gap: 1.6rem;
  align-items: flex-start;
  border: 1px solid #cccccc;
  border-radius: 0.8rem;
  background-color: var(--background-color);
  transition: all 0.2s ease;
  
  &:hover {
    border-color: var(--grayscale-color-200);
    box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.06);
    transform: translateY(-1px);
  }

  &__content {
    flex: 1;
  }

  &__question {
    font-size: 1.4rem;
    font-weight: 700;
    color: var(--grayscale-color-900);
    margin: 0 0 0.8rem 0;
  }

  &__question-number {
    margin-right: .6rem;
    
    &--flashcard {
      color: rgb(5 150 105);
    }
    
    &--question {
      color: rgb(234 88 12);
    }
  }

  &__answer {
    font-size: 1.4rem;
    line-height: 1.5;
    color: var(--grayscale-color-600);
    margin: 0;
    font-weight: 400;
  }
}

.Exam-status {
  position: absolute;
  top: 1rem;
  left: 1rem;
  right: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;

  &__question-status {
    padding: .5rem 1rem .5rem 1rem;
    border-radius: 9999px;
    font-size: 1.5rem;

    &.exam-started {
      background-color: #16a34a;
      color: #ffffff;
    }

    &.exam-not-started {
      background-color: #d1d5db;
      font-weight: 500;
    }
  }

  &__progress-bar-container {
    width: 16rem;
    background-color: #e5e7eb;
    border-radius: 9999px;
    height: 0.625rem;

    &__progress-bar {
      height: 0.625rem;
      border-radius: 9999px;
      transition: all 0.5s ease-out;

      &.exam-started {
        background-color: #16a34a;
      }

      &.exam-not-started {
        background-color: #9ca3af;
      }
    }

  }

  @media (max-width: 400px) {
    .progress-bar-container {
      width: 10rem;
    }   
  }
}
.NotificationBell {
    position: relative;
    
    &__button {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0.8rem;
      background: transparent;
      border: none;
      cursor: pointer;
      border-radius: 50%;
      transition: all 0.2s ease;
  
      &:hover {
        background-color: transparent;
      }
  
      &:focus {
        outline: none;
        background-color: transparent;
      }
  
      &:active {
        background-color: transparent;
        transform: scale(0.95);
      }
    }
  
    &__icon {
      color: var(--grayscale-color-950);
      transition: all 0.2s ease;
  
      &:hover {
        color: var(--primary-color-500);
      }
    }
  
    &__badge {
      position: absolute;
      top: 0.6rem;
      right: 0.6rem;
      width: 0.6rem;
      height: 0.6rem;
      background-color: var(--primary-color-500);
      border-radius: 50%;
      box-shadow: 0 0 0 2px var(--background-color);
    }
  
    .Dropdown {
      right: auto;
      left: 0;
      transform: translateX(-80%);
      width: 36rem;
      max-height: 48rem;
      background: var(--background-color);
      border-radius: 1.2rem;
      box-shadow: 0 4px 20px rgba(0, 0, 0, 0.08);
      overflow: hidden;
      border: 1px solid var(--grayscale-color-200);
      backdrop-filter: blur(8px);
  
      @media (max-width: 575px) {
        position: fixed;
        top: 6.4rem;
        left: 0;
        right: 0;
        width: 100%;
        transform: none;
        max-height: calc(100vh - 6.4rem);
        border-radius: 0;
      }
    }
  
    &__header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 1.6rem;
      border-bottom: 1px solid var(--grayscale-color-200);
      background-color: var(--background-color);
      position: sticky;
      top: 0;
      z-index: 1;
  
      h3 {
        font-size: 1.6rem;
        font-weight: 600;
        color: var(--grayscale-color-950);
        margin: 0;
      }
    }
  
    &__mark-all {
      display: flex;
      align-items: center;
      gap: 0.4rem;
      font-size: 1.4rem;
      color: var(--primary-color-600);
      background: var(--primary-color-50);
      border: 1px solid var(--primary-color-200);
      cursor: pointer;
      padding: 0.6rem 1.2rem;
      border-radius: 0.6rem;
      transition: all 0.2s ease;
      font-weight: 500;
  
      &:hover {
        background-color: var(--primary-color-100);
        color: var(--primary-color-700);
        border-color: var(--primary-color-300);
      }
  
      &:active {
        transform: translateY(1px);
      }
    }
  
    &__content {
      max-height: 36rem;
      overflow-y: auto;
      padding: 0.8rem;
      background-color: var(--background-color);
      scrollbar-width: thin;
      scrollbar-color: var(--grayscale-color-400) transparent;
  
      &::-webkit-scrollbar {
        width: 0.6rem;
      }
  
      &::-webkit-scrollbar-track {
        background: transparent;
      }
  
      &::-webkit-scrollbar-thumb {
        background-color: var(--grayscale-color-400);
        border-radius: 0.3rem;
      }
    }
  
    &__item {
      padding: 1.2rem;
      border-radius: 0.8rem;
      cursor: pointer;
      transition: all 0.2s ease;
      margin-bottom: 0.8rem;
      border: 1px solid transparent;
  
      &:last-child {
        margin-bottom: 0;
      }
  
      &:hover {
        background-color: var(--grayscale-color-100);
        border-color: var(--grayscale-color-200);
      }
  
      &--unread {
        background-color: var(--primary-color-50);
        border-color: var(--primary-color-100);
  
        .NotificationBell__item-title {
          font-weight: 600;
          color: var(--primary-color-900);
        }
  
        &:hover {
          background-color: var(--primary-color-100);
          border-color: var(--primary-color-200);
        }
      }
  
      &--changelog {
        border-left: 3px solid var(--primary-color-500);
        background-color: var(--primary-color-50);
        
        .NotificationBell__tag--changelog {
          color: var(--primary-color-700);
          background-color: var(--primary-color-100);
          border: 1px solid var(--primary-color-200);
        }

        &:hover {
          background-color: var(--primary-color-100);
          transform: translateX(4px);

          .NotificationBell__item-title {
            color: var(--primary-color-900);
          }
        }
      }
    }
  
    &__item-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 0.8rem;
    }
  
    &__tag {
      font-size: 1.2rem;
      font-weight: 600;
      padding: 0.4rem 0.8rem;
      border-radius: 0.4rem;
  
      &--new_feature {
        color: var(--primary-color-700);
        background-color: var(--primary-color-100);
        border: 1px solid var(--primary-color-200);
      }
  
      &--update {
        color: #0C4A6E;
        background-color: #E0F2FE;
        border: 1px solid #BAE6FD;
      }
  
      &--maintenance {
        color: var(--secondary-color-700);
        background-color: var(--secondary-color-100);
        border: 1px solid var(--secondary-color-200);
      }
  
      &--changelog {
        color: var(--primary-color-700);
        background-color: var(--primary-color-100);
        border: 1px solid var(--primary-color-200);
      }
    }
  
    &__date {
      font-size: 1.2rem;
      color: var(--grayscale-color-600);
    }
  
    &__item-title {
      font-size: 1.4rem;
      color: var(--grayscale-color-950);
      margin-bottom: 0.4rem;
      line-height: 1.3;
    }
  
    &__item-desc {
      font-size: 1.3rem;
      color: var(--grayscale-color-700);
      line-height: 1.4;
      margin: 0;
    }
  
    &__loading,
    &__error,
    &__empty {
      padding: 4rem 2rem;
      text-align: center;
      color: var(--grayscale-color-600);
      font-size: 1.4rem;
    }
  
    &__error {
      color: var(--error-color-500);
    }
  
    &__footer {
      padding: 1.2rem 1.6rem;
      border-top: 1px solid var(--grayscale-color-200);
      background-color: var(--background-color);
      position: sticky;
      bottom: 0;
      z-index: 1;
    }
  
    &__view-all {
      width: 100%;
      padding: 1rem 1.6rem;
      font-size: 1.4rem;
      font-weight: 500;
      color: var(--grayscale-color-700);
      background-color: var(--grayscale-color-50);
      border: 1px solid var(--grayscale-color-100);
      border-radius: 0.8rem;
      cursor: pointer;
      transition: all 0.2s ease;
      display: flex;
      align-items: center;
      justify-content: center;
  
      &:hover {
        background-color: var(--primary-color-50);
        border-color: var(--primary-color-100);
        color: var(--primary-color-600);
      }
  
      &:focus {
        outline: none;
      }
  
      &:active {
        background-color: var(--primary-color-100);
      }
  
      @media (max-width: 575px) {
        font-size: 1.5rem;
        padding: 1.2rem 1.6rem;
      }
    }
  }
.RepetitionQuestion {
  perspective: 1000px;
  margin-bottom: 2rem;

  &__inner {
    position: relative;
    height: 28rem;
    padding: 2rem 2.4rem 1.5rem;
    background-color: var(--grayscale-color-50);
    border: 1px solid var(--grayscale-color-200);
    border-radius: 1.2rem;
    box-shadow: 0 0.4rem 0.8rem rgba(#262624, 0.15);
    transition: all 0.3s ease;
    overflow: hidden;
    
    .RepetitionQuestion--revealed & {
      display: flex;
      flex-direction: column;
    }
  }

  &__content {
    position: relative;
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 0 1rem;
    height: 4.5rem;
    margin-bottom: 0;

    .RepetitionQuestion--revealed & {
      padding-bottom: 0;
      margin-bottom: 0;
      border-bottom: none;
      position: relative;
      
      &::after {
        display: none;
      }
    }
  }

  &__controls-container {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1.5rem;
    background-color: rgba(229, 231, 235, 0.2);
    padding: 0.5rem 1.5rem;
    border-radius: 2rem;
    transition: all 0.3s ease;
    
    &:hover {
      background-color: rgba(229, 231, 235, 0.3);
    }
  }

  &__reveal-control {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: transform 0.3s;

    &:hover {
      transform: scale(1.1);
    }
  }

  &__document-control {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: transform 0.3s;
    
    &:hover {
      transform: scale(1.1);
    }
  }

  &__document-icon {
    font-size: 2.2rem !important;
    color: var(--grayscale-color-800) !important;
    transition: all 0.2s ease-in-out;
    
    &:hover {
      color: var(--grayscale-color-950) !important;
    }
  }

  &__state-control {
    cursor: pointer;
    padding: 0.3rem;
    border-radius: 50%;
    transition: all 0.2s ease;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 3.2rem;
    height: 3.2rem;

    @media (max-width: 768px) {
      width: 4rem;
      height: 4rem;
      padding: 0.8rem;
    }

    &:hover {
      background-color: rgba(234, 88, 12, 0.1);
      transform: translateY(-2px);
    }

    @media (hover: none) {
      &:active {
        background-color: rgba(234, 88, 12, 0.15);
        transform: scale(0.95);
      }
    }
  }

  &__state-icon {
    font-size: 2.2rem !important;
    color: rgb(234 88 12) !important;
    transition: all 0.3s ease;

    @media (max-width: 768px) {
      font-size: 2.6rem !important;
    }

    &--review {
      color: rgb(220 38 38) !important;
    }

    &--known {
      color: rgb(34 197 94) !important;
    }
  }

  &__text-wrapper {
    flex: 1;
    width: 100%;
    display: flex;
    padding: 0.5rem 1.2rem;
    margin-bottom: 0;
    cursor: pointer;

    .RepetitionQuestion--revealed & {
      align-items: flex-start;
      justify-content: flex-start;
      overflow-y: auto;
      padding: 0.8rem 1.8rem 1rem;
      margin-top: 0.3rem;
      scrollbar-width: thin;
      scrollbar-color: rgba(234, 88, 12, 0.5) transparent;

      &::before {
        display: none;
      }

      &::after {
        content: '';
        display: block;
        height: 1.5rem;
      }

      &::-webkit-scrollbar {
        width: 5px;
        height: 5px;
      }

      &::-webkit-scrollbar-track {
        background: transparent;
        border-radius: 2.5px;
        margin: 2px 0;
      }

      &::-webkit-scrollbar-thumb {
        background: rgba(234, 88, 12, 0.5);
        border-radius: 2.5px;
        border: 0;
        background-clip: padding-box;

        &:hover {
          background: rgba(234, 88, 12, 0.7);
        }
      }
    }

    .RepetitionQuestion--state-1 & {
      scrollbar-color: rgba(220, 38, 38, 0.5) transparent;

      &::-webkit-scrollbar-thumb {
        background: rgba(220, 38, 38, 0.5);

        &:hover {
          background: rgba(220, 38, 38, 0.7);
        }
      }
    }

    .RepetitionQuestion--state-2 & {
      scrollbar-color: rgba(34, 197, 94, 0.5) transparent;

      &::-webkit-scrollbar-thumb {
        background: rgba(34, 197, 94, 0.5);

        &:hover {
          background: rgba(34, 197, 94, 0.7);
        }
      }
    }

    .RepetitionQuestion:not(.RepetitionQuestion--revealed) & {
      align-items: center;
      justify-content: center;
      overflow: auto;
      margin-top: .8rem;
    }
  }

  &__text {
    font-weight: 600;
    color: var(--grayscale-color-950);
    font-size: 1.6rem;
    line-height: 1.6;
    margin: 0;
    opacity: 1;
    transition: opacity 0.25s cubic-bezier(0.4, 0, 0.2, 1);
    width: 100%;

    &--question {
      text-align: center;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      position: relative;
      animation: fadeIn 0.3s ease-out;
      padding: .3rem 0;
      margin-top: 0.3rem;
      max-height: 100%;
      width: 100%;
    }

    &--answer {
      text-align: left;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      position: relative;
      animation: fadeIn 0.3s ease-out;
      padding: 0.3rem 0;
      margin-top: 0.3rem;
      max-height: 100%;
      width: 100%;
      
      &::before {
        display: none;
      }
    }

    // Rimuoviamo lo stile generico per evitare conflitti
    .RepetitionQuestion--revealed & {
      padding: 0;
    }
  }

  &__icon {
    font-size: 2.2rem !important;
    transition: all 0.4s cubic-bezier(0.4, 0, 0.2, 1);
    color: rgb(234 88 12) !important;

    @media (max-width: 768px) {
      font-size: 2.6rem !important;
    }

    &--revealed {
      transform: rotate(360deg);
    }
  }

  &__reset-icon {
    display: none;
  }

  // Effetti hover per lo stato non locked
  &:hover:not(&--locked) {
    .RepetitionQuestion__inner {
      transform: translateY(-4px);
      box-shadow:
        0 0.8rem 2.4rem rgba(#262624, 0.15),
        0 0 0 2px rgba(#262624, 0.08);
    }
  }

  // Stato "revealed" base (senza stato di confidenza)
  &--revealed {
    .RepetitionQuestion__inner {
      background-color: var(--grayscale-color-50);
      border: 2px solid rgba(234, 88, 12, 0.3);
    }

    .RepetitionQuestion__icon {
      color: rgb(234 88 12) !important;
    }

    .RepetitionQuestion__controls-container {
      margin-bottom: 0.3rem;
    }

    .RepetitionQuestion__header {
      margin-bottom: 0.1rem;
    }
  }

  // Stati di conoscenza
  &--state-1 {
    .RepetitionQuestion__icon {
      color: rgb(220 38 38) !important;
    }

    .RepetitionQuestion__state-control {
      background-color: rgba(220, 38, 38, 0.1);
    }

    .RepetitionQuestion__inner {
      border: 2px solid rgba(220, 38, 38, 0.3);
    }

    &.RepetitionQuestion--revealed {
      .RepetitionQuestion__icon {
        color: rgb(220 38 38) !important;
      }

      .RepetitionQuestion__inner {
        border: 2px solid rgba(220, 38, 38, 0.3);
      }
    }
  }

  &--state-2 {
    .RepetitionQuestion__icon {
      color: rgb(34 197 94) !important;
    }

    .RepetitionQuestion__state-control {
      background-color: rgba(34, 197, 94, 0.1);

      &:hover {
        background-color: rgba(34, 197, 94, 0.15);
      }
    }

    .RepetitionQuestion__inner {
      border: 2px solid rgba(34, 197, 94, 0.3);
    }

    &.RepetitionQuestion--revealed {
      .RepetitionQuestion__icon {
        color: rgb(34 197 94) !important;
      }

      .RepetitionQuestion__inner {
        border: 2px solid rgba(34, 197, 94, 0.3);
      }
    }
  }

  &--info-shown {
    .RepetitionQuestion__inner {
      height: auto;
      min-height: 28rem;
    }
  }
}

@keyframes fadeIn {
  from { opacity: 0; transform: translateY(8px); }
  to { opacity: 1; transform: translateY(0); }
}
.VerifyEmail {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: var(--grayscale-color-100);  // Colore di sfondo aggiornato
  padding: 3rem;

  &__header {
    text-align: center;
    margin-bottom: 2rem;
  }

  &__logo {
    max-width: 160px;
  }

  &__container {
    background: #ffffff;
    border: 1px solid #e0e0e0;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    padding: 2rem;
    max-width: 500px;
    width: 100%;
    text-align: center;
  }

  &__socials {
    margin-top: 2rem;
    width: 100%;
  }

  &__message {
    h4 {
      font-size: 1.5rem;
      color: var(--grayscale-color-800);
      margin-bottom: 1rem;
    }

    .VerifyEmail__sendButton {
      background-color: #30844F; // Colore verde di StudierAI
      color: #fff;
      border: none;
      padding: 1rem 2rem;
      font-size: 1rem;
      border-radius: 5px;
      cursor: pointer;
      transition: background-color 0.3s;

      &:hover {
        background-color: #75b477; // Colore verde più scuro per hover
      }
    }
  }

  @media (max-width: 768px) {
    padding: 2rem;

    &__container {
      padding: 1.5rem;
    }

    &__logo {
      max-width: 140px;
    }
  }

  @media (max-width: 480px) {
    padding: 1rem;

    &__container {
      padding: 1rem;
    }

    &__logo {
      max-width: 120px;
    }
  }
}

.MultipleChoice {
  -webkit-perspective: 1000px;
  perspective: 1000px;
  -webkit-transition: -webkit-transform 0.3s ease;
  transition: -webkit-transform 0.3s ease;
  -o-transition: transform 0.3s ease;
  transition: transform 0.3s ease;
  transition: transform 0.3s ease, -webkit-transform 0.3s ease;
  margin-bottom: 2rem;

  &--locked {
    .MultipleChoice__inner {
      background: linear-gradient(135deg, rgb(243 232 255 / 40%) 0%, rgb(243 232 255 / 70%) 100%);
      border: 1px solid rgb(147 51 234 / 50%);
      cursor: not-allowed;
      position: relative;
      overflow: hidden;
      box-shadow: 0 0.4rem 0.8rem rgba(147, 51, 234, 0.08);
      transition: all 0.3s ease;

      &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(
          to bottom,
          transparent 0%,
          rgb(243 232 255 / 70%) 90%
        );
        opacity: 0.9;
        transition: opacity 0.3s ease;
        pointer-events: none;
      }

      &:hover {
        transform: scale(1.02);
        border-color: rgb(147 51 234 / 70%);
        box-shadow: 0 0.8rem 2rem rgba(147, 51, 234, 0.12);

        &::after {
          opacity: 1;
        }
      }
    }

    .MultipleChoice__icon {
      color: rgb(147 51 234 / 40%) !important;
    }

    .MultipleChoice__text {
      color: rgb(107 33 168);
    }

    .MultipleChoice__difficulty {
      opacity: 0.6;
    }
  }

  &__preview {
    position: relative;
    
    &-fade {
      position: relative;
      margin-top: 1.2rem;
      padding-top: 1.2rem;
      border-top: 1px dashed rgb(147 51 234 / 30%);
    }
  }

  &__content-truncated {
    display: flex;
    align-items: center;
    gap: 0.8rem;
    padding: 1rem 1.4rem;
    background-color: rgb(243 232 255 / 80%);
    border: 1px dashed rgb(147 51 234 / 60%);
    border-radius: 0.8rem;
    transition: all 0.3s ease;

    svg {
      color: rgb(147 51 234);
      font-size: 1.6rem;
      flex-shrink: 0;
    }

    span {
      font-family: roboto;
      font-size: 1.4rem;
      color: rgb(107 33 168);
      font-style: italic;
    }

    &:hover {
      background-color: rgb(243 232 255);
      border-color: rgb(147 51 234 / 80%);
      transform: translateY(-1px);
    }
  }

  &__inner {
    position: relative;
    min-height: 12rem;
    padding: 2rem;
    background-color: var(--grayscale-color-50);
    border-radius: 1.2rem;
    -webkit-box-shadow: 0 0.4rem 0.8rem rgba($color: #262624, $alpha: 0.15);
    box-shadow: 0 0.4rem 0.8rem rgba($color: #262624, $alpha: 0.15);
    display: flex;
    flex-direction: column;
    gap: 1.6rem;
    transition: all 0.3s ease;

    @media (max-width: 768px) {
      padding: 1.6rem;
      gap: 1.2rem;
    }

    &:hover {
      @media (min-width: 769px) {
        transform: translateY(-2px);
        box-shadow: 0 0.8rem 1.6rem rgba($color: #262624, $alpha: 0.2);
      }
    }
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media (max-width: 768px) {
      margin-bottom: 1rem;
    }

    &-left {
      display: flex;
      align-items: center;
      gap: 0.8rem;

      @media (max-width: 768px) {
        gap: 0.6rem;
      }
    }

    &-right {
      display: flex;
      align-items: center;
      gap: 0.8rem;
    }
  }

  &__reveal-btn,
  &__reset-btn {
    background: none;
    border: none;
    padding: 0.4rem;
    border-radius: 0.6rem;
    cursor: pointer;
    font-size: 1.6rem;
    display: flex;
    align-items: center;
    justify-content: center;
    color: rgb(147 51 234);
    transition: all 0.2s ease;
    -webkit-tap-highlight-color: transparent;

    @media (max-width: 768px) {
      padding: 0.6rem;
    }

    &:hover {
      @media (min-width: 769px) {
        background-color: rgb(243 232 255);
      }
    }

    &:active {
      background-color: rgb(243 232 255);
    }
  }

  &__icon {
    flex-shrink: 0;
    font-size: 2rem !important;
    color: rgb(147 51 234) !important;
    width: 2rem !important;
    height: 2rem !important;

    @media (max-width: 768px) {
      font-size: 1.8rem !important;
      width: 1.8rem !important;
      height: 1.8rem !important;
    }
  }

  &__difficulty {
    padding: 0.4rem 0.8rem;
    border-radius: 0.4rem;
    font-size: 1.2rem;
    font-weight: 500;
    text-transform: capitalize;

    @media (max-width: 768px) {
      padding: 0.3rem 0.6rem;
      font-size: 1.1rem;
    }

    &--easy {
      background-color: rgb(220 252 231);
      color: rgb(22 163 74);
    }

    &--medium {
      background-color: rgb(254 249 195);
      color: rgb(202 138 4);
    }

    &--hard {
      background-color: rgb(254 226 226);
      color: rgb(220 38 38);
    }
  }

  &__question {
    margin-bottom: 1.6rem;

    @media (max-width: 768px) {
      margin-bottom: 1.2rem;
    }
  }

  &__text {
    font-weight: 600;
    text-align: left;
    color: var(--grayscale-color-950);
    font-size: 1.5rem;
    line-height: 1.4;

    @media (max-width: 768px) {
      font-size: 1.4rem;
    }
  }

  &__choices {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    @media (max-width: 768px) {
      gap: 0.8rem;
    }
  }

  &__choice {
    position: relative;
    width: 100%;
    padding: 1.2rem;
    border: 1px solid rgb(218, 218, 218);
    border-radius: 0.8rem;
    background-color: white;
    text-align: left;
    font-size: 1.4rem;
    color: var(--grayscale-color-950);
    transition: all 0.2s ease;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 1rem;
    -webkit-tap-highlight-color: transparent;

    @media (max-width: 768px) {
      padding: 1.4rem;
      font-size: 1.3rem;
    }

    &-text {
      flex: 1;
      line-height: 1.4;
    }

    &:hover:not(:disabled) {
      @media (min-width: 769px) {
        background-color: rgb(243, 232, 255);
        border-color: rgb(147 51 234);
      }
    }

    &:active {
      background-color: rgb(243, 232, 255);
      border-color: rgb(147 51 234);
    }

    &--selected {
      background-color: rgb(243, 232, 255);
      border-color: rgb(147 51 234);
    }

    &--correct {
      background-color: rgb(220 252 231);
      border-color: rgb(22 163 74);
      color: rgb(22 163 74);
      font-weight: 500;
    }

    &--incorrect {
      background-color: rgb(254 226 226);
      border-color: rgb(220 38 38);
      color: rgb(220 38 38);
      font-weight: 500;
    }

    &:disabled {
      opacity: 1;
      cursor: default;

      &:not(.MultipleChoice__choice--correct):not(.MultipleChoice__choice--incorrect) {
        opacity: 0.5;
      }
    }
  }

  &__choice-icon {
    flex-shrink: 0;
    font-size: 1.8rem;
    display: flex;
    align-items: center;

    @media (max-width: 768px) {
      font-size: 1.6rem;
    }

    &--correct {
      color: rgb(22 163 74);
    }

    &--incorrect {
      color: rgb(220 38 38);
    }
  }

  &__feedback {
    padding: 1.6rem;
    border-radius: 0.8rem;
    font-size: 1.4rem;
    margin-top: 1rem;

    @media (max-width: 768px) {
      padding: 1.2rem;
      font-size: 1.3rem;
      margin-top: 0.8rem;
    }

    &-header {
      display: flex;
      align-items: center;
      gap: 0.8rem;
      margin-bottom: 1rem;
      font-weight: 600;
      font-size: 1.5rem;

      @media (max-width: 768px) {
        font-size: 1.4rem;
        margin-bottom: 0.8rem;
      }
    }

    &--correct {
      background-color: rgb(220 252 231);
      color: rgb(22 163 74);
    }

    &--incorrect {
      background-color: rgb(254 226 226);
      color: rgb(220 38 38);
    }

    &-icon {
      &--correct {
        color: rgb(22 163 74);
      }

      &--incorrect {
        color: rgb(220 38 38);
      }
    }

    &-text {
      margin: 0;
      font-weight: 500;
      line-height: 1.5;
    }
  }
} 
.ExamSummary {
  text-align: center;
  margin-bottom: 2rem;

  &__score {
    padding: 1.5rem 2rem;
    padding-bottom: 0;
    border-radius: 0.5rem;
    margin-bottom: 1rem;
    font-size: 1.5rem;
    color: white;
    display: inline-block;
    width: 100%;
    max-width: 600px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0 auto;

    &--green {
      color: #28a745;
    }

    &--yellow {
      color: #ffc107;
    }

    &--red {
      color: #ff1900d6;
    }

    .ExamSummary__label {
      font-size: 1rem;
      margin-bottom: 0.5rem;
      color: inherit;
    }

    .ExamSummary__score-value {
      font-size: 3rem;
      font-weight: 800;
      font-family: Roboto;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 0.5rem;
      color: inherit;
    }

    .info-icon {
      cursor: pointer;
      color: #4a4a4a;
      padding: 0.2rem;
      border-radius: 50%;
    }

    .ExamSummary__feedback {
      font-family: 'Roboto';
      padding: .7rem 1rem;
      border-radius: 0.5rem;

      margin-top: 0.5rem;
      font-size: 1.2rem;
      color: inherit;
      text-align: center;

      &--green {
        background-color: #28a745;
        color: white;
      }

      &--yellow {
        background-color: #ffc107;
        color: white;
      }

      &--red {
        background-color: #ff1900d6;
        color: white;
      }
    }
  }

  .popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 10;

    .popup-inner {
      background-color: white;
      padding: 20px;
      border-radius: 10px;
      box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.3);
      max-width: 600px;
      text-align: left;

      .ExamSummary__popup-title {
        font-size: 1.5rem;
        font-weight: bold;
        margin-bottom: 1rem;
      }

      p {
        font-size: 1.4rem;
      }

      .ExamSummary__popup-content {
        font-size: 1.2rem;
        margin-bottom: 1rem;
      }
    }

    .close-btn {
      background-color: #4a4a4a;
      color: white;
      border: none;
      padding: 10px;
      border-radius: 5px;
      cursor: pointer;
      display: block;
      margin: 20px auto 0;
    }
  }

}
.SignUpIntro {
  position: relative;
  width: 100%;
  max-width: 450px;
  margin: 0 auto;

  .Heading {
    width: 100%;
    text-align: left;
    margin: 0;
    font-size: 3.2rem;
    line-height: 1.2;
    color: var(--grayscale-color-900);
    font-weight: 600;
  }

  &__text {
    margin-block-start: 0.4rem;
    font-size: 1.6rem;
    line-height: 1.5;
    color: var(--grayscale-color-600);
    width: 100%;
    text-align: left;
  }

  &__signInLink {
    text-align: center;
    color: var(--grayscale-color-600);
    margin-block-start: 2.4rem;
    font-size: 1.5rem;

    &-link {
      text-decoration: underline;
      color: #30844F;
      font-weight: 500;

      &:hover {
        color: darken(#30844F, 10%);
      }
    }
  }

  .highlight {
    color: #30844F;
  }

  @media screen and (max-width: 768px) {
    .Heading {
      font-size: 2.8rem;
      padding: 0 2rem;
      text-align: center;
      margin-bottom: 0.8rem;
    }

    &__text {
      font-size: 1.6rem;
      line-height: 1.6;
      padding: 0 2rem;
      margin: 0 auto;
      max-width: 100%;
      text-align: center;
    }

    &__signInLink {
      margin-block-start: 2rem;
      font-size: 1.4rem;
      padding: 0 2rem;
    }
  }

  @media screen and (max-width: 480px) {
   
    
    .Heading {
      width: 100%;
      text-align: left;
      margin: 0;
      font-size: 3.2rem;
      line-height: 1.4;
      color: var(--grayscale-color-900);
      font-weight: 600;
    }

    &__text {
      font-size: 1.5rem;
      line-height: 1.4;
      text-align: left;
    }

    &__signInLink {
      font-size: 1.25rem;
      margin-block-start: 1.8rem;
    }
  }
}

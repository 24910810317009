.Menu {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--grayscale-color-50);
  z-index: 10;
  overflow-y: scroll;

  &__header {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    padding: 1.6rem;
    padding-bottom: .8rem;
    margin-top: 3rem;
    border-bottom: 1px solid var(--grayscale-color-200);

    @media (min-width: 768px) and (max-width: 819px) {
      margin-top: 5rem;
    }

    &-box {
      padding: 1.2rem;
      background-color: rgba($color: rgb(213, 213, 211), $alpha: 0.25);
      border-radius: 8px;

      & > svg {
        font-size: 2rem;
        color: var(--grayscale-color-900);
      }

      &:hover {
        background-color: rgba($color: rgb(213, 213, 211), $alpha: 0.4);
      }
    }
  }

  &__navbar {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    row-gap: 1.4rem;
    padding: 3.2rem 1.6rem;
    color: var(--grayscale-color-50);

    &-navItem {
      height: -webkit-fit-content;
      height: -moz-fit-content;
      height: fit-content;
      padding: 1.3rem 1.2rem;
      font-family: 'Roboto';
      font-size: 2.4rem;
      color: var(--grayscale-color-950);
      background-color: transparent;
      border-radius: .8rem;
    }
  }

  &__footer {
    display: flex;
    flex-direction: column-reverse;
    gap: 1.2rem;
    margin: 0;
    padding: 2rem 1.6rem;
    background-color: white;
    border-top: 1px solid var(--grayscale-color-100);

    &-upgradePlan {
      width: 100%;
      margin: 0;
      padding: 1.4rem;
      background-color: var(--primary-color-400);
      border-radius: 1.2rem;
      font-size: 1.6rem;
      font-weight: 600;
      text-align: center;
      box-shadow: 0 2px 8px rgba(0, 0, 0, 0.08);
      transition: all 0.3s ease;

      &:hover {
        transform: translateY(-2px);
        box-shadow: 0 4px 12px rgba(0, 0, 0, 0.12);
      }
    }

    &-logout {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 0.8rem;
      padding: 1.4rem;
      background-color: white;
      border: 1px solid var(--grayscale-color-200);
      border-radius: 1.2rem;
      transition: all 0.3s ease;

      &:hover {
        background-color: var(--grayscale-color-50);
        border-color: var(--grayscale-color-300);
      }

      .Menu__logout-icon {
        width: 2rem;
        height: 2rem;
        color: var(--grayscale-color-600);
        transition: color 0.3s ease;
      }

      .Menu__logout-text {
        font-size: 1.6rem;
        font-weight: 600;
        color: var(--grayscale-color-600);
        transition: color 0.3s ease;
      }

      &:hover {
        .Menu__logout-icon,
        .Menu__logout-text {
          color: var(--grayscale-color-800);
        }
      }
    }
  }

  &__logout-icon {
    font-size: 2rem;
    color: gray;
    
    @media (min-width: 991px) {
      display: none;
    }
  }
  
  &__logout-text {
    color: rgb(97, 97, 97);
  }
}

.Countdown-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 15px; 
  margin-top: 2rem;
  font-family: 'Roboto';

  &__card {
    background: linear-gradient(145deg, #349d5c, #2d8951);
    padding: 8px 12px;
    border-radius: 8px;
    text-align: center;
    min-width: 4.5rem;
    box-shadow: 0 4px 15px rgba(52, 157, 92, 0.2);
    transition: transform 0.3s ease;

    &:hover {
      transform: translateY(-2px);
    }
  }

  .PriceCard__countdown--scolastico & {
    &__card {
      background: linear-gradient(145deg, rgb(131, 3, 182), rgb(109, 1, 151));
      box-shadow: 0 4px 15px rgba(131, 3, 182, 0.2);
    }
  }

  &__time {
    font-size: 1.8rem;
    font-weight: 700;
    color: white;
  }

  &__label {
    font-size: 1rem;
    font-weight: 500;
    margin-top: .4rem;
    color: rgba(255, 255, 255, 0.9);
    text-transform: uppercase;
    letter-spacing: 0.5px;
    text-align: center;
  }
}

.GoBack {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 4rem;
  height: 4rem;
  background-color: rgba($color: var(--grayscale-color100-rgb), $alpha: 0.5);
  border-radius: 50%;
  cursor: pointer;

  &__icon {
    font-size: 1.8rem;
    color: var(--grayscale-color-950);
  }

  &:hover {
    background-color: rgba($color: var(--grayscale-color100-rgb), $alpha: 0.7);
  }
}

.Hero {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: center;
  padding-top: 12rem;
  animation: fadeIn 0.8s ease-out;
  min-height: 100vh;
  overflow: hidden;

  &__background-wrapper {
    position: absolute;
    inset: 0;
    width: 100vw;
    height: 100vh;
    left: 0;
    top: 0;
    z-index: 0;
    pointer-events: none;
    display: none;

    @media (min-width: 1024px) {
      display: block;
    }
  }

  .container {
    position: relative;
    z-index: 1;
  }

  @media (max-width: 768px) {
    padding-top: 15rem;
  }

  @media (max-width: 480px) {
    padding-top: 12rem;
  }

  &__content {
    text-align: center;
    max-width: 800px;
    margin: 0 auto;
    animation: slideUp 0.6s ease-out;
    position: relative;
    z-index: 2;
  }

  &__typewriter {
    font-size: 6rem;
    margin-bottom: 1.6rem;
    opacity: 0;
    animation: fadeIn 0.3s ease-out forwards;
    color: var(--grayscale-color-900);

    @media (max-width: 991px) {
      font-size: 4rem;
    }
  }

  &__text {
    margin-bottom: 3.2rem;
    font-size: 2.3rem;
    color: var(--grayscale-color-800);
    animation: slideUp 0.6s ease-out;
    
    @media (max-width: 991px) {
      font-size: 1.9rem;
    }
  }

  &__btn {
    margin-bottom: 1.6rem;
    font-size: 1.8rem;
    padding: 1.2rem 3rem;
    transition: all 0.3s ease;
    box-shadow: 0 2px 8px rgba(52, 149, 89, 0.2);

    &:hover {
      transform: translateY(-2px);
      box-shadow: 0 4px 12px rgba(52, 149, 89, 0.3);
    }

    &:active {
      transform: translateY(0);
      box-shadow: 0 2px 8px rgba(52, 149, 89, 0.2);
    }

    &--login {
      display: none;

      @media (max-width: 575px) {
        display: none;
      }
    }

    @media (max-width: 575px) {
      width: 100%;
      padding: 1rem 2.5rem;
    }

    &--primary {
      padding-right: 16px;
      
      &::after {
        content: '→';
        display: inline-block;
        margin-left: 8px;
        vertical-align: middle;
      }
    }
  }

  @media (max-width: 767px) {
    &__content {
      text-align: center;
    }

    &__row {
      -webkit-box-orient: vertical;
      -webkit-box-direction: reverse;
      -ms-flex-direction: column-reverse;
      flex-direction: column-reverse;
      text-align: center;
    }
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes slideUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

// Import base folder card styles
@import './FolderCard.scss';

// Additional Folder specific styles
.Folder {
  &__saveIcon {
    color: var(--success-color) !important;
    font-size: 22px;
    cursor: pointer;
    
    &:hover {
      color: var(--success-color-dark) !important;
    }
  }
  
  &__editIcon {
    font-size: 20px;
    
    &:hover {
      color: var(--primary-color) !important;
    }
  }
  
  &__deleteIcon {
    font-size: 20px;
    
    &:hover {
      color: var(--danger-color) !important;
    }
  }
  
  &__nameInput {
    width: 100%;
    font-size: 1rem;
    padding: 8px;
    border-radius: 4px;
    border: 1px solid var(--primary-color);
    background-color: var(--input-background);
    color: var(--text-color);
    
    &:focus {
      outline: none;
      box-shadow: 0 0 0 2px rgba(var(--primary-rgb), 0.2);
    }
  }
  
  // Stile per Document_titleInput identico a quello dei documenti
  .Document_titleInput {
    padding: 0.4rem 0.8rem;
    margin-top: -0.2rem;
    margin-bottom: 0;
    height: auto;
    border-radius: 0.5rem;
    border: 1px solid var(--grayscale-color-200);
    font-size: 1.5rem;
    width: 100%;
    transition: all 0.2s ease;
    background-color: var(--background-color);
    color: var(--grayscale-color-900);
    font-weight: normal;
    line-height: 1.5;

    &:focus {
      outline: none;
      border-color: var(--primary-color-400);
      box-shadow: 0 0 0 3px rgba(var(--primary-color-rgb), 0.12);
    }
  }
  
  // Responsive design per il componente Folder
  @media (max-width: 768px) {
    .FolderCard__card {
      min-height: 170px;
    }
    
    .FolderCard__name {
      font-size: 1.3rem;
    }
    
    .FolderCard__box-text {
      font-size: 1.2rem;
    }
    
    .FolderCard__document-count {
      font-size: 1.1rem;
      padding: 0.3rem 0.8rem;
    }
    
    &__saveIcon,
    &__editIcon,
    &__deleteIcon {
      font-size: 18px;
    }
  }
  
  // Gestione dello stato attivo
  &--active {
    .FolderCard__card {
      border: 1px solid var(--grayscale-color-300);
      background-color: rgba(var(--background-color-rgb), 0.98);
      box-shadow: 0 8px 16px -2px rgba(0, 0, 0, 0.08),
                  0 4px 8px -2px rgba(0, 0, 0, 0.06),
                  0 0 0 1px rgba(var(--primary-color-rgb), 0.06),
                  0 0 8px 2px rgba(var(--primary-color-rgb), 0.04);
    }
  }
  
  // Stile per il hover
  &:hover {
    .FolderCard__card {
      transform: translateY(-2px);
      box-shadow: 0 8px 16px -2px rgba(0, 0, 0, 0.08),
                  0 4px 8px -2px rgba(0, 0, 0, 0.06);
    }
  }
  
  // Stile per il nome quando è in modalità di modifica
  &--editing {
    .FolderCard__card {
      border: 1px solid var(--primary-color-300);
      box-shadow: 0 0 0 1px rgba(var(--primary-color-rgb), 0.15);
    }
    
    .FolderCard__name {
      color: var(--primary-color);
    }
  }
  
  // Stilizzazione aggiuntiva per migliorare la compatibilità con MaterialsDocuments
  &--with-margin {
    margin-bottom: 16px;
  }
}

// Stili per la Folder all'interno di MaterialsDocuments
.MaterialsDocuments {
  .Folder {
    margin-right: 16px;
    
    &:last-child {
      margin-right: 0;
    }
    
    @media (max-width: 768px) {
      margin-right: 12px;
      margin-bottom: 12px;
    }
  }
  
  &__folders-grid {
    .Folder {
      margin-right: 0;
    }
  }
}
.Feedback {
  display: flex;
  flex-direction: column;
  gap: 0.6rem;
  margin-left: auto;

  &__button {
    width: 3.6rem;
    height: 3.6rem;
    border-radius: 0.8rem;
    border: 1px solid #cccccc;
    background-color: var(--background-color);
    cursor: pointer;
    transition: all 0.2s ease;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;

    &:disabled {
      opacity: 0.5;
      cursor: not-allowed;
      pointer-events: none;
    }

    &:hover:not(:disabled) {
      background: var(--grayscale-color-50);
      transform: translateY(-1px);
      box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.06);

      &.Feedback__button--thumbs-up {
        border-color: rgb(34, 197, 94);
        background-color: rgba(34, 197, 94, 0.05);
        .Feedback__icon {
          background-color: rgb(34, 197, 94);
          opacity: 0.8;
        }
      }

      &.Feedback__button--thumbs-down {
        border-color: rgb(239, 68, 68);
        background-color: rgba(239, 68, 68, 0.05);
        .Feedback__icon {
          background-color: rgb(239, 68, 68);
          opacity: 0.8;
        }
      }
    }

    &--active {
      transform: translateY(0);
      pointer-events: none;

      &.Feedback__button--thumbs-up {
        background-color: rgba(34, 197, 94, 0.1);
        border-color: rgb(34, 197, 94);
        box-shadow: 0 0 0 1px rgb(34, 197, 94);

        .Feedback__icon {
          background-color: rgb(34, 197, 94);
          opacity: 1;
        }

        &:hover:not(:disabled) {
          background-color: rgba(34, 197, 94, 0.15);
        }
      }

      &.Feedback__button--thumbs-down {
        background-color: rgba(239, 68, 68, 0.1);
        border-color: rgb(239, 68, 68);
        box-shadow: 0 0 0 1px rgb(239, 68, 68);

        .Feedback__icon {
          background-color: rgb(239, 68, 68);
          opacity: 1;
        }

        &:hover:not(:disabled) {
          background-color: rgba(239, 68, 68, 0.15);
        }
      }
    }
  }

  &__icon {
    width: 2rem;
    height: 2rem;
    opacity: 0.6;
    transition: all 0.2s ease;
    mask-size: contain;
    mask-repeat: no-repeat;
    mask-position: center;
    background-color: var(--grayscale-color-600);

    &--thumbs-up {
      mask-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='currentColor' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpath d='M14 9V5a3 3 0 0 0-3-3l-4 9v11h11.28a2 2 0 0 0 2-1.7l1.38-9a2 2 0 0 0-2-2.3zM7 22H4a2 2 0 0 1-2-2v-7a2 2 0 0 1 2-2h3'%3E%3C/path%3E%3C/svg%3E");

      &:hover {
        background-color: rgb(34, 197, 94);
      }
    }

    &--thumbs-down {
      mask-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='currentColor' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpath d='M10 15v4a3 3 0 0 0 3 3l4-9V2H5.72a2 2 0 0 0-2 1.7l-1.38 9a2 2 0 0 0 2 2.3zm7-13h3a2 2 0 0 1 2 2v7a2 2 0 0 1-2 2h-3'%3E%3C/path%3E%3C/svg%3E");

      &:hover {
        background-color: rgb(239, 68, 68);
      }
    }
  }

  /* Stato non selezionato hover */
  &__button:not(.Feedback__button--active):hover {
    &:nth-child(1) { // Thumbs up
      .Feedback__icon {
        background-color: rgb(34, 197, 94);
        opacity: 0.8;
      }
    }
    &:nth-child(2) { // Thumbs down
      .Feedback__icon {
        background-color: rgb(239, 68, 68);
        opacity: 0.8;
      }
    }
  }

  /* Effetto hover più pronunciato */
  &__button:hover:not(:disabled):not(.Feedback__button--active) {
    &:nth-child(1) { // Thumbs up
      border-color: rgb(34, 197, 94, 0.5);
      background-color: rgba(34, 197, 94, 0.05);
    }
    &:nth-child(2) { // Thumbs down
      border-color: rgb(239, 68, 68, 0.5);
      background-color: rgba(239, 68, 68, 0.05);
    }
  }
}
.Home__header {
  padding: 1.6rem;
}

.Home__content {
  padding: 1.6rem;
}

.Home__video {
  width: auto;
  height: 320px;

  & > iframe {
    width: 100%;
    height: 100%;
  }
}

.Home__description {
  -webkit-margin-before: 1.6rem;
  margin-block-start: 1.6rem;
}

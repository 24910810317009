.BetaTestimonialSliderItem {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 2rem 2.5rem;
  background: #ffffff;
  border-radius: 16px;
  margin: 10px;
  min-height: auto;
  border: 1px solid rgba(128, 128, 128, 0.2);
  transition: all 0.3s ease;

  &__text {
    font-size: 1.5rem;
    margin: 1.2rem 0;
    font-weight: 700;
    line-height: 1.5;
    color: #2c3e50;
    overflow-wrap: break-word;
    word-wrap: break-word;
  }

  &__stars {
    display: flex;
    justify-content: center;
    gap: 0.2rem;
    color: var(--primary-color-500);
    margin-bottom: 0.8rem;
    font-size: 1.3rem;
  }

  &__name {
    font-weight: 600;
    font-size: 1.1rem;
    margin-top: 1rem;
    color: var(--primary-color-500);
    opacity: 0.9;
  }

  @media (max-width: 768px) {
    padding: 1.8rem 2rem;
    
    &__text {
      font-size: 1.4rem;
      margin: 1rem 0;
    }

    &__stars {
      font-size: 1.2rem;
    }

    &__name {
      font-size: 1rem;
    }
  }
}


.CheckVerifyEmail {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: var(--grayscale-color-100);  // Colore di sfondo aggiornato
  padding: 3rem;

  &__header {
    text-align: center;
    margin-bottom: 2rem;
  }

  &__logo {
    max-width: 160px;
  }

  &__container {
    background: #ffffff;
    border: 1px solid #e0e0e0;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    padding: 2rem;
    max-width: 500px;
    width: 100%;
    text-align: center;
  }

  &__socials {
    margin-top: 2rem;
    width: 100%;
  }

  @media (max-width: 768px) {
    padding: 2rem;

    &__container {
      padding: 1.5rem;
    }

    &__logo {
      max-width: 140px;
    }
  }

  @media (max-width: 480px) {
    padding: 1rem;

    &__container {
      padding: 1rem;
    }

    &__logo {
      max-width: 120px;
    }
  }
}

.Flashcards {
  &__container {
    padding: 0.6rem;
    background-color: var(--background-color);
    border-radius: 0.8rem;
    border: 1px solid var(--grayscale-color-200);
    transition: all 0.2s ease;
    perspective: 1000px;
    cursor: pointer;
    margin-bottom: 1.2rem;
    
    &:hover {
      border-color: var(--grayscale-color-300);
      transform: translateY(-1px);
      box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.06);
    }

    &--locked {
      background: linear-gradient(135deg, rgb(220 252 231 / 50%) 0%, rgb(220 252 231 / 80%) 100%);
      border: 1px solid rgb(34 197 94 / 60%);
      cursor: not-allowed;
      
      &:hover {
        transform: none;
        box-shadow: none;
        border-color: rgb(34 197 94 / 80%);
      }

      .Flashcards__preview {
        color: rgb(5 150 105);
      }

      .Flashcards__lock-message {
        color: rgb(5 150 105);
      }
    }

    &--flipped .Flashcards__card {
      transform: rotateY(180deg);
    }
  }

  &__card {
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
    transition: transform 0.6s;
    transform-style: preserve-3d;
    min-height: 200px;

    &-front,
    &-back {
      position: absolute;
      width: 100%;
      height: 100%;
      backface-visibility: hidden;
      padding: 1.5rem;
      display: flex;
      flex-direction: column;
      gap: 1rem;
    }

    &-front {
      justify-content: center;
      align-items: center;

      .Flashcards__number {
        margin-bottom: 1rem;
      }

      .Flashcards__content {
        text-align: center;
        overflow-y: auto;
        max-height: 70%;
      }
    }

    &-back {
      transform: rotateY(180deg);
      background-color: rgb(5 150 105 / 0.05);
      justify-content: center;

      .Flashcards__number {
        display: none;
      }

      .Flashcards__content {
        text-align: center;
        overflow-y: auto !important;
        max-height: 75%;
        -webkit-overflow-scrolling: touch;
        padding-right: 6px;
        scrollbar-width: thin !important;
        scrollbar-color: rgb(5 150 105) transparent !important;
        margin-bottom: 1rem;
        
        /* Stile per la scrollbar più sottile e coerente */
        &::-webkit-scrollbar {
          width: 2px !important; /* Ancora più sottile */
          height: 30px !important;
        }
        
        &::-webkit-scrollbar-track {
          background: transparent !important;
        }
        
        &::-webkit-scrollbar-thumb {
          background-color: rgb(5 150 105) !important; /* Verde esattamente come il background */
          border-radius: 20px !important;
          height: 30px !important;
          min-height: 30px !important;
          max-height: 30px !important;
        }
      }
    }
  }

  &__number {
    font-size: 1.4rem;
    font-weight: 700;
    color: rgb(5 150 105);
    margin-right: .6rem;
  }

  &__content {
    font-size: 1.4rem;
    line-height: 1.5;
    color: var(--grayscale-color-900);
    margin: 0;
  }

  &__preview {
    padding: 1.6rem;
    text-align: center;

    &-text {
      color: var(--grayscale-color-600);
      font-size: 1.4rem;
      margin-bottom: 1rem;
    }
  }

  &__lock-message {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
    color: var(--grayscale-color-500);
    font-size: 1.4rem;

    .Flashcards__lock-icon {
      font-size: 1.2rem;
    }
  }

  &__feedback {
    display: flex;
    gap: 0.8rem;
    padding: 1rem;
    border-top: 1px solid var(--grayscale-color-200);
    margin-top: 1rem;

    &-button {
      padding: 0.8rem 1.2rem;
      border-radius: 0.6rem;
      font-size: 1.4rem;
      font-weight: 500;
      transition: all 0.2s;
      border: none;
      cursor: pointer;

      &--helpful {
        background-color: rgb(5 150 105 / 0.1);
        color: rgb(5 150 105);

        &:hover {
          background-color: rgb(5 150 105 / 0.2);
        }

        &.Flashcards__feedback-button--active {
          background-color: rgb(5 150 105);
          color: white;
        }
      }

      &--not-helpful {
        background-color: rgb(239 68 68 / 0.1);
        color: rgb(239 68 68);

        &:hover {
          background-color: rgb(239 68 68 / 0.2);
        }

        &.Flashcards__feedback-button--active {
          background-color: rgb(239 68 68);
          color: white;
        }
      }
    }
  }

  &__stats {
    display: flex;
    padding: 1.2rem 1.6rem;
    background: linear-gradient(135deg, rgb(240 253 244 / 95%) 0%, rgb(220 252 231 / 95%) 100%);
    border-radius: 0.8rem;
    border: 1px solid rgb(5 150 105 / 60%);
    margin-bottom: 2rem;
    width: 100%;
    backdrop-filter: blur(8px);
    transition: all 0.3s ease;

    @media (max-width: 1110px) {
      padding: 1.2rem;
    }

    &:hover {
      border-color: rgb(5 150 105);
      box-shadow: 0 4px 12px -2px rgba(5, 150, 105, 0.1);
      transform: translateY(-1px);
    }

    &-header {
      display: flex;
      align-items: center;
      gap: 1.2rem;
      width: 100%;
    }

    &-icon {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 3.2rem;
      height: 3.2rem;
      border-radius: 0.8rem;
      color: rgb(5 150 105);
      flex-shrink: 0;

      @media (max-width: 1110px) {
        width: 2.8rem;
        height: 2.8rem;
      }

      svg {
        width: 1.8rem;
        height: 1.8rem;
        
        @media (max-width: 1110px) {
          width: 1.6rem;
          height: 1.6rem;
        }
      }

      &--disabled {
        background-color: transparent;
        cursor: not-allowed;
      }
    }

    &-info {
      flex: 1;
    }

    &-details {
      display: flex;
      align-items: center;
      gap: 1.6rem;

      @media (max-width: 1110px) {
        flex-direction: column;
        align-items: flex-start;
        gap: 0.4rem;
      }
    }

    &-item {
      font-family: roboto;
      font-size: 1.4rem;
      color: rgb(71 85 105);
      display: flex;
      align-items: center;
      gap: 0.6rem;

      @media (max-width: 1110px) {
        font-size: 1.3rem;
      }

      &--premium {
        &:first-child {
          font-weight: 600;
          font-size: 1.5rem;
          color: rgb(5 150 105);
          
          @media (max-width: 1110px) {
            font-size: 1.4rem;
            margin-bottom: 0.2rem;
          }
        }

        span.premium {
          font-weight: 600;
          background: linear-gradient(135deg, rgb(5 150 105) 0%, rgb(4 120 87) 100%);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        }
      }
    }
  }

  &__premium-banner {
    display: flex;
    align-items: center;
    margin-bottom: 2rem;
    background: linear-gradient(135deg, rgb(220 252 231 / 50%) 0%, rgb(220 252 231 / 80%) 100%);
    border: 1px solid rgb(34 197 94 / 60%);
    border-radius: 1.2rem;
    backdrop-filter: blur(8px);
    transition: all 0.3s ease;
    overflow: hidden;

    &:hover {
      border-color: rgb(34 197 94 / 80%);
      box-shadow: 0 8px 24px -4px rgba(22, 163, 74, 0.1);
      transform: translateY(-1px);
    }

    &-content {
      flex: 1;
      display: flex;
      align-items: center;
      gap: 2rem;
      padding: 1.4rem 2rem;

      @media (max-width: 768px) {
        flex-direction: column;
        align-items: flex-start;
        gap: 1rem;
        padding: 1.2rem 1.6rem;
      }
    }

    &-header {
      display: flex;
      align-items: center;
      gap: 1rem;
      margin: 0;
      min-width: max-content;

      h6 {
        font-family: roboto;
        font-size: 1.6rem;
        font-weight: 600;
        color: rgb(5 150 105);
        margin: 0;
      }
    }

    &-icon {
      font-size: 2rem;
      color: rgb(5 150 105);
    }

    p {
      font-family: roboto;
      font-size: 1.4rem;
      line-height: 1.5;
      color: rgb(20 83 45);
      margin: 0;
      flex: 1;
    }
  }
} 
.HamburgerMenu {
  display: none;
  width: 4.8rem;
  height: 4.8rem;
  padding: 1.2rem;
  background-color: rgba($color: var(--grayscale-color100-rgb), $alpha: 0.5);
  border-radius: 50%;
  cursor: pointer;

  &__button {
    width: 2.4rem;
    height: 2.4rem;

    &-icon {
      width: 100%;
      height: 100%;
      color: var(--grayscale-color-600);
    }
  }

  &:active {
    background-color: rgba($color: var(--grayscale-color100-rgb), $alpha: 1);

    & > &__button > * {
      color: var(--grayscale-color-700);
    }
  }

  @media (max-width: 991px) {
    display: block;
    -ms-flex-item-align: center;
    -ms-grid-row-align: center;
    align-self: center;
  }
}

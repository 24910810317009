.chat-active {
  display: flex;
  justify-content: center;
  gap: 1rem;
  padding: 1rem;
  margin-bottom: 23rem;
}

@media (max-width: 534px) {
  .chat-active {
    margin-bottom: 19rem;
  }   
}

@media (min-width: 740px) and (max-height: 1030px) {
  .chat-active {
    margin-bottom: 20rem;
  }   
}

// Stile per il pulsante dei suggerimenti
.hint-button-container {
  position: relative;
  display: flex;
  justify-content: center;
  z-index: 100;
}

.hint-nav-icon {
  color: white;
}

.hint-button {
  width: 62px;
  height: 62px;
  border-radius: 50%;
  background-color: #F5B800;
  color: white;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.3s;
  box-shadow: 0 4px 10px rgba(245, 184, 0, 0.3);
  position: relative;
  
  &:hover {
    background-color: #E6AD00;
    transform: translateY(-2px);
    box-shadow: 0 6px 15px rgba(245, 184, 0, 0.35);
  }
  
  &.active {
    background-color: #E6AD00;
    box-shadow: 0 4px 10px rgba(245, 184, 0, 0.3),
               0 0 0 3px rgba(245, 184, 0, 0.3);
  }
  
  &.inactive, &:disabled {
    background-color: #F5B800;
    opacity: 0.7;
    pointer-events: none;
    cursor: not-allowed;
    transform: none;
    box-shadow: 0 4px 10px rgba(245, 184, 0, 0.2);
  }

  // Tooltip
  &::before {
    content: attr(data-tooltip);
    position: absolute;
    bottom: 100%;
    left: 50%;
    transform: translateX(-50%);
    margin-bottom: 4px;
    padding: 6px 10px;
    background-color: rgba(0, 0, 0, 0.8);
    color: white;
    font-size: 0.9rem;
    border-radius: 4px;
    white-space: nowrap;
    opacity: 0;
    visibility: hidden;
    transition: all 0.2s ease;
    pointer-events: none;
    z-index: 1000;
  }

  &:hover::before {
    opacity: 1;
    visibility: visible;
  }
  
  // Mostra la freccia del tooltip solo quando non è attivo
  &:not(.active):hover::after {
    opacity: 1;
    visibility: visible;
  }
}

.hint-popup {
  position: absolute;
  bottom: 100%;
  transform: translateX(-50%);
  width: 300px;
  background-color: #F5B800;
  border-radius: 12px;
  padding: 1rem;
  margin-bottom: 0.50rem;
  box-shadow: 0 8px 20px rgba(245, 184, 0, 0.25);
  animation: slideDown 0.3s ease-out forwards;
  z-index: 20;
  
  // Aggiungo la freccia sotto alla card dei suggerimenti
  &::after {
    content: '';
    position: absolute;
    bottom: -24px; // Posiziono la freccia sotto la card
    left: 50%;
    transform: translateX(-50%);
    border-width: 12px;
    border-style: solid;
    border-color: #F5B800 transparent transparent transparent;
    filter: drop-shadow(0 2px 3px rgba(0, 0, 0, 0.1));
    z-index: 10;
  }
}

@keyframes slideDown {
  from {
    opacity: 0;
    transform: translate(-50%, 10px);
  }
  to {
    opacity: 1;
    transform: translate(-50%, 0);
  }
}

.hint-content {
  color: white;
  font-size: 1.3rem;
  line-height: 1.5;
  text-align: center;
  margin-bottom: 2rem;
  min-height: 60px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  padding-top: 4.5rem;
}

.hint-icon {
  position: absolute;
  top: 0px;
  left: 50%;
  width: 30px;
  transform: translateX(-50%);
  font-size: 1.8rem;
  color: white;
  filter: drop-shadow(0 0 8px rgba(255, 255, 255, 0.8));
  animation: glowEffect 2s infinite alternate;
}

@keyframes glowEffect {
  from {
    filter: drop-shadow(0 0 5px rgba(255, 255, 255, 0.6));
    transform: translateX(-50%) scale(1);
  }
  to {
    filter: drop-shadow(0 0 12px rgba(255, 255, 255, 0.9));
    transform: translateX(-50%) scale(1.1);
  }
}

.hint-navigation {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1.5rem;
}

.hint-nav-button {
  background-color: rgba(255, 255, 255, 0.2);
  color: white;
  border: none;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.2s;
  
  &:hover:not(:disabled) {
    background-color: rgba(255, 255, 255, 0.3);
  }
  
  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
}

.hint-indicator {
  display: flex;
  gap: 0.5rem;
  
  span {
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background-color: rgba(255, 255, 255, 0.3);
    transition: all 0.2s;
    
    &.active {
      background-color: white;
      transform: scale(1.2);
    }
  }
}

.controls {
  display: flex;
  justify-content: center;
  gap: 1rem;
  padding: 1rem;
  transition: ease-in-out;
  transition-duration: .2s;

  .control-button {
    padding: 2rem;
    border-radius: 100%;
    transition: all 0.3s;
    border: #ffffff;
    color: #ffffff;
    cursor: pointer;
    position: relative;

    // Effetto hover comune a tutti i pulsanti
    &:hover {
      transform: translateY(-2px);
      box-shadow: 0 6px 15px rgba(0, 0, 0, 0.2);
    }

    // Tooltip base
    &::before {
      content: attr(data-tooltip);
      position: absolute;
      bottom: 100%;
      transform: translateX(0);
      margin-bottom: 4px;
      padding: 6px 10px;
      background-color: rgba(0, 0, 0, 0.8);
      color: white;
      font-size: 0.9rem;
      border-radius: 4px;
      white-space: nowrap;
      opacity: 0;
      visibility: hidden;
      transition: all 0.2s ease;
      pointer-events: none;
      z-index: 1000;
    }

    &:hover::before,
    &:hover::after {
      opacity: 1;
      visibility: visible;
    }

    // Stile specifico per il tooltip del microfono
    &.mic::before {
      left: -90%;
    }

    // Stile specifico per il tooltip della chat
    &.chat::before {
      right: -35%;
      left: auto;
    }

    &.mic {
      &.recording {
        background-color: #dc2626;

        &:hover {
          background-color: #c52222;
          transform: translateY(-2px);
          box-shadow: 0 6px 15px rgba(220, 38, 38, 0.25);
        }
      }

      &.not-recording {
        background-color: #16a34a;

        &:hover {
          background-color: #15803d;
          transform: translateY(-2px);
          box-shadow: 0 6px 15px rgba(22, 163, 74, 0.25);
        }

        &.mic-inactive {
          background-color: #16a34a;
          pointer-events: none;
          opacity: 0.7;
          transform: none;
          box-shadow: none;
        }
      }
    }

    &.chat {
      background-color: #2563eb;

      &:hover {
        background-color: #1d4ed8;
        transform: translateY(-2px);
        box-shadow: 0 6px 15px rgba(37, 99, 235, 0.25);
      }

      &.inactive {
        background-color: #2563eb;
        pointer-events: none;
        opacity: 0.7;
        transform: none;
        box-shadow: none;
      }
    }
  }
}

// Stile per disabilitare temporaneamente i tooltip
.tooltip-disabled {
  &::before,
  &::after {
    display: none !important;
    opacity: 0 !important;
    visibility: hidden !important;
  }
  
  &:hover::before,
  &:hover::after {
    display: none !important;
    opacity: 0 !important;
    visibility: hidden !important;
  }
}
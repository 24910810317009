.Question__container {
  border: 1px solid #e5e7eb;
  border-radius: 1rem;
  padding: 1.25rem;
  border-left-width: 3px;
  transition: background-color 0.2s ease-in-out;
  margin-top: 1rem;

  &:hover {
    background-color: #f9fafb;
  }

  &--correct {
    border-left-color: #22c55e;
  }

  &--incorrect {
    border-left-color: #ef4444;
  }

  &--partial {
    border-left-color: #f59e0b;
  }

  &--skipped {
    border-left-color: #d1d5db;
  }
}

.Question__header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
  cursor: pointer;

  .Question__title {
    display: flex;
    align-items: center;
    font-weight: 600;
    font-size: 1.3rem;
    color: inherit;

    &-wrapper {
      display: flex;
      align-items: center;
    }

    &-icon {
      margin-right: 0.5rem;
    }

    &-badge {
      display: inline-block;
      padding: 0.25rem 0.75rem;
      border-radius: 9999px;
      margin-right: 0.5rem;
      margin-left: .8rem;
      font-weight: 600;
      font-size: 1.3rem;

      &--correct {
        background-color: #d1fae5;
        color: #065f46;
      }

      &--incorrect {
        background-color: #fee2e2;
        color: #b91c1c;
      }

      &--partial {
        background-color: #fef3c7;
        color: #92400e;
      }

      &--skipped {
        background-color: #e5e7eb;
        color: #6b7280;
      }
    }

  }
}

.Question__title-text {
  display: inline;
  max-width: 300px;
  font-size: 1.3rem;
  color: #333333;
  cursor: pointer;

  @media (min-width: 1024px) {
    max-width: 400px;
  }
}

.Question__content {
  margin-top: 1rem;
  font-size: 1rem;

  .Question__content__answer,
  .Question__feedback {
    padding: 1rem;
    border-radius: 0.5rem;

    &-title {
      display: flex;
      align-items: center;
      gap: 0.5rem;
      font-weight: 800;
      font-size: 1.4rem;
      margin-bottom: 0.5rem;
      color: inherit;
    }

    &-text {
      font-size: 1.4rem;
      font-weight: 500;
      word-break: break-word;
    }

    &-icon {
      font-size: 1rem;
    }
  }

  .Question__content__answer {
    background-color: #ebf5f9;

    &-title-text {
      font-size: 1.4rem;
      font-weight: 800;
    }
  }

  .Question__feedback {
    margin-top: 1rem;
    background-color: #fef3c7;
    
    &-title-text {
      font-size: 1.4rem;
      font-weight: 800;
    }

    &--correct {
      .Question__feedback-title {
        color: #22c55e;
      }
    }

    &--incorrect {
      .Question__feedback-title {
        color: #ef4444;
      }
    }

    &--partial {
      .Question__feedback-title {
        color: #f59e0b;
      }
    }
  }
}

.Question__icon {
  color: #6b7280;
  font-size: 1.25rem;
}
